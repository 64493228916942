// Copy from https://gitlab.com/fluv/fluv_global/-/blob/main/src/screens/pets/PetProfileEditView.tsx#L1-731
import React, { useCallback, useEffect, useState } from "react";
import { View, ScrollView, KeyboardAvoidingView, Platform, StyleSheet, Dimensions } from "react-native";
import { BaseStyle, useTheme } from "../../config";
import { SafeAreaView, Icon, SelectModal } from "../../components";
import { Image, Text } from "../../ui";
import { useTranslation } from "react-i18next";
import { BaseColor } from "../../config";
import { TouchableOpacity } from "react-native-gesture-handler";
import { getCertificatePictureUrl, TCertificateInput } from "../../models";
import { CertificateType, CertificateTypeUtil } from "../../enums";
import { CERTIFICATE_CATEGORIES } from "../../constant/certificatesCategory";
import * as ImagePicker from "expo-image-picker";
import { useAuth } from "../../context/Auth";
import { minWidth } from "../../config/theme";
import { usePromise } from "../../hooks";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height * 0.6;

type Props = {
  defaultInput?: TCertificateInput; // for update
  onChangeInput: (input: TCertificateInput | null) => void;
  onDelete?: () => void;
};

export default React.memo<Props>((props) => {
  const { defaultInput, onChangeInput, onDelete } = props;
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { userId } = useAuth();
  const offsetKeyboard = Platform.select({
    ios: 0,
    android: 20,
  });

  // Copy from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/screens/certificate/CertificateEditableView.tsx#L40-42
  const [type, setType] = useState<CertificateType>(defaultInput?.type || 0);
  const [category, setCategory] = useState<string | undefined>(defaultInput?.category || "");
  const [pictureUriOrId, setPictureUriOrId] = useState<string>(defaultInput?.pictureUriOrId || "");

  const [onOpenTypeModal, setOnOpenTypeModal] = useState(false);
  const [onOpenCategoryModal, setOnOpenCategoryModal] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  // For update
  useEffect(() => {
    if (!defaultInput) return;
    const { type, category, pictureUriOrId } = defaultInput;
    setType(type);
    setCategory(category);
    setPictureUriOrId(pictureUriOrId);
  }, [defaultInput]);

  const isTypesHaveCategory = [CertificateType.PET_GROOMER, CertificateType.PET_SITTER].includes(type);

  // Copy from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/screens/certificate/CertificateEditableView.tsx#L44-54
  useEffect(() => {
    if (type && (!isTypesHaveCategory || category) && pictureUriOrId) {
      onChangeInput({ type, category, pictureUriOrId });
    } else {
      onChangeInput(null);
    }
  }, [type, category, pictureUriOrId]);

  const certificateTypeOptions = CertificateTypeUtil.list().map((ct) => ({
    text: t(`license:certificate_type_${CertificateType[ct]}`),
    value: ct,
  }));
  certificateTypeOptions.unshift({ text: t("certificate:select_type"), value: 0 });

  const certificateCategoryOptions = type
    ? CERTIFICATE_CATEGORIES[type].map((cc) => ({
        text: t(`license:certificate_category_${cc}`),
        value: cc,
      }))
    : [];
  certificateCategoryOptions.unshift({ text: t("certificate:select_category"), value: "" });

  const handleChangeCertificateType = useCallback((option) => {
    setType(option.value);
  }, []);

  const handleChangeCertificateCategory = useCallback((option) => {
    setCategory(option.value);
  }, []);

  const handleSelectPicture = useCallback(async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert("沒有取得您的權限");
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({ allowsEditing: true, aspect: [1, 1] });
      if (!result.cancelled) {
        setPictureUriOrId(result.uri);
      }
    }
  }, []);

  const PictureUrl = usePromise(() => {
    setIsLoadingImage(true);
    return getCertificatePictureUrl(userId, pictureUriOrId).finally(() => setIsLoadingImage(false));
  }, [userId, pictureUriOrId]);

  const isUpdateMode = !!defaultInput;
  const shouldRenderCategory = (isUpdateMode && category) || (!!type && isTypesHaveCategory);

  return (
    <View
      style={[
        BaseStyle.safeAreaView,
        {
          backgroundColor: colors.card,
        },
      ]}
    >
      <SafeAreaView style={BaseStyle.safeAreaView} edges={["right", "left", "bottom"]}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "android" ? "height" : "padding"}
          keyboardVerticalOffset={offsetKeyboard}
          style={{ flex: 1 }}
        >
          <ScrollView contentContainerStyle={styles.contain}>
            {/* Type */}
            <View style={styles.buttonContainer}>
              <View>
                <Text body2 semibold>
                  {t("certificate:type")}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  disabled={isUpdateMode}
                  style={styles.selectButton}
                  onPress={() => {
                    setOnOpenTypeModal(true);
                  }}
                >
                  {/* @ts-ignore */}
                  <Text grayColor={type === 0 ? true : false}>
                    {isUpdateMode
                      ? t(`license:certificate_type_${CertificateType[type!]}`)
                      : certificateTypeOptions.filter((option) => option.value === type)[0]?.text}
                  </Text>
                  {!isUpdateMode && <Icon name="angle-down" size={18} style={{ marginLeft: 5 }} />}
                </TouchableOpacity>
              </View>
              <SelectModal
                visible={onOpenTypeModal}
                options={certificateTypeOptions}
                selected={certificateTypeOptions.filter((option) => option.value === type)[0]}
                onChange={(item: any) => {
                  setOnOpenTypeModal(false);
                  handleChangeCertificateType(item);
                }}
              />
            </View>

            {/* Category */}
            {shouldRenderCategory && (
              <View style={styles.buttonContainer}>
                <View>
                  <Text body2 semibold>
                    {t("certificate:category")}
                  </Text>
                </View>
                <View>
                  <TouchableOpacity
                    disabled={!!defaultInput}
                    style={styles.selectButton}
                    onPress={() => {
                      setOnOpenCategoryModal(true);
                    }}
                  >
                    {/* @ts-ignore */}
                    <Text grayColor={category === "" ? true : false}>
                      {isUpdateMode
                        ? t(`license:certificate_category_${category}`)
                        : certificateCategoryOptions.filter((option) => option.value === category)[0]?.text}
                    </Text>
                    {!isUpdateMode && <Icon name="angle-down" size={18} style={{ marginLeft: 5 }} />}
                  </TouchableOpacity>
                </View>
                <SelectModal
                  visible={onOpenCategoryModal}
                  options={certificateCategoryOptions}
                  selected={certificateCategoryOptions.filter((option) => option.value === category)[0]}
                  onChange={(item: any) => {
                    setOnOpenCategoryModal(false);
                    handleChangeCertificateCategory(item);
                  }}
                />
              </View>
            )}

            {/* Picture */}
            <View style={[styles.buttonContainer, { justifyContent: "center" }]}>
              <TouchableOpacity onPress={handleSelectPicture}>
                {!isLoadingImage && !!PictureUrl && (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={{ uri: PictureUrl }}
                      style={{ height: screenHeight, width: screenWidth }}
                      resizeMode="contain"
                    />
                  </View>
                )}
                <View style={{ justifyContent: "center", alignItems: "center", paddingVertical: 10 }}>
                  <Text darkPrimaryColor>{t("certificate:select_picture")}</Text>
                </View>
              </TouchableOpacity>
            </View>

            {/* Delete */}
            {isUpdateMode && (
              <View style={[styles.buttonContainer, { justifyContent: "center" }]}>
                <TouchableOpacity onPress={onDelete}>
                  <View style={{ justifyContent: "center", alignItems: "center", paddingVertical: 10 }}>
                    <Text dangerColor>{t("common:delete")}</Text>
                  </View>
                </TouchableOpacity>
              </View>
            )}
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </View>
  );
});

const styles = StyleSheet.create({
  contain: {
    alignItems: "center",
    paddingVertical: 5,
  },
  selectButton: {
    padding: 10,
    height: 46,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    color: BaseColor.grayColor,
    borderRadius: 5,
    flexDirection: "row",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: BaseColor.whiteColor,
    paddingHorizontal: 20,
    marginVertical: 5,
  },
});
