import { getAuth } from "firebase/auth";
import { CLOUD_FUNCTION_URL } from "../constant/backend";

export type PrivateAPIResult<T> = {
  success: boolean;
  data?: T;
  error?: string;
};

export const PrivateAPI = {
  post: async <T>(
    path: string,
    payload: { [key: string]: unknown }
  ): Promise<PrivateAPIResult<T> | null> => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) return null;

    const idToken = await currentUser.getIdToken(true);
    const result = await fetch(`https://apiservice.fluv.com/cloudfunction`, {
      method: "POST",
      body: JSON.stringify({
        payload,
        idToken,
        path: `${CLOUD_FUNCTION_URL}/${path}`,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((r) => r.json());
    return result;
  },
  get: async <T>(
    path: string,
    payload: { [key: string]: unknown }
  ): Promise<any> => {
    const { userId, orderId, email, productName } = payload;
    const result = await fetch(
      `http://localhost:3000/payment?path=${path}&userId=${userId}&orderId=${orderId}&email=${email}&productName=${productName}`,
      {
        headers: {
          "Content-Type": "text/html; charset=utf-8",
          Accept: "text/html",
        },
      }
    ).then((d) => d.text());
    return result;
  },
};
