import { getToken, getMessaging, onMessage } from "firebase/messaging";
import React, { useEffect } from "react";
import { useAuth } from "../context/Auth";
import { updateDeviceFcmToken, getCurrentUserId } from "../models";

export default function BackgroundMessage() {
  useAuth();
  const userId = getCurrentUserId();

  useEffect(() => {
    (async () => {
      if (!userId) return;

      const messaging = getMessaging();

      let currentToken;

      try {
        currentToken = await getToken(messaging, {
          vapidKey: process.env.FIREBASE_FCM_VAPID_KEY
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        // https://github.com/firebase/quickstart-js/blob/master/messaging/index.html#L114
        console.error("BQT3");
        throw e;
        // ...
      }

      if (!currentToken) {
        // Show permission request UI
        // https://github.com/firebase/quickstart-js/blob/master/messaging/index.html#L108
        console.error("VFY7");
        throw new Error("VFY7");
      }

      try {
        await updateDeviceFcmToken(userId, currentToken);
      } catch (e) {
        console.error("T7PG");
        throw e;
      }
    })();
  }, [userId]);
  return null;
}
