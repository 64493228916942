// import appenv from '../appenv';

// export const WEB_URL = (() => {
//   if (appenv.isProd) {
//     return 'https://fluv.com';
//   }
//   if (appenv.isStaging) {
//     return 'https://skyeischihuahua.fluv.com';
//   }
//   return 'https://skyeischihuahua.fluv.com'; // local debug
// })();

// export const DYNAMIC_LINK_DOMAIN = (() => {
//   if (appenv.isProd) {
//     return 'https://fluv.me';
//   }
//   if (appenv.isStaging) {
//     return 'https://skyeischihuahua.fluv.me';
//   }
//   return 'https://skyeischihuahua.fluv.me'; // local debug
// })();

// export const PACKAGE_NAME = (() => {
//   if (appenv.isProd) {
//     return 'com.ifluvyou.fluv';
//   }
//   if (appenv.isStaging) {
//     return 'com.ifluvyou.fluv.staging';
//   }
//   return 'com.ifluvyou.fluv.staging'; // local debug
// })();

// export const FIREBASE_STORAGE_BUCKET = (() => {
//   if (appenv.isProd) {
//     return 'fluv-prod.appspot.com';
//   }
//   if (appenv.isStaging) {
//     return 'fluv-dev.appspot.com';
//   }
//   return 'fluv-dev.appspot.com'; // local debug
// })();

// export const FACEBOOK_CONFIG = (() => {
//   return {
//     appId: '463534051134043',
//   };
// })();

// export const GOOGLE_SERVICE_API_KEY = (() => {
//   return 'AIzaSyA1y5qxy2wLlKPzV_7H7mVF9-P0WQ5pcc0';
// })();

// export const CLOUD_FUNCTION_URL = (() => {
//   if (appenv.isProd) {
//     return 'https://us-central1-fluv-prod.cloudfunctions.net';
//   }
//   if (appenv.isStaging) {
//     return 'https://us-central1-fluv-dev.cloudfunctions.net';
//   }
//   return 'https://us-central1-fluv-dev.cloudfunctions.net';
// })();

// export const APPLE_STORE_ID = (() => {
//   if (appenv.isProd) {
//     return '1478414083';
//   }
//   if (appenv.isStaging) {
//     return '1477232424';
//   }
//   return '1477232424';
// })();

// export const APPLE_STORE_PROVIDER_TOKEN = (() => {
//   if (appenv.isProd) {
//     return '120360302';
//   }
//   if (appenv.isStaging) {
//     return ''; // no itunes analytics
//   }
//   return ''; // no itunes analytics
// })();

// export const OFFICIAL_ACCOUNT_ID = (() => {
//   if (appenv.isProd) {
//     return '!18D0gfK4puq1Ep25wgqx';
//   }
//   if (appenv.isStaging) {
//     return '!zZd0uPhprENx2o8VB4sV';
//   }
//   return '!zZd0uPhprENx2o8VB4sV';
// })();

import appenv from "../appenv";

export const WEB_URL = (() => {
  if (appenv.isProd) {
    return "https://fluv.com";
  }
  if (appenv.isStaging) {
    return "https://fluv.com";
  }
  return "https://fluv.com"; // local debug
})();

export const DYNAMIC_LINK_DOMAIN = (() => {
  if (appenv.isProd) {
    return "https://fluv.me";
  }
  if (appenv.isStaging) {
    return "https://fluv.me";
  }
  return "https://fluv.me"; // local debug
})();

export const PACKAGE_NAME = (() => {
  if (appenv.isProd) {
    return "com.ifluvyou.fluv";
  }
  if (appenv.isStaging) {
    return "com.ifluvyou.fluv";
  }
  return "com.ifluvyou.fluv"; // local debug
})();

export const FIREBASE_STORAGE_BUCKET = (() => {
  if (appenv.isProd) {
    return "fluv-prod.appspot.com";
  }
  if (appenv.isStaging) {
    return "fluv-prod.appspot.com";
  }
  return "fluv-prod.appspot.com"; // local debug
})();

export const FACEBOOK_CONFIG = (() => {
  return {
    appId: "463534051134043",
  };
})();

export const GOOGLE_SERVICE_API_KEY = (() => {
  return "AIzaSyA1y5qxy2wLlKPzV_7H7mVF9-P0WQ5pcc0";
})();

export const CLOUD_FUNCTION_URL = (() => {
  if (appenv.isProd) {
    return "https://us-central1-fluv-prod.cloudfunctions.net";
  }
  if (appenv.isStaging) {
    return "https://us-central1-fluv-prod.cloudfunctions.net";
  }
  return "https://us-central1-fluv-prod.cloudfunctions.net";
})();

export const APPLE_STORE_ID = (() => {
  if (appenv.isProd) {
    return "1478414083";
  }
  if (appenv.isStaging) {
    return "1478414083";
  }
  return "1478414083";
})();

export const APPLE_STORE_PROVIDER_TOKEN = (() => {
  if (appenv.isProd) {
    return "120360302";
  }
  if (appenv.isStaging) {
    return ""; // no itunes analytics
  }
  return ""; // no itunes analytics
})();

export const OFFICIAL_ACCOUNT_ID = (() => {
  if (appenv.isProd) {
    return "!18D0gfK4puq1Ep25wgqx";
  }
  if (appenv.isStaging) {
    return "!18D0gfK4puq1Ep25wgqx";
  }
  return "!18D0gfK4puq1Ep25wgqx";
})();
