import { StyleSheet, Text, View } from "react-native";
import React, { ReactNode } from "react";
import { BaseColor, useTheme } from "../../../config";
import { Icon } from "../../../components";
import { Flex } from "../../../ui";

type Props = {
  iconName: string;
  text: string;
  content: ReactNode;
};

const FieldSubRow = React.memo<Props>((props) => {
  const { iconName, text, content } = props;
  const { colors } = useTheme();
  return (
    <Flex.Row
      paddingTop={4}
      paddingBottom={8}
      paddingHorizontal={4}
      vCenter
      between
    >
      <Flex.Row vCenter paddingLeft={28}>
        <Icon size={16} name={iconName} color={BaseColor.dividerColor} />
        <Flex paddingLeft={12}>
          <Text style={styles.rowSubTitle}>{text}</Text>
        </Flex>
      </Flex.Row>
      <Flex fill hEnd paddingLeft={12}>
        {content}
      </Flex>
    </Flex.Row>
  );
});

export default FieldSubRow;

const styles = StyleSheet.create({
  rowSubTitle: {
    color: "#212121",
    fontSize: 15,
  },
});
