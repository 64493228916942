import { StyleSheet, Text, View } from "react-native";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { WebView } from "react-native-webview";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/Auth";
import { useSubscription } from "../../hooks";
import { getCurrentUserId, subscribeOrder } from "../../models";
import validate from "validate.js";
import { CLOUD_FUNCTION_URL } from "../../constant/backend";
import { ServiceType } from "../../enums";
import { PrivateAPI } from "../../models/api";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";
import { isFeatureEnabled } from "../../models/_config";

const UIDS = {
  AMY: "1YTuWjqlcfVvcFSmc0gjQZFvDiA2",
  PARENT: "JOCxzNvybLbM9u50fxIwwu3M3S82",
};

const PaymentScreen = ({ route }: any) => {
  const { orderId } = route.params;
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { t } = useTranslation();
  let { userId } = useAuth();
  userId = getCurrentUserId();

  const order = useSubscription({
    subscription: subscribeOrder,
    variables: {
      orderId,
    },
  });

  const { paid, snapshots } = order || {};
  const { service, consumerProfile } = snapshots || {};
  const { type } = service || {};

  useEffect(() => {
    if (paid) {
      // navigation.navigate("")
      // NavigationService.dismiss('PaymentModal');
      // LoadingSpinner.hide();
    }
  }, [paid]);

  const handleLoadStart = useCallback(() => {
    // LoadingSpinner.show();
  }, []);

  const handleLoadEnd = useCallback(() => {
    // LoadingSpinner.hide();
  }, []);

  const validateEmail = useCallback((email?: string) => {
    if (!email) return false;
    return !validate({ from: email }, { from: { email: true } });
  }, []);

  let contactEmail: string | undefined;

  if (consumerProfile) {
    const cunsumerEmail =
      consumerProfile.email ||
      consumerProfile.facebookEmail ||
      consumerProfile.appleEmail;
    if (validateEmail(cunsumerEmail)) {
      contactEmail = cunsumerEmail;
    } else {
      contactEmail = "";
    }
  }

  const fetchPayment = async () => {
    if (!!userId && !!orderId && contactEmail !== undefined && type) {
      const pathname = await isFeatureEnabled("86eq75fy7") || Object.values(UIDS).includes(userId) ? "/creditCard" : "/payment";
      window.open(
        `${CLOUD_FUNCTION_URL}${pathname}?userId=${userId}&orderId=${orderId}&email=${contactEmail}&productName=${t(
          `enum:type_${ServiceType[type]}`
        )}`,
        "_blank"
      );
      navigation.navigate("OrderDetailScreen", { orderId });
    }
    return <></>;
  };

  useLayoutEffect(() => {
    fetchPayment();
  }, [userId, orderId, contactEmail, type]);
  return (
    <View style={{ flex: 1 }}>
      {/* {!!userId && !!orderId && contactEmail !== undefined && type && (
        // app using
        // <WebView
        //   source={{
        //     uri: `${CLOUD_FUNCTION_URL}/payment?userId=${userId}&orderId=${orderId}&email=${contactEmail}&productName=${t(`enum:type_${ServiceType[type]}`,)}`,
        //   }}
        //   onLoadStart={handleLoadStart}
        //   onLoadEnd={handleLoadEnd}
        // />
        //
      )} */}
    </View>
  );
};

export default PaymentScreen;

const styles = StyleSheet.create({});
