
import common from "../../public/locales/zh-TW/common.json";
import sign_in from "../../public/locales/zh-TW/sign_in.json";
import tabs from "../../public/locales/zh-TW/tabs.json";
import search from "../../public/locales/zh-TW/search.json";
import enumJson from "../../public/locales/zh-TW/enum.json";
import license from "../../public/locales/zh-TW/license.json";
import campaign from "../../public/locales/zh-TW/campaign.json";
import page from "../../public/locales/zh-TW/page.json";
import preview_reserve from "../../public/locales/zh-TW/preview_reserve.json";
import reserve from "../../public/locales/zh-TW/reserve.json";
import profile from "../../public/locales/zh-TW/profile.json";
import messenger from "../../public/locales/zh-TW/messenger.json";
import pets from "../../public/locales/zh-TW/pets.json";
import sitter_profile from "../../public/locales/zh-TW/sitter_profile.json";
import service_detail from "../../public/locales/zh-TW/service_detail.json";
import order from "../../public/locales/zh-TW/order.json";
import review from "../../public/locales/zh-TW/review.json";
import coupon from "../../public/locales/zh-TW/coupon.json";
import order_notification from "../../public/locales/zh-TW/order_notification.json";
import chat from "../../public/locales/zh-TW/chat.json";
import menu from "../../public/locales/zh-TW/menu.json";
import service from "../../public/locales/zh-TW/service.json";
import sitter_verifi from "../../public/locales/zh-TW/sitter_verifi.json";
import certificate from "../../public/locales/zh-TW/certificate.json";

export default {
  common,
  sign_in,
  tabs,
  search,
  enum: enumJson,
  license,
  campaign,
  page,
  preview_reserve,
  reserve,
  profile,
  messenger,
  pets,
  sitter_profile,
  service_detail,
  order,
  review,
  coupon,
  order_notification,
  chat,
  menu,
  service,
  sitter_verifi,
  certificate,
};

