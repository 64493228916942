import { Images } from "../config/images";

export default [
  {
    id: "1",
    image: Images.topsitter.sitter4,
    name: "のどか",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "July 19th 2019",
    price: "$1500,99",
    rate: 4,
    rateCount: "85 of 100",
    numReviews: 100,
    author: {
      image: Images.topsitter.sitter4,
      point: "9.5",
      name: "by Steve Garrett",
    },
    tags: [
      { icon: "certificate", name: "domestic_dog_trainer" },
      { icon: "id-card", name: "trimmer" },
    ],
  },
  {
    id: "2",
    image: Images.topsitter.sitter3,
    name: "まりん",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "July 19th 2019",
    price: "$1500,99",
    rate: 4,
    rateCount: "85 of 100",
    numReviews: 100,
    author: {
      image: Images.topsitter.sitter3,
      point: "9.5",
      name: "by Steve Garrett",
    },
    tags: [
      { icon: "certificate", name: "domestic_dog_trainer" },
      { icon: "id-card", name: "animal_assisted_welfare_worker" },
      { icon: "id-card", name: "trimmer" },
    ],
  },
  {
    id: "3",
    image: Images.topsitter.sitter2,
    name: "ゆめ",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "July 19th 2019",
    price: "$1500,99",
    rate: 4,
    rateCount: "85 of 100",
    numReviews: 100,
    author: {
      image: Images.topsitter.sitter2,
      point: "9.5",
      name: "by Steve Garrett",
    },
    tags: [
      { icon: "certificate", name: "domestic_dog_trainer" },
      { icon: "id-card", name: "animal_assisted_welfare_worker" },
      { icon: "id-card", name: "trimmer" },
    ],
  },
  {
    id: "4",
    image: Images.topsitter.sitter1,
    name: "あかり",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "July 19th 2019",
    price: "$1500,99",
    rate: 4,
    rateCount: "85 of 100",
    numReviews: 100,
    author: {
      image: Images.topsitter.sitter1,
      point: "9.5",
      name: "by Steve Garrett",
    },
    tags: [
      { icon: "certificate", name: "domestic_dog_trainer" },
      { icon: "id-card", name: "animal_assisted_welfare_worker" },
      { icon: "id-card", name: "trimmer" },
    ],
  },
];
