import React, { useEffect, useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { AsyncStorage } from "../components";
import { auth } from "../config/firebase";

export function useAuthentication() {
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    const unsubscribeFromAuthStatuChanged = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);
        AsyncStorage.setItem(
          "Auth",
          JSON.stringify({
            currentUserId: user.uid,
          })
        );
      } else {
        // User is signed out
        setUser(undefined);
      }
    });

    return unsubscribeFromAuthStatuChanged;
  }, []);

  return {
    user,
  };
}
