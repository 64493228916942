import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Text } from "../ui";
import { useTheme, Images } from "../config";
import { useTranslation } from "react-i18next";

export default function ReserveItem(props: any) {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { style, name, startDate, endDate, price, total, onPress } = props;

  return (
    <TouchableOpacity
      style={[styles.contain, { shadowColor: colors.border }, style]}
      onPress={onPress}
      activeOpacity={0.9}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Image source={Images.homeBanner} style={{width: 100, height: 100, borderRadius: 10, marginRight: 10}}/> */}
        <View style={{ flexDirection: "column", width: "100%" }}>
          <View
            style={[
              styles.nameContent,
              {
                borderBottomColor: colors.card,
                backgroundColor: colors.accent,
              },
            ]}
          >
            <Text body2 whiteColor semibold>
              {t(`enum:type_${name}`)}
            </Text>
          </View>
          <View
            style={[styles.mainContent, { backgroundColor: colors.accent }]}
          >
            <View style={{ flex: 1, alignItems: "flex-start" }}>
              <Text caption2 whiteColor>
                {t("enum:start_date")}
              </Text>
              <Text body1 whiteColor semibold>
                {startDate}
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end" }}>
              <Text caption2 whiteColor>
                {t("enum:end_date")}
              </Text>
              <Text body1 whiteColor semibold>
                {endDate}
              </Text>
            </View>
          </View>
          <View style={[styles.validContent, { backgroundColor: colors.card }]}>
            <Text overline semibold>
              {total}
            </Text>
            <Text overline semibold>
              {price}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contain: {
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 1.0,
    elevation: 5,
  },
  nameContent: {
    alignItems: "flex-start",
    borderBottomWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  validContent: {
    flexDirection: "row",
    paddingHorizontal: 12,
    paddingVertical: 7,
    justifyContent: "space-between",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  mainContent: {
    paddingHorizontal: 12,
    paddingVertical: 20,
    flexDirection: "row",
  },
});
