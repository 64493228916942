import { StatusBar, StatusBarStyle, StyleSheet, View } from "react-native";
import React, { ReactNode, useMemo } from "react";
import { useMemoStyles } from "../config/theme";
import { Edge, SafeAreaView } from "react-native-safe-area-context";

export type ScreenProps = {
  variant?: string;
  padding?: boolean;
  topSafe?: boolean;
  bottomSafe?: boolean;
  leftSafe?: boolean;
  rightSafe?: boolean;
  statusBar?: {
    barStyle?: StatusBarStyle;
    networkActivityIndicatorVisible?: boolean;
    showHideTransition?: "fade" | "slide";
    backgroundColor?: string;
    translucent?: boolean;
    animated?: boolean;
    hidden?: boolean;
  };
  children: ReactNode;
};

export default React.memo<ScreenProps>((props) => {
  const {
    padding,
    topSafe,
    bottomSafe,
    leftSafe,
    rightSafe,
    statusBar: statusBarProps,
    children,
  } = props;
  const finalStyle = useMemoStyles([styles.root]);
  const finalSafeAreaStyle = useMemoStyles([styles.safeArea]);
  const finalInnerStyle = useMemoStyles([
    styles.inner,
    padding ? styles.padding : {},
  ]);

  const edges: Edge[] = useMemo(() => {
    const edgesList: Edge[] = [];
    topSafe ? edgesList.push("top") : null;
    bottomSafe ? edgesList.push("bottom") : null;
    leftSafe ? edgesList.push("left") : null;
    rightSafe ? edgesList.push("right") : null;
    return edgesList;
  }, [topSafe, bottomSafe, leftSafe, rightSafe]);

  return (
    <View style={finalStyle}>
      <SafeAreaView edges={edges} style={[finalSafeAreaStyle, styles.safeArea]}>
        <View style={finalInnerStyle}>{children}</View>
      </SafeAreaView>
      <StatusBar {...statusBarProps} />
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
    overflow: "hidden",
  },
  safeArea: {
    flex: 1,
  },
  inner: {
    flex: 1,
  },
  padding: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
});
