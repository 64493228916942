import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { BaseStyle, useTheme } from "../../config";
import { Header, SafeAreaView, Icon } from "../../components";
import { Text, TextInput, Image } from "../../ui";
import { useTranslation } from "react-i18next";
import { BaseColor } from "../../config";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useAuth } from "../../context/Auth";
import * as ImagePicker from "expo-image-picker";
import {
  getProfile,
  getProfilePictureUrl,
  updateProfile,
  getCurrentUserId,
} from "../../models";
import { usePromise } from "../../hooks";
import { minWidth } from "../../config/theme";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";
import PhoneVerifications from "../../models/PhoneVerifications";
import PhoneVerificationModal from "../../components/PhoneVerificationModal";
import { PhoneVerification } from "../../api-typescript-axios-client-generated/models";

export default function ProfileEditScreen({ route }: any) {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  // Following existing convent using `useAuth` to ensure `getAuth` in `getCurrentUserId` works at some point.
  useAuth();
  const userId = getCurrentUserId();
  const currentUserProfile = usePromise(async () => {
    if (!userId) return;
    return await getProfile(userId);
  }, [userId]);

  const offsetKeyboard = Platform.select({
    ios: 0,
    android: 20,
  });

  const [avatarUriOrId, setAvatarUriOrId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [facebookEmail, setFacebookEmail] = useState("");
  const [phoneVerification, setPhoneVerification] =
    useState<PhoneVerification | null>(null);
  const [error, setError] = useState("");
  const [phoneVerificationModalShown, setPhoneVerificationModalShown] =
    useState(false);
  const [phoneVerificationCode, setPhoneVerificationCode] = useState("");
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    if (!currentUserProfile) return;
    setAvatarUriOrId(
      currentUserProfile.avatarId !== undefined
        ? currentUserProfile.avatarId
        : ""
    );
    setName(
      currentUserProfile.displayName !== undefined
        ? currentUserProfile.displayName
        : ""
    );
    setDescription(
      currentUserProfile.description !== undefined
        ? currentUserProfile.description
        : ""
    );
    setEmail(
      currentUserProfile.email !== undefined ? currentUserProfile.email : ""
    );
    setPhone(
      currentUserProfile.phone !== undefined ? currentUserProfile.phone : ""
    );
    setFacebookEmail(
      currentUserProfile.facebookEmail !== undefined
        ? currentUserProfile.facebookEmail
        : ""
    );
  }, [currentUserProfile]);

  useEffect(() => {
    const getPhoneVerification = async () => {
      setLoading(true);

      try {
        const { data }: { data: PhoneVerification } =
          await PhoneVerifications.get();

        setPhoneVerification(data);
      } catch (e: any) {
        switch (e.message) {
          case "88RZ":
          case "F46A":
            console.error(e);
            break;

          default:
            console.error(e);
            throw e;
        }
      } finally {
        setLoading(false);
      }
    };

    getPhoneVerification();
  }, [currentUserProfile?.phone]);

  let isPhoneVerified = false;
  if (phoneVerification) {
    isPhoneVerified = phoneVerification.verifiedAt !== 0;
  }

  const handleSelectAvatar = useCallback(async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert("沒有取得您的權限，請打開您的權限");
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: true,
        aspect: [1, 1],
      });
      if (!result.cancelled) {
        setAvatarUriOrId(result.uri);
      }
    }
  }, []);

  const handleChangeName = useCallback((text) => {
    setName(text);
  }, []);

  const handleChangeDiscription = useCallback((text) => {
    setDescription(text);
  }, []);

  const handleChangeEmail = useCallback((text) => {
    setEmail(text);
  }, []);

  const handleChangePhone = useCallback((text) => {
    setPhone(text);
  }, []);

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("UserTab", { screen: "Home" });
    }
  };

  const handlePhoneVerification = async (code?: string) => {
    if (phoneVerificationModalShown) {
      setModalLoading(true);
    } else {
      setLoading(true);
    }

    try {
      const res = await PhoneVerifications.post(code);

      if (res.success) {
        const { data } = await PhoneVerifications.get();

        setPhoneVerification(data);

        const { verifiedAt } = data;

        setPhoneVerificationModalShown(!verifiedAt || verifiedAt === 0);
      }
    } catch (e: any) {
      switch (e.message) {
        case "D8RA":
          setError(e.message);
          break;

        case "error:resend_within_60s":
        case "6ZAD":
          setError(e.message);
          setPhoneVerificationModalShown(true);
          break;

        default:
          console.error(e);
          throw e;
      }
    } finally {
      setModalLoading(false);
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);

    try {
      await updateProfile({
        coverUriOrId: currentUserProfile?.coverId,
        avatarUriOrId: avatarUriOrId,
        displayName: name,
        description: description,
        email: email,
        phone: phone,
      });

      const { data } = await PhoneVerifications.get();

      if (data && data.verifiedAt === 0) {
        alert(t("Please verify your phone number。"));
      }
    } catch (e: any) {
      switch (e.message) {
        case "F46A":
          alert(t("Please verify your phone number。"));
          break;

        default:
          console.error(e);
          throw e;
      }
    }
    setTimeout(() => {
      setLoading(false);
      handleGoBack();
    }, 1500);
  };

  return (
    <View style={{ flex: 1, backgroundColor: colors.card }}>
      <LoadingSpinner visible={loading} />
      <Header
        style={BaseStyle.menu}
        titleStyle={{ color: BaseColor.whiteColor }}
        title={t("profile:edit")}
        subTitle={""}
        renderLeft={() => {
          return (
            <Icon name="arrow-left" size={20} color={BaseColor.whiteColor} />
          );
        }}
        onPressLeft={handleGoBack}
        renderRight={() => {
          return (
            <View>
              <Text whiteColor bold>
                {t("common:update")}
              </Text>
            </View>
          );
        }}
        onPressRight={handleUpdate}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "android" ? "height" : "padding"}
          keyboardVerticalOffset={offsetKeyboard}
          style={{ flex: 1 }}
        >
          <ScrollView contentContainerStyle={styles.contain}>
            <View
              style={{
                backgroundColor: BaseColor.whiteColor,
                width: "100%",
                minWidth: minWidth,
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 12,
                marginVertical: 10,
              }}
            >
              {/* image */}
              <TouchableOpacity onPress={handleSelectAvatar}>
                {avatarUriOrId !== "" ? (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Image
                      source={{
                        uri: getProfilePictureUrl(userId, avatarUriOrId),
                      }}
                      style={{ width: 80, height: 80, borderRadius: 40 }}
                    />
                  </View>
                ) : (
                  <></>
                )}
                <View>
                  <Text darkPrimaryColor>{t("profile:select_avatar")}</Text>
                </View>
              </TouchableOpacity>
            </View>

            {/* displayName */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginVertical: 5,
                paddingVertical: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("profile:display_name")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                }}
              >
                <TextInput
                  onChangeText={handleChangeName}
                  onFocus={() => {}}
                  placeholder={t("profile:display_name_placeholder")}
                  value={name}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
            </View>

            {/* description */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginVertical: 5,
                paddingVertical: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("profile:description")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                }}
              >
                <TextInput
                  onChangeText={handleChangeDiscription}
                  onFocus={() => {}}
                  placeholder={t("profile:description_placeholder")}
                  value={description}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
            </View>

            {/* email and phone */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginVertical: 5,
                paddingVertical: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("profile:contact_info")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                  flexDirection: "column",
                }}
              >
                {facebookEmail && <Text>Facebook Email: {facebookEmail}</Text>}
              </View>
              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                  flexDirection: "column",
                }}
              >
                <View style={{ marginBottom: 10 }}>
                  <Text>信箱</Text>
                </View>
                <TextInput
                  onChangeText={handleChangeEmail}
                  onFocus={() => {}}
                  placeholder={"ifluvyou@gmail.com"}
                  value={email}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                  flexDirection: "column",
                }}
              >
                <View style={{ marginBottom: 10, flexDirection: "row" }}>
                  <Text style={{ paddingRight: 5 }}>電話</Text>
                  <Text primaryColor>{isPhoneVerified && t("Verified")}</Text>
                </View>
                <TextInput
                  onChangeText={handleChangePhone}
                  onFocus={() => {}}
                  placeholder={"09123456789"}
                  value={phone}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
                {!isPhoneVerified &&
                  (phone === "" ? (
                    <Text grayColor>{t("Send verification code")}</Text>
                  ) : (
                    <TouchableOpacity onPress={() => handlePhoneVerification()}>
                      <Text primaryColor>{t("Send verification code")}</Text>
                    </TouchableOpacity>
                  ))}
                {error === "D8RA" && (
                  <Text style={{ paddingTop: 5 }} dangerColor bold>
                    {t(
                      "If you don't receive the verification code, please ensure your phone number is correct."
                    )}
                  </Text>
                )}
              </View>
              <PhoneVerificationModal
                code={phoneVerificationCode}
                error={error}
                loading={modalLoading}
                onChangeCode={setPhoneVerificationCode}
                onDismiss={setPhoneVerificationModalShown}
                onVerify={handlePhoneVerification}
                phone={currentUserProfile?.phone}
                phoneVerification={phoneVerification}
                visable={phoneVerificationModalShown}
              />
            </View>
            <View style={{ paddingHorizontal: 20 }}>
              <Text>{t("profile:emergency_contact_info_tips")}</Text>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentTitle: {
    alignItems: "flex-start",
    width: "100%",
    height: 32,
    justifyContent: "center",
  },
  contain: {
    alignItems: "center",
  },
  textInput: {
    height: 46,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 5,
    padding: 10,
    width: "100%",
    color: BaseColor.grayColor,
  },
  thumb: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 20,
  },
});
