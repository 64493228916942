import { StackNavigationProp } from "@react-navigation/stack";
import {
  ImageStyle,
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from "react-native";
import { MainStackParamList } from "../navigation/RootNavigation";

/**
 * Fontweight setting
 * - This font weight will be used for style of screens where needed
 * - Check more how to use font weight with url below
 * @url http://passionui.com/docs/felix-travel/theme
 */

type fontWeightType =
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900"
  | "normal"
  | "bold"
  | undefined;

interface FontWeight {
  regular: fontWeightType;
  thin: fontWeightType;
  ultraLight: fontWeightType;
  light: fontWeightType;
  medium: fontWeightType;
  semibold: fontWeightType;
  bold: fontWeightType;
  heavy: fontWeightType;
  black: fontWeightType;
}

export const FontWeight: FontWeight = {
  thin: "100",
  ultraLight: "200",
  light: "300",
  regular: "400",
  medium: "500",
  semibold: "600",
  bold: "700",
  heavy: "800",
  black: "900",
};

/**
 * Typography setting
 * - This font weight will be used for all template
 * - Check more how to use typography in url below
 * @url http://passionui.com/docs/felix-travel/theme
 */
export const Typography = StyleSheet.create({
  header: {
    fontSize: 34,
    fontWeight: FontWeight.regular,
  },
  title1: {
    fontSize: 28,
    fontWeight: FontWeight.regular,
  },
  title2: {
    fontSize: 22,
    fontWeight: FontWeight.regular,
  },
  title3: {
    fontSize: 20,
    fontWeight: FontWeight.regular,
  },
  headline: {
    fontSize: 18,
    fontWeight: FontWeight.regular,
  },
  body1: {
    fontSize: 17,
    fontWeight: FontWeight.regular,
  },
  body2: {
    fontSize: 16,
    fontWeight: FontWeight.regular,
  },
  callout: {
    fontSize: 15,
    fontWeight: FontWeight.regular,
  },
  subhead: {
    fontSize: 14,
    fontWeight: FontWeight.regular,
  },
  footnote: {
    fontSize: 13,
    fontWeight: FontWeight.regular,
  },
  caption1: {
    fontSize: 12,
    fontWeight: FontWeight.regular,
  },
  caption2: {
    fontSize: 11,
    fontWeight: FontWeight.black,
  },
  overline: {
    fontSize: 10,
    fontWeight: FontWeight.regular,
  },
});

export type TStyle =
  | StyleProp<ViewStyle>
  | StyleProp<TextStyle>
  | StyleProp<ImageStyle>;
export type TColor = string;
export type TQueryStatus = "next" | "first";
export type CarouselProps = {
  images?: string[];
  onPress: (item: any) => void;
};

export type TBaseColor = {
  grayColor: string;
  dividerColor: string;
  whiteColor: string;
  fieldColor: string;
  yellowColor: string;
  navyBlue: string;
  kashmir: string;
  orangeColor: string;
  blueColor: string;
  pinkColor: string;
  greenColor: string;
  successColor: string;
  warningColor: string;
  dangerColor: string;
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
  // text
  lowlightedTextColor: string;
  textColor: string;
  highlightedTextColor: string;
  placeholderTextColor: string;
};

export type TColors = {
  primary: string;
  primaryDark: string;
  primaryLight: string;
  accent: string;
  accentLight: string;
  background: string;
  card: string;
  text: string;
  border: string;
  notification: string;
};

export type MainStackScreenNavigationProp =
  StackNavigationProp<MainStackParamList>;
