import React, { useCallback, useState } from "react";
import { View, ScrollView, TouchableOpacity, StyleSheet } from "react-native";
import { BaseStyle, useTheme } from "../../config";
import {
  Header,
  SafeAreaView,
  Icon,
  ProfileDetail,
  AsyncStorage,
} from "../../components";
import { Button, Text } from "../../ui";
import { UserData } from "../../fakedata";
import { useTranslation } from "react-i18next";
import { BaseColor } from "../../config";
import { getAuth, signOut } from "firebase/auth";
import { useAuth } from "../../context/Auth";
import { usePromise } from "../../hooks";
import {
  getCurrentUserId,
  getProfile,
  getProfilePictureUrl,
} from "../../models";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";

export default function Profile() {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  let { userId } = useAuth();
  userId = getCurrentUserId();
  const { colors } = useTheme();
  const { t } = useTranslation();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  // const [userData, setUserData] = useState(UserData[0]);
  const currentUserProfile = usePromise(async () => {
    return await getProfile(userId);
  }, [userId]);

  const userPicture = getProfilePictureUrl(
    userId,
    currentUserProfile?.avatarId
  );

  const onLogOut = () => {
    setLoading(true);
    navigation.navigate("Auth");
    setTimeout(() => {
      AsyncStorage.setItem(
        "Auth",
        JSON.stringify({
          currentUserId: null,
        })
      );
      signOut(auth);
      setLoading(false);
    }, 750);
  };

  return (
    <View style={{ flex: 1 }}>
      <LoadingSpinner visible={loading} />
      <Header title={t("tabs:profile")} subTitle={""} />
      <ProfileDetail
        style={{ paddingHorizontal: 20, paddingBottom: 20, paddingTop: 20 }}
        image={userPicture}
        textFirst={currentUserProfile?.displayName}
        textSecond={""}
        icon={true}
        onPress={() => {}}
      />
      <SafeAreaView style={{ flex: 1 }} edges={["right", "left", "bottom"]}>
        <ScrollView>
          <View style={styles.contain}>
            <TouchableOpacity
              style={[
                styles.profileItem,
                {
                  borderBottomColor: colors.border,
                  borderBottomWidth: 1,
                  marginTop: 10,
                },
              ]}
              onPress={() => {
                // navigation.navigate('SitterProfile');
                const text = "立即下載App，申請保姆";
                if (confirm(text) == true) {
                  window.open("https://bit.ly/3QrSvjk");
                } else {
                }
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Icon style={{ marginRight: 10 }} name="id-card" size={18} />
                <Text body1>{t("profile:sitter_profile")}</Text>
              </View>
              <View>
                <Text>即將推出</Text>
                {/* <Icon
                  name="angle-right"
                  size={18}
                  color={colors.primary}
                  style={{marginLeft: 5}}
                /> */}
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.profileItem,
                {
                  borderBottomColor: colors.border,
                  borderBottomWidth: 1,
                  marginTop: 10,
                },
              ]}
              onPress={() => {
                navigation.navigate("PetsScreen", {
                  userId,
                });
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Icon style={{ marginRight: 10 }} name="paw" size={18} />
                <Text body1>{t("profile:pets")}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{ marginLeft: 5 }}
              />
            </TouchableOpacity>
            {/* <TouchableOpacity
              style={[
                styles.profileItem,
                {borderBottomColor: colors.border, borderBottomWidth: 1, marginTop: 10},
              ]}
              onPress={() => {
                // navigation.navigate('SitterProfile');
              }}>
              <View style={{flexDirection: 'row'}}>
                <Icon style={{marginRight: 10}} name="gift" size={18} />
                <Text body1>{t('profile:coupon')}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{marginLeft: 5}}
              />
            </TouchableOpacity> */}
            {/* <TouchableOpacity
              style={[
                styles.profileItem,
                {borderBottomColor: colors.border, borderBottomWidth: 1, marginTop: 10},
              ]}
              onPress={() => {
                // navigation.navigate('SitterProfile');
              }}>
              <View style={{flexDirection: 'row'}}>
                <Icon style={{marginRight: 10}} name="gifts" size={18} />
                <Text body1>{t('profile:invite_friends')}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{marginLeft: 5}}
              />
            </TouchableOpacity>  */}
            <TouchableOpacity
              style={[
                styles.profileItem,
                {
                  borderBottomColor: colors.border,
                  borderBottomWidth: 1,
                  marginTop: 10,
                },
              ]}
              onPress={() => {
                window.open(`https://lin.ee/XQ3cli4`, "_blank");
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Icon style={{ marginRight: 10 }} name="headset" size={18} />
                <Text body1>{t("profile:common_problem")}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{ marginLeft: 5 }}
              />
            </TouchableOpacity>
            {/* <TouchableOpacity
              style={styles.profileItem}
              onPress={() => {
                navigation.navigate('Setting');
              }}>
              <View style={{flexDirection: 'row'}}>
                <Icon style={{marginRight: 10}} name="cog" size={18} />
                <Text body1>{t('profile:setting')}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{marginLeft: 5}}
              />
            </TouchableOpacity> */}
            <View style={{ paddingHorizontal: 20, paddingVertical: 15 }}>
              <Button
                style={{ backgroundColor: colors.background }}
                styleText={{ color: BaseColor.dangerColor }}
                full
                loading={loading}
                onPress={() => onLogOut()}
              >
                {t("enum:sign_out")}
              </Button>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentTitle: {
    alignItems: "flex-start",
    width: "100%",
    height: 32,
    justifyContent: "center",
  },
  contain: {
    flex: 1,
    paddingHorizontal: 20,
  },
  textInput: {
    height: 56,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 5,
    padding: 10,
    width: "100%",
  },
  profileItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 20,
  },
});
