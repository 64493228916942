import { generateId } from "../utils";
import * as FileSystem from "expo-file-system";
import { ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../config/firebase";
import { Platform } from "react-native";

export const isUri = (uriOrId: string): boolean => {
  // path could includes `:/`, `://`, `:///`, `:///`
  // https://en.wikipedia.org/wiki/File_URI_scheme#How_many_slashes?
  return uriOrId.includes(":/") || uriOrId.includes("data:");
};

export const downloadImageToTempFolder = async (
  remoteUri: string,
  fileName: string,
  extension = "png"
): Promise<string> => {
  let file: any = {};
  if (Platform.OS === "web") {
    let response = await fetch(remoteUri);
    let imageBlob = await response.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    file = { uri: imageObjectURL };
  } else {
    file = await FileSystem.downloadAsync(
      remoteUri,
      `${FileSystem.documentDirectory}${fileName}.${extension}`
    );
  }
  return file.uri;
};

export const uploadImage = async (
  uriOrId: string,
  targetPath: string,
  options?: {
    extension?: string;
    onProgressChange?: (progress: number, tempPictureId: string) => void;
  }
): Promise<string> => {
  const { extension = "jpg", onProgressChange } = options || {};
  if (!uriOrId) {
    throw new Error("uri or id cannot be empty");
  }
  if (!isUri(uriOrId)) return uriOrId;

  const metaData = {
    contentType: "image/jpeg",
  };

  const pictureId = generateId(20);
  let fileUri = uriOrId;
  if (uriOrId.includes("https://") || uriOrId.includes("http://")) {
    fileUri = await downloadImageToTempFolder(uriOrId, pictureId, extension);
  }

  const pictureRef = ref(storage, `${targetPath}/${pictureId}.${extension}`);

  try {
    await new Promise<void>(async (resolve, reject) => {
      const img = await fetch(fileUri);
      const bytes = await img.blob();
      const uploadTask = uploadBytesResumable(pictureRef, bytes);
      const unsubscribe = uploadTask.on(
        "state_changed",
        async (snapshot) => {
          if (onProgressChange) {
            onProgressChange(
              snapshot.bytesTransferred / snapshot.totalBytes,
              pictureId
            );
          }
          if (snapshot.bytesTransferred === snapshot.totalBytes) {
            resolve();
          }
        },
        (error) => {
          reject();
          unsubscribe();
          alert(`uploadImage - ${error}`);
        }
      );
    });
    return pictureId;
  } catch {
    throw new Error("upload failed");
  }
};
