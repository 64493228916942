import areas from "./areas.json";

type TArea = {
  [key: string]: {
    name: string;
    supplement?: string;
  };
};

type TCityArea = {
  name: string;
  areas: TArea;
};

export const ALL_AREAS = areas as unknown as TCityArea[];

const FLATTENED_AREAS = ALL_AREAS.reduce<{ [code: string]: { name: string } }>(
  (result, c) => ({ ...result, ...c.areas }),
  {}
);

export const FLATTENED_AREAS_MAP = Object.keys(FLATTENED_AREAS).reduce<{
  [code: string]: string;
}>((result, code) => {
  const area = FLATTENED_AREAS[code];
  result[code] = area.name;
  return result;
}, {});

export const getShortAddress = (code: string): string => {
  let result = "";
  ALL_AREAS.forEach((city) => {
    const area = city.areas[code];
    if (area) {
      result = `${city.name} ${area.name}`;
    }
  });
  return result;
};

export const getShortAreasName = (areaCodes: string[]): string => {
  const finalAreaNames: string[] = [];
  ALL_AREAS.forEach((city) => {
    const { name: cityName, areas } = city;

    const targetAreaNames: string[] = [];
    Object.keys(areas).forEach((code) => {
      const area = areas[code];
      if (areaCodes.includes(code)) {
        targetAreaNames.push(area.name);
      }
    });

    const includesWholeCity =
      Object.keys(areas).length !== 0 &&
      Object.keys(areas).length === targetAreaNames.length;
    if (includesWholeCity) {
      finalAreaNames.push(cityName);
    } else {
      finalAreaNames.push(...targetAreaNames);
    }
  });

  return finalAreaNames.join(", ");
};
