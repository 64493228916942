import {
  Linking,
  ScrollView,
  StyleProp,
  StyleSheet,
  Touchable,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-native-modal";
import { useTranslation } from "react-i18next";
import { BaseColor, BaseStyle, useTheme } from "../../config";
import { Header, Icon, SelectModal } from "../../components";
import { Flex, Image, Text, TextInput } from "../../ui";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  DayOfWeek,
  DayOfWeekUtil,
  PetCategory,
  PetCategoryUtil,
  PetSize,
  PetSizeUtil,
  PriceLevel,
  PriceLevelUtil,
  ServiceType,
  ServiceTypeUtil,
} from "../../enums";
import {
  createService,
  getCurrentUserId,
  getServicePictureUrl,
  subscribeServicePrices,
  TServiceInput,
  TServicePlace,
  updateService,
} from "../../models";
import { ALL_AREAS } from "../../constant/areas";
import PlaceModal from "../../components/PlaceModal";
import { useSubscription } from "../../hooks";
import * as ImagePicker from "expo-image-picker";
import { getAuth } from "firebase/auth";
import { minWidth } from "../../config/theme";
import { hasPicturesLink, getPicturesLink, getPicturesText } from "../../utils";
import dynamic from "next/dynamic";
import { transition } from "./sitterManageMachine";
// We need `{ssr: false}` otherwise there'll be a "requestAnimationFrame is not defined" error.
const NestableDraggableFlatList = dynamic(
  import("react-native-draggable-flatlist").then(
    (module) => module.NestableDraggableFlatList
  ),
  { ssr: false }
);
const NestableScrollContainer = dynamic(
  import("react-native-draggable-flatlist").then(
    (module) => module.NestableScrollContainer
  ),
  { ssr: false }
);

interface ServiceManageProps {
  modalVisible: boolean;
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
  onDismiss: () => void;
  defaultInput?: TServiceInput;
  serviceId?: string;
}

const fixServiceType = (
  type: ServiceType | undefined | 0
): ServiceType | undefined => (type === 0 ? undefined : type);

const CUSTOM_PRICE = 300;

const ServiceManage = React.memo((props: ServiceManageProps) => {
  const { modalVisible, style, onPress, onDismiss, defaultInput, serviceId } =
    props;
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();
  const { currentUser } = auth;
  const [type, setType] = useState<ServiceType | undefined>(
    defaultInput?.type || 0
  );
  const [availableDays, setAvailableDays] = useState<DayOfWeek[]>(
    defaultInput?.availableDays || [7]
  );
  const [title, setTitle] = useState<string>(defaultInput?.title || "");
  const [description, setDescription] = useState<string>(
    defaultInput?.description || ""
  );
  const [areaCodes, setAreaCodes] = useState<string[]>(
    defaultInput?.areaCodes || ["0"]
  );
  const [place, setPlace] = useState<TServicePlace | undefined>(
    defaultInput?.place
  );
  const [pictureUrisOrIds, setPictureUrisOrIds] = useState<string[]>(
    defaultInput?.pictureUrisOrIds || []
  );
  const [petCategories, setPetCategories] = useState<PetCategory[]>(
    defaultInput?.petCategories || [0]
  );
  const [petSizes, setPetSizes] = useState<PetSize[]>(
    defaultInput?.petSizes || [0]
  );
  const [price, setPrice] = useState<number | undefined>(defaultInput?.price);
  const [priceLevel, setPriceLevel] = useState<PriceLevel | undefined>(
    defaultInput?.priceLevel ||
      (defaultInput?.priceLevel === null ? 0 : PriceLevel.LV1)
  );
  const [onOpenServiceTypeModal, setOnOpenServiceTypeModal] = useState(false);
  const [onOpenAvailableDay, setOnOpenAvailableDay] = useState(false);
  const [onOpenAreaPlace, setOnOpenAreaPlace] = useState(false);
  const [onOpenCategoryModal, setOnOpenCategoryModal] = useState(false);
  const [onOpenSizeModal, setOnOpenSizeModal] = useState(false);
  const [onOpenPriceLevel, setOnOpenPriceLevel] = useState(false);
  const [isShowPlaceModal, setIsShowPlaceModal] = useState(false);

  const prices = useSubscription({
    subscription: subscribeServicePrices,
    variables: {
      serviceType: type,
    },
  });
  // unit
  const unit = type ? t(`enum:type_unit_${ServiceType[type]}`) : "";

  // type
  const serviceTypeOptions = ServiceTypeUtil.list()
    .map((st) => ({
      text: t(`search:type_${ServiceType[st]}`),
      value: st,
    }))
    .filter((st) => st.value !== 101)
    .filter((st) => st.value !== 102);
  serviceTypeOptions.unshift({ text: t("service:select_type"), value: 0 });

  const priceLevelOptions = PriceLevelUtil.list().map((pl) => ({
    text: `${t(`enum:price_level_${PriceLevel[pl]}`)} $${
      prices?.[pl] || ""
    }${unit}`,
    value: pl,
  }));
  if (type === ServiceType.DROPIN) {
    priceLevelOptions.unshift({
      text: t("Neightbour ${{price}} / time", {price: CUSTOM_PRICE}),
      value: 0
    });
  }

  // available day
  const availableDayOptions = DayOfWeekUtil.list().map((dw) => ({
    text: t(`enum:day_of_week_${DayOfWeek[dw]}`),
    value: dw,
  }));
  availableDayOptions.unshift({
    text: t("service:select_day_of_week"),
    value: 7,
  });

  // area
  const areaPlaceOptions = ALL_AREAS.map((c) => {
    return Object.entries(c.areas).map(([key, value]) => ({
      text: `${key} ${c.name} ${value.name} ${value.supplement || ""}`,
      value: key,
    }));
  }).reduce((prev, curr) => {
    return prev.concat(curr);
  });
  areaPlaceOptions.unshift({ text: t("service:select_areas"), value: "0" });

  // petCategory
  const petCategoryOptions = PetCategoryUtil.list().map((pc) => ({
    text: t(`pets:category_${PetCategory[pc]}`),
    value: pc,
  }));
  petCategoryOptions.unshift({ text: t("pets:select_category"), value: 0 });

  // Size
  const petSizeOptions = PetSizeUtil.list().map((ps) => ({
    text: t(`pets:pet_size_${PetSize[ps]}`),
    description: t(`pets:pet_size_tip_${PetSize[ps]}`),
    value: ps,
  }));
  petSizeOptions.unshift({
    text: t("pets:select_size"),
    value: 0,
    description: "",
  });

  const handleSelectServicePciker = useCallback(async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert("沒有取得您的權限");
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsMultipleSelection: true,
      });
      if (!result.cancelled) {
        setPictureUrisOrIds((oldUrisOrIds) => [
          ...oldUrisOrIds,
          ...result.selected.map((value) => value.uri),
        ]);
      }
    }
  }, []);

  const handleRemovePicture = useCallback(
    (pictureUriOrId) => {
      pictureUrisOrIds.splice(pictureUrisOrIds.indexOf(pictureUriOrId), 1);
      setPictureUrisOrIds([...pictureUrisOrIds]);
    },
    [pictureUrisOrIds]
  );

  const renderPictureItem = useCallback(
    ({ item, move, moveEnd, isActive }) => {
      const pictureUriOrId = item;
      const userId = getCurrentUserId();
      const uri = getServicePictureUrl(userId, pictureUriOrId);

      return (
        <Flex>
          <TouchableOpacity
            style={{
              // backgroundColor: isActive ? palette.secondaryColor : undefined,
              opacity: isActive ? 0.7 : 1,
            }}
            onLongPress={move}
            onPressOut={moveEnd}
          >
            <Flex key={item} padding={4}>
              <Image style={{ width: 92, height: 92 }} source={{ uri }} />
            </Flex>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.removePictureWrapper}
            onPress={() => handleRemovePicture(pictureUriOrId)}
          >
            <View
              style={[
                styles.removePicture,
                { backgroundColor: BaseColor.color2 },
              ]}
            >
              <Icon size={16} name="times" color="white" />
            </View>
          </TouchableOpacity>
        </Flex>
      );
    },
    [currentUser, handleRemovePicture]
  );

  const handleMovePicture = useCallback(({ data }) => {
    setPictureUrisOrIds(data);
  }, []);
  
  const currentServiceType = ServiceType[type];  
  const handleChangeServiceType = useCallback(
    (option) => {
      const nextServiceType = ServiceType[option.value];
      const nextState = transition(currentServiceType, {
        type: nextServiceType,
        value: { serviceType: nextServiceType, priceLevel: priceLevel },
      });

      setType(option.value);
      setPriceLevel(nextState?.priceLevel);
      setPrice(nextState?.priceLevel === 0 ? CUSTOM_PRICE : undefined);
    },
    [CUSTOM_PRICE, priceLevel, currentServiceType]
  );
  
  const handleChangePriceLevel = useCallback((option) => {
    const nextPriceLevel = option.value;
    const nextState = transition(currentServiceType, {
      type: nextPriceLevel, 
      value: { serviceType: currentServiceType, priceLevel: nextPriceLevel },
    });

    setPriceLevel(nextState?.priceLevel);
    setPrice(type === ServiceType.DROPIN && option.value === 0 ? CUSTOM_PRICE : undefined);
  }, [CUSTOM_PRICE, type, currentServiceType]);

  const handleChangeAvailableDay = useCallback((options: any[]) => {
    setAvailableDays(
      options
        .filter((option) => option.value !== 7)
        .map((option) => option.value)
    );
  }, []);

  const handleChangeTitle = useCallback((text) => {
    setTitle(text);
  }, []);

  const handleChangeDescription = useCallback((text) => {
    setDescription(text);
  }, []);

  const handleChangeAreaPlace = useCallback((option) => {
    setAreaCodes(
      option
        .filter((option: any) => option.value !== "0")
        .map((option: any) => option.value)
    );
  }, []);

  const handleChangeCategory = useCallback((option) => {
    setPetCategories(
      option
        .filter((option: any) => option.value !== 0)
        .map((option: any) => option.value)
    );
  }, []);

  const handleChangeSize = useCallback((option) => {
    setPetSizes(
      option
        .filter((option: any) => option.value !== 0)
        .map((option: any) => option.value)
    );
  }, []);

  const handleFooterTitlePress = useCallback(() => {
    Linking.openURL(getPicturesLink(fixServiceType(type)));
  }, [type]);

  return (
    <Modal isVisible={modalVisible} style={[style, styles.bottomModal]}>
      <LoadingSpinner visible={isLoading} />
      <View style={[{ backgroundColor: colors.card }, styles.contentBottom]}>
        <ScrollView>
          <Header
            style={BaseStyle.menu}
            titleStyle={{ color: BaseColor.whiteColor }}
            title={t("sitter_profile:add_service")}
            subTitle={""}
            renderRight={() => {
              return (
                <Text style={{ color: BaseColor.whiteColor }}>
                  {defaultInput
                    ? `${t("common:update")}`
                    : `${t("common:create")}`}
                </Text>
              );
            }}
            onPressRight={async () => {
              /* @ts-ignore */
              if (!type || type === 0) {
                alert("請選擇服務類別");
                return;
              }
              if (priceLevel)
                if (availableDays.length === 0 || availableDays[0] === 7) {
                  /* @ts-ignore */
                  alert("請選擇可提供星期");
                  return;
                }
              if (title === "") {
                alert("請輸入標題");
                return;
              }
              if (description === "") {
                alert("請輸入詳細服務內容");
                return;
              }
              if (
                type !== ServiceType.TAVERN &&
                type !== ServiceType.VET_HOSPITAL
              ) {
                if (areaCodes.length === 0 || areaCodes[0] === "0") {
                  alert("請選擇可服務區域");
                  return;
                }
              }
              if (!place) {
                alert("請輸入可服務地點");
                return;
              }
              if (pictureUrisOrIds.length === 0) {
                alert("請選擇服務照片");
                return;
              }
              /* @ts-ignore */
              if (petCategories.length === 0 || petCategories[0] === 0) {
                alert("請選擇可服務寵物種類");
                return;
              }
              /* @ts-ignore */
              if (petSizes.length === 0 || petSizes[0] === 0) {
                alert("請選擇可服務寵物大小");
                return;
              }
              setIsLoading(true);
              const inputData = {
                type,
                title,
                description,
                areaCodes,
                place,
                availableDays,
                pictureUrisOrIds,
                petCategories,
                petSizes,
                price,
                priceLevel,
              };
              try {
                if (defaultInput) {
                  await updateService(serviceId || "", inputData);
                } else {
                  await createService(inputData);
                }
              } catch (e) {
                alert(e);
              }
              setIsLoading(false);
              onDismiss();
            }}
            renderLeft={() => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    onDismiss();
                  }}
                >
                  <Icon
                    name="times"
                    size={20}
                    style={{ color: BaseColor.whiteColor }}
                  />
                </TouchableOpacity>
              );
            }}
          />

          {/* service type */}
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingHorizontal: 20,
              backgroundColor: BaseColor.whiteColor,
              marginTop: 5,
              borderBottomWidth: 1,
              borderBottomColor: colors.card,
            }}
            onPress={() => {
              setOnOpenServiceTypeModal(true);
            }}
          >
            <View>
              <Text body2 semibold>
                {t("service:type")}
              </Text>
            </View>
            <View>
              <TouchableOpacity
                style={styles.selectButton}
                onPress={() => {
                  setOnOpenServiceTypeModal(true);
                }}
              >
                <Text style={{ color: BaseColor.placeholderTextColor }}>
                  {serviceTypeOptions.filter(
                    (option) => option.value === type
                  )[0]?.text || ""}
                </Text>
                <Icon name="angle-down" size={18} style={{ marginLeft: 10 }} />
              </TouchableOpacity>
            </View>
            <SelectModal
              visible={onOpenServiceTypeModal}
              options={serviceTypeOptions}
              selected={
                serviceTypeOptions.filter((option) => option.value === type)[0]
              }
              onChange={(item: any) => {
                setOnOpenServiceTypeModal(false);
                handleChangeServiceType(item);
              }}
            />
          </TouchableOpacity>

          {/* price level */}
          {prices ? (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
                backgroundColor: BaseColor.whiteColor,
                borderBottomWidth: 1,
                borderBottomColor: colors.card,
              }}
              onPress={() => {
                setOnOpenPriceLevel(true);
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("service:price_level")}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  style={styles.selectButton}
                  onPress={() => {
                    setOnOpenPriceLevel(true);
                  }}
                >
                  <Text style={{ color: BaseColor.placeholderTextColor }}>
                    {priceLevelOptions.filter(
                      (option) => option.value === priceLevel
                    )[0]?.text || ""}
                  </Text>
                  <Icon
                    name="angle-down"
                    size={18}
                    style={{ marginLeft: 10 }}
                  />
                </TouchableOpacity>
              </View>
              <SelectModal
                visible={onOpenPriceLevel}
                options={priceLevelOptions}
                selected={
                  priceLevelOptions.filter(
                    (option) => option.value === priceLevel
                  )[0]
                }
                onChange={(item: any) => {
                  setOnOpenPriceLevel(false);
                  handleChangePriceLevel(item);
                }}
              />
            </TouchableOpacity>
          ) : (
            <></>
          )}

          {/* available day */}
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingHorizontal: 20,
              backgroundColor: BaseColor.whiteColor,
              marginBottom: 20,
            }}
            onPress={() => {
              setOnOpenAvailableDay(true);
            }}
          >
            <View>
              <Text body2 semibold>
                {t("service:available_days")}
              </Text>
            </View>
            <View>
              <TouchableOpacity
                style={styles.selectButton}
                onPress={() => {
                  setOnOpenAvailableDay(true);
                }}
              >
                <View style={{ maxWidth: 200, justifyContent: "flex-end" }}>
                  <Text
                    style={{
                      textAlign: "right",
                      color: BaseColor.placeholderTextColor,
                    }}
                  >
                    {availableDayOptions
                      .filter((option: any) => {
                        return availableDays.includes(option.value);
                      })
                      .map((item) => {
                        return item.text;
                      })
                      .join(" ,")}
                  </Text>
                </View>
                <Icon name="angle-down" size={18} style={{ marginLeft: 10 }} />
              </TouchableOpacity>
            </View>
            <SelectModal
              visible={onOpenAvailableDay}
              options={availableDayOptions}
              multiSelected={availableDayOptions.filter((option: any) =>
                availableDays.includes(option.value)
              )}
              multiSelect={true}
              onChange={(item: any) => {
                setOnOpenAvailableDay(false);
                handleChangeAvailableDay(item);
              }}
            />
          </TouchableOpacity>

          {/* service description */}
          <View style={{ paddingHorizontal: 20 }}>
            <Text>{t("service:title_and_description")}</Text>
          </View>
          {/* title */}
          <View
            style={{
              backgroundColor: "white",
              width: "100%",
              marginTop: 10,
              paddingVertical: 5,
              borderBottomWidth: 1,
              borderBottomColor: colors.card,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("service:title")}
                </Text>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                marginVertical: 10,
                paddingHorizontal: 20,
              }}
            >
              <TextInput
                onChangeText={handleChangeTitle}
                onFocus={() => {}}
                placeholder={t("service:title_placeholder")}
                value={title}
                style={{
                  borderColor: BaseColor.dividerColor,
                  paddingHorizontal: 10,
                  borderWidth: 1,
                }}
              />
            </View>
          </View>
          {/* description */}
          <View
            style={{
              backgroundColor: "white",
              width: "100%",
              paddingVertical: 5,
              marginBottom: 20,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("service:description")}
                </Text>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                marginVertical: 10,
                paddingHorizontal: 20,
              }}
            >
              <TextInput
                onChangeText={handleChangeDescription}
                onFocus={() => {}}
                placeholder={t(
                  type === ServiceType.DROPIN
                    ? "service:description_placeholder_custom_price"
                    : "service:description_placeholder"
                )}
                value={description}
                style={{
                  borderColor: BaseColor.dividerColor,
                  paddingHorizontal: 10,
                  borderWidth: 1,
                  height: 80,
                }}
                numberOfLines={2}
                multiline={true}
              />
            </View>
          </View>

          <View style={{ paddingHorizontal: 20 }}>
            <Text>{t("service:place_info")}</Text>
          </View>

          {/* service area */}
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingHorizontal: 20,
              marginTop: 10,
              backgroundColor: BaseColor.whiteColor,
              height: "auto",
              borderBottomWidth: 1,
              borderBottomColor: colors.card,
            }}
            onPress={() => {
              setOnOpenAreaPlace(true);
            }}
          >
            <View>
              <Text body2 semibold>
                {t("service:areas")}
              </Text>
            </View>
            <View>
              <TouchableOpacity
                style={[styles.selectButton, { height: "auto" }]}
                onPress={() => {
                  setOnOpenAreaPlace(true);
                }}
              >
                <View style={{ maxWidth: 200, justifyContent: "flex-end" }}>
                  <Text
                    style={{
                      textAlign: "right",
                      color: BaseColor.placeholderTextColor,
                    }}
                  >
                    {areaPlaceOptions
                      .filter((option: any) => {
                        return areaCodes.includes(option.value);
                      })
                      .map((item) => {
                        return item.text;
                      })
                      .join(" ,")}
                  </Text>
                </View>
                <Icon name="angle-down" size={18} style={{ marginLeft: 10 }} />
              </TouchableOpacity>
            </View>
            <SelectModal
              visible={onOpenAreaPlace}
              options={areaPlaceOptions}
              multiSelected={areaPlaceOptions.filter((option: any) =>
                areaCodes.includes(option.value)
              )}
              multiSelect={true}
              onChange={(item: any) => {
                setOnOpenAreaPlace(false);
                handleChangeAreaPlace(item);
              }}
            />
          </TouchableOpacity>

          {/* service place */}
          <PlaceModal
            visible={isShowPlaceModal}
            onDismiss={(visible) => setIsShowPlaceModal(visible)}
            onSelect={(place) => {
              setPlace(place);
            }}
          />
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingHorizontal: 20,
              backgroundColor: BaseColor.whiteColor,
            }}
            onPress={() => {
              setIsShowPlaceModal(true);
            }}
          >
            <View>
              <Text body2 semibold>
                {t("service:place")}
              </Text>
            </View>
            <View>
              <TouchableOpacity
                style={styles.selectButton}
                onPress={() => {
                  setIsShowPlaceModal(true);
                }}
              >
                <Text
                  style={{
                    maxWidth: 200,
                    justifyContent: "flex-end",
                    textAlign: "right",
                    color: BaseColor.placeholderTextColor,
                  }}
                >
                  {place ? place?.address : t("service:select_place")}
                </Text>
                <Icon name="angle-down" size={18} style={{ marginLeft: 10 }} />
              </TouchableOpacity>
            </View>
          </TouchableOpacity>
          <View
            style={{
              paddingHorizontal: 20,
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <Text footnote>{t("service:place_tips_to_consumer")}</Text>
          </View>

          {/* picture */}
          <View style={{ paddingHorizontal: 20 }}>
            <Text>{t("service:pictures")}</Text>
          </View>
          <NestableScrollContainer
            style={{
              flexDirection: "row",
              width: "100%",
              paddingHorizontal: 20,
              marginTop: 10,
              backgroundColor: BaseColor.whiteColor,
              borderBottomWidth: 1,
              borderBottomColor: colors.card,
            }}
          >
            <NestableDraggableFlatList
              horizontal
              showsHorizontalScrollIndicator={false}
              data={pictureUrisOrIds}
              renderItem={renderPictureItem}
              keyExtractor={(i: any) => i}
              onDragEnd={handleMovePicture}
              autoscrollThreshold={5}
              dragItemOverflow={true}
            />
          </NestableScrollContainer>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingHorizontal: 20,
              paddingVertical: 10,
              backgroundColor: BaseColor.whiteColor,
              marginBottom: 20,
            }}
            onPress={() => {
              handleSelectServicePciker();
            }}
          >
            <View>
              <Text body2 primaryColor style={{ textAlign: "center" }}>
                {t(getPicturesText(fixServiceType(type), pictureUrisOrIds))}
              </Text>
            </View>
          </TouchableOpacity>
          <View
            style={{
              paddingHorizontal: 20,
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <Text footnote>
              {t(
                "Service photos are the first impression your guests have of your service, so it’s important to choose a good photo!"
              )}{" "}
              {hasPicturesLink(fixServiceType(type)) && (
                <Text
                  style={styles.footerTitleText}
                  onPress={handleFooterTitlePress}
                >
                  {t("Learn how to choose good service photos.", {
                    keySeparator: false,
                  })}
                </Text>
              )}
            </Text>
          </View>

          <View style={{ paddingHorizontal: 20 }}>
            <Text>{t("service:acceptable_pets")}</Text>
          </View>

          {/* pet category */}
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingHorizontal: 20,
              backgroundColor: BaseColor.whiteColor,
              marginTop: 10,
              borderBottomWidth: 1,
              borderBottomColor: colors.card,
            }}
            onPress={() => {
              setOnOpenCategoryModal(true);
            }}
          >
            <View>
              <Text body2 semibold>
                {t("pets:category")}
              </Text>
            </View>
            <View>
              <TouchableOpacity
                style={styles.selectButton}
                onPress={() => {
                  setOnOpenCategoryModal(true);
                }}
              >
                <View style={{ maxWidth: 200, justifyContent: "flex-end" }}>
                  <Text
                    style={{
                      textAlign: "right",
                      color: BaseColor.placeholderTextColor,
                    }}
                  >
                    {petCategoryOptions
                      .filter((option: any) => {
                        return petCategories.includes(option.value);
                      })
                      .map((item) => {
                        return item.text;
                      })
                      .join(" ,")}
                  </Text>
                </View>
                <Icon name="angle-down" size={18} style={{ marginLeft: 10 }} />
              </TouchableOpacity>
            </View>
            <SelectModal
              visible={onOpenCategoryModal}
              options={petCategoryOptions}
              multiSelected={petCategoryOptions.filter((option: any) =>
                petCategories.includes(option.value)
              )}
              multiSelect={true}
              onChange={(item: any) => {
                setOnOpenCategoryModal(false);
                handleChangeCategory(item);
              }}
            />
          </TouchableOpacity>

          {/* pet size */}
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingHorizontal: 20,
              backgroundColor: BaseColor.whiteColor,
              marginBottom: 30,
            }}
            onPress={() => {
              setOnOpenSizeModal(true);
            }}
          >
            <View>
              <Text body2 semibold>
                {t("pets:size")}
              </Text>
            </View>
            <View>
              <TouchableOpacity
                style={styles.selectButton}
                onPress={() => {
                  setOnOpenSizeModal(true);
                }}
              >
                <View style={{ maxWidth: 200, justifyContent: "flex-end" }}>
                  <Text
                    style={{
                      textAlign: "right",
                      color: BaseColor.placeholderTextColor,
                    }}
                  >
                    {petSizeOptions
                      .filter((option: any) => {
                        return petSizes.includes(option.value);
                      })
                      .map((item) => {
                        return item.text;
                      })
                      .join(" ,")}
                  </Text>
                </View>
                <Icon name="angle-down" size={18} style={{ marginLeft: 10 }} />
              </TouchableOpacity>
            </View>
            <SelectModal
              visible={onOpenSizeModal}
              options={petSizeOptions}
              multiSelected={petSizeOptions.filter((option: any) =>
                petSizes.includes(option.value)
              )}
              multiSelect={true}
              onChange={(item: any) => {
                setOnOpenSizeModal(false);
                handleChangeSize(item);
              }}
            />
          </TouchableOpacity>
        </ScrollView>
      </View>
    </Modal>
  );
});

export default ServiceManage;

const styles = StyleSheet.create({
  footerTitleText: {
    textDecorationLine: "underline",
  },
  bottomModal: {
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    width: "100%",
  },
  contentBottom: {
    width: "100%",
    minWidth: minWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flex: 1,
  },
  contentSwipeDown: {
    paddingTop: 20,
    alignItems: "flex-end",
  },
  selectButton: {
    padding: 10,
    height: 46,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    color: BaseColor.grayColor,
    borderRadius: 5,
    flexDirection: "row",
  },
  removePictureWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 999,
  },
  removePicture: {
    width: 24,
    height: 24,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
  },
});
