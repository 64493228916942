import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import { BaseColor, useTheme } from "../config";
import { Image, Tag, Text } from "../ui";
import { StarRating } from ".";

type TSitterItem = {
  style?: StyleProp<ViewStyle>;
  image: string;
  title: string;
  license: string;
  rate: string;
  distance: string;
  totalReviews: string;
  onPress: () => void;
};

const SitterItem = (props: TSitterItem) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const {
    style,
    image,
    title,
    license,
    rate,
    distance,
    totalReviews,
    onPress,
  } = props;
  return (
    <View style={[styles.content, style]}>
      <TouchableOpacity onPress={onPress} activeOpacity={0.9}>
        <img
          src={image}
          style={{
            width: 150,
            height: 120,
            borderRadius: 8,
            objectFit: "cover",
          }}
          loading="lazy"
          alt="sitter avatar"
        />
        <Tag status style={styles.tagStatus}>
          {distance}
          {t("search:distance_km")}
        </Tag>
        {/* <Icon
            name="heart"
            solid={liked}
            color={liked ? colors.primaryLight : BaseColor.whiteColor}
            size={18}
            style={styles.iconGirdLike}
          /> */}
      </TouchableOpacity>
      {/* <Text
        footnote
        semibold
        grayColor
        style={{marginTop: 5}}
        numberOfLines={1}
      >
        {license}
      </Text> */}
      <Text subhead semibold style={{ marginTop: 5 }} numberOfLines={1}>
        {title}
      </Text>
      <View style={styles.rowRate}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <StarRating
            disabled={false}
            starSize={10}
            maxStars={1}
            rating={1}
            fullStarColor={BaseColor.yellowColor}
          />
          <Tag rateSmall style={{ marginLeft: 5 }}>
            {rate}
          </Tag>
          <View>
            <Text caption2 grayColor numberOfLines={1}>
              {" "}
              · {t("enum:rating")}
              {"(" + totalReviews + ")"}
            </Text>
          </View>
        </View>
        {/* <Text caption2 grayColor numberOfLines={1}>
        </Text> */}
      </View>
    </View>
  );
};

export default SitterItem;

const styles = StyleSheet.create({
  content: {
    flex: 1,
    width: "100%",
    maxWidth: 150,
  },
  tagStatus: {
    position: "absolute",
    top: 5,
    left: 5,
  },
  rowRate: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    justifyContent: "space-between",
  },
});
