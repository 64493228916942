import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet } from "react-native";
import { FeatherIcon, Icon } from "../../components";
import { useTheme } from "../../config";
import {
  HomeScreen,
  ChatListScreen,
  OrderScreen,
  ProfileScreen,
} from "../../screens";

export type UserTabParamList = {
  Home: undefined;
  Order: undefined;
  Chat: undefined;
  Profile: undefined;
};

const HOME = "Home";
const ORDER = "Order";
const CHAT = "Chat";
const PROFILE = "Profile";

const BottomTab = createBottomTabNavigator<UserTabParamList>();

const UserTabNavigator = () => {
  const { t } = useTranslation();
  const { baseColors, colors } = useTheme();
  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={({ route }) => ({
        tabBarInactiveTintColor: baseColors.grayColor,
        tabBarActiveTintColor: colors.accent,
        tabBarStyle: { display: Platform.OS === "web" ? "none" : "flex" },
        headerShown: false,
        tabBarLabelStyle: {
          fontSize: 12,
        },
        title: `${t(`tabs:${route.name.toLowerCase()}`)}`,
        tabBarIcon: ({ size, focused, color }) => {
          let iconName: string;
          let iconSize: number = Platform.OS === "ios" ? size : size * 0.8;
          let rn = route.name;
          switch (rn) {
            case HOME:
              iconName = "home";
              break;
            case ORDER:
              iconName = "calendar";
              break;
            case CHAT:
              iconName = "message-square";
              break;
            case PROFILE:
              iconName = "user";
              break;
            default:
              iconName = "";
              break;
          }
          return <FeatherIcon name={iconName} size={iconSize} color={color} />;
        },
      })}
    >
      <BottomTab.Screen name={HOME} component={HomeScreen} />
      <BottomTab.Screen name={ORDER} component={OrderScreen} />
      <BottomTab.Screen name={CHAT} component={ChatListScreen} />
      <BottomTab.Screen name={PROFILE} component={ProfileScreen} />
    </BottomTab.Navigator>
  );
};

export default UserTabNavigator;

const styles = StyleSheet.create({});
