import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useRef, useState } from "react";
import { Image } from "../ui";
import { ICarouselInstance } from "react-native-reanimated-carousel";
import { BaseColor } from "../config";
import { CarouselProps } from "../config/typography";
import dynamic from "next/dynamic";

const width = Dimensions.get("window").width;

// We need `{ssr: false}` otherwise there'll be a "Cannot use import statement outside a module" error.
const Carousel = dynamic(() => import("react-native-reanimated-carousel"), {
  ssr: false,
});

export default (props: CarouselProps) => {
  const { images, onPress } = props;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const ref = useRef<ICarouselInstance>(null);
  return (
    <>
      <View style={{ alignItems: "center" }}>
        <Carousel
          vertical={false}
          width={width}
          height={width * 0.5}
          ref={ref}
          style={{ width: "100%" }}
          autoPlay={false}
          autoPlayInterval={2000}
          onProgressChange={(_, absoluteProgress) => {
            // (progressValue.value = absoluteProgress)
          }}
          onScrollEnd={(index) => {
            setCurrentIndex(index);
          }}
          data={images || []}
          snapEnabled={true}
          pagingEnabled={true}
          mode="parallax"
          modeConfig={{
            parallaxScrollingScale: 0.9,
            parallaxScrollingOffset: 50,
          }}
          renderItem={({ item }: any) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  onPress(item);
                }}
              >
                <Image
                  source={item}
                  style={{ width: "100%", height: width / 2 }}
                  resizeMode="cover"
                />
              </TouchableOpacity>
            );
          }}
        />
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: 100,
            alignSelf: "center",
            position: "absolute",
            bottom: 25,
          }}
        >
          {images?.map((_, index) => {
            return (
              <PaginationItem
                backgroundColor={BaseColor.color2}
                currentIndex={currentIndex}
                index={index}
                key={index}
                isRotate={false}
                length={images.length}
                onPress={() => {
                  ref.current?.scrollTo({ index: index, animated: true });
                }}
              />
            );
          })}
        </View>
      </View>
    </>
  );
};

interface PaginationItemProps {
  index: number;
  backgroundColor: string;
  length: number;
  currentIndex: number;
  isRotate?: boolean;
  onPress?: () => void;
}
const PaginationItem = (props: PaginationItemProps) => {
  const { index, length, backgroundColor, isRotate, onPress, currentIndex } =
    props;
  const width = 10;
  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          backgroundColor:
            currentIndex === index ? BaseColor.color2 : BaseColor.whiteColor,
          width,
          height: width,
          borderRadius: 50,
          overflow: "hidden",
          transform: [
            {
              rotateZ: isRotate ? "90deg" : "0deg",
            },
          ],
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.2,
          shadowRadius: 1.41,

          elevation: 2,
        }}
      >
        {/* <Animated.View
          style={[
            {
              borderRadius: 50,
              backgroundColor,
              flex: 1,
            },
            animStyle,
          ]}
        /> */}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({});
