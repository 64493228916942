// Copy from https://gitlab.com/fluv/fluv_global/-/blob/main/src/screens/pets/PetProfileManagerScreen.tsx#L1-199
import React, { useCallback, useState } from "react";
import { View, StyleSheet } from "react-native";
import { BaseStyle } from "../../config";
import { Header, Icon } from "../../components";
import { Text } from "../../ui";
import { useTranslation } from "react-i18next";
import { BaseColor } from "../../config";
import { TCertificateInput, updateCertificate } from "../../models";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useNavigation } from "@react-navigation/native";
import { MainStackScreenNavigationProp } from "../../config/typography";
import CertificateEditableView from "./CertificateEditableView";

export default function CertificateCreateScreen({ route }: any) {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { t } = useTranslation();
  const [certificateInput, setCertificateInput] = useState<TCertificateInput | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeInput = useCallback((inputValue: TCertificateInput | null) => {
    if (inputValue) {
      setCertificateInput(inputValue);
    }
  }, []);

  const handleCreateCertificate = useCallback(async () => {
    if (!certificateInput) {
      alert(t("certificate:must_fill_all_fields"));
      return;
    }
    setIsLoading(true);
    try {
      await updateCertificate(certificateInput);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
    navigation.navigate("CertificateListScreen", {});
  }, [certificateInput]);

  return (
    <View style={{ flex: 1 }}>
      <LoadingSpinner visible={isLoading} />
      <Header
        style={BaseStyle.menu}
        titleStyle={{ color: BaseColor.whiteColor }}
        title={t("certificate:add_certificate")}
        subTitle={""}
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} color={BaseColor.whiteColor} />;
        }}
        onPressLeft={() => {
          navigation.navigate("CertificateListScreen", {});
        }}
        renderRight={() => {
          return (
            <View>
              <Text whiteColor bold>
                {t("create")}
              </Text>
            </View>
          );
        }}
        onPressRight={handleCreateCertificate}
      />
      <CertificateEditableView onChangeInput={handleChangeInput} />
    </View>
  );
}
