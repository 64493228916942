import {
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { Icon } from "../index";
import { Flex } from "../../ui";
import { Button } from "../../ui";
import { BaseColor, useTheme } from "../../config";
/* @ts-ignore */
import moment from "moment";
import { useTranslation } from "react-i18next";
import { minWidth } from "../../config/theme";
import dynamic from "next/dynamic";

// We need `{ssr: false}` otherwise there'll be a "Cannot use import statement outside a module" error.
const Calendar: any = dynamic(
  import("react-native-calendars").then((module) => module.Calendar),
  { ssr: false }
);

type Props = {
  markingType: "dot" | "multi-dot" | "period" | "multi-period" | "custom";
  defaultDates: string[];
  disabledDays: number[];
  startSelected?: boolean;
  onSelectDates: (dateStrs: string[]) => void;
  dismissModal?: () => void;
  visable: boolean;
};

const CalendarPicker = (props: Props) => {
  const {
    markingType,
    dismissModal,
    onSelectDates,
    disabledDays,
    defaultDates,
    startSelected,
    visable,
  } = props;
  const { t } = useTranslation();
  const today = new Date();
  const { colors } = useTheme();
  const color = colors.accent;
  const [startStr, setStartStr] = useState<string | null>(null);
  const [endStr, setEndStr] = useState<string | null>(null);
  const [dates, setDates] = useState<any[]>([]);

  const markedDates = dates.reduce((result, dateStr, index) => {
    if (dates.length === 1) {
      return {
        [dateStr]: {
          selected: true,
          startingDay: true,
          endingDay: true,
          color,
          textColor: "white",
          selectedTextColor: "white",
        },
      };
    }
    if (index === 0) {
      result[dateStr] = {
        selected: true,
        startingDay: true,
        color,
        textColor: "white",
        selectedTextColor: "white",
      };
    } else if (index === dates.length - 1) {
      result[dateStr] = {
        selected: true,
        endingDay: true,
        color,
        textColor: "white",
        selectedTextColor: "white",
      };
    } else {
      result[dateStr] = {
        selected: true,
        color,
        textColor: "white",
        selectedTextColor: "white",
      };
    }
    return result;
  }, {});

  useEffect(() => {
    setDates(defaultDates);
    if (startSelected && defaultDates && defaultDates.length > 0) {
      setStartStr(defaultDates[0]);
    }
  }, []);

  const createDateRange = ({
    startStr,
    endStr,
  }: {
    startStr: string;
    endStr: string | null;
  }): string[] => {
    const dateRange: string[] = [];
    dateRange.push(startStr);

    if (startStr && endStr) {
      let start = moment(startStr).startOf("day").add(1, "days");
      const end = moment(endStr).startOf("day");
      while (end.isAfter(start)) {
        dateRange.push(start.format("YYYY-MM-DD"));
        start = start.add(1, "days");
      }
      dateRange.push(endStr);
    }
    return dateRange;
  };

  const handleSelectDate = ({
    dateString: selectedStr,
  }: {
    dateString: string;
  }) => {
    if (markingType === "period") {
      let period: {
        startStr: string;
        endStr: string | null;
      };

      if (!startStr || (startStr && endStr)) {
        period = { startStr: selectedStr, endStr: null };
      } else {
        const startDate = moment(startStr || undefined);
        const selectedDate = moment(selectedStr);
        if (selectedDate.isAfter(startDate)) {
          /* @ts-ignore */
          period = { startStr, endStr: selectedStr };
        } else {
          period = { startStr: selectedStr, endStr: null };
        }
      }
      const datesTemp = createDateRange(period);
      setStartStr(period.startStr);
      setEndStr(period.endStr);
      setDates(datesTemp);
    } else if (markingType === "custom") {
      setDates([selectedStr]);
    }
  };

  const handleSelect = () => {
    if (onSelectDates) onSelectDates(dates);
    if (dismissModal) dismissModal();
  };

  const handleClear = () => {
    if (onSelectDates) onSelectDates([]);
    if (dismissModal) dismissModal();
  };

  return (
    <Modal transparent={true} visible={visable}>
      <TouchableWithoutFeedback
        onPress={() => {
          if (dismissModal) dismissModal();
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(33, 33, 33, 0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 15,
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                minWidth: minWidth,
                height: "auto",
                maxHeight: "auto",
                paddingTop: 12,
                paddingBottom: 24,
                paddingHorizontal: 15,
              }}
            >
              <Flex paddingHorizontal={8} paddingBottom={24}>
                <Calendar
                  minDate={today.toString()}
                  maxDate={new Date(today.getTime() + 180 * 86400000).toString()}
                  onDayPress={handleSelectDate}
                  monthFormat="yyyy MM"
                  markingType={markingType}
                  markedDates={markedDates}
                  hideArrows={false}
                  renderArrow={(direction: any) => {
                    if (direction === "left") {
                      return (
                        <Icon
                          name="angle-left"
                          color={colors.primary}
                          size={18}
                        />
                      );
                    } else {
                      return (
                        <Icon
                          name="angle-right"
                          color={colors.primary}
                          size={18}
                        />
                      );
                    }
                  }}
                />
                <Flex paddingHorizontal={8} paddingTop={16}>
                  <Button
                    onPress={handleSelect}
                    style={{
                      backgroundColor: colors.accent,
                      borderRadius: 100,
                    }}
                  >
                    {t("enum:select")}
                  </Button>
                </Flex>
                <Flex paddingHorizontal={8} paddingTop={8}>
                  <Button
                    onPress={handleClear}
                    style={{ backgroundColor: "white" }}
                    styleText={{ color: colors.text }}
                  >
                    {t("enum:clear")}
                  </Button>
                </Flex>
              </Flex>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default CalendarPicker;

const styles = StyleSheet.create({});
