import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAuth } from "firebase/auth";
import {
  getProfile,
  updateLastCheckedInTime,
  updateOldUidFromProfile,
} from "./profile";

let sharedCurrentUserId: string | null;
let id: string | undefined = undefined;

export const checkIn = () => {
  const interval = setInterval(() => {
    if (!id) return;
    updateLastCheckedInTime();
    clearInterval(interval);
  }, 10);
};

export const getCurrentUserId = (): string => {
  const auth = getAuth();
  const { currentUser } = auth;
  getCurrentUserIdNew(currentUser)
  .then((result) => {
    id = result;
  });
  if (id !== undefined) {
    return id;
  }
  const myId = currentUser ? currentUser.uid : "";
  return myId;

  // if(sharedCurrentUserId === undefined) {
  //   const persistedAuthData = await AsyncStorage.getItem('Auth');
  //   if (persistedAuthData) {
  //     const { currentUserId } = JSON.parse(persistedAuthData) || {};
  //     sharedCurrentUserId = currentUserId || null
  //   } else {
  //     sharedCurrentUserId = null
  //   }

  //   return sharedCurrentUserId || currentUser.uid
  // }
};

let switchToUid = null;
export const getCurrentUserIdNew = async (currentUser: any) => {
  if (currentUser === null) return;
  const newProfile = await getProfile(currentUser.uid);
  if (currentUser.uid === "B5ibW5TMlMYkju8L7gblZOpm0Mt1") {
    if (switchToUid === null) {
      const input = String(prompt("請輸入保姆 Id", currentUser.uid));
      if (/^[0-9A-Za-z]{28}$/.test(input)) {
        switchToUid = input;
        await updateOldUidFromProfile(currentUser.uid, switchToUid);
      }
    }
    return switchToUid || currentUser.uid;
  } else if (!newProfile) {
    console.warn("cant get new profile");
    return undefined;
  } else if (newProfile.oldUid) {
    return newProfile.oldUid;
  }
  return newProfile?.id;
};

export const changeCurrentUser = (currentUserId: string | null): void => {
  sharedCurrentUserId = currentUserId;
  AsyncStorage.setItem(
    "Auth",
    JSON.stringify({
      currentUserId,
    })
  );
};
