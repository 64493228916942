import React, { useCallback, useState } from "react";
import {
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
} from "react-native";
import { BaseStyle, useTheme } from "../../config";
import { Header, Icon } from "../../components";
import { Text } from "../../ui";
import { UserData } from "../../fakedata";
import { useTranslation } from "react-i18next";
import { BaseColor } from "../../config";
import { TouchableOpacity } from "react-native-gesture-handler";
import { usePromise } from "../../hooks";
import {
  createPet,
  deletePet,
  getPet,
  TPetInput,
  updatePet,
} from "../../models";
import PetProfileEditView from "./PetProfileEditView";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";

export default function PetProfileManagerScreen({ route }: any) {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { petId, userId, sitterId, serviceId } = route.params;
  const [defaultInput, setDefaultInput] = useState<TPetInput | undefined>();
  const [petInput, setPetInput] = useState<TPetInput | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  usePromise(async () => {
    if (userId && petId && userId === "" && petId === "")
      return setDefaultInput(undefined);
    if (!userId || !petId) {
      return setDefaultInput(undefined);
    }
    const pet = await getPet(userId, petId);
    if (!pet) return setDefaultInput(undefined);

    const {
      avatarId,
      name,
      category,
      size,
      birthYear,
      birthMonth,
      gender,
      neuteredOrSpayed,
      regularVaccination,
      healthStatus,
      personalities = [],
    } = pet;

    setDefaultInput({
      avatarUriOrId: avatarId,
      name,
      category,
      size,
      birthYear,
      birthMonth,
      gender,
      neuteredOrSpayed,
      regularVaccination,
      healthStatus,
      personalities,
    });
  }, [userId, petId]);

  const handleChangeInput = useCallback((inputValue: TPetInput | null) => {
    if (inputValue) {
      setPetInput(inputValue);
    }
  }, []);

  const handleUpdateOrCreatePet = useCallback(async () => {
    if (petId !== "") {
      if (!petInput) {
        alert(t("pets:must_fill_all_fields"));
        return;
      }
      setIsLoading(true);
      try {
        await updatePet(petId, petInput);
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
      navigation.navigate("PetsScreen", {
        userId: userId,
      });
    } else {
      if (!petInput) {
        alert(t("pets:must_fill_all_fields"));
        return;
      }

      setIsLoading(true);
      try {
        await createPet(petInput);
      } catch (e) {
        // TODO: use snackbar to display the status
        console.warn(e);
      }
      setIsLoading(false);
      navigation.pop();
      // navigation.navigate("PetsScreen", {
      //   userId: userId
      // })
    }
  }, [petInput]);

  const handleDelete = useCallback(() => {
    const text = `${
      t("pet:confirm_delete_title") + "\n" + t("pet:confirm_delete_message")
    }`;
    if (confirm(text) == true) {
      deletePet(userId, petId);
      navigation.navigate("PetsScreen");
    } else {
    }
  }, [userId, petId]);

  return (
    <View style={{ flex: 1 }}>
      <LoadingSpinner visible={isLoading} />
      <Header
        style={BaseStyle.menu}
        titleStyle={{ color: BaseColor.whiteColor }}
        title={petId !== "" ? t("pets:update_pet") : t("pets:add_pet")}
        subTitle={""}
        renderLeft={() => {
          return (
            <Icon name="arrow-left" size={20} color={BaseColor.whiteColor} />
          );
        }}
        onPressLeft={() => {
          navigation.pop();
          // if(sitterId && serviceId && sitterId !== '' && serviceId !== '') {
          //   navigation.navigate('CreateOrderScreen', {
          //     serviceId,
          //     sitterId
          //   })
          // } else {
          //   navigation.navigate("PetsScreen", {
          //     userId
          //   })
          // }
        }}
        renderRight={() => {
          return (
            <View>
              <Text whiteColor bold>
                {petId && petId !== "" ? t("update") : t("create")}
              </Text>
            </View>
          );
        }}
        onPressRight={handleUpdateOrCreatePet}
      />
      <PetProfileEditView
        defaultInput={defaultInput}
        onChangeInput={handleChangeInput}
        onDelete={handleDelete}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  contentTitle: {
    alignItems: "flex-start",
    width: "100%",
    height: 32,
    justifyContent: "center",
    marginTop: 10,
  },
  contain: {
    alignItems: "center",
    padding: 20,
  },
  textInput: {
    height: 46,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 5,
    padding: 10,
    width: "100%",
    color: BaseColor.grayColor,
  },
  thumb: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 20,
  },
  selectContent: {
    width: "100%",
    flex: 1,
  },
  selectButton: {
    padding: 10,
    height: 46,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    color: BaseColor.grayColor,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 5,
    flexDirection: "row",
  },
});
