import * as ReactNavigation from "@react-navigation/native"
import { useRouter } from "solito/router";
import { getLinking } from "../navigation/RootNavigation.v1";

const linking = getLinking<any>();

function getPath(componentName: string, object: any) {
  let path;
  let query;
  if (componentName === "UserTab") {
    path = linking.config.screens.UserTab.screens[object.screen];
  } else {
    path = linking.config.screens[componentName];
    query = object;
  }
  return {
    pathname: "/" + path,
    query: query,
  };
}

export const useNavigation =
  process.env.NEXT_PUBLIC_ON === "1"
    ? function useNavigation<T>() {
        console.warn("useNavigation is deprecated, please use solito router ");
        const { push } = useRouter();
        const navigation = {
          navigate: (componentName: any, object: any) => {
            push(getPath(componentName, object));
          },
        };
        return navigation;
      }
    : ReactNavigation.useNavigation;
