import { usePromise } from "../hooks/promise";
import type * as configs from "../models/_config";
import { isFeatureEnabled } from "../models/_config";

interface FeatureFlagProps {
  id: configs.FeatureFlag["id"];
  children: () => JSX.Element;
}
export const FeatureFlag = ({
  id,
  children
}: FeatureFlagProps): JSX.Element | null => {
  const featureEnabled = usePromise(() => isFeatureEnabled(id), [id]) ?? false;
  return featureEnabled ? children() : null;
};
export const useFeatureFlag = (id: configs.FeatureFlag["id"]) =>
  usePromise(() => isFeatureEnabled(id), []) ?? false;
