import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  where,
  deleteField,
} from "firebase/firestore";

import { Dimensions } from "react-native";
import { auth, db } from "../config/firebase";
import { CertificateType } from "../enums";
import { generateId, removeEmpty } from "../utils";
import {
  getPictureSize,
  getStoragePrivateUrl,
  getStoragePublicUrl,
} from "../utils/url";
import { getCurrentUserId } from "./auth";
import { isUri, uploadImage } from "./storage";
import { TSubscription } from "./types";
import { ProfileApi } from "../typescript-axios-client-generated";
import { toDocSnapDate, toDocSnap } from "./ex";

const screenWidth = Dimensions.get("window").width;

export type TBusiness = {
  owerId: string;
  userIds: Array<string>;
};

export type TCertificate = {
  id: string;
  type: CertificateType;
  category?: string;
  pictureId: string;
};

export type TProfile = {
  id: string;
  createdAt: number;
  updatedAt: number;
  facebookId?: string;
  facebookEmail?: string;
  appleEmail?: string;
  coverId?: string;
  avatarId?: string;
  displayName: string;
  description?: string;
  email?: string;
  phone?: string;
  certificates: TCertificate[];
  inHouseRating: number;
  lastCheckedInAt: Date;
  avgRating: number;
  totalReviews: number;
  totalComments: number;
  oldUid: string;
  isAvailableHoliday: boolean | undefined;
};

export type TProfileInput = {
  coverUriOrId?: string;
  avatarUriOrId?: string;
  displayName: string;
  description?: string;
  email?: string;
  phone?: string;
};

// Copy from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/models/profile.ts#L55-59
export type TCertificateInput = {
  type: CertificateType;
  category?: string;
  pictureUriOrId: string;
};

export const profileRef = collection(db, "profiles");

export const getProfileDoc = (id: string) => {
  return doc(profileRef, id);
};

export const toProfile = (
  snapshot: DocumentSnapshot<DocumentData>
): TProfile | undefined => {
  if (!snapshot.data()) return undefined;

  const {
    createdAt,
    updatedAt,
    facebookId,
    facebookEmail,
    appleEmail,
    coverId,
    avatarId,
    displayName,
    description,
    email,
    phone,
    certificates = {},
    inHouseRating = 6,
    lastCheckedInAt,
    // score 60
    avgRating = 5,
    totalReviews = 0,
    totalComments = 0,
    oldUid,
    isAvailableHoliday = undefined,
  } = snapshot.data() || {};

  return {
    id: snapshot.id,
    createdAt: createdAt?.second * 1000,
    updatedAt: updatedAt?.second * 1000,
    facebookId: facebookId || null,
    facebookEmail: facebookEmail || null,
    appleEmail: appleEmail || null,
    coverId: coverId || null,
    avatarId: avatarId || null,
    displayName: displayName || null,
    description: description || null,
    email: email || null,
    phone: phone || null,
    certificates:
      certificates && Object.keys(certificates).map((id) => {
        const { type, category, pictureId } = certificates[id];
        return {
          id,
          type,
          category: category || null,
          pictureId,
        };
      }) || null,
    inHouseRating: inHouseRating || null,
    lastCheckedInAt: lastCheckedInAt?.toDate() || null,
    avgRating: avgRating || null,
    totalReviews: totalReviews || null,
    totalComments: totalComments || null,
    oldUid: oldUid || null,
    isAvailableHoliday: isAvailableHoliday || null,
  };
};

export const getProfilePictureUrl = (
  userId?: string,
  pictureId?: string
): string | undefined => {
  if (!userId || !pictureId) return "https://imgur.com/03aImsh.jpg";
  if (isUri(pictureId)) return pictureId;

  return getStoragePublicUrl(
    `users_public/${userId}/profile_pictures/${pictureId}_thumb_${getPictureSize(
      screenWidth
    )}x.jpg`
  );
};

export const getProfile = async (
  userId: string
): Promise<TProfile | undefined> => {
  if (userId === "" || userId === undefined) return;
  const profileSnap = getProfileDoc(userId);
  if (!profileSnap) return undefined;

  const snapshot = await getDoc(profileSnap);

  return toProfile(snapshot);
};

export type TCreateProfileInput = {
  name: string;
  avatarUrl?: string;
  [key: string]: unknown;
};

export const createProfile = async (
  profileInput: TCreateProfileInput
): Promise<void> => {
  const { name, avatarUrl, ...others } = profileInput;
  const { currentUser } = auth;
  if (!currentUser) return;
  const myId = getCurrentUserId();

  const profile = await getDoc(getProfileDoc(myId));
  if (profile.exists()) return;

  try {
    // upload to cloud storage
    const avatarId = avatarUrl
      ? await uploadImage(avatarUrl, `users_public/${myId}/profile_pictures`)
      : null;
    await setDoc(
      getProfileDoc(myId),
      removeEmpty({
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        lastCheckedInAt: serverTimestamp(),
        displayName: name,
        avatarId: avatarId,
        ...others,
      }),
      { merge: true }
    );
  } catch (error) {
    signOut(auth);
    throw error;
  }
};

export const updateProfile = async (profile: TProfileInput): Promise<void> => {
  const { currentUser } = auth;
  if (!currentUser) return;
  const myId = getCurrentUserId();
  if (!myId) return;
  const profileDoc = getProfileDoc(myId);
  const {
    coverUriOrId,
    avatarUriOrId,
    displayName,
    description,
    email,
    phone,
  } = profile;
  const [coverId, avatarId] = await Promise.all([
    coverUriOrId
      ? uploadImage(coverUriOrId, `users_public/${myId}/profile_pictures`)
      : Promise.resolve(undefined),
    avatarUriOrId
      ? uploadImage(avatarUriOrId, `users_public/${myId}/profile_pictures`)
      : Promise.resolve(undefined),
  ]);

  await setDoc(
    profileDoc,
    removeEmpty({
      updatedAt: serverTimestamp(),
      coverId,
      avatarId,
      displayName,
      description,
      email,
      phone,
    }),
    { merge: true }
  );
};

export const updateLastCheckedInTime = async (): Promise<void> => {
  const myId = await getCurrentUserId();
  if (!myId) return;

  const profileDoc = getProfileDoc(myId);

  await setDoc(
    profileDoc,
    {
      lastCheckedInAt: serverTimestamp(),
    },
    { merge: true }
  );
};

export const updateIsAvailableHoliday = async (
  isAvailableHoliday: boolean
): Promise<void> => {
  const myId = await getCurrentUserId();
  if (!myId) return;

  const profileDoc = getProfileDoc(myId);

  await setDoc(
    profileDoc,
    removeEmpty({
      isAvailableHoliday,
    }),
    { merge: true }
  );
};

export const subscribeProfile: TSubscription<{ userId?: string }, TProfile> = ({
  variables,
  onChange,
}) => {
  const { userId } = variables || {};
  if (!userId) return () => {};

  const profileDoc = getProfileDoc(userId);

  return onSnapshot(profileDoc, (snapshot) => {
    if (!onChange) return;
    onChange(toProfile(snapshot) || null);
  });
};

export const getProfileWithEmail = async (email: string, newUid: string) => {
  if (!email && email === "") {
    console.warn("new uid has no email");
    return;
  }
  try {
    const profileQuery = query(profileRef, where("facebookEmail", "==", email));
    const profiles = await getDocs(profileQuery);
    if (profiles.size <= 0) return undefined;
    // sort function
    // reduce function
    return toProfile(
      profiles.docs.filter((profile) => profile.id !== newUid)[0]
    );
  } catch {
    return undefined;
  }
};

export const updateOldUidFromProfile = async (
  uid: string,
  oldUid: string | undefined
) => {
  if (!oldUid) return;
  const profileDoc = getProfileDoc(uid);

  await setDoc(
    profileDoc,
    removeEmpty({
      updatedAt: serverTimestamp(),
      oldUid: oldUid,
    }),
    { merge: true }
  );
};

// Copy from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/models/profile.ts#L124-129
export const getCertificatePictureUrl = async (
  userId?: string,
  pictureId?: string
): Promise<string | undefined> => {
  if (!userId || !pictureId) return undefined;
  if (isUri(pictureId)) return pictureId;

  return getStoragePrivateUrl(
    `users_private/${userId}/certificate_pictures/${pictureId}.jpg`
  );
};

// Copy from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/models/profile.ts#L255-286
export const updateCertificate = async (
  certificateInput: TCertificateInput
): Promise<void> => {
  const myId = await getCurrentUserId();
  if (!myId) return;

  const profileDoc = getProfileDoc(myId);

  const { type, category, pictureUriOrId } = certificateInput;

  let categoryStr = "";
  if (category === "OTHERS") {
    categoryStr = `_o${generateId(8)}`;
  } else if (category) {
    categoryStr = `_${category}`;
  }
  const certificateId = `${type}${categoryStr}`;

  const pictureId = await uploadImage(
    pictureUriOrId,
    `users_private/${myId}/certificate_pictures`
  );

  await setDoc(
    profileDoc,
    {
      updatedAt: serverTimestamp(),
      certificates: {
        [certificateId]: removeEmpty({
          type,
          category,
          pictureId,
        }),
      },
    },
    { merge: true }
  );
};

export const getExProfile = async (
  profileId: string
): Promise<TProfile | undefined> => {
  if (profileId === "") return;
  const axiosResponse = await new ProfileApi().getProfileById(profileId);
  axiosResponse.data.data.lastCheckedInAt = toDocSnapDate(
    axiosResponse.data.data.lastCheckedInAt
  );

  const snapshot = toDocSnap(profileId, axiosResponse);

  //@ts-ignore
  return toProfile(snapshot);
};

// Copy from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/models/profile.ts#L288-297
export const deleteCertificate = async (
  certificateId: string
): Promise<void> => {
  const myId = await getCurrentUserId();
  if (!myId) return;
  const profileDoc = getProfileDoc(myId);

  await setDoc(
    profileDoc,
    {
      updatedAt: serverTimestamp(),
      certificates: {
        [`${certificateId}`]: deleteField(),
      },
    },
    { merge: true }
  );
};

const ACTIVE_DURATION = 30 * 24 * 60 * 60 * 1000;
export const isProfileActive = ({ lastCheckedInAt }: TProfile) =>
  new Date(Date.now() - ACTIVE_DURATION) < lastCheckedInAt
