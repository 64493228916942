import { StyleSheet } from "react-native";
import { BaseColor } from "./theme";

/**
 * Common basic style defines
 */
export const BaseStyle = StyleSheet.create({
  textInput: {
    height: 46,
    borderRadius: 5,
    paddingHorizontal: 10,
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  headerLogoStyle: {
    width: 124,
    height: 48,
  },
  safeAreaView: {
    flex: 1,
  },
  menu: {
    backgroundColor: BaseColor.color1,
    paddingVertical: 5,
  },
});
