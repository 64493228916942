import { Platform, StyleSheet } from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  AnalyzeScreen,
  AnnouncementScreen,
  CalendarScreen,
  ChatListScreen,
  ProfileScreen,
} from "../../screens";
import { FeatherIcon } from "../../components";
import { useTheme } from "../../config";
export type SitterTabParamList = {
  Announcement: undefined;
  Chat: undefined;
  Calendar: undefined;
  Analyze: undefined;
  Profile: undefined;
};
// Screen Name
const ANNOUNCEMENTNAME = "Announcement";
const CHATNAME = "Chat";
const CALENDARNAME = "Calendar";
const ANALYZE = "Analyze";
const PROFILENAME = "Profile";

const BottomTab = createBottomTabNavigator<SitterTabParamList>();

const SitterTabNavigator = () => {
  const { t } = useTranslation();
  const { baseColors, colors } = useTheme();
  return (
    <BottomTab.Navigator
      initialRouteName="Announcement"
      screenOptions={({ route }) => ({
        tabBarInactiveTintColor: baseColors.grayColor,
        tabBarActiveTintColor: colors.accent,
        headerShown: false,
        tabBarLabelStyle: {
          fontSize: 12,
        },
        title: `${t(`tabs:${route.name.toLowerCase()}`)}`,
        tabBarIcon: ({ size, focused, color }) => {
          let iconName: string;
          let iconSize: number = Platform.OS === "ios" ? size : size * 0.8;
          let rn = route.name;
          switch (rn) {
            case ANNOUNCEMENTNAME:
              iconName = "at-sign";
              break;
            case CHATNAME:
              iconName = "message-square";
              break;
            case CALENDARNAME:
              iconName = "calendar";
              break;
            case ANALYZE:
              iconName = "pie-chart";
              break;
            case PROFILENAME:
              iconName = "user";
              break;
            default:
              iconName = "";
              break;
          }
          return <FeatherIcon name={iconName} size={iconSize} color={color} />;
        },
      })}
    >
      <BottomTab.Screen
        name={ANNOUNCEMENTNAME}
        component={AnnouncementScreen}
      />
      <BottomTab.Screen name={CHATNAME} component={ChatListScreen} />
      <BottomTab.Screen name={CALENDARNAME} component={CalendarScreen} />
      <BottomTab.Screen name={ANALYZE} component={AnalyzeScreen} />
      <BottomTab.Screen name={PROFILENAME} component={ProfileScreen} />
    </BottomTab.Navigator>
  );
};

export default SitterTabNavigator;
