// Update from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/enums/CertificateType.ts#L1-51
import { BaseColor } from "../config";

/*
single digits values are deprecated, please use 3-digit values, 1xx for green,
2xx for pink, 3xx for purple, 5xx for orange. xx is the order of the appearence
in the CertificateListScreen.tsx
*/
export enum CertificateType {
  PCRC = 1, // 警察刑事紀錄證明（良民證）Police Criminal Record Certificate
  PET_CARER_LICENSE = 101,
  PET_SITTER = 102,
  BUSINESS_PERMIT = 2, // 特定寵物業許可證（特寵證）
  SUPER_SITTER = 201,
  VETERINARIAN = 301, // 獸醫執照
  VETERINARIAN_PRACTICE = 302, // 獸醫執業執照
  VETERINARY_AIDE = 303, // 獸醫佐執照（已停止頒發）
  VET_ASSISTANT = 304,
  // 4 is reserved for future usage
  PET_GROOMER = 5, // 美容師執照
  PET_TRAINER = 501,
}

export const CertificateTypeUtil = {
  list: (): CertificateType[] => [
    CertificateType.PCRC,
    CertificateType.PET_CARER_LICENSE,
    CertificateType.PET_SITTER,
    CertificateType.BUSINESS_PERMIT,
    CertificateType.SUPER_SITTER,
    CertificateType.VETERINARIAN,
    CertificateType.VETERINARIAN_PRACTICE,
    CertificateType.VETERINARY_AIDE,
    CertificateType.VET_ASSISTANT,
    CertificateType.PET_GROOMER,
    CertificateType.PET_TRAINER,
  ],
};

export const CERTIFICATE_TYPE_COLORS = Object.freeze({
  [CertificateType.PCRC]: BaseColor.color3,
  [CertificateType.PET_CARER_LICENSE]: BaseColor.color3,
  [CertificateType.PET_SITTER]: BaseColor.color3,
  [CertificateType.BUSINESS_PERMIT]: BaseColor.color3,
  [CertificateType.SUPER_SITTER]: BaseColor.color2,
  [CertificateType.VETERINARIAN]: BaseColor.color4,
  [CertificateType.VETERINARIAN_PRACTICE]: BaseColor.color4,
  [CertificateType.VETERINARY_AIDE]: BaseColor.color4,
  [CertificateType.VET_ASSISTANT]: BaseColor.color4,
  [CertificateType.PET_GROOMER]: BaseColor.color5,
  [CertificateType.PET_TRAINER]: BaseColor.color5,
});
