export const RECOMMEND_SITTER_FORM_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLSfRzdRuDx0tCMgQOWWjdNylibTKOGmHU3_93h8gwn-6cGGaFg/viewform?usp=pp_url'
export const RECOMMEND_SITTER_TRACKING_FORM_LINK = 'https://bit.ly/40OmaYF';

export interface RecommendSitter {
  typeName?: string,
  startDate?: string,
  endDate?: string,
  areaCode?: string,
  petDetail?: string,
  address?: string,
  petCategorys?: string[],
  notes?: string,
  consumerId?: string
}

export const Form = {
  getRecommendSitterLink: (formData: RecommendSitter) => {
    if (Object.keys(formData).length === 0) return RECOMMEND_SITTER_TRACKING_FORM_LINK;
    const {typeName, startDate, endDate, areaCode, petDetail, address, petCategorys, notes, consumerId} = formData
    const params = new URLSearchParams();
    params.append('entry.515520096', typeName ?? '');
    params.append('entry.396347807', startDate ?? '');
    params.append('entry.1821029185', endDate ?? '');
    params.append('entry.1310629744', address ?? '');
    params.append('entry.1780930117', petDetail ?? '');
    params.append('entry.1436043323', notes ?? '' );
    params.append('entry.1255443787', consumerId ?? '');
    params.append('entry.362837298', areaCode ?? '');
    if (petCategorys) {
      petCategorys.forEach((petCategory) => params.append('entry.296349694', petCategory));
    }
    return RECOMMEND_SITTER_FORM_LINK+'&'+params.toString()
  }
}
