import { StyleSheet, Text, View } from "react-native";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { changeCurrentUser, getCurrentUserId } from "../models/auth";
import { getAuth, onIdTokenChanged } from "firebase/auth";
import jwtDecode from "jwt-decode";
import { getBrand } from "../models";
import { useSubscription } from "../hooks";
import { subscribeAccess } from "../models/access";

export type TAuth = {
  loading: boolean;
  userId: string;
  realUserId: string;
  changeAccount: (userId: string | null) => void;
};

const defaultAuth: TAuth = {
  loading: true,
  userId: "",
  realUserId: "",
  changeAccount: () => {},
};

const AuthContext = React.createContext(defaultAuth);

export const Auth = React.memo<{ children: ReactNode }>((props) => {
  const { children } = props;

  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [realUserId, setRealUserId] = useState("");

  const changeAccount = (newUserId: string | null) => {
    const currentUserId = getCurrentUserId();
    setUserId(newUserId || "");
    changeCurrentUser(newUserId);
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        user.getIdToken().then((newToken) => {
          setToken(newToken);

          const { role } = jwtDecode(newToken) || ({} as any);
        });
      }

      const realMyId = user?.uid || null;
      setRealUserId(realMyId || "");

      const currentMyId = await getCurrentUserId();
      if (
        !realMyId ||
        !currentMyId ||
        currentMyId === realMyId ||
        currentMyId === ""
      ) {
        changeAccount(realMyId);
      } else {
        const brand = await getBrand(currentMyId);
        const { id: brandId = null, managers = [] } = brand || {};
        const activeManagerIds = managers
          .filter((m) => m.active)
          .map((m) => m.id);

        if (activeManagerIds.includes(realMyId)) {
          changeAccount(brandId);
        } else {
          changeAccount(realMyId);
        }

        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const access = useSubscription({
    subscription: subscribeAccess,
    variables: {
      userId: realUserId,
    },
  });
  const { updatedAt: accessLastUpdateAt } = access || {};

  useEffect(() => {
    if (token) {
      const { iat } = jwtDecode(token) || ({} as any);
      const tokenIssueAt = iat * 1000;

      if (
        tokenIssueAt &&
        accessLastUpdateAt &&
        tokenIssueAt < accessLastUpdateAt
      ) {
        const user = auth.currentUser;
        if (user) user.getIdToken(true);
      }
    }
  }, [token, accessLastUpdateAt]);

  return (
    <AuthContext.Provider
      value={{ loading, userId, realUserId, changeAccount }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export const useAuth = (): TAuth => useContext(AuthContext);
