import {
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React, { useMemo, useState } from "react";
import { BaseColor, useTheme } from "../../config";
import { Flex } from "../../ui";
import { Button } from "../../ui";
import { useTranslation } from "react-i18next";
import { minWidth } from "../../config/theme";

interface TimeModalProps {
  visible: boolean;
  onSelect: (time: number | undefined) => void;
  onDismiss: (visible: boolean) => void;
}

const TimePicker = (props: TimeModalProps) => {
  const { visible, onSelect, onDismiss } = props;
  const [selectedTime, setSelectedTime] = useState<number | undefined>(
    undefined
  );
  const { colors } = useTheme();
  const { t } = useTranslation();
  const HOURS = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];

  const hourOptions = useMemo(
    () =>
      HOURS.map((n) => ({
        title: `${n - 1}:00 ~ ${n}:00`,
        value: n,
      })),
    []
  );

  const handleClear = () => {
    onDismiss(false);
    onSelect(undefined);
    setSelectedTime(undefined);
  };

  const handleSelect = () => {
    onSelect(selectedTime);
    onDismiss(false);
  };

  return (
    <Modal transparent={true} visible={visible}>
      <TouchableWithoutFeedback
        onPress={() => {
          onDismiss(false);
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(33, 33, 33, 0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 15,
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                minWidth: minWidth,
                height: "auto",
                maxHeight: "auto",
                paddingTop: 12,
                paddingBottom: 24,
              }}
            >
              <ScrollView style={{ height: "auto", maxHeight: 300 }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {hourOptions.map((hour) => (
                    <View
                      key={hour.value}
                      style={{
                        width: "40%",
                        marginHorizontal: 5,
                        marginVertical: 5,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setSelectedTime(hour.value);
                        }}
                      >
                        <View
                          style={[
                            styles.itemContainer,
                            selectedTime === hour.value
                              ? styles.active
                              : { backgroundColor: colors.card },
                          ]}
                        >
                          <Text
                            style={[
                              selectedTime === hour.value
                                ? { color: BaseColor.color2 }
                                : {},
                            ]}
                          >
                            {hour.title}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  ))}
                </View>
              </ScrollView>
              <Flex paddingHorizontal={8} paddingTop={16}>
                <Button
                  onPress={handleSelect}
                  style={{ backgroundColor: colors.accent, borderRadius: 100 }}
                >
                  {t("enum:select")}
                </Button>
              </Flex>
              <Flex paddingHorizontal={8} paddingTop={8}>
                <Button
                  onPress={handleClear}
                  style={{ backgroundColor: "white" }}
                  styleText={{ color: colors.text }}
                >
                  {t("enum:clear")}
                </Button>
              </Flex>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default TimePicker;

const styles = StyleSheet.create({
  itemContainer: {
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  active: {
    borderColor: BaseColor.color2,
    borderWidth: 1,
    backgroundColor: "white",
    color: BaseColor.color2,
  },
});
