type TState = "initial" | "dropin" | "not_dropin";
type TUnguardedEventType =
  | "DROPIN"
  | "TAVERN"
  | "WALKING"
  | "BATHING"
  | "GROOMING"
  | 0
  | 1
  | 2
  | 3;
type TEventType =
  | "SELECT_INITIAL"
  | "SELECT_DROPIN"
  | "SELECT_NOT_DROPIN"
  | "SELECT_PRICELEVEL_0"
  | "SELECT_PRICELEVEL_NOT_0";
type TEventValue = {
  serviceType: string | number | undefined;
  priceLevel: number | undefined;
};
type TEvent = {
  type: TEventType | TUnguardedEventType;
  value: TEventValue;
};
type TNextState = TEventValue | undefined;
type TSitterManageMachine = {
  [key in TState]: {
    SELECT_INITIAL: (value: TEventValue) => TNextState;
    SELECT_DROPIN: (value: TEventValue) => TNextState;
    SELECT_NOT_DROPIN: (value: TEventValue) => TNextState;
    SELECT_PRICELEVEL_0?: (value: TEventValue) => TNextState;
    SELECT_PRICELEVEL_NOT_0?: (value: TEventValue) => TNextState;
  };
};

const sitterManageMachine: TSitterManageMachine = {
  initial: {
    SELECT_INITIAL: () => ({ serviceType: 0, priceLevel: 1 }),
    SELECT_DROPIN: () => ({ serviceType: "DROPIN", priceLevel: 0 }),
    SELECT_NOT_DROPIN: ({ serviceType }) => ({
      serviceType,
      priceLevel: 1,
    }),
  },
  dropin: {
    SELECT_INITIAL: () => ({ serviceType: 0, priceLevel: 1 }),
    SELECT_DROPIN: ({priceLevel}) => ({ serviceType: "DROPIN", priceLevel: priceLevel }),
    SELECT_NOT_DROPIN: ({ serviceType, priceLevel }) => ({
      serviceType,
      priceLevel: priceLevel === 0 ? 1 : priceLevel,
    }),
    SELECT_PRICELEVEL_0: () => ({
      serviceType: "DROPIN",
      priceLevel: 0,
    }),
    SELECT_PRICELEVEL_NOT_0: ({ priceLevel }) => ({
      serviceType: "DROPIN",
      priceLevel,
    }),
  },
  not_dropin: {
    SELECT_INITIAL: () => ({ serviceType: 0, priceLevel: 1 }),
    SELECT_DROPIN: () => ({
      serviceType: "DROPIN",
      priceLevel: 0,
    }),
    SELECT_NOT_DROPIN: ({ serviceType, priceLevel }) => ({
      serviceType,
      priceLevel,
    }),
    SELECT_PRICELEVEL_NOT_0: ({ serviceType, priceLevel }) => ({
      serviceType,
      priceLevel,
    }),
  },
};

function stateGuard(state: string): TState {
  switch (state) {
    case "DROPIN":
      return "dropin";
    case "TAVERN":
    case "WALKING":
    case "BATHING":
    case "GROOMING":
      return "not_dropin";
    default:
      return "initial";
  }
}

function eventGuard(event: TEvent): TEventType {
  switch (event.type) {
    case "DROPIN":
      return "SELECT_DROPIN";
    case "TAVERN":
    case "WALKING":
    case "BATHING":
    case "GROOMING":
      return "SELECT_NOT_DROPIN";
    case 0:
      return "SELECT_PRICELEVEL_0";
    case 1:
    case 2:
    case 3:
      return "SELECT_PRICELEVEL_NOT_0";
    default:
      return "SELECT_INITIAL";
  }
}

export function transition(currentState: string, event: TEvent): TNextState {
  const guardedState = stateGuard(currentState);
  const guardedEvent = eventGuard(event);
  const transititonEvent = sitterManageMachine[guardedState][guardedEvent];

  return transititonEvent && (transititonEvent(event.value) as TNextState);
}
