import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

export const getLinking = <T>() => {
  const linking: LinkingOptions<T> = {
    prefixes: [Linking.createURL("https://app.fluv.com")],
    config: {
      initialRouteName: "UserTab",
      screens: {
        Auth: "signin",
        UserTab: {
          initialRouteName: "Home",
          screens: {
            Home: "home",
            Order: "order",
            Chat: "chatlist",
            Profile: "user",
          },
        },
        SearchScreen: "search",
        ServiceDetailScreen: "service-detail",
        SitterDetailScreen: "sitter-detail",
        CreateOrderScreen: "create-order",
        OrderDetailScreen: "order-detail",
        OrderConfirmScreen: "order-confirm",
        PaymentScreen: "payment",
        ProfileEditScreen: "profile-edit",
        ChatRoomScreen: "chatroom",
        PetsScreen: "user/pet",
        PetProfileManagerScreen: "pet-profile-manager",
        CreateReviewScreen: "create-review",
        Verification: "accounts/me/verification/:code",
      },
    },
  };
  return linking;
};
