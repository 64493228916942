import { FlatList, StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import { AsyncStorage } from "../../components";
import { Text } from "../../ui";
import SitterItem from "../../components/SitterItem";
import { useTranslation } from "react-i18next";
import { useSubscription } from "../../hooks";
import {
  getProfilePictureUrl,
  subscribeNearBySitterService,
} from "../../models";
import { distance } from "../../utils";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";

const NearBySitter = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const [nearBySitters, setNearBySitters] = useState<any[]>([]);
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const payload = useSubscription({
    subscription: subscribeNearBySitterService,
    variables: {
      serviceType: 8,
    },
  });

  useEffect(() => {
    if (Object.keys(payload || {}).length <= 0) return;
    const services = payload?.searchResult;
    let sitters: any[] = [];
    services?.map((service) => {
      if (
        sitters.filter((sitter) => sitter.id === service.providerId).length <= 0
      ) {
        const distanceNumber = distance(
          location.latitude,
          location.longitude,
          service.place.location.latitude,
          service.place.location.longitude,
          "K"
        ).toFixed(2);
        if (parseInt(distanceNumber) > 20) return;
        const avatarUrl = getProfilePictureUrl(
          service.providerId,
          service.profile?.avatarId
        );
        sitters.push({
          ...service.profile,
          distanceNumber: distanceNumber,
          avatarUrl: avatarUrl,
        });
      }
    });
    setNearBySitters(
      sitters.sort((a, b) => {
        return parseFloat(a.distanceNumber) > parseFloat(b.distanceNumber)
          ? 1
          : -1;
      })
    );
  }, [payload, location]);

  useEffect(() => {
    AsyncStorage.getItem("currentLocation").then(
      (currentLocation: string | null) => {
        if (currentLocation !== null) {
          const location = currentLocation.split("&") as Array<string>;
          setLocation({
            latitude: parseFloat(location[2]),
            longitude: parseFloat(location[3]),
          });
        }
      }
    );
  }, []);
  return (
    <>
      <View>
        <Text
          title3
          semibold
          style={{ paddingVertical: 20, paddingHorizontal: 20 }}
        >
          {t("common:nearby_sitter", { serviceType: t("enum:type_TAVERN") })}
        </Text>
        <FlatList
          contentContainerStyle={{
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 20,
          }}
          horizontal={true}
          data={nearBySitters}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <View>
              <SitterItem
                style={{ marginRight: 20 }}
                title={item.displayName}
                image={item.avatarUrl}
                license={t("license:identity_verified")}
                totalReviews={item.totalReviews}
                rate={item.avgRating.toString()}
                distance={item.distanceNumber}
                onPress={() => {
                  navigation.navigate("SitterDetailScreen", {
                    sitterId: item.id,
                  });
                }}
              />
            </View>
          )}
        />
      </View>
    </>
  );
};

export default NearBySitter;

const styles = StyleSheet.create({});
