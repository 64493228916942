import { Platform, Linking } from "react-native";

import { TLocation } from "../models";

export const openMap = async (
  address: string,
  location: TLocation
): Promise<void> => {
  const { latitude, longitude } = location;

  const defaultScheme = "https://www.google.com/maps/@";
  const scheme = Platform.select({
    ios: "maps",
    android: "geo",
    default: defaultScheme,
  });
  const path = `${latitude},${longitude}?q=${address}`;
  const url = `${scheme}:${path}`;
  const supported = await Linking.canOpenURL(url);

  if (supported) {
    await Linking.openURL(url);
  } else {
    await Linking.openURL(`${defaultScheme}:${path}`);
  }
};
