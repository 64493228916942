import { KeyboardAvoidingView, StyleSheet, View } from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import { RouteProp, useRoute } from "@react-navigation/native";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";
import { MainStackParamList } from "../../navigation/RootNavigation";
import {
  getCurrentUserId,
  getProfilePictureUrl,
  subscribeProfile,
  createReview,
  getReview,
  TReview,
  getOrder,
  TOrder,
} from "../../models";
import { FeatherIcon, Header, Icon, StarRating } from "../../components";
import { useTranslation } from "react-i18next";
import { BaseStyle, useTheme } from "../../config";
import { Text, Image, TextInput } from "../../ui";
import { usePromise, useSubscription } from "../../hooks";
import LoadingSpinner from "../../components/LoadingSpinner";

const CreateReviewScreen = React.memo(() => {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const route = useRoute<RouteProp<MainStackParamList, "CreateReviewScreen">>();
  const { sitterId, orderId } = route.params;
  const { baseColors, colors } = useTheme();
  const { t } = useTranslation();
  const myId = getCurrentUserId();
  const reviewId = orderId || myId;

  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const order = usePromise(() => orderId && getOrder(orderId), [orderId]);

  const sitterProfile = useSubscription({
    subscription: subscribeProfile,
    variables: {
      userId: sitterId,
    },
  });

  const review: TReview | null = usePromise(async () => {
    if (!reviewId || !sitterId) return null;
    return await getReview(sitterId, reviewId);
  }, [reviewId, sitterId]);

  // This function parameter order type is TOrder or null, because usePromise possible null
  const canOrderAcceptReview = (
    orderId?: string,
    order: TOrder | null,
    myId: string
  ): boolean => {
    if (!orderId) return true;
    if (order === null) return true;
    if (order === undefined) return false;
    if (order?.providerId === myId) {
      return order.executed;
    } else {
      return order.checked;
    }
  };

  const { displayName, avatarId } = sitterProfile || {};
  const avatarUrl = getProfilePictureUrl(sitterId, avatarId);

  useEffect(() => {
    if (
      sitterId === myId ||
      review ||
      !canOrderAcceptReview(orderId, order, myId)
    ) {
      navigation.pop();
    }
  }, [myId, sitterId, review, orderId, order]);

  if (myId === "") {
    navigation.navigate("SitterDetailScreen", {
      sitterId,
    });
  }

  const handleChangeComment = useCallback(
    (value) => {
      setComment(value);
    },
    [comment]
  );

  const handleChangeRating = useCallback(
    (value) => {
      setRating(value);
    },
    [rating]
  );

  const onSubmit = async () => {
    if (!sitterId || sitterId === "" || rating === 0 || !myId || myId === "")
      return;
    try {
      setIsLoading(true);
      const result = await createReview(reviewId, {
        rating,
        comment,
        senderId: myId,
        senderSide: 2,
        recipientId: sitterId,
      });
      if (result) {
        setIsLoading(false);
        navigation.pop();
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <View>
      <LoadingSpinner visible={isLoading} />
      <Header
        title={t("review:add_review")}
        subTitle={""}
        style={BaseStyle.menu}
        titleStyle={{ color: baseColors.whiteColor }}
        onPressLeft={() => {
          navigation.pop();
        }}
        renderLeft={() => {
          return (
            <FeatherIcon name="x" size={20} color={baseColors.whiteColor} />
          );
        }}
        onPressRight={() => {
          onSubmit();
        }}
        renderRight={() => {
          return (
            <View>
              <Text
                style={{
                  color:
                    rating > 0 ? baseColors.whiteColor : colors.primaryLight,
                }}
              >
                {t("review:submit")}
              </Text>
            </View>
          );
        }}
      />
      <KeyboardAvoidingView>
        <View style={styles.contain}>
          <View style={styles.sitterContain}>
            <Image source={avatarUrl} style={styles.sitterAvatar} />
            <Text numberOfLines={1} style={styles.sitterDisplayName}>
              {displayName}
            </Text>
          </View>
          <View style={styles.starContain}>
            <StarRating
              selectedStar={handleChangeRating}
              rating={rating}
              fullStarColor={baseColors.color2}
            />
          </View>
          <View style={styles.textInputContain}>
            <TextInput
              value={comment}
              onFocus={() => {}}
              onChangeText={handleChangeComment}
              style={styles.textInput}
              placeholder={t("review:leave_your_review")}
            />
          </View>
        </View>
      </KeyboardAvoidingView>
    </View>
  );
});

export default CreateReviewScreen;

const styles = StyleSheet.create({
  contain: {
    flex: 1,
    paddingTop: 48,
    alignItems: "center",
    paddingHorizontal: 15,
  },
  sitterAvatar: {
    width: 72,
    height: 72,
    borderRadius: 36,
  },
  sitterDisplayName: {
    marginTop: 18,
    textAlign: "center",
  },
  sitterContain: {
    maxWidth: 280,
  },
  starContain: {
    marginTop: 18,
    maxWidth: 280,
  },
  textInputContain: {
    marginTop: 18,
    width: "100%",
  },
  textInput: {
    borderBottomColor: "gray",
    borderBottomWidth: 0.1,
  },
});
