//This is copied SitterManage.tsx file
import { Linking, ScrollView, StyleProp, StyleSheet, Touchable, TouchableOpacity, View, ViewStyle } from 'react-native'
import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'react-native-modal';
import { useTranslation } from 'react-i18next';
import { BaseColor, BaseStyle, useTheme } from '../../config';
import { Header, Icon, SelectModal } from '../../components';
import { Text, TextInput } from '../../ui'
import LoadingSpinner from '../../components/LoadingSpinner';
import { TProfile, getCurrentUserId, getProfile } from '../../models';
import { minWidth } from '../../config/theme';
import { Complain, ContextType, complainTypes } from '../../models/Complain';
import { usePromise } from '../../hooks';
import { useNavigation } from '../../hooks/useNavigation';
import { MainStackScreenNavigationProp } from '../../config/typography';
import { OFFICIAL_ACCOUNT_ID } from '../../constant/backend';

export interface ComplainContext {
  target: TProfile | undefined,
  contextId: string,
  contextType: ContextType
}

export interface ComplainModalProps {
  modalVisible: boolean,
  style?: StyleProp<ViewStyle>,
  onPress?: () => void;
  onDismiss: () => void;
  context: ComplainContext
}

const ComplainModal = React.memo((props: ComplainModalProps) => {
  const {modalVisible, style, onPress, onDismiss, context} = props;
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { target, contextId, contextType } =  context;
  const {t} = useTranslation();
  const {colors} = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [showComplainType, setShowComplainType] = useState(false);
  const [message, setMessage] = useState('');
  const [complainTypeId, setComplainTypeId] = useState('');
  const [isSend, setIsSend] = useState(false);
  const userId = getCurrentUserId();
  const myProfile = usePromise(async() => await getProfile(userId), [userId]);

  const complainTypesOption = complainTypes.map((complainType) => ({
    text: t(complainType.value),
    value: complainType.id,
  }));

  useEffect(() => {
    if(complainTypeId && (!['XCV5', '0FQF'].includes(complainTypeId) || message !== ""))  {
      setIsSend(true)
    } else {
      setIsSend(false)
    }
  }, [complainTypeId, message])

  const handleChangeMessage = useCallback((text) => {
    setMessage(text)
  }, [])

  const handleSend = async() => {
    if(!isSend || !myProfile) return;
    try {
      await Complain.send({
        source: myProfile,
        target: target,
        complainTypeId,
        message,
        contextId,
        contextType,
        t
      })
    } catch(e) {
      if (Complain.isSendError(e as Error)) {
        alert(t('Complain Fail'));
      } else if (Complain.isSendComplainTextError(e as Error)) {
        alert(t('Immediately notify the helper'));
      } else {
        throw e;
      }
    }
    onDismiss();
    navigation.navigate('ChatRoomScreen', {
      userId: OFFICIAL_ACCOUNT_ID,
      name: '毛小愛小幫手'
    })
  }

  return (
    <Modal
      isVisible={modalVisible}
      style={[style, styles.bottomModal]}
    >
      <LoadingSpinner visible={isLoading}/>
      <View style={[{backgroundColor: colors.card}, styles.contentBottom]}>
        <ScrollView>
          <Header
            style={BaseStyle.menu}
            titleStyle={{color: BaseColor.whiteColor}}
            title={t('Complain Page')}
            subTitle={''}
            onPressLeft={onDismiss}
            renderLeft={() => {
              return (
                <Icon name="times" size={20} style={{color: BaseColor.whiteColor}}/>
              )
            }}
            renderRight={() => {
              return (
                <View>
                  <Text lightPrimaryColor={!isSend} whiteColor={isSend} bold>{t('Send')}</Text>
                </View>
              )
            }}
            onPressRight={handleSend}
          />
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                paddingHorizontal: 20,
                marginTop: 20,
                backgroundColor: BaseColor.whiteColor,
              }}
              onPress={() => {setShowComplainType(true)}}
            >
              <View>
                <Text body2 semibold>{t('Complain Type')}</Text>
              </View>
              <View>
                <View style={styles.selectButton}>
                  {
                    complainTypeId !== '' ? (
                      <Text style={{maxWidth: 250, justifyContent: 'flex-end', textAlign: 'right'}}>
                        {t(complainTypes.find(ct => ct.id === complainTypeId).value)}
                      </Text>
                    ) : (
                      <Icon name="angle-down" size={18} style={{marginLeft: 10}}/>
                    )
                  }
                </View>
              </View>
            </TouchableOpacity>

          <View style={{paddingHorizontal: 20, marginTop: 10, marginBottom: 5}}>
            <Text grayColor>{t('Detailed Description')}</Text>
          </View>

          {/* Pet Name */}
          <View style={{
            backgroundColor: 'white',
            width: '100%',
            marginVertical: 5,
            paddingVertical: 5
          }}>
            <View style={{width: '100%', marginVertical: 10, paddingHorizontal: 20}}>
              <TextInput
                onChangeText={handleChangeMessage}
                onFocus={() => {}}
                placeholder={t('Please describe what happened (optional).')}
                value={message}
                style={{borderColor: BaseColor.dividerColor, paddingHorizontal: 10, borderWidth: 1}}
              />
            </View>
          </View>
        </ScrollView>
        <SelectModal
          visible={showComplainType}
          options={complainTypesOption}
          selected={complainTypesOption.find(option => option.value === complainTypeId)}
          onChange={(item: any) => {
            setShowComplainType(false)
            setComplainTypeId(item.value);
          }}
        />
      </View>
    </Modal>
  )
})

export default ComplainModal

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    width: '100%',
  },
  contentBottom: {
    width: '100%',
    minWidth: minWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flex: 1
  },
  contentSwipeDown: {
    paddingTop: 20,
    alignItems: 'flex-end',
  },
  selectButton: {
    padding: 10,
    minHeight: 46,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    color: BaseColor.grayColor,
    borderRadius: 5,
    flexDirection: 'row'
  },
  removePictureWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 999,
  },
  removePicture: {
    width: 24,
    height: 24,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
