import areas from "../constant/areas.json";
import serviceType from "../constant/serviceType.json";
import translation from "../../public/locales/zh-TW/search.json";
import memo from "../utils/memo.v1";
import { join } from "../utils/URL.v1";
import { checkLocationPermission } from "../utils/permission";
import { SortOptions, SortSelected } from "../constant/sortOptions";

export type ServiceAreaCode = string;

export type ServiceAreaCodes = ServiceAreaCode[];

export type ServiceType = number;

export interface Service {
  id: string;
  areaCodes: ServiceAreaCodes[];
  place: ServicePlace;
  type: ServiceType;
}

export interface ServiceNames {
  [key: string]: string;
}

export interface ServicePlace {
  areaCode: ServiceAreaCode;
  address: string;
}

export interface AreaNames {
  [key: string]: string[];
}

export const AreaName = {
  AREA_CODE_ERROR: "P32J",

  import: memo((areas) =>
    Object.fromEntries(
      areas.flatMap(({ name, areas }) =>
        Object.entries(areas).map(([areaCode, city]) => [
          areaCode,
          [name, city.name],
        ])
      )
    )
  ),

  get: (areaCode: ServiceAreaCode, areaNames: AreaNames = AreaName.import(areas)) => {
    const names = areaNames[areaCode];
    if (!names) {
      throw new Error("7QEM");
    }
    return names;
  },

  isAreaCodeError: (e) => e.message === AreaName.AREA_CODE_ERROR,

  toAreaCode: (cityName: string, areaName: string) => {
    const areaNames = AreaName.import(areas);
    const [areaCode, foundMutipleError] = Object.keys(areaNames).filter(
      (areaCode) =>
        areaNames[areaCode][0] === cityName &&
        areaNames[areaCode][1] === areaName
    );
    if (foundMutipleError) throw new Error("G3D5");
    if (!areaCode) throw new Error(AreaName.AREA_CODE_ERROR);
    return areaCode;
  },
};

const Service = {
  assertAreaCode: (service: Service) => {
    if (
      typeof service.place.areaCode !== "string" &&
      typeof service.place.address !== "string" &&
      !Array.isArray(service.areaCodes) &&
      service.areaCodes.length >= 1
    ) {
      throw new Error("2TAR");
    }
  },

  assertServiceId: (service: Service) => {
    if (typeof service.id !== "string") {
      throw new Error("MWHB");
    }
  },

  assertType: (service: Service) => {
    if (![3, 4, 5, 6, 8, 101, 102].includes(service.type)) {
      throw new Error("X582");
    }
  },

  AREA_CODE_ERROR: "PFNB",

  isAreaCodeError: (e) => e.message === Service.AREA_CODE_ERROR,

  getAreaCode: (service: Service) => {
    const {
      areaCodes,
      place: { areaCode, address },
    } = service;

    const code =
      /^\d{3}$/.exec(areaCode)?.[0] ??
      /^\d{3}(?=\D)/.exec(address)?.[0] ??
      areaCodes?.[0];

    if (typeof code !== "string") {
      throw new Error(Service.AREA_CODE_ERROR);
    }

    return code;
  },

  getDefaultSort: async (isReferrered: boolean) => {
    if ((await checkLocationPermission()) || isReferrered) {
      return SortOptions[2];
    }
    return SortSelected;
  },

  getId: (service: Service) => service.id,

  getType: (service: Service) => service.type,

  import: memo((firestoreExportServices) =>
    Object.entries(firestoreExportServices).map(([id, service]) => ({ id, ...service }))
  ),

  isId: (serviceId: string) => /^[0-9A-Za-z]{20}$/.test(serviceId),

  toLoc: (service: Service) => {
    Service.assertAreaCode(service);
    Service.assertType(service);
    Service.assertServiceId(service);

    const areaNames = AreaName.import(areas);
    const serviceNames = ServiceName.import(serviceType, translation);

    const areaCode = Service.getAreaCode(service);
    const names = AreaName.get(areaCode, areaNames);

    const type = Service.getType(service);
    const typeName = ServiceName.get(type, serviceNames);

    const id = Service.getId(service);

    return join("services", "台灣", ...names, typeName, id);
  },
};

export const ServiceName = {
  import: memo((serviceType, translation) =>
    Object.fromEntries(
      Object.entries(serviceType)
        .filter(([, { type }]) => type !== "help")
        .map(([, { type, name }]) => [type, translation[name]])
    )
  ),

  get: (type: ServiceType, serviceNames: ServiceNames) => {
    const serviceType = serviceNames[type];
    if (typeof serviceType !== "string") {
      throw new Error("M0MN", { cause: { serviceType } });
    }

    return serviceType;
  },

  getServiceTypeName: (type: ServiceType) => {
    if(!type) throw new Error("DE2D");
    const serviceNames = ServiceName.import(serviceType, translation);
    return ServiceName.get(type, serviceNames);
  },

  isServiceTypeError: (e) => e.message === ServiceName.SERVICE_TYPE_ERROR,

  SERVICE_TYPE_ERROR: "ME2N",

  toServiceType: (serviceName: string) => {
    const serviceNames = ServiceName.import(serviceType, translation);
    const [type, foundMutipleError] = Object.keys(serviceNames).filter(
      (type) => serviceNames[type] === serviceName
    );
    if (foundMutipleError) throw new Error("F6TN");
    if (!type) throw new Error(ServiceName.SERVICE_TYPE_ERROR);
    return type;
  },
};

export default Service;
