/* eslint-disable camelcase */
import en from "./en";
import ja from "./ja";
import zhTW from "./zh-TW";

const lang = {
  en,
  ja,
  "zh-TW": zhTW,
};

export default lang;
