import React, { useState } from 'react'
import { Icon } from '.'
import { BaseColor } from '../config'
import { TouchableOpacity } from 'react-native'
import ComplainModal, { ComplainContext } from '../screens/complain/ComplainModal';

interface ComplainProps {
  color?: string;
  style?: object;
  size?: number;
  context: ComplainContext
}

const Complain = ({...props}: ComplainProps) => {
  const { color, style, size, context } = props;
  const [showComplainModal, setShowComplainModal] = useState(false)
  const handleClose = () => {
    setShowComplainModal(false)
  }
  const handleOpen = () => {
    setShowComplainModal(true)
  }
  return (
    <>
      <TouchableOpacity
        style={{
          marginRight: 15,
          width: 32,
          height: 32,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 16,
          ...style
        }}
        onPress={handleOpen}
      >
        <Icon name="flag" size={size || 20} color={color || BaseColor.whiteColor}/>
      </TouchableOpacity>
      <ComplainModal
        modalVisible={showComplainModal}
        onDismiss={handleClose}
        context={context}
      />
    </>
  )
}

export default Complain
