import { BaseColor, BaseStyle, useTheme } from "../../config";
import { CertificateType } from "../../enums";
import { Header, Icon } from "../../components";
import { MainStackScreenNavigationProp } from "../../config/typography";
import { SectionList, StyleSheet, TouchableOpacity, View } from "react-native";
import { TCertificate } from "../../models/profile";
import { Text, Flex } from "../../ui";
import { getCurrentUserId, subscribeProfile } from "../../models";
import { useAuth } from "../../context/Auth";
import { useNavigation } from "@react-navigation/native";
import { useSubscription } from "../../hooks";
import { useTranslation } from "react-i18next";
import CertificateItem from "../../components/CertificateItem";
import Feather from "react-native-vector-icons/Feather";
import React, { useCallback } from "react";

export default function CertificateListScreen({ route }: any) {
  // const { userId } = route.params;
  useAuth();
  const userId = getCurrentUserId();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { colors } = useTheme();
  const { t } = useTranslation();

  const profile = useSubscription({
    subscription: subscribeProfile,
    variables: {
      userId,
    },
  });

  const { certificates = [] } = profile || {};

  const handleAddCertificate = useCallback(() => {
    navigation.navigate("CertificateCreateScreen", {});
  }, []);

  const handleViewCertificateDetail = (id: string, type: number, category: string, pictureId: string) => {
    navigation.navigate("CertificateUpdateScreen", {
      id: id,
      type: type,
      category: category,
      pictureUriOrId: pictureId,
    });
  };

  const keyExtractor = useCallback((item) => item.id, []);

  const renderItem = ({ item }: { item: TCertificate | { id: string } }) => {
    if ("id" in item && item.id === "add") {
      return (
        <TouchableOpacity
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            paddingHorizontal: 20,
            backgroundColor: BaseColor.whiteColor,
            marginVertical: 15,
            borderBottomWidth: 1,
            borderBottomColor: colors.card,
          }}
          onPress={handleAddCertificate}
        >
          <Flex.Row vCenter paddingVertical={12}>
            <Feather size={28} name="plus" color={BaseColor.grayColor} />
            <Flex paddingLeft={16}>
              <Text textColor style={{ fontSize: 16, fontWeight: "bold" }}>
                {t("certificate:add_certificate")}
              </Text>
            </Flex>
          </Flex.Row>
        </TouchableOpacity>
      );
    }

    return <CertificateItem item={item as TCertificate} onViewCertificateDetail={handleViewCertificateDetail} />;
  };

  // Copy from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/screens/certificate/CertificateListScreen.tsx#L64-126
  const petCareRelatedCerts: TCertificate[] = [];
  const veterinarianCerts: TCertificate[] = [];
  const otherPetServicesCerts: TCertificate[] = [];
  const specialCerts: TCertificate[] = [];

  certificates.forEach((cert) => {
    if (
      cert.type === CertificateType.PCRC ||
      cert.type === CertificateType.BUSINESS_PERMIT ||
      cert.type === CertificateType.PET_CARER_LICENSE ||
      cert.type === CertificateType.PET_SITTER
    ) {
      petCareRelatedCerts.push(cert);
    } else if (
      cert.type === CertificateType.VETERINARIAN ||
      cert.type === CertificateType.VETERINARIAN_PRACTICE ||
      cert.type === CertificateType.VETERINARY_AIDE ||
      cert.type === CertificateType.VET_ASSISTANT
    ) {
      veterinarianCerts.push(cert);
    } else if (cert.type === CertificateType.PET_GROOMER || cert.type === CertificateType.PET_TRAINER) {
      otherPetServicesCerts.push(cert);
    } else if (cert.type === CertificateType.SUPER_SITTER) {
      specialCerts.push(cert);
    }
  });

  type SectionsProps = {
    title?: string;
    data: (TCertificate | { id: string })[];
  };

  const sections: SectionsProps[] = [
    {
      data: [
        {
          id: "add",
        },
      ],
    },
  ];

  if (petCareRelatedCerts.length > 0) {
    sections.push({
      title: t("certificate:pet_care_related_certificates"),
      data: petCareRelatedCerts,
    });
  }
  if (veterinarianCerts.length > 0) {
    sections.push({
      title: t("certificate:veterinarian_certificates"),
      data: veterinarianCerts,
    });
  }
  if (otherPetServicesCerts.length > 0) {
    sections.push({
      title: t("certificate:other_pet_services_certificates"),
      data: otherPetServicesCerts,
    });
  }
  if (specialCerts.length > 0) {
    sections.push({
      title: t("certificate:special_type"),
      data: specialCerts,
    });
  }

  return (
    <View style={{ flex: 1, backgroundColor: colors.card }}>
      <Header
        title={t("certificate:certificate")}
        subTitle={""}
        style={BaseStyle.menu}
        titleStyle={{ color: BaseColor.whiteColor }}
        renderLeft={() => <Icon name="arrow-left" size={20} color={BaseColor.whiteColor} />}
        onPressLeft={() => {
          navigation.navigate("UserTab", { screen: "Home" });
        }}
        onPressRight={() => {}}
      />

      <SectionList
        sections={sections}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        renderSectionHeader={({ section: { title } }) => (
          <Text
            textColor
            style={{ paddingTop: 20, paddingHorizontal: 16, paddingBottom: 6, fontSize: 15, lineHeight: 18 }}
          >
            {title}
          </Text>
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({});
