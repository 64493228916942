import {
  KeyboardAvoidingView,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-native-modal";
import LoadingSpinner from "../../components/LoadingSpinner";
import { BaseColor, BaseStyle, useTheme } from "../../config";
import { ScrollView } from "react-native-gesture-handler";
import { Header, Icon } from "../../components";
import { Text, TextInput } from "../../ui";
import { t } from "i18next";
import { useAuth } from "../../context/Auth";
import { usePromise, useSubscription } from "../../hooks";
import {
  getMerchant,
  subscribeMerchant,
  TMerchant,
  updateMerchant,
} from "../../models/merchant";
import { minWidth } from "../../config/theme";
import { getCurrentUserId } from "../../models";

interface SalaryAccountProps {
  visible: boolean;
  onDismiss: () => void;
  style?: StyleProp<ViewStyle>;
}

const SalaryAccountScreen = (props: SalaryAccountProps) => {
  const { visible, style, onDismiss } = props;
  const [isLoading, setIsLoading] = useState(false);
  let { userId } = useAuth();
  userId = getCurrentUserId();
  const [bankCode, setBankCode] = useState<string>();
  const [bankBranchCode, setBankBranchCode] = useState<string>();
  const [bankAccount, setBankAccount] = useState<string>();

  const brand: TMerchant | undefined | null = useSubscription({
    subscription: subscribeMerchant,
    variables: {
      userId: userId,
    },
  });

  useEffect(() => {
    if (brand?.bank === undefined || brand.bank === null) return;
    setBankCode(brand.bank?.code);
    setBankBranchCode(brand.bank?.branchCode);
    setBankAccount(brand.bank?.account);
  }, [brand]);

  const { colors } = useTheme();

  const onUpdate = async () => {
    if (bankCode && bankBranchCode && bankAccount) {
      setIsLoading(true);
      try {
        await updateMerchant({
          bank: {
            code: bankCode,
            branchCode: bankBranchCode,
            account: bankAccount,
          },
        });
      } catch (e) {
        console.warn(e);
      }
      setIsLoading(false);
      onDismiss();
    }
  };

  const handleChangeBankCode = useCallback((text) => {
    if (isNaN(text)) return;
    setBankCode(text);
  }, []);

  const handleChangeBankBranchCode = useCallback((text) => {
    if (isNaN(text)) return;
    setBankBranchCode(text);
  }, []);

  const handleChangeBankAccount = useCallback((text) => {
    if (isNaN(text)) return;
    setBankAccount(text);
  }, []);

  return (
    <Modal isVisible={visible} style={[style, styles.bottomModal]}>
      <LoadingSpinner visible={isLoading} />
      <KeyboardAvoidingView
        style={[{ backgroundColor: colors.card }, styles.contentBottom]}
      >
        <ScrollView>
          <View>
            <Header
              style={BaseStyle.menu}
              titleStyle={{ color: BaseColor.whiteColor }}
              title={t("sitter_verifi:salary_account")}
              subTitle={""}
              onPressRight={onUpdate}
              renderRight={() => {
                return (
                  <Text style={{ color: BaseColor.whiteColor }}>
                    {t("common:done")}
                  </Text>
                );
              }}
              renderLeft={() => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      onDismiss();
                    }}
                  >
                    <Icon
                      name="times"
                      size={20}
                      style={{ color: BaseColor.whiteColor }}
                    />
                  </TouchableOpacity>
                );
              }}
            />
            {/* bank code */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginTop: 10,
                paddingVertical: 5,
                borderBottomWidth: 1,
                borderBottomColor: colors.card,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("sitter_verifi:bank_code")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                }}
              >
                <TextInput
                  onChangeText={handleChangeBankCode}
                  onFocus={() => {}}
                  placeholder={t("sitter_verifi:bank_code_placeholder")}
                  value={bankCode}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
            </View>

            {/*  bank_branch_code */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginTop: 10,
                paddingVertical: 5,
                borderBottomWidth: 1,
                borderBottomColor: colors.card,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("sitter_verifi:bank_branch_code")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                }}
              >
                <TextInput
                  onChangeText={handleChangeBankBranchCode}
                  onFocus={() => {}}
                  placeholder={t("sitter_verifi:bank_branch_code_placeholder")}
                  value={bankBranchCode}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
            </View>

            {/*  account */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginTop: 10,
                paddingVertical: 5,
                borderBottomWidth: 1,
                borderBottomColor: colors.card,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("sitter_verifi:bank_account")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                }}
              >
                <TextInput
                  onChangeText={handleChangeBankAccount}
                  onFocus={() => {}}
                  placeholder={t("sitter_verifi:bank_account_placeholder")}
                  value={bankAccount}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
            </View>
            <View
              style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 10 }}
            >
              <Text grayColor>{t("sitter_verifi:bank_account_alert")}</Text>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default SalaryAccountScreen;

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    width: "100%",
  },
  contentBottom: {
    width: "100%",
    minWidth: minWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flex: 1,
  },
});
