import { TSubscription } from "./types";
import { ServiceType } from "../enums";
import {
  collection,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../config/firebase";

export type BannerAction =
  | {
      type: "Search";
      serviceType?: ServiceType;
    }
  | {
      type: "Link";
      link: string;
    }
  | {
      type: "Chat";
      userId: string;
    }
  | {
      type: "Referral";
    };

export type Banner = {
  id: string;
  order: number;
  imageUrl: string;
  active: boolean;
  action: BannerAction;
  coupon?: {
    title: string;
    code: string;
  };
};

export const toBanner = (
  snapshot: DocumentSnapshot<DocumentData>
): Banner | null => {
  if (!snapshot.data()) return null;

  const { order, imageUrl, active, action, coupon } = snapshot.data() || {};
  return {
    id: snapshot.id,
    order,
    imageUrl,
    active,
    action: {
      type: action.type,
      link: action.link,
      serviceType: action.serviceType,
    },
    coupon,
  };
};

const bannerRef = collection(db, "banners");

export const subscribeBanners: TSubscription<null, Banner[]> = ({
  onChange,
}) => {
  return onSnapshot(bannerRef, (snapshot) => {
    if (!onChange) return;
    onChange(
      (snapshot.docs.map(toBanner).filter(Boolean) as Banner[])
        .filter((item) => item.active)
        .sort((a, b) => (a.order > b.order ? 1 : -1))
    );
  });
};
