// copied from fluv mobile src/helpers/link.ts
import dynamicLinks, { FirebaseDynamicLinksTypes } from './dynamic-links';
import queryString from 'query-string';

import { DYNAMIC_LINK_DOMAIN, PACKAGE_NAME, APPLE_STORE_ID, APPLE_STORE_PROVIDER_TOKEN } from '../constant/backend';

export const getShortLink = async (
  link: string,
  openApp: boolean,
  analytics: FirebaseDynamicLinksTypes.DynamicLinkAnalyticsParameters,
): Promise<string> => {
  const utmString = queryString.stringify({
    utm_source: analytics.source,
    utm_medium: analytics.medium,
    utm_campaign: analytics.campaign,
  });

  const finalLink = link.includes('?') ? `${link}&${utmString}` : `${link}?${utmString}`;
  let params: FirebaseDynamicLinksTypes.DynamicLinkParameters = {
    link: finalLink,
    domainUriPrefix: DYNAMIC_LINK_DOMAIN,
    analytics,
    itunes: {
      providerToken: APPLE_STORE_PROVIDER_TOKEN,
      campaignToken: analytics.campaign,
    },
  };

  if (openApp) {
    params = {
      ...params,
      ios: {
        appStoreId: APPLE_STORE_ID, // open store
        bundleId: PACKAGE_NAME, // open app
      },
      android: {
        packageName: PACKAGE_NAME, // open store or app
      },
    };
  }

  const shortLink = await dynamicLinks().buildShortLink(params, dynamicLinks.ShortLinkType.SHORT);

  return shortLink;
};
