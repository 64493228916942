import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { OFFICIAL_ACCOUNT_ID } from "../constant/backend";
import { sendMessage } from "./chat";
import { TProfile } from "./profile";
import { db } from "../config/firebase";

export type ComplainTypeId = "NB29"|"7MTK"|"R5EB"|"HZMF"|"09VE"|"CHEP"|"0FQF"|"2V7A"|"9DBN"|"HPNS"|"ZGWR"|"XCV5";
export type ContextType = 'profiles' | 'chats' | 'services';

class ComplainError extends Error {}
ComplainError.prototype.name = "ComplainError";

class SendError extends ComplainError {}
SendError.prototype.name = "SendError";

class SendComplainTextError extends ComplainError {}
SendComplainTextError.prototype.name = "SendComplainTextError"

interface ComplainType {
  id: ComplainTypeId
  value: string
}

interface SendType {
  source: TProfile,
  target: TProfile,
  complainTypeId: ComplainTypeId,
  message: string | null,
  contextId: string,
  contextType: ContextType
}

export const complainTypes: ComplainType[] = [
  { id: "NB29" ,value: "The sitter did not respond."},
  { id: "7MTK" ,value: "The sitter did not show up as scheduled."},
  { id: "R5EB" ,value: "The service description provided by the sitter is unclear."},
  { id: "HZMF" ,value: "The sitter did not fulfill the agreed-upon service hours."},
  { id: "09VE" ,value: "The sitter did not provide updates on the pet's condition."},
  { id: "CHEP" ,value: "The reported time of the sitter entering or leaving the house is inaccurate."},
  { id: "0FQF" ,value: "The listed service does not match the actual provided service please specify which service in the \"detailed description\"."},
  { id: "2V7A" ,value: "The sitter requested to bypass platform transactions."},
  { id: "9DBN" ,value: "The pet owner's description of the pet is inaccurate."},
  { id: "HPNS" ,value: "The pet owner did not show up as scheduled."},
  { id: "ZGWR" ,value: "The pet owner requested to bypass platform transactions."},
  { id: "XCV5" ,value: "Other please provide a detailed description in the \"detailed description\" field."}
]

export const Complain = {
  isSendError: (e: Error) => e instanceof SendError,
  isSendComplainTextError: (e: Error) => e instanceof SendComplainTextError,
  send: async ({source, target, complainTypeId, message, contextId, contextType, t}: SendType) => {
    try {
      await addDoc(collection(db, 'complains'), {
        sourceUid: source.id,
        targetUid: target.id,
        complainTypeId,
        message,
        contextId,
        contextType,
        createdAt: serverTimestamp(),
      })
    } catch(e) {
      // firebase firestore error code: https://firebase.google.com/docs/reference/node/firebase.firestore#firestoreerrorcode
      switch (e.code) {
        case 'firestore/cancelled':
          //Nothing to do, because this is user want do it
          break;
        case 'firestore/unauthenticated':
        case 'firestore/unavailable':
        case 'firestore/data-loss':
        case 'firestore/internal':
        case 'firestore/unimplemented':
        case 'firestore/out-of-range':
        case 'firestore/aborted':
        case 'firestore/failed-precondition':
        case 'firestore/resource-exhausted':
        case 'firestore/permission-denied':
        case 'firestore/deadline-exceeded':
        case 'firestore/invalid-argument':
        case 'firestore/unknown':
        case 'firestore/already-exists':
        case 'firestore/not-found':
          throw new SendError();
        default:
          throw e;
      }
    }
    try {
      await sendMessage(OFFICIAL_ACCOUNT_ID, {
        text: t('send_complain_message', {
          targetUid: target.id,
          contextType,
          contextId,
          complainType: complainTypes.find(ct => ct.id === complainTypeId).value,
          message,
          keySeparator: false
        })
      })
    } catch(e) {
      switch (e.message) {
        case 'uri or id cannot be empty':
        case 'upload failed':
          throw new SendComplainTextError();
        default:
          throw e
      }
    }
  },
}
