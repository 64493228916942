import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { ServiceType } from "../enums";
import { orderRef } from "./orders";
import { TSubscription } from "./types";

export type TCoupon = {
  id: string;
  createdAt: number;
  updatedAt: number;
  expiredAt?: number;
  title: string;
  discount: number;
  mass: boolean;
  reusable: boolean;
  available: boolean;
  serviceTypes?: ServiceType[];
  ownerId?: string; // one-time use
  refereeId?: string; // one-time use
  referrerId?: string; // one-time use
};

const couponRef = collection(db, "coupons");

const getCouponDoc = (couponId: string) => {
  return doc(couponRef, couponId);
};

export const toCoupon = (
  snapshot: DocumentSnapshot<DocumentData>
): TCoupon | null => {
  if (!snapshot.data()) return null;

  const {
    createdAt,
    updatedAt,
    expiredAt,
    title = "",
    discount,
    mass = false,
    reusable = false,
    available,
    serviceTypes,
    ownerId,
    refereeId,
    referrerId,
  } = snapshot.data() || {};

  return {
    id: snapshot.id,
    createdAt: createdAt?.seconds * 1000,
    updatedAt: updatedAt?.seconds * 1000,
    expiredAt: expiredAt?.seconds * 1000,
    title,
    discount,
    mass,
    reusable,
    available,
    serviceTypes,
    ownerId,
    refereeId,
    referrerId,
  };
};

export const isCouponUsed = async (
  consumerId: string,
  couponId: string
): Promise<boolean> => {
  if (!consumerId) return true;
  const orderQuery = query(
    orderRef,
    where("cancelled", "==", false),
    where("consumerId", "==", consumerId),
    where("couponId", "==", couponId)
  );

  const snapshot = await getDocs(orderQuery);
  return snapshot.docs.length > 0;
};

export const subscribeCoupons: TSubscription<
  {
    userId: string | undefined;
    serviceType?: ServiceType;
    available?: boolean;
  },
  TCoupon[]
> = ({ variables, onChange }) => {
  const { userId, serviceType, available } = variables || {};

  let couponQuery = query(couponRef, where("ownerId", "==", userId));
  if (available !== undefined) {
    couponQuery = query(couponQuery, where("available", "==", available));
  }
  return onSnapshot(couponQuery, (snapshot) => {
    if (!onChange) return;
    onChange(
      (snapshot.docs.map(toCoupon).filter(Boolean) as TCoupon[])
        .filter((c) => !c.expiredAt || c.expiredAt > Date.now()) // TODO: should display expired coupon
        .filter(
          (c) =>
            !c.serviceTypes ||
            !serviceType ||
            c.serviceTypes.includes(serviceType)
        )
        .sort((c1, c2) => {
          return c1.createdAt - c2.createdAt;
        })
        .sort((c1, c2) => {
          if (c1.expiredAt && c2.expiredAt) {
            return c1.expiredAt - c2.expiredAt;
          }
          if (!c1.expiredAt) return 1;
          if (!c2.expiredAt) return -1;
          return 0;
        })
    );
  });
};

export const subscribeCoupon: TSubscription<
  { serviceType?: ServiceType; userId?: string; couponId?: string },
  TCoupon
> = ({ variables, onChange }) => {
  const { serviceType, userId, couponId } = variables || {};
  if (!couponId) return () => {};

  const couponDoc = getCouponDoc(couponId);
  return onSnapshot(couponDoc, async (snapshot) => {
    if (!onChange) return;

    let couponCanUsedByMe = false;
    const coupon = toCoupon(snapshot);
    if (coupon) {
      const { ownerId, available, reusable, serviceTypes, expiredAt } = coupon;

      const allowTypeToUse =
        !serviceTypes || !serviceType || serviceTypes.includes(serviceType);
      const allowMeToUse = !ownerId || ownerId === userId;
      const expired = expiredAt && expiredAt < Date.now();

      couponCanUsedByMe =
        available && allowTypeToUse && allowMeToUse && !expired;

      // needs to check order
      if (userId && couponCanUsedByMe && !reusable) {
        couponCanUsedByMe = !(await isCouponUsed(userId, couponId));
      }
    }
    onChange(couponCanUsedByMe ? coupon : null);
  });
};
