import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { Dimensions } from "react-native";
import { auth } from "../config/firebase";
import { PetCategory, PetGender, PetPersonality, PetSize } from "../enums";
import { removeEmpty } from "../utils";
import { getPictureSize, getStoragePublicUrl } from "../utils/url";
import { getCurrentUserId } from "./auth";
import { getProfileDoc } from "./profile";
import { isUri, uploadImage } from "./storage";
import { TSubscription } from "./types";

const screenWidth = Dimensions.get("window").width;

export type TPet = {
  id: string;
  createdAt: number;
  updatedAt: number;
  avatarId: string;
  name: string;
  category: PetCategory;
  size: PetSize;
  birthYear: number;
  birthMonth?: number;
  gender: PetGender;
  neuteredOrSpayed: boolean;
  regularVaccination: boolean;
  healthStatus: string;
  personalities: PetPersonality[];
};

export type TPetInput = {
  avatarUriOrId: string;
  name: string;
  category: PetCategory;
  size: PetSize;
  birthYear: number;
  birthMonth?: number;
  gender: PetGender;
  neuteredOrSpayed: boolean;
  regularVaccination: boolean;
  healthStatus: string;
  personalities: PetPersonality[];
};

export const toPet = (
  snapshot: DocumentSnapshot<DocumentData>
): TPet | null => {
  if (!snapshot.data()) return null;

  const {
    createdAt,
    updatedAt,
    avatarId,
    name,
    category,
    size,
    birthYear,
    birthMonth,
    gender,
    neuteredOrSpayed,
    regularVaccination,
    healthStatus,
    personalities,
  } = snapshot.data() || {};

  return {
    id: snapshot.id,
    createdAt: createdAt?.seconds * 1000,
    updatedAt: updatedAt?.seconds * 1000,
    avatarId,
    name,
    category,
    size,
    birthYear,
    birthMonth,
    gender,
    neuteredOrSpayed,
    regularVaccination,
    healthStatus,
    personalities,
  };
};

const petRef = (userId: string) => {
  return collection(getProfileDoc(userId), "pets");
};

const getPetDoc = (userId: string, petId: string) => {
  return doc(petRef(userId), petId);
};

export const getPetPictureUrl = (
  userId: string,
  pictureId: string
): string | null => {
  if (!userId || !pictureId) return null;
  if (isUri(pictureId)) return pictureId;

  return getStoragePublicUrl(
    `users_public/${userId}/pet_pictures/${pictureId}_thumb_${getPictureSize(
      screenWidth
    )}x.jpg`
  );
};

export const getPets = async (userId: string): Promise<TPet[] | null> => {
  const petsCollection = petRef(userId);
  if (!petsCollection) return null;

  const snapshot = await getDocs(petsCollection);
  return snapshot.docs.map(toPet).filter(Boolean) as TPet[];
};

export const getPet = async (
  userId: string,
  petId: string
): Promise<TPet | null> => {
  const petDoc = getPetDoc(userId, petId);
  if (!petDoc) return null;

  const snapshot = await getDoc(petDoc);
  return toPet(snapshot);
};

export const subscribePets: TSubscription<{ userId?: string }, TPet[]> = ({
  variables,
  onChange,
}) => {
  const { userId } = variables || {};
  if (!userId) return () => {};

  const petQuery = query(
    petRef(userId),
    orderBy("category", "asc"),
    orderBy("name", "asc")
  );

  return onSnapshot(petQuery, (snapshot) => {
    if (!onChange) return;
    onChange(snapshot.docs.map(toPet).filter(Boolean) as TPet[]);
  });
};

export const subscribePet: TSubscription<
  { userId: string; petId: string },
  TPet
> = ({ variables, onChange }) => {
  const { userId, petId } = variables || {};
  if (!userId || !petId) return () => {};
  const petDoc = getPetDoc(userId, petId);

  return onSnapshot(petDoc, (snapshot) => {
    if (!onChange) return;
    onChange(toPet(snapshot));
  });
};

export const createPet = async (petInput: TPetInput): Promise<void> => {
  const { currentUser } = auth;
  if (!currentUser) return;
  const myId = getCurrentUserId();

  const {
    avatarUriOrId,
    name,
    category,
    size,
    birthYear,
    birthMonth,
    gender,
    neuteredOrSpayed,
    regularVaccination,
    healthStatus,
    personalities,
  } = petInput;

  const avatarId = await uploadImage(
    avatarUriOrId,
    `users_public/${myId}/pet_pictures`
  );
  const petsCollection = petRef(myId);

  await addDoc(petsCollection, {
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    avatarId,
    name,
    category,
    size,
    birthYear,
    birthMonth,
    gender,
    neuteredOrSpayed,
    regularVaccination,
    healthStatus,
    personalities,
  });
};

export const updatePet = async (
  petId: string,
  petInput: TPetInput
): Promise<void> => {
  const myId = getCurrentUserId();

  const petDoc = getPetDoc(myId, petId);
  const {
    avatarUriOrId,
    name,
    category,
    size,
    birthYear,
    birthMonth,
    gender,
    neuteredOrSpayed,
    regularVaccination,
    healthStatus,
    personalities,
  } = petInput;
  const avatarId = await uploadImage(
    avatarUriOrId,
    `users_public/${myId}/pet_pictures`
  );
  await setDoc(
    petDoc,
    removeEmpty({
      updatedAt: serverTimestamp(),
      avatarId,
      name,
      category,
      size,
      birthYear,
      birthMonth,
      gender,
      neuteredOrSpayed,
      regularVaccination,
      healthStatus,
      personalities,
    }),
    { merge: true }
  );
};

export const deletePet = async (
  userId: string,
  petId: string
): Promise<void> => {
  const petDoc = getPetDoc(userId, petId);
  if (!petDoc) return;

  await deleteDoc(petDoc);
};
