import { StyleSheet, View } from "react-native";
import React from "react";
import { Flex, Text } from "../../ui";
import { PetCategory, PetSize, ServiceType } from "../../enums";
import { calculateQuantity, TBreakdown, TCoupon, TPet } from "../../models";
import { useTranslation } from "react-i18next";
import FieldRow from "./row/FieldRow";
import FieldSubRow from "./row/FieldSubRow";
import { BaseColor } from "../../config";

type Props = {
  type: ServiceType;
  pets: TPet[];
  dates: string[];
  startHour?: number;
  endHour?: number;
  frames?: number;
  times?: number;
  coupon?: TCoupon;
  breakdown?: TBreakdown;
  price?: number;
  discountedPrice?: number;
};

const OrderBreakdown = React.memo<Props>((props) => {
  const {
    type,
    pets,
    dates,
    startHour,
    endHour,
    frames,
    times,
    coupon,
    breakdown,
    price,
    discountedPrice,
  } = props;
  const { t } = useTranslation();
  const quantity = calculateQuantity({
    type,
    dates,
    frames,
    times,
    startHour,
    endHour,
  });

  return (
    <>
      {breakdown && pets && (
        <>
          <Flex paddingVertical={4}>
            {Object.keys(breakdown).map((petId) => {
              const { name, size, category } =
                pets.filter((pet) => pet.id === petId)[0] || {};
              const petItem = breakdown[petId];
              return (
                <Flex key={petId} paddingBottom={4}>
                  <FieldRow
                    iconName="check-square"
                    text={name}
                    content={null}
                  />
                  <FieldSubRow
                    iconName="chevron-right"
                    text={
                      type === ServiceType.VET_TO_HOME ||
                      type === ServiceType.VET_HOSPITAL
                        ? t("order:registration_fee")
                        : t("order:price_base")
                    }
                    content={
                      discountedPrice ? (
                        <Text>
                          <Text style={styles.titleDeletion}>
                            ${petItem.basePrice}
                          </Text>
                          <Text style={styles.title}>
                            ${petItem.discountedBasePrice}
                          </Text>
                        </Text>
                      ) : (
                        <Text style={styles.title}>${petItem.basePrice}</Text>
                      )
                    }
                  />
                  {petItem.extra &&
                    Object.keys(petItem.extra).map((extraKey) => {
                      const extraValue = petItem.extra?.[extraKey as "size"];
                      return (
                        <Flex key={extraKey}>
                          <FieldSubRow
                            iconName="chevron-right"
                            text={t(`order:price_extra_size`, {
                              size: t(`pets:pet_size_${PetSize[size]}`),
                              category: t(
                                `pets:category_${PetCategory[category]}`
                              ),
                            })}
                            content={
                              <Text style={styles.title}>${extraValue}</Text>
                            }
                          />
                        </Flex>
                      );
                    })}
                </Flex>
              );
            })}
          </Flex>
        </>
      )}
      <Flex paddingVertical={8}>
        <FieldRow
          iconName="dollar-sign"
          text={t("order:sub_total")}
          content={
            discountedPrice ? (
              <Text>
                <Text style={styles.titleDeletion}>${price}</Text>
                <Text style={styles.title}> ${discountedPrice}</Text>
              </Text>
            ) : (
              <Text style={styles.title}>${price}</Text>
            )
          }
        />
        <Flex paddingBottom={4}>
          {(type === ServiceType.DROPIN ||
            type === ServiceType.WALKING ||
            type === ServiceType.BATHING ||
            type === ServiceType.GROOMING ||
            type === ServiceType.VET_TO_HOME ||
            type === ServiceType.VET_HOSPITAL) && (
            <FieldSubRow
              iconName="chevron-right"
              text={t("order:dates_number")}
              content={<Text style={styles.title}>x {dates.length}</Text>}
            />
          )}
          {(type === ServiceType.DROPIN || type === ServiceType.WALKING) && (
            <FieldSubRow
              iconName="chevron-right"
              text={t("order:service_length_30mins")}
              content={<Text style={styles.title}>x {frames}</Text>}
            />
          )}
          {(type === ServiceType.DROPIN || type === ServiceType.WALKING) && (
            <FieldSubRow
              iconName="chevron-right"
              text={t("order:times_per_day")}
              content={<Text style={styles.title}>x {times}</Text>}
            />
          )}
          {type === ServiceType.TAVERN && (
            <FieldSubRow
              iconName="chevron-right"
              text={t("order:meals_number")}
              content={<Text style={styles.title}>x {quantity}</Text>}
            />
          )}
          {type === ServiceType.TAVERN && (
            <FieldSubRow
              iconName="chevron-right"
              text={t("order:room")}
              content={<Text style={styles.title}>{t("order:free")}</Text>}
            />
          )}
          {type !== ServiceType.VET_TO_HOME &&
            type !== ServiceType.VET_HOSPITAL && (
              <FieldSubRow
                iconName="chevron-right"
                text={t("order:insurance")}
                content={<Text style={styles.title}>{t("order:free")}</Text>}
              />
            )}
          {coupon && (
            <FieldSubRow
              iconName="chevron-right"
              text={t("order:coupon_discount")}
              content={
                <Text style={styles.title}>
                  {coupon.discount < 1
                    ? t(`coupon:discount_${coupon.discount * 100}`)
                    : `- ${coupon.discount}`}
                </Text>
              }
            />
          )}
        </Flex>
      </Flex>
    </>
  );
});

export default OrderBreakdown;

const styles = StyleSheet.create({
  title: {
    color: "#212121",
    fontSize: 16,
    fontWeight: "bold",
  },
  titleDeletion: {
    color: "#212121",
    fontSize: 16,
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
});
