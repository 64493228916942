import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigation } from "../../hooks/useNavigation";
import { useTranslation } from "react-i18next";
import { BaseColor, useTheme } from "../../config";
import { usePromise, useSubscription } from "../../hooks";
import {
  calculateQuantity,
  getCurrentUserId,
  isCouponUsed,
  redeemCoupon,
  subscribeCoupon,
  subscribeOrder,
} from "../../models";
import { useAuth } from "../../context/Auth";
import { Header, Icon } from "../../components";
import { Button, Separator, Text, Flex } from "../../ui";
import FieldRow from "./row/FieldRow";
import OrderBreakdown from "./OrderBreakdown";
import { ServiceType } from "../../enums";
import CouponSelectModal from "./CouponSelectModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import { MainStackScreenNavigationProp } from "../../config/typography";

const OrderConfirmScreen = ({ route }: any) => {
  const { orderId } = route.params;
  const { t } = useTranslation();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { colors } = useTheme();
  let { userId } = useAuth();
  userId = getCurrentUserId();
  const [couponId, setCouponId] = useState<string>("");
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const order = useSubscription({
    subscription: subscribeOrder,
    variables: {
      orderId,
    },
  });

  const {
    dates = [],
    startHour,
    endHour,
    frames,
    times,
    breakdown,
    price = 0,
    snapshots,
    couponId: oldCouponId,
  } = order || {};

  const { service, pets = [] } = snapshots || {};
  const { type } = service || {};

  const coupon = useSubscription({
    subscription: subscribeCoupon,
    variables: {
      serviceType: type,
      userId,
      couponId,
    },
  });

  const checkCoupon = usePromise(async () => {
    if (!coupon) return;
    return await isCouponUsed(userId, couponId);
  }, [coupon]);

  useEffect(() => {
    if (!couponId && oldCouponId) {
      setCouponId(oldCouponId);
    }
  }, [oldCouponId]);

  // calculate final amount after using coupon
  const quantity = type
    ? calculateQuantity({
        type,
        dates,
        frames,
        times,
        startHour,
        endHour,
      })
    : 0;

  let amount = order?.changedAmount || price * quantity;
  const { discount: couponDiscount } = coupon || {};
  if (couponDiscount) {
    const realCouponDiscount =
      couponDiscount < 1 ? Math.round(amount * couponDiscount) : couponDiscount;
    amount -= realCouponDiscount;
  }

  const handleSelectCoupon = useCallback((id) => {
    setCouponId(id);
  }, []);

  const handlePay = useCallback(async () => {
    const startPay = () => {
      // NavigationService.dismiss('OrderConfirmModal');
      {
        /*@ts-ignore*/
      }
      navigation.navigate("PaymentScreen", { orderId });
    };
    // couponId is different
    if (oldCouponId !== couponId) {
      setIsLoading(true);
      try {
        await redeemCoupon(orderId, couponId || null, price, amount);
        startPay();
      } catch (e) {
        // TODO: use snackbar to display the status
        console.warn(e);
      }
      setIsLoading(false);
    } else {
      // no change, directly pay
      startPay();
    }
  }, [oldCouponId, orderId, couponId, price, amount]);

  const discountStr = useMemo(() => {
    if (!coupon) return undefined;
    const { discount } = coupon;
    return discount >= 1
      ? t("coupon:price_discount", { discount })
      : t(`coupon:percentage_discount_${discount * 100}`);
  }, [coupon]);
  return (
    <View style={{ flex: 1 }}>
      <LoadingSpinner visible={isLoading} />
      <Header
        title={t("order:order_breakdown_confirm")}
        subTitle={""}
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} color={colors.primary} />;
        }}
        onPressLeft={() => {
          navigation.pop();
          // navigation.navigate('UserTab', { screen: 'Order' });
        }}
      />

      <ScrollView style={{ paddingTop: 70 }}>
        <Flex paddingBottom={24} paddingTop={32}>
          <Separator />
          <Flex paddingHorizontal={24} paddingVertical={4}>
            <FieldRow
              iconName="gift"
              text={t("order:coupon")}
              content={
                <TouchableOpacity
                  onPress={() => {
                    setShowCouponModal(true);
                  }}
                >
                  <View>
                    <Text>
                      {coupon !== undefined
                        ? discountStr
                        : t("coupon:select_coupon")}
                    </Text>
                  </View>
                </TouchableOpacity>
              }
            />
            <CouponSelectModal
              visible={showCouponModal}
              onSelect={(couponId) => handleSelectCoupon(couponId)}
              onDismiss={(visable) => {
                setShowCouponModal(visable);
              }}
              serviceType={type}
              userId={userId}
            />
          </Flex>
          <Separator />
        </Flex>
        <Flex paddingBottom={24}>
          <Separator />
          <Flex paddingHorizontal={24} paddingVertical={4}>
            {type && (
              <OrderBreakdown
                type={type}
                pets={pets}
                dates={dates}
                startHour={startHour}
                endHour={endHour}
                frames={frames}
                times={times}
                coupon={coupon || undefined}
                breakdown={breakdown}
                price={price}
              />
            )}
            <Separator />
          </Flex>
          <Flex paddingHorizontal={24} paddingVertical={4}>
            <FieldRow
              iconName="shopping-cart"
              text={t("order:check_total")}
              content={<Text body2>${amount}</Text>}
            />
          </Flex>
          <Flex paddingHorizontal={24} paddingVertical={24}>
            <Button onPress={handlePay}>{t("order:confirm_pay")}</Button>
            {type === ServiceType.VET_TO_HOME ||
              (type === ServiceType.VET_HOSPITAL && (
                <Flex.Row paddingTop={16}>
                  <Flex paddingTop={1} paddingRight={12}>
                    <Icon
                      size={18}
                      name="flag"
                      color={BaseColor.highlightedTextColor}
                    />
                  </Flex>
                  <Flex fill>
                    <Text footnote style={{ color: BaseColor.textColor }}>
                      {t("order:vet_charge_reminder")}
                    </Text>
                  </Flex>
                </Flex.Row>
              ))}
          </Flex>
          <Separator />
        </Flex>
      </ScrollView>
    </View>
  );
};

export default OrderConfirmScreen;

const styles = StyleSheet.create({});
