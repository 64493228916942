import React, { useState } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  RefreshControl,
  TouchableOpacity,
} from "react-native";
import { BaseColor, BaseStyle, useTheme } from "../../config";
import { Header, SafeAreaView, Icon } from "../../components";
import { Text } from "../../ui";
import Menu from "../../ui/Menu";
import { useTranslation } from "react-i18next";
import PetItem from "../../components/PetItem";
import { useAuth } from "../../context/Auth";
import { useNavigation } from "../../hooks/useNavigation";
import { useSubscription } from "../../hooks";
import { getCurrentUserId, subscribePets } from "../../models";
import { MainStackScreenNavigationProp } from "../../config/typography";

export default function PetsScreen({ route }: any) {
  const { userId } = route.params;
  let { userId: myId } = useAuth();
  myId = getCurrentUserId();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { colors } = useTheme();
  const { t } = useTranslation();
  const [refreshing] = useState(false);
  const isMe = userId === myId;
  const [isShowMenu, setIsShowMenu] = useState(false);
  const pets = useSubscription({
    subscription: subscribePets,
    variables: {
      userId,
    },
  });

  const renderItem = (item: any) => {
    return (
      <PetItem
        {...item}
        userId={userId}
        allowsSelection={isMe}
        style={{ paddingVertical: 10, marginHorizontal: 20 }}
        onPress={() => {
          navigation.navigate("PetProfileManagerScreen", {
            petId: item.id,
            userId,
          });
        }}
      />
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <Menu
        visible={isShowMenu}
        onDismiss={() => {
          setIsShowMenu(false);
        }}
      />
      <Header
        title={t("pets:title")}
        subTitle={""}
        style={BaseStyle.menu}
        titleStyle={{ color: BaseColor.whiteColor }}
        renderLeft={() => (
          <Icon name="arrow-left" size={20} color={BaseColor.whiteColor} />
        )}
        onPressLeft={() => {
          navigation.navigate("UserTab", { screen: "Home" });
        }}
        renderRight={() => (
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("PetProfileManagerScreen", {
                  petId: "",
                  userId: myId,
                });
              }}
              style={{
                marginRight: 15,
                width: 32,
                height: 32,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 16,
              }}
            >
              <Icon name="plus" size={20} color={BaseColor.whiteColor} />
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                width: 32,
                height: 32,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 16,
              }}
              onPress={() => {
                setIsShowMenu(true);
              }}
            >
              <View>
                <Icon name="bars" size={18} color={BaseColor.whiteColor} />
              </View>
            </TouchableOpacity>
          </View>
        )}
        onPressRight={() => {}}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        {pets && pets?.length > 0 ? (
          <FlatList
            data={pets}
            keyExtractor={(item, index) => item.id}
            renderItem={({ item }) => renderItem(item)}
          />
        ) : (
          <></>
        )}
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  tabbar: {
    height: 40,
  },
  tab: {
    flex: 1,
  },
  indicator: {
    height: 2,
  },
  label: {
    fontWeight: "400",
  },
  containProfileItem: {
    paddingLeft: 20,
    paddingRight: 20,
  },
});
