import React, { useEffect, useRef, useState } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  RefreshControl,
  StyleSheet,
  Dimensions,
  Platform,
} from "react-native";
import { BaseStyle, BaseColor, useTheme } from "../../config";
import {
  Header,
  Icon,
  ProfileDescription,
  ProfilePerformance,
  RateDetail,
  CommentItem,
  StarRating,
  Carousel,
} from "../../components";
import { Button, Text, Flex } from "../../ui";
import { TabBar } from "react-native-tab-view";

import { useTranslation } from "react-i18next";
import { usePromise, useSubscription } from "../../hooks";
import {
  getCurrentUserId,
  getProfile,
  getProfilePictureUrl,
  getExService,
  getService,
  getServicePictureUrl,
  getSitterServiceNumber,
  subscribeReviews,
  subscribeService,
  TProfile,
  TService,
  TServiceDetail,
  TServiceSearchResult,
} from "../../models";
import {
  DayOfWeek,
  PetCategory,
  PetSize,
  ServiceType,
  ServiceTypeMap,
} from "../../enums";
import { useDiscount } from "../../modules";
// import Carousel from 'react-native-reanimated-carousel';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { t } from "i18next";
import { getAuth } from "firebase/auth";
import { useAuthReady } from "../../hooks";
import { minWidth } from "../../config/theme";
import { RWebShare } from "react-web-share";
import ServiceManage from "./ServiceManage";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";
import { relativeToNow } from "../../utils";
import { getShortAddress, getShortAreasName } from "../../constant/areas";

const width = Dimensions.get("window").width;

export default function ServiceDetailScreen({ route }: any) {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { colors } = useTheme();
  const { t, i18n } = useTranslation();
  const { serviceId, reviews, ...params } = route.params;
  const auth = getAuth();
  const { currentUser } = auth;
  const currentUserId = getCurrentUserId();
  const { getDiscount } = useDiscount();
  const reviewRef = useRef(null);
  const [showServiceManage, setShowServiceManage] = useState(false);
  let serviceDetail: TService = useSubscription(
    {
      subscription: subscribeService,
      variables: {
        serviceId: serviceId,
      },
    },
    params.serviceDetail
  );

  serviceDetail = useAuthReady(() => getExService(serviceId), [serviceId], params.serviceDetail);

  /* @ts-ignore */
  let sitterProfile: TProfile = usePromise(async () => {
    if (!serviceDetail) return;
    return await getProfile(serviceDetail.providerId);
  }, [serviceDetail], params.sitterProfile);

  const { label, basePriceRangeStr, discountedBasePriceRangeStr } =
    getDiscount(serviceDetail);
  const unit = t(`enum:type_unit_${ServiceType[serviceDetail?.type] || ""}`);
  let sitterPicture = getProfilePictureUrl(
    sitterProfile?.id,
    sitterProfile?.avatarId
  );
  const serviceNumber = usePromise(async () => {
    if (!sitterProfile) return;
    return await getSitterServiceNumber(sitterProfile?.id);
  }, [sitterProfile]);
  const userPerformance = [
    { value: "97.01%", title: "message" },
    { value: serviceNumber, title: "service" },
    { value: sitterProfile?.totalReviews, title: "reviews" },
  ];
  const images = serviceDetail?.pictureIds.map((id: string) =>
    getServicePictureUrl(sitterProfile?.id, id)
  ) as string[];
  // When tab is activated, set what's index value

  // Customize UI tab bar
  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      indicatorStyle={[styles.indicator, { backgroundColor: colors.primary }]}
      style={[styles.tabbar, { backgroundColor: colors.background }]}
      tabStyle={styles.tab}
      inactiveColor={BaseColor.grayColor}
      activeColor={colors.text}
      renderLabel={({ route, focused, color }) => (
        <View style={{ flex: 1, alignItems: "center" }}>
          <Text headline semibold={focused} style={{ color }}>
            {route.title}
          </Text>
        </View>
      )}
    />
  );

  // Render correct screen container when tab is activated
  // const renderScene = ({route, jumpTo}: any) => {
  //   switch (route.key) {
  //     case 'description':
  //       return <DescriptionTab
  //         jumpTo={jumpTo}
  //         navigation={navigation}
  //         serviceDetail={serviceDetail}
  //       />;
  //     case 'review':
  //       return <ReviewTab
  //         jumpTo={jumpTo}
  //         navigation={navigation}
  //         sitterProfile={sitterProfile}
  //       />;
  //   }
  // };

  const isMe = (): boolean => {
    if (sitterProfile && currentUser && currentUserId === sitterProfile?.id) {
      return true;
    } else {
      return false;
    }
  };

  const scrollDown = (ref: any) => {
    if (Platform.OS === "web") {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <ScrollView style={{ flex: 1 }}>
        <View
          style={{ flex: 1, paddingBottom: 86, backgroundColor: colors.card }}
        >
          <Header
            title={""}
            subTitle={""}
            renderLeft={() => {
              return (
                <View
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                    backgroundColor: BaseColor.whiteColor,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon name="arrow-left" size={12} color={colors.primary} />
                </View>
              );
            }}
            onPressLeft={() => {
              navigation.pop();
              // if(sitterId !== undefined) {
              //   navigation.navigate("SitterDetailScreen", {
              //     sitterId
              //   })
              // } else {
              //   AsyncStorage.getItem('searchQuery')
              //   .then((searchQueryString: string | null) => {
              //     const searchQuery = searchQueryString?.split('&');
              //     if(!searchQuery) {
              //       navigation.navigate('SearchScreen', {
              //         type: '1',
              //         category: '1',
              //         size: '5',
              //         areaCode: '104'
              //       })
              //     } else {
              //       navigation.navigate('SearchScreen', {
              //         type: searchQuery[0],
              //         category: searchQuery[1],
              //         size: searchQuery[2],
              //         areaCode: searchQuery[3]
              //       })
              //     }
              //   })
              // }
            }}
            renderRight={() => {
              return null;
              // return (
              //   <View
              //     style={{
              //       width: 24,
              //       height: 24,
              //       borderRadius: 12,
              //       backgroundColor: BaseColor.whiteColor,
              //       justifyContent: 'center',
              //       alignItems: 'center'
              //     }}
              //   >
              //     <RWebShare
              //       data={{
              //         text: "Like humans, flamingos make friends for life",
              //         url: "https://on.natgeo.com/2zHaNup",
              //         title: t('common:share'),
              //       }}
              //       onClick={() => console.log("shared successfully!")}
              //       sites={['facebook', 'mail', 'copy']}
              //     >
              //       <Icon name="share" size={12} color={BaseColor.color1}/>
              //     </RWebShare>
              //   </View>
              // )
            }}
            style={{ position: "absolute", zIndex: 1 }}
          />
          <View style={[BaseStyle.safeAreaView]}>
            {images !== undefined &&
            images.length > 0 &&
            images[0] !== undefined ? (
              <Carousel images={images} onPress={() => {}} />
            ) : null}
            <ProfileDescription
              image={sitterPicture}
              name={sitterProfile?.displayName}
              description={sitterProfile?.description}
              tags={sitterProfile?.certificates}
              style={{
                padding: 16,
                backgroundColor: BaseColor.whiteColor,
                marginBottom: 8,
                marginTop: 5,
              }}
              onPress={() => {
                navigation.navigate("SitterDetailScreen", {
                  sitterId: serviceDetail.providerId,
                  serviceId: serviceId,
                  preRoute: "ServiceDetail",
                });
              }}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                padding: 16,
                backgroundColor: BaseColor.whiteColor,
                marginBottom: 8,
              }}
            >
              <View style={{ flex: 1, paddingLeft: 10 }}>
                <ProfilePerformance
                  isMe={isMe()}
                  flexDirection="row"
                  data={userPerformance}
                  type="primary"
                  renderLeft={() => {
                    return (
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Icon
                          name={isMe() ? "cog" : "comment"}
                          size={18}
                          color={BaseColor.color1}
                        />
                        {isMe() ? (
                          <Text body2 grayColor>
                            {t("profile:sitter_profile")}
                          </Text>
                        ) : (
                          <Text body2 grayColor>
                            {t("enum:message")}
                          </Text>
                        )}
                      </View>
                    );
                  }}
                  onRightPress={() => {
                    scrollDown(reviewRef);
                  }}
                  onLeftPress={() => {
                    if (currentUserId === sitterProfile.id) {
                      navigation.navigate("SitterDetailScreen", {
                        sitterId: sitterProfile.id,
                      });
                    } else {
                      navigation.navigate("ChatRoomScreen", {
                        userId: sitterProfile.id,
                        name: encodeURI(sitterProfile?.displayName || ""),
                      });
                    }
                  }}
                />
              </View>
            </View>
            <View style={{ flex: 1, backgroundColor: colors.card }}>
              <DescriptionTab serviceDetail={serviceDetail} />
              <ReviewTab
                sitterProfile={sitterProfile}
                reviewRef={reviewRef}
                reviews={reviews}
              />
            </View>
          </View>
        </View>
      </ScrollView>
      <View
        /* @ts-ignore */
        style={{
          width: "100%",
          minWidth: minWidth,
          backgroundColor: "transparent",
          height: 84,
          position: "fixed",
          bottom: 0,
        }}
      >
        <View
          style={[
            styles.contentButtonBottom,
            {
              borderTopColor: colors.border,
              position: "absolute",
              bottom: 0,
              width: "100%",
              backgroundColor: "white",
            },
          ]}
        >
          <View>
            <Text headline primaryColor semibold>
              {t("enum:currency")} {basePriceRangeStr}
              {unit}
            </Text>
            <View style={{ marginTop: 5, flexDirection: "row" }}>
              <View style={{ width: 70 }}>
                <StarRating
                  disabled={true}
                  starSize={10}
                  maxStars={5}
                  rating={sitterProfile?.avgRating}
                  selectedStar={(rating) => {}}
                  fullStarColor={BaseColor.yellowColor}
                />
              </View>
              <Text caption1 primaryColor semibold style={{ marginLeft: 5 }}>
                · {sitterProfile?.totalReviews}
              </Text>
            </View>
            <Text grayColor>
              {sitterProfile && (
                <Text
                  numberOfLines={1}
                  caption1
                  grayColor
                  style={{
                    marginLeft: 3,
                  }}
                >
                  {t("last seen {{time}}", {
                    time: relativeToNow(
                      i18n.language,
                      sitterProfile.lastCheckedInAt
                    ),
                  })}
                </Text>
              )}
            </Text>
          </View>
          <Button
            style={{
              backgroundColor: isMe()
                ? BaseColor.color1
                : !serviceDetail?.enabled
                ? BaseColor.grayColor
                : BaseColor.color1,
            }}
            onPress={() => {
              if (isMe()) {
                setShowServiceManage(true);
              } else {
                if (!serviceDetail.enabled) {
                } else {
                  navigation.navigate("CreateOrderScreen", {
                    serviceId: serviceId,
                    sitterId: sitterProfile?.id,
                  });
                }
              }
            }}
          >
            {isMe() ? (
              <>{t("sitter_profile:edit")}</>
            ) : (
              <>{t("enum:quick_reserve")}</>
            )}
          </Button>
        </View>
      </View>
      {serviceDetail ? (
        <ServiceManage
          modalVisible={showServiceManage}
          onPress={() => {}}
          onDismiss={() => {
            setShowServiceManage(false);
          }}
          defaultInput={{
            type: serviceDetail?.type,
            title: serviceDetail?.title,
            description: serviceDetail?.description,
            areaCodes: serviceDetail?.areaCodes,
            place: serviceDetail?.place,
            availableDays: serviceDetail?.availableDays,
            pictureUrisOrIds: serviceDetail?.pictureIds,
            petCategories: serviceDetail?.petCategories,
            petSizes: serviceDetail?.petSizes,
            price: serviceDetail?.price,
            priceLevel: serviceDetail?.priceLevel === null ? null : Number(serviceDetail?.priceLevel),
          }}
          serviceId={serviceDetail.id}
        />
      ) : null}
    </>
  );
}

type DescriptionTab = {
  serviceDetail: TService;
};

const DescriptionTab = React.memo<DescriptionTab>(({ serviceDetail }) => {
  const { t } = useTranslation();
  return (
    <View
      style={{
        padding: 16,
        backgroundColor: BaseColor.whiteColor,
        marginBottom: 8,
      }}
    >
      <h1 style={{ fontSize: 24 }}>{serviceDetail?.title}</h1>
      <Text headline semibold primaryColor style={{ marginTop: 16 }}>
        {/* Title */}
        {t(`search:type_${ServiceTypeMap.get(serviceDetail?.type)}`)}
      </Text>
      {/* Description */}
      <Text body2 style={{ marginTop: 10 }}>
        {serviceDetail?.description}
      </Text>
      {/* acceptable Something */}
      <Flex paddingVertical={16}>
        <Flex.Row>
          <Flex fill paddingRight={8}>
            <Text caption2 grayColor>
              {t("service_detail:acceptable_pets")}
            </Text>
            <Flex paddingTop={8}>
              <Text subhead>
                {serviceDetail?.petCategories
                  .map(
                    (petCategory) =>
                      `${t(`pets:category_${PetCategory[petCategory]}`)} ${t(
                        `pets:emoji_category_${PetCategory[petCategory]}`
                      )}`
                  )
                  .join(", ")}
              </Text>
            </Flex>
          </Flex>
          <Flex fill paddingRight={8}>
            <Text caption2 grayColor>
              {t("service_detail:acceptable_sizes")}
            </Text>
            <Flex paddingTop={8}>
              <Text subhead>
                {serviceDetail?.petSizes
                  .map((petSize) => `${t(`pets:pet_size_${PetSize[petSize]}`)}`)
                  .join(", ")}
              </Text>
            </Flex>
          </Flex>
        </Flex.Row>
        <Flex paddingTop={16}>
          <Text caption2 grayColor>
            {t("service_detail:available_days")}
          </Text>
          <Flex paddingVertical={8}>
            <Text subhead>
              {serviceDetail?.availableDays
                .map(
                  (availableDay) =>
                    `${t(`enum:day_of_week_short_${DayOfWeek[availableDay]}`)}`
                )
                .join(", ")}
            </Text>
          </Flex>
        </Flex>
        {serviceDetail?.type !== ServiceType.TAVERN &&
        serviceDetail?.type !== ServiceType.VET_HOSPITAL ? (
          <Flex paddingTop={16}>
            <Text caption2 grayColor>
              {t("service_detail:acceptable_areas")}
            </Text>
            <Flex paddingVertical={8}>
              <Text subhead>
                {serviceDetail?.areaCodes
                  ? getShortAreasName(serviceDetail?.areaCodes)
                  : ""}
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Flex paddingTop={16}>
            <Text caption2 grayColor>
              {t("service_detail:acceptable_place")}
            </Text>
            <Flex paddingVertical={8}>
              <Text subhead>
                {serviceDetail?.place &&
                  (serviceDetail?.type === ServiceType.TAVERN
                    ? getShortAddress(serviceDetail?.place.areaCode || "") ||
                      serviceDetail?.place.name ||
                      serviceDetail?.place.address
                    : serviceDetail?.place.address)}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </View>
  );
});

type ReviewTab = {
  sitterProfile: TProfile;
  reviewRef: any;
};

const ReviewTab = React.memo<ReviewTab>(
  ({ sitterProfile, reviewRef, reviews }) => {
    const { avgRating, totalReviews } = sitterProfile || {};

    return (
      <View
        style={{ backgroundColor: BaseColor.whiteColor, padding: 16 }}
        ref={reviewRef}
      >
        <Text headline semibold primaryColor style={{ marginTop: 16 }}>
          {t("enum:rating")}
        </Text>
        <RateDetail
          point={avgRating}
          totalReviews={totalReviews}
          reviews={reviews}
        />
        <FlatList
          contentContainerStyle={{ padding: 16 }}
          horizontal={true}
          data={reviews?.payload}
          keyExtractor={(item, index) => item.id}
          renderItem={({ item }) => (
            <View style={{ paddingRight: 16 }}>
              <CommentItem
                userId={item.snapshots.senderProfile?.id || ""}
                style={{ marginTop: 10 }}
                avatarId={item.snapshots.senderProfile?.avatarId || ""}
                name={item.snapshots.senderProfile?.displayName || ""}
                rate={item.rating}
                comment={item.comment || ""}
                createdAt={item.createdAt}
              />
            </View>
          )}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  location: {
    flexDirection: "row",
    marginTop: 10,
  },
  contentTag: {
    marginLeft: 20,
    marginTop: 10,
    width: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  tabbar: {
    height: 40,
  },
  tab: {
    flex: 1,
  },
  indicator: {
    height: 2,
  },
  label: {
    fontWeight: "400",
  },
  contentImageGird: {
    flexDirection: "row",
    height: 160,
    marginTop: 10,
  },
  SearchItem: {
    width: 160,
  },
  contentButtonBottom: {
    borderTopWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    bottom: 0,
  },
  lineInformation: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 10,
    borderBottomWidth: 1,
  },

  // calendar
  contentPickDate: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    padding: 10,
  },
  itemPick: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  contentCalendar: {
    borderRadius: 8,
    width: "100%",
  },
  contentActionCalendar: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 15,
  },
});
