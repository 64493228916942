import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useSubscription } from "../hooks";
import {
  subscribeAllPrices,
  subscribeAllExtras,
  subscribeCampaign,
  subscribeCampaignOrderCount,
  TService,
  TPet,
  TBreakdown,
  getCurrentUserId,
} from "../models";
import { removeEmpty } from "../utils";
import { ServiceType, PriceLevel } from "../enums";
import { useAuth } from "../context/Auth";

export type Discount = {
  label?: string;
  detail?: string;
  basePrice?: number;
  discountedBasePrice?: number;
  breakdown?: TBreakdown;
  basePriceRangeStr?: string;
  discountedBasePriceRangeStr?: string;
};

export type UseDiscount = {
  title: string;
  description: string;
  getDiscount: (service: TService, pets?: TPet[]) => Discount;
};

const currentCampaignId: string = "";

export const useDiscount = (): UseDiscount => {
  const { t } = useTranslation();
  let { userId } = useAuth();
  userId = getCurrentUserId();
  // price
  const allPrices = useSubscription({
    subscription: subscribeAllPrices,
  });

  const allExtras = useSubscription({
    subscription: subscribeAllExtras,
  });

  // campaign
  const campaign = useSubscription({
    subscription: subscribeCampaign,
    variables: {
      campaignId: currentCampaignId,
    },
  });
  const {
    id: campaignId,
    quota = 0,
    cost = 0,
    overrides,
    providersMap,
  } = campaign || {};
  const { price: overridedPrice } = overrides || {};

  // orders
  const campaignOrderCount = useSubscription({
    subscription: subscribeCampaignOrderCount,
    variables: {
      consumerId: userId,
      campaignId: currentCampaignId,
    },
  });

  const getDiscount = useCallback(
    (service: TService, pets?: TPet[]) => {
      const {
        type,
        providerId,
        price: customPrice,
        priceLevel,
      } = service || {};
      const basePrice =
        (!priceLevel ? customPrice : allPrices?.[type]?.[priceLevel]) || 0;
      const { categories: extraCategories = {} } = allExtras?.[type] || {};

      // condition
      let label: string | undefined;
      let detail: string | undefined;
      let discountedBasePrice: number | undefined;
      if (campaignId === "20191115_free_week") {
        const isProvider =
          providerId && providersMap && providersMap[providerId];
        if (
          isProvider &&
          type === ServiceType.BATHING &&
          priceLevel === PriceLevel.LV1 &&
          cost < quota &&
          campaignOrderCount === 0
        ) {
          label = currentCampaignId
            ? t(`campaign:c${currentCampaignId}_label`)
            : undefined;
          detail = currentCampaignId
            ? t(`campaign:c${currentCampaignId}_content`)
            : undefined;
          discountedBasePrice = overridedPrice;
        }
      }

      // calculate detail prices for each pets
      let breakdown: TBreakdown | undefined;
      if (
        (type === ServiceType.BATHING ||
          type === ServiceType.GROOMING ||
          type === ServiceType.TAVERN ||
          type === ServiceType.VET_TO_HOME ||
          type === ServiceType.VET_HOSPITAL) &&
        pets
      ) {
        breakdown = pets.reduce<TBreakdown>((result, pet) => {
          const { id: petId, category, size } = pet;
          const { sizes: extraSizes = {} } = extraCategories?.[category] || {};

          result[petId] = {
            basePrice,
            discountedBasePrice,
            extra: {},
          };
          if (extraSizes && extraSizes[size]) {
            result[petId].extra.size = extraSizes[size];
          }
          return removeEmpty(result);
        }, {});
      }

      // calculate max extra price
      let maxExtraPrice = 0;
      Object.keys(extraCategories).forEach((ecKey) => {
        const {
          sizes: extraSizes = {},
          /* @ts-ignore */
        } = extraCategories[ecKey];
        Object.keys(extraSizes).forEach((esKey) => {
          const extraPrice = extraSizes[esKey];
          if (maxExtraPrice < extraPrice) {
            maxExtraPrice = extraPrice;
          }
        });
      });

      let basePriceRangeStr = basePrice ? `$${basePrice}` : "";
      let discountedBasePriceRangeStr = discountedBasePrice
        ? `$${discountedBasePrice}`
        : "";
      if (maxExtraPrice > 0) {
        basePriceRangeStr = basePrice
          ? `$${basePrice} ~ $${basePrice + maxExtraPrice}`
          : "";
        discountedBasePriceRangeStr = discountedBasePrice
          ? `$${discountedBasePrice} ~ $${discountedBasePrice + maxExtraPrice}`
          : "";
      }

      return {
        label,
        detail,
        basePrice,
        discountedBasePrice,
        breakdown,
        basePriceRangeStr,
        discountedBasePriceRangeStr,
      };
    },
    [allPrices, allExtras, campaign, campaignOrderCount]
  );

  return {
    title: t(`campaign:all_campaign_title`),
    description: t(`campaign:all_campaign_description`),
    getDiscount,
  };
};
