export enum ServiceType {
  // OVERNIGHT = 1,
  // DAYCARE = 2,
  DROPIN = 3,
  WALKING = 4,
  BATHING = 5,
  GROOMING = 6,
  // MIDWAY = 7,
  TAVERN = 8,

  // brand
  VET_TO_HOME = 101,
  VET_HOSPITAL = 102,
}

export const ServiceTypeUtil = {
  list: (): ServiceType[] => [
    ServiceType.TAVERN,
    ServiceType.DROPIN,
    ServiceType.BATHING,
    ServiceType.GROOMING,
    ServiceType.VET_TO_HOME,
    ServiceType.VET_HOSPITAL,
    ServiceType.WALKING,
  ],
};

export const ServiceTypeMap = new Map([
  [3, "DROPIN"],
  [4, "WALKING"],
  [5, "BATHING"],
  [6, "GROOMING"],
  [8, "TAVERN"],
  [101, "VET_TO_HOME"],
  [102, "VET_HOSPITAL"],
]);
