import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Image, Text } from "../ui";
import { useTheme } from "../config";

export default function ListThumbSquare(props: any) {
  const { colors } = useTheme();
  const {
    style,
    imageStyle,
    image,
    txtLeftTitle,
    txtContent,
    txtRight,
    onPress,
  } = props;
  return (
    <TouchableOpacity
      style={[styles.item, style]}
      onPress={onPress}
      activeOpacity={0.9}
    >
      <View style={[styles.contain, { borderBottomColor: colors.border }]}>
        <Image source={image} style={[styles.thumb, imageStyle]} />
        <View style={styles.content}>
          <View style={styles.left}>
            <Text headline semibold>
              {txtLeftTitle}
            </Text>
            <Text
              numberOfLines={1}
              footnote
              grayColor
              style={{
                paddingTop: 5,
              }}
            >
              {txtContent}
            </Text>
          </View>
          <View style={styles.right}>
            <Text caption2 grayColor numberOfLines={1}>
              {txtRight}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  item: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
  },
  contain: {
    flexDirection: "row",
    borderBottomWidth: 1,
    paddingTop: 5,
    paddingBottom: 5,
  },
  thumb: { width: 48, height: 48, marginRight: 10 },
  content: {
    flex: 1,
    flexDirection: "row",
  },
  left: {
    flex: 7.5,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  right: {
    flex: 2.5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
});
