import {
  GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useMemo } from "react";
import { TCoupon } from "../../models";
import { useTranslation } from "react-i18next";
import { BaseColor, useTheme } from "../../config";
import { Icon } from "../../components";
import { Flex, Text } from "../../ui";
import moment from "moment";
import { ServiceType } from "../../enums";

type Props = TCoupon & {
  allowsSelection: boolean;
  selected: boolean;
  onPress: (couponId: string) => void;
};

const CouponItem = React.memo<Props>((props) => {
  const {
    id,
    expiredAt,
    title,
    discount,
    serviceTypes,
    allowsSelection,
    selected,
    onPress,
  } = props;
  const { t } = useTranslation();
  const { colors } = useTheme();

  const discountStr = useMemo(() => {
    return discount >= 1
      ? t("coupon:price_discount", { discount })
      : t(`coupon:percentage_discount_${discount * 100}`);
  }, [discount]);
  const titleStr = title.includes("coupon:") ? t(title) : title || id;
  return (
    <TouchableOpacity
      onPress={() => {
        onPress(id);
      }}
      disabled={allowsSelection}
      style={{
        borderWidth: 1,
        shadowColor: "#000000",
        shadowOpacity: 0.4,
        shadowRadius: 1,
        shadowOffset: {
          height: 1,
          width: 1,
        },
        paddingHorizontal: 10,
        borderRadius: 10,
        marginVertical: 5,
      }}
    >
      <Flex fill paddingVertical={8}>
        <Flex>
          <Text
            style={{
              color: BaseColor.highlightedTextColor,
              marginTop: 12,
              marginBottom: 10,
            }}
            headline
          >
            {discountStr}
          </Text>
          <Flex paddingBottom={10}>
            <Text body2>{titleStr}</Text>
          </Flex>
          <Flex.Row paddingBottom={8} vCenter>
            <Icon size={14} name="calendar" color={BaseColor.textColor} />
            <Flex paddingLeft={12}>
              <Text subhead>
                {expiredAt
                  ? `${t("coupon:expired_at")}  ${moment(expiredAt).format(
                      "YYYY/MM/DD HH:mm:ss"
                    )}`
                  : t("coupon:no_expired")}
              </Text>
            </Flex>
          </Flex.Row>
          {!!serviceTypes && (
            <Flex.Row paddingBottom={8} vCenter>
              <Icon
                size={14}
                name="shopping-cart"
                color={BaseColor.textColor}
              />
              <Flex paddingLeft={12}>
                <Text subhead style={{ color: BaseColor.textColor }}>
                  {t("coupon:limited_services")}{" "}
                  <Text subhead style={{ color: colors.accent }}>
                    {serviceTypes
                      .map((type: any) => t(`enum:type_${ServiceType[type]}`))
                      .join(", ")}
                  </Text>
                </Text>
              </Flex>
            </Flex.Row>
          )}
        </Flex>
      </Flex>
    </TouchableOpacity>
  );
});

export default CouponItem;

const styles = StyleSheet.create({});
