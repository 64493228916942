import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import { Icon } from "../components";
import { Text } from "../ui";
import { Button } from "../ui";
import Modal from "react-native-modal";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { BaseColor, useTheme } from "../config";
import { useTranslation } from "react-i18next";

interface SelectModalProps {
  visible: boolean;
  options: Array<SelectOption>;
  selected?: SelectOption;
  onChange: any;
  multiSelected?: SelectOption[];
  multiSelect?: boolean;
}

interface SelectOption {
  value: any;
  text: any;
  description?: string;
  checked?: boolean;
}

const SelectModal = (props: SelectModalProps) => {
  const { visible, options, selected, onChange, multiSelect, multiSelected } =
    props;
  const { t } = useTranslation();
  const { colors } = useTheme();
  const cardColor = colors.card;
  const [modalVisible, setModalVisible] = useState(false);
  const [optionSelected, setOptionSelected] = useState<
    SelectOption | undefined
  >(selected);
  const [optionMultiSelected, setOptionMultiSelected] = useState<
    SelectOption[] | undefined
  >(multiSelected);
  const [selectOptions, setSelectOptions] = useState(
    options.map((item: SelectOption): SelectOption => {
      if (multiSelect) {
        return {
          ...item,
          checked: multiSelected?.includes(item.value),
        };
      } else {
        return {
          ...item,
          checked: item?.value === optionSelected?.value,
        };
      }
    })
  );

  useEffect(() => {
    setSelectOptions(
      options.map((item: SelectOption) => {
        return {
          ...item,
          checked: optionMultiSelected
            ? optionMultiSelected?.filter(
                (select: any) => select.value === item.value
              ).length > 0
            : false,
        };
      })
    );
  }, [optionMultiSelected]);

  const onSelectFilter = (selected: SelectOption) => {
    if (multiSelect) {
      if (!optionMultiSelected) return;
      if (
        optionMultiSelected?.filter((item) => item.value === selected.value)
          .length <= 0
      ) {
        setOptionMultiSelected((oldItem: any) => [...oldItem, selected]);
      } else {
        setOptionMultiSelected(
          optionMultiSelected?.filter((item) => item.value !== selected.value)
        );
      }
    } else {
      setSelectOptions(
        options.map((item: SelectOption) => {
          return {
            ...item,
            checked: item?.value == selected.value,
          };
        })
      );
    }
  };

  const onMultiSelect = (selected: SelectOption) => {};

  const onMultiSelected = () => {
    setModalVisible(true);
    // setSelectOptions()
  };

  const onOpenSelectModal = () => {
    setModalVisible(true);
    if (multiSelect) {
      setSelectOptions(
        options.map((item: SelectOption) => {
          return {
            ...item,
            checked: multiSelected?.includes(item),
          };
        })
      );
    } else {
      setSelectOptions(
        options.map((item: SelectOption) => {
          return {
            ...item,
            checked: item?.value === selected?.value,
          };
        })
      );
    }
  };

  const onApply = () => {
    const selectOption: any = selectOptions.filter(
      (item: SelectOption) => item?.checked
    );
    if (multiSelect) {
      setOptionMultiSelected(selectOption);
      setModalVisible(false);
      onChange(selectOption);
    } else {
      if (selectOption.length > 0) {
        setOptionSelected(selectOption[0]);
        setModalVisible(false);
        onChange(selectOption[0]);
      }
    }
  };

  useEffect(() => {
    if (visible) {
      onOpenSelectModal();
    }
  }, [visible]);
  return (
    <Modal
      isVisible={modalVisible}
      // onSwipeComplete={() => {
      //   setModalVisible(false);
      //   setSelectOptions(options);
      // }}
      swipeDirection={["down"]}
      style={[styles.bottomModal]}
    >
      <View
        style={[styles.contentFilterBottom, { backgroundColor: cardColor }]}
      >
        <View style={styles.contentSwipeDown}>
          <View style={styles.lineSwipeDown} />
        </View>
        <ScrollView style={{ height: "auto", maxHeight: 250 }}>
          {selectOptions.map((item: SelectOption) => (
            <TouchableOpacity
              style={[
                styles.contentActionModalBottom,
                { borderBottomColor: colors.border },
              ]}
              key={item?.value}
              onPress={() => onSelectFilter(item)}
            >
              <Text body2 semibold primaryColor={item?.checked}>
                {t(item?.text)}
              </Text>
              {item?.description ? (
                <Text footnote light>
                  {t(item.description)}
                </Text>
              ) : (
                <></>
              )}
              {item?.checked && (
                <Icon name="check" size={14} color={colors.primary} />
              )}
            </TouchableOpacity>
          ))}
        </ScrollView>
        <Button
          full
          style={{ marginTop: 10, marginBottom: 20 }}
          onPress={() => onApply()}
        >
          {t("enum:select")}
        </Button>
      </View>
    </Modal>
  );
};

export default SelectModal;

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  contentFilterBottom: {
    width: "100%",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingHorizontal: 20,
  },
  contentSwipeDown: {
    paddingTop: 10,
    alignItems: "center",
  },
  lineSwipeDown: {
    width: 30,
    height: 2.5,
    backgroundColor: BaseColor.dividerColor,
  },
  contentActionModalBottom: {
    flexDirection: "row",
    paddingVertical: 15,
    justifyContent: "space-between",
    borderBottomWidth: 1,
  },
});
