import {
  Linking,
  ScrollView,
  StyleProp,
  StyleSheet,
  Touchable,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { WEB_URL } from "../../../src/constant/backend";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-native-modal";
import { useTranslation } from "react-i18next";
import { BaseColor, BaseStyle, useTheme } from "../../config";
import { Header, Icon } from "../../components";
import { Text } from "../../ui";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  DayOfWeek,
  DayOfWeekUtil,
  PetCategory,
  PetCategoryUtil,
  PetSize,
  PetSizeUtil,
  PriceLevel,
  PriceLevelUtil,
  ServiceType,
  ServiceTypeUtil,
  Status,
} from "../../enums";
import {
  createService,
  createSitterReferralLink,
  getCurrentUserId,
  getServicePictureUrl,
  subscribeProfile,
  subscribeRegistration,
  subscribeReviews,
  subscribeServicePrices,
  TReviews,
  TServiceInput,
  TServicePlace,
} from "../../models";
import { ALL_AREAS } from "../../constant/areas";
import PlaceModal from "../../components/PlaceModal";
import { usePromise, useSubscription } from "../../hooks";
import * as ImagePicker from "expo-image-picker";
import { getAuth } from "firebase/auth";
import ProfileReviewScreen from "./ProfileReviewScreen";
import SalaryAccountScreen from "./SalaryAccountScreen";
import { subscribeMerchant } from "../../models/merchant";
import { minWidth } from "../../config/theme";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";

interface ServiceManageProps {
  modalVisible: boolean;
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
  onDismiss: () => void;
}

const getStatusColor = (status?: Status | null) => {
  if (status === Status.WAITING) {
    return BaseColor.warningColor;
  }
  if (status === Status.PROCESSING) {
    return BaseColor.warningColor;
  }
  if (status === Status.ACCEPTED) {
    return BaseColor.successColor;
  }
  if (status === Status.REJECTED) {
    return BaseColor.dangerColor;
  }
  if (status === Status.COMPLETED) {
    return BaseColor.successColor;
  }
  return undefined;
};

const SitterManage = React.memo((props: ServiceManageProps) => {
  const scrollViewRef = useRef<ScrollView | null>(null);

  const { modalVisible, style, onPress, onDismiss } = props;
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowProfileReview, setIsShowProfileReview] = useState(false);
  const [isShowSalaryAccount, setIsShowSalaryAccount] = useState(false);
  const userId = getCurrentUserId();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const registration = useSubscription({
    subscription: subscribeRegistration,
    variables: {
      userId: userId,
    },
  });
  const referralLink =
    usePromise(async () => {
      const myId = await getCurrentUserId();
      if (!myId) return "";
      return createSitterReferralLink({
        userId: myId,
        campaign: "share_by_provider",
      });
    }, [userId]) ?? "";
  const { kyc, contracts, tests } = registration || {};
  const { identity } = kyc || {};
  const { brokerage } = contracts || {};
  const { experience: experienceTest, platform: platformTest } = tests || {};

  const result =
    useSubscription({
      subscription: subscribeReviews,
      variables: {
        userId: userId,
      },
    }) ||
    ({
      payload: [],
      lastReviews: undefined,
    } as TReviews);
  const reviews = result.payload;
  const reviewStatus = reviews.length >= 2 ? Status.COMPLETED : null;

  const merchant = useSubscription({
    subscription: subscribeMerchant,
    variables: {
      userId: userId,
    },
  });
  const merchantStatus = merchant ? Status.COMPLETED : null;

  // Copied from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/screens/profile/SitterSettingsScreen.tsx#L50-56
  const profile = useSubscription({
    subscription: subscribeProfile,
    variables: {
      userId,
    },
  });
  const { certificates = [] } = profile || {};

  const handleCertificateList = () => {
    navigation.navigate("CertificateListScreen", {});
    onDismiss();
  };

  const handleProfileReview = () => {
    setIsShowProfileReview(true);
  };

  const handleExperienceTest = () => {
    Linking.openURL(
      `https://docs.google.com/forms/d/e/1FAIpQLSfi9ULfczrgPq_sVo4zc9qE3b_S7h-bwX0MqqH88U30j6oShw/viewform?usp=pp_url&entry.1294957977=${userId}`
    );
  };

  const handlePlatformTest = () => {
    Linking.openURL(
      `https://docs.google.com/forms/d/e/1FAIpQLSfx5giWQtwhjAgcdxCLEthLRKDMFVfcwBhwFARAacg6HCs5XQ/viewform?usp=pp_url&entry.1294957977=${userId}`
    );
  };

  const handleSalaryAccount = () => {
    setIsShowSalaryAccount(true);
  };

  const handleSignContract = () => {
    Linking.openURL(
      "https://www.dottedsign.com/public-form/sign?uuid=a8cc40bb-2717-4932-af53-98cf62aaed54"
    );
  };

  // copied from fluv mobile src/screens/profile/SitterSettingsScreen.tsx
  const handleTwoReferrals = () => {
    if (scrollViewRef.current) {
      // HACK: SectionList has no scrollToEnd function.
      // use ScrollView to wrap SectionList to scrollToEnd.
      // SectionList.scrollToLocation is not usable because it is hard to measure content height of this list.
      // using scrollToLocation will cause the following issue.
      // https://stackoverflow.com/questions/54129743/crash-when-doing-scrolltolocation-on-sectionlist
      scrollViewRef.current.scrollToEnd();
    }
  };

  const handleCopyLink = () => {
    if (!userId) {
      return;
    }
    navigator.clipboard.writeText(referralLink);
    alert(t("Referral Link is Copied"));
  };

  const handleCopyTemplate = () => {
    if (!userId) {
      return;
    }
    navigator.clipboard.writeText(referralTemplate);
    alert(t("Referral Template is Copied"));
  };

  const verifiSteps = [
    {
      step: "1.",
      title: "profile_review",
      onPress: handleProfileReview,
      status: identity,
    },
    {
      step: "2.",
      title: "experience_test",
      onPress: handleExperienceTest,
      status: experienceTest,
    },
    {
      step: "3.",
      title: "platform_test",
      onPress: handlePlatformTest,
      status: platformTest,
    },
    {
      step: "4.",
      title: "salary_account",
      onPress: handleSalaryAccount,
      status: merchantStatus,
    },
    {
      step: "5.",
      title: "sign_contract",
      onPress: handleSignContract,
      status: brokerage,
    },
    {
      step: "6.",
      title: "two_referrals",
      onPress: handleTwoReferrals,
      status: reviewStatus,
    },
  ];

  const referralTemplate = t(
    [
      "如果你願意成為我的推薦人，請依照以下步驟",
      "1. 打開連結點選右上角三條線登入 {{url}}",
      "2. 登入後到我的個人頁面點擊評論 ➕給予推薦",
      "有你的幫忙可以讓更多毛小孩被幫助以及被愛 💕，謝謝你！",
    ].join("\n\n"),
    {
      url: referralLink,
    }
  );

  return (
    <Modal isVisible={modalVisible} style={[style, styles.bottomModal]}>
      <ProfileReviewScreen
        visible={isShowProfileReview}
        onDismiss={() => {
          setIsShowProfileReview(false);
        }}
      />
      <SalaryAccountScreen
        visible={isShowSalaryAccount}
        onDismiss={() => {
          setIsShowSalaryAccount(false);
        }}
      />
      <LoadingSpinner visible={isLoading} />
      <View style={[{ backgroundColor: colors.card }, styles.contentBottom]}>
        <ScrollView ref={scrollViewRef}>
          <Header
            style={BaseStyle.menu}
            titleStyle={{ color: BaseColor.whiteColor }}
            title={t("sitter_verifi:title")}
            subTitle={""}
            onPressLeft={() => {
              onDismiss();
            }}
            renderLeft={() => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    onDismiss();
                  }}
                >
                  <Icon
                    name="times"
                    size={20}
                    style={{ color: BaseColor.whiteColor }}
                  />
                </TouchableOpacity>
              );
            }}
          />

          <View
            style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 5 }}
          >
            <Text grayColor>
              {t("sitter_verifi:steps")} {t("sitter_verifi:verifi_tip")}
            </Text>
          </View>
          {/* verifi step */}
          {verifiSteps.map((verifiStep) => {
            return (
              <TouchableOpacity
                key={verifiStep.step}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                  backgroundColor: BaseColor.whiteColor,
                  marginTop: 5,
                  borderBottomWidth: 1,
                  borderBottomColor: colors.card,
                }}
                onPress={verifiStep.onPress}
              >
                <View>
                  <Text body2 semibold>
                    {verifiStep.step} {t(`sitter_verifi:${verifiStep.title}`)}
                  </Text>
                </View>
                <View>
                  <TouchableOpacity
                    style={styles.selectButton}
                    onPress={verifiStep.onPress}
                  >
                    <Text style={{ color: getStatusColor(verifiStep.status) }}>
                      {verifiStep.status
                        ? t(`enum:status_${Status[verifiStep.status]}`)
                        : ""}
                    </Text>
                    <Icon
                      name="angle-down"
                      size={18}
                      style={{ marginLeft: 10 }}
                    />
                  </TouchableOpacity>
                </View>
              </TouchableOpacity>
            );
          })}
          {/* certificates */}
          <View
            style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 5 }}
          >
            <Text grayColor>{t("certificate:certificate")}</Text>
          </View>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingHorizontal: 20,
              backgroundColor: BaseColor.whiteColor,
              marginTop: 5,
              borderBottomWidth: 1,
              borderBottomColor: colors.card,
            }}
            onPress={handleCertificateList}
          >
            <View>
              <Text body2 semibold>
                {t("certificate:manage_certificates")}
              </Text>
            </View>
            <View style={{ padding: 10, height: 46 }}>
              <View style={{ flexDirection: "row" }}>
                <Text textColor semibold>
                  {certificates.length}
                </Text>
                <Icon name="angle-down" size={18} style={{ marginLeft: 10 }} />
              </View>
            </View>
          </TouchableOpacity>
          <View
            style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 5 }}
          >
            <Text grayColor>{t("certificate:upload_certificates_tip")}</Text>
          </View>

          <View
            style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 5 }}
          >
            <Text grayColor>{t("Referral Link")}</Text>
          </View>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingVertical: 15,
              paddingHorizontal: 20,
              backgroundColor: BaseColor.whiteColor,
              marginTop: 5,
              borderBottomWidth: 1,
              borderBottomColor: colors.card,
            }}
            onPress={handleCopyLink}
          >
            <View style={{ flex: 1 }}>
              <Text body2 semibold>
                {referralLink}
              </Text>
            </View>
          </TouchableOpacity>
          <View
            style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 5 }}
          >
            <Text grayColor>{t("Click to Copy Link")}</Text>
          </View>
          <View
            style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 5 }}
          >
            <Text grayColor>{t("Referral Template")}</Text>
          </View>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingVertical: 15,
              paddingHorizontal: 20,
              backgroundColor: BaseColor.whiteColor,
              marginTop: 5,
              borderBottomWidth: 1,
              borderBottomColor: colors.card,
            }}
            onPress={handleCopyTemplate}
          >
            <View style={{ flex: 1 }}>
              <Text body2 semibold>
                {referralTemplate}
              </Text>
            </View>
          </TouchableOpacity>
          <View
            style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 5 }}
          >
            <Text grayColor>{t("Click to Copy Template")}</Text>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
});

export default SitterManage;

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    width: "100%",
  },
  contentBottom: {
    width: "100%",
    minWidth: minWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flex: 1,
  },
  contentSwipeDown: {
    paddingTop: 20,
    alignItems: "flex-end",
  },
  selectButton: {
    padding: 10,
    height: 46,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    color: BaseColor.grayColor,
    borderRadius: 5,
    flexDirection: "row",
  },
  removePictureWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 999,
  },
  removePicture: {
    width: 24,
    height: 24,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
  },
});
