import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useCallback } from "react";
import { Banner, subscribeBanners } from "../../models";
import { useSubscription } from "../../hooks";
// import Carousel from 'react-native-reanimated-carousel';
import { Carousel } from "../../components";
import { useNavigation } from "../../hooks/useNavigation";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Swiper from "react-native-web-swiper";
import { MainStackScreenNavigationProp } from "../../config/typography";

const width = Dimensions.get("window").width;

const Banners = React.memo(() => {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const banners = useSubscription({
    subscription: subscribeBanners,
  });
  const images = banners?.map((banner: Banner) => banner.imageUrl) || [];
  const handleBannerClick = useCallback(
    async (item) => {
      console.log("item", item);
      console.log("banners", banners);
      if (banners) {
        const banner = banners.filter(
          (banner: Banner) => banner.imageUrl === item
        )[0] as Banner;
        switch (banner.action.type) {
          case "Search":
            const currentLocation: string | null = await AsyncStorage.getItem(
              "currentLocation"
            );
            if (currentLocation !== null) {
              const location = currentLocation.split("&") as Array<string>;
              navigation.navigate("SearchScreen", {
                type: banner.action.serviceType,
                category: [],
                size: [],
                areaCode: location[1],
              });
            }
            break;
          case "Link":
            window.open(banner.action.link, "_blank");
            break;
          case "Chat":
          case "Referral":
          default:
            window.open(`https://lin.ee/XQ3cli4`, "_blank");
            break;
        }
      }
    },
    [banners]
  );

  if (images.length > 0) {
    return (
      <View>
        <Carousel images={images} onPress={handleBannerClick} />
        {/* <Carousel
            vertical={false}
            width={width}
            height={width / 2}
            loop={true}
            style={{ width: '100%' }}
            autoPlay={false}
            autoPlayInterval={2000}
            data={images}
            mode="parallax"
            modeConfig={{
              parallaxScrollingScale: 0.9,
              parallaxScrollingOffset: 50,
            }}
            pagingEnabled={true}
            renderItem={({ item }: any) => {
              return (<>
                <TouchableOpacity style={{flex: 1}} onPress={() => {handleBannerClick(item, banners)}}>
                  <Image source={item} style={{width: '100%',height: '100%'}} resizeMode="contain"/>
                </TouchableOpacity>
              </>)
            }}
          /> */}
      </View>
    );
  } else {
    return null;
  }
});

export default Banners;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
  slide: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "#fff",
    fontSize: 25,
    fontWeight: "bold",
  },
  topLeft: {
    padding: 30,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  topRight: {
    padding: 30,
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  bottomLeft: {
    padding: 30,
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  bottomRight: {
    padding: 30,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
});
