import {
  FlatList,
  Modal,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { TextInput, Text } from "../ui";
import { BaseColor } from "../config";
import { useTranslation } from "react-i18next";
import { getAreaCode, searchPlaces, TLocation } from "../models/location";
import { Button } from "../ui";
import { TServicePlace } from "../models";
import { minWidth } from "../config/theme";

interface PlaceModalProps {
  visible: boolean;
  onSelect: (place: TServicePlace) => void;
  onDismiss: (visible: boolean) => void;
}

const PlaceModal = React.memo<PlaceModalProps>((props) => {
  const { visible, onDismiss, onSelect } = props;
  const { t } = useTranslation();
  const [searchPlace, setSearchPlace] = useState("");
  const [places, setPlaces] = useState<TServicePlace[]>([]);
  const [selectedPlace, setSelectedPlace] = useState<TServicePlace>();

  const handleChangeSearchText = async (text: string) => {
    setSearchPlace(text);
  };

  const handleSearch = async () => {
    const places = (await searchPlaces(searchPlace)) as TServicePlace[];
    setPlaces(places);
  };
  return (
    <Modal transparent={true} visible={visible}>
      <TouchableWithoutFeedback
        onPress={() => {
          onDismiss(false);
          setSearchPlace("");
          setPlaces([]);
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(33, 33, 33, 0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 15,
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                minWidth: minWidth,
                height: 400,
                maxHeight: 400,
                paddingTop: 12,
                paddingBottom: 24,
                paddingHorizontal: 15,
              }}
            >
              <View style={{ marginBottom: 5 }}>
                <Text
                  style={{ color: BaseColor.warningColor, marginBottom: 5 }}
                >
                  {t("search:address_warn")}
                </Text>
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <TextInput
                    onChangeText={handleChangeSearchText}
                    onFocus={() => {}}
                    placeholder={t("search:address_input")}
                    value={searchPlace}
                    style={{
                      borderColor: BaseColor.dividerColor,
                      paddingHorizontal: 10,
                      borderWidth: 1,
                      flex: 11,
                      marginRight: 5,
                    }}
                  />
                  <Button
                    style={{ borderRadius: 10, width: "5rem" }}
                    onPress={handleSearch}
                  >
                    {t("search:title")}
                  </Button>
                </View>
              </View>
              <ScrollView style={{ marginTop: 10, paddingHorizontal: 10 }}>
                {places.map((place, index) => (
                  <TouchableOpacity
                    key={index}
                    style={{ marginVertical: 10 }}
                    onPress={async () => {
                      setSearchPlace(place.address || searchPlace);
                      const latitude = place.location?.latitude || 0;
                      const longitude = place.location?.longitude || 0;
                      setSelectedPlace({
                        ...place,
                        areaCode:
                          (await getAreaCode({ latitude, longitude })) ||
                          undefined,
                      });
                    }}
                  >
                    <View>
                      <Text>{place.name}</Text>
                      <Text caption2 grayColor>
                        {place.address}
                      </Text>
                    </View>
                  </TouchableOpacity>
                ))}
              </ScrollView>
              <Button
                onPress={() => {
                  if (!selectedPlace) return;
                  onSelect(selectedPlace);
                  setSearchPlace("");
                  setPlaces([]);
                  onDismiss(false);
                }}
              >
                {t("enum:select")}
              </Button>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
});

export default PlaceModal;

const styles = StyleSheet.create({});
