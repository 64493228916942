import React from "react";
import { View, StyleSheet } from "react-native";
import { Images, BaseColor, useTheme } from "../config";
import { StarRating } from "../components";
import { Text, Image } from "../ui";
import moment from "moment";
import { getProfilePictureUrl } from "../models";

type TCommentItem = {
  style: any;
  avatarId: string;
  name: string;
  rate: number;
  comment: string;
  createdAt: number;
  userId: string;
};

const CommentItem = React.memo<TCommentItem>((props) => {
  const { colors } = useTheme();
  const cardColor = colors.card;
  const { style, avatarId, name, rate, comment, createdAt, userId } = props;
  const image = getProfilePictureUrl(userId, avatarId);
  const date = moment(createdAt).format("ll");
  return (
    <View style={[styles.contain, { backgroundColor: cardColor }, style]}>
      <View style={{ flexDirection: "row", marginBottom: 10 }}>
        <View style={styles.contentLeft}>
          <Image source={image} style={styles.thumb} />
          <View style={{ width: "70%" }}>
            <Text headline semibold numberOfLines={1}>
              {name}
            </Text>
            <View style={styles.contentRate}>
              <StarRating
                disabled={true}
                starSize={14}
                maxStars={5}
                rating={rate}
                selectedStar={(rating) => {}}
                fullStarColor={BaseColor.yellowColor}
              />
            </View>
          </View>
        </View>
        <View style={styles.contentRight}>
          <Text caption2 grayColor numberOfLines={1}>
            {date}
          </Text>
        </View>
      </View>
      <View>
        <Text
          body2
          style={{
            marginTop: 10,
          }}
        >
          {comment}
        </Text>
      </View>
    </View>
  );
});
export default CommentItem;
const styles = StyleSheet.create({
  contain: {
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  contentLeft: {
    flex: 8,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  thumb: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 5,
  },
  contentRight: {
    flex: 4,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  contentRate: {
    flex: 1,
    marginTop: 5,
    flexDirection: "row",
  },
});
