import { getAuth } from "firebase/auth";
import { PhoneVerificationApi } from "../api-typescript-axios-client-generated/api";

const getBaseOptions = async () => {
  const idToken = await getAuth()?.currentUser?.getIdToken(true);
  if (!idToken) throw new Error("88RZ");

  return {
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  };
};

const createPhoneVerificationApi = async () => {
  const baseOptions = await getBaseOptions();

  return new PhoneVerificationApi({
    baseOptions,
  });
};

async function get() {
  const phoneVerificationApi = await createPhoneVerificationApi();

  try {
    const phoneVerification = await phoneVerificationApi.getPhoneVerification();

    return phoneVerification.data;
  } catch (e: any) {
    throw new Error(e.response.data.error);
  }
}

async function post(code?: string) {
  const phoneVerificationApi = await createPhoneVerificationApi();

  let body = { code };
  if (code) {
    body.code = code;
  }

  try {
    const phoneVerification =
      await phoneVerificationApi.handlePhoneVerification(body);

    return phoneVerification.data;
  } catch (e: any) {
    throw new Error(e.response.data.error);
  }
}

const PhoneVerifications = {
  get,
  post,
};

export default PhoneVerifications;
