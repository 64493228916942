import {
  collection,
  connectFirestoreEmulator,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { removeEmpty } from "../utils";
import { getCurrentUserId } from "./auth";
import { TSubscription } from "./types";

export type TBank = {
  code: string;
  branchCode: string;
  account: string;
};

export type TMerchant = {
  id: string;
  createdAt: number;
  updatedAt: number;
  // serial: ?string,
  // engName: ?string,
  // city: ?string,
  // area: ?string,
  // zipCode: ?string,
  // address: ?string,
  bank?: TBank;
};

export type TMerchantInput = {
  // engName: string,
  // city: string,
  // area: string,
  // zipCode: string,
  // address: string,
  bank: TBank;
};

const toMerchant = (
  snapshot: DocumentSnapshot<DocumentData>
): TMerchant | null => {
  if (!snapshot.data()) return null;

  const { createdAt, updatedAt, bank } = snapshot.data() || {};
  return {
    id: snapshot.id,
    createdAt: createdAt?.seconds * 1000,
    updatedAt: updatedAt?.seconds * 1000,
    bank,
  };
};

const merchantRef = collection(db, "merchants");

export const getMerchantDoc = (userId: string) => {
  return doc(merchantRef, userId);
};

export const getMerchant = async (
  userId: string
): Promise<TMerchant | null> => {
  const merchantDoc = getMerchantDoc(userId);

  const snapshot = await getDoc(merchantDoc);
  return toMerchant(snapshot);
};

export const subscribeMerchant: TSubscription<
  { userId?: string },
  TMerchant
> = ({ variables, onChange }) => {
  const { userId } = variables || {};
  if (!userId) return () => {};
  const merchantDoc = getMerchantDoc(userId);

  return onSnapshot(merchantDoc, (snapshot) => {
    if (!onChange) return;
    onChange(toMerchant(snapshot));
  });
};

export const updateMerchant = async (
  merchant: TMerchantInput
): Promise<void> => {
  const myId = getCurrentUserId();
  console.log("myId", myId);
  if (!myId || myId === "") return;
  const merchantDoc = getMerchantDoc(myId);

  const { bank } = merchant;
  const { code, branchCode, account } = bank || {};

  await setDoc(
    merchantDoc,
    removeEmpty({
      updatedAt: serverTimestamp(),
      bank: {
        code,
        branchCode,
        account,
      },
    }),
    { merge: true }
  );
};
