import lang from "../lang";

/**
 * Basic Setting Variables Define
 */
export const BaseSetting = {
  name: "Fluv毛小愛",
  displayName: "Fluv 毛小愛",
  appVersion: "1.0.0",
  defaultLanguage: "zh-TW",
  languageSupport: Object.keys(lang),
  resourcesLanguage: lang,
};
