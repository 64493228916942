import * as Updates from "expo-updates";

const releaseChannel = Updates.releaseChannel || "local";

let isProd = false;
let isStaging = false;
let isLocal = false;
let env = null;

if (releaseChannel.includes("prod")) {
  isProd = true;
  env = "prod";
} else if (releaseChannel.includes("staging")) {
  isStaging = true;
  env = "staging";
} else {
  isLocal = true;
  env = "staging";
}

// 判斷使用者在哪個國家

export default {
  isProd,
  isStaging,
  isLocal,
  env,
};
