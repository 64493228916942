import { StyleSheet, View } from "react-native";
import React, { ReactNode } from "react";
import { Icon } from "../../../components";
import { Flex, Text } from "../../../ui";
import { BaseColor, useTheme } from "../../../config";

type Props = {
  iconName: string;
  text?: string;
  tip?: string;
  content: ReactNode;
};

const FieldRow = React.memo<Props>((props) => {
  const { iconName, text, tip, content } = props;
  const { colors } = useTheme();

  return (
    <Flex paddingVertical={8}>
      <Flex.Row vCenter between>
        <Flex.Row vCenter>
          <Icon size={14} name={iconName} color={colors.text} />
          <Flex paddingLeft={16}>
            <Text style={styles.text}>{text}</Text>
          </Flex>
        </Flex.Row>
        <Flex fill hEnd paddingLeft={12}>
          {content}
        </Flex>
      </Flex.Row>
      <Flex paddingLeft={32}>
        {!!tip && (
          <Text style={{ fontSize: 13, color: colors.text }}>{tip}</Text>
        )}
      </Flex>
    </Flex>
  );
});

export default FieldRow;

const styles = StyleSheet.create({
  text: {
    color: "#212121",
    fontSize: 16,
  },
});
