export enum PetPersonality {
  ENTHUSIASM = 1,
  FRIENDLY = 2,
  SMART = 3,
  ENERGETIC = 4,
  QUIET = 5,
  SHY = 6,
  NEEDY = 7,
  TIMID = 8,
  BRAVE = 9,
  GLUTTONOUS = 10,
  SENSITIVE = 11,
  STUBBORN = 12,
  NAUGHTY = 13,
}

export const PetPersonalityUtil = {
  list: (): PetPersonality[] => [
    PetPersonality.ENTHUSIASM,
    PetPersonality.FRIENDLY,
    PetPersonality.SMART,
    PetPersonality.ENERGETIC,
    PetPersonality.QUIET,
    PetPersonality.SHY,
    PetPersonality.NEEDY,
    PetPersonality.TIMID,
    PetPersonality.BRAVE,
    PetPersonality.GLUTTONOUS,
    PetPersonality.SENSITIVE,
    PetPersonality.STUBBORN,
    PetPersonality.NAUGHTY,
  ],
};
