import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { useAuthentication } from "../hooks";
import { useAuth } from "../context/Auth";
import { checkIn } from "../models/auth";
import AuthStack from "./Stack/AuthStack";
import { LocalData } from "../context/LocalData";
import { View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  NavigationContainer,
  NavigatorScreenParams,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useTheme } from "../config";
import UserTabNavigator, { UserTabParamList } from "./Tab/UserTabNavigator";
import SitterTabNavigator, {
  SitterTabParamList,
} from "./Tab/SitterTabNavigator";
import * as Linking from "expo-linking";
import { withSearchScreenPageProps } from "../screenPages/SearchScreenPage.tsx";

import {
  CatboxScreen,
  CertificateCreateScreen,
  CertificateListScreen,
  CertificateUpdateScreen,
  CreateOrderScreen,
  ChatRoomScreen,
  OrderConfirmScreen,
  OrderDetailScreen,
  OrderEditScreen,
  ProfileEditScreen,
  SearchScreen,
  ServiceDetailScreen,
  SitterDetailScreen,
  PetsScreen,
  PaymentScreen,
  PetProfileManagerScreen,
  CreateReviewScreen,
  SignInScreen,
  VerificationScreen,
} from "../screens";

export type MainStackParamList = {
  Auth: undefined;
  UserTab: NavigatorScreenParams<UserTabParamList>;
  SitterTab: NavigatorScreenParams<SitterTabParamList>;
  SitterOnBoarding: undefined;
  UserOnBoarding: undefined;
  SearchScreen:
    | {
        type: any;
        category: any;
        size: any;
        areaCode: any;
      }
    | undefined;
  SitterDetailScreen:
    | {
        orderId?: string | undefined;
        sitterId: string | undefined;
        serviceId?: string | undefined;
        preRoute?: string;
      }
    | undefined;
  ServiceDetailScreen:
    | {
        serviceId?: string | undefined;
        sitterId?: string | undefined;
      }
    | undefined;
  CatboxScreen: {};
  CertificateCreateScreen: {};
  CertificateListScreen: {};
  CertificateUpdateScreen:
    | {
        id: string | undefined;
        type: number | undefined;
        category?: string | undefined;
        pictureUriOrId: string | undefined;
      }
    | undefined;
  CreateOrderScreen:
    | {
        serviceId: string | undefined;
        sitterId: string | undefined;
      }
    | undefined;
  OrderDetailScreen:
    | {
        orderId: string;
      }
    | undefined;
  OrderConfirmScreen:
    | {
        orderId: string;
      }
    | undefined;
  OrderEditScreen:
    | {
        orderId: string;
      }
    | undefined;
  PaymentScreen: undefined;
  ProfileEditScreen:
    | {
        userId: string | undefined;
      }
    | undefined;
  ChatRoomScreen:
    | {
        userId: string | undefined;
        name: string;
      }
    | undefined;
  PetsScreen:
    | {
        userId: string | undefined;
      }
    | undefined;
  PetProfileManagerScreen:
    | {
        petId: string | undefined;
        userId: string | undefined;
        serviceId?: string | undefined;
        sitterId?: string | undefined;
      }
    | undefined;
  CreateReviewScreen: {
    sitterId: string;
    orderId?: string;
  };
};
const MainStack = createStackNavigator<MainStackParamList>();

const RootNavigation = () => {
  const language = "zh-TW";
  const { theme } = useTheme();
  /**
   * when reducer language change
   */
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const { userId } = useAuth();

  // only be triggered at user/brand changed
  useEffect(checkIn, [userId]);

  const linking: LinkingOptions<MainStackParamList> = {
    prefixes: [Linking.createURL("https://app.fluv.com")],
    config: {
      initialRouteName: "UserTab",
      screens: {
        Auth: "signin",
        UserTab: {
          initialRouteName: "Home",
          screens: {
            Home: "home",
            Order: "order",
            Chat: "chatlist",
            Profile: {
              path: "user",
            },
          },
        },
        SearchScreen: "search",
        ServiceDetailScreen: "service-detail",
        SitterDetailScreen: "sitter-detail",
        CatboxScreen: "catbox",
        CertificateCreateScreen: "~certificate-create",
        CertificateListScreen: "~certificate-list",
        CertificateUpdateScreen: "~certificate-update",
        CreateOrderScreen: "create-order",
        OrderDetailScreen: "order-detail",
        OrderConfirmScreen: "order-confirm",
        OrderEditScreen: "order/:orderId/edit",
        PaymentScreen: "payment",
        ProfileEditScreen: "profile-edit",
        ChatRoomScreen: "chatroom",
        PetsScreen: "user/pet",
        PetProfileManagerScreen: "pet-profile-manager",
        CreateReviewScreen: "create-review",
        Verification: "accounts/me/verification/:code",
      },
    },
  };

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <View style={{ flex: 1, width: "100%", maxWidth: "100%" }}>
        <LocalData>
          <NavigationContainer theme={theme} linking={linking}>
            <MainStack.Navigator
              screenOptions={{
                headerShown: false,
              }}
              initialRouteName={"UserTab"}
            >
              {/* Auth */}
              <MainStack.Screen
                name="Auth"
                component={SignInScreen}
                options={{
                  gestureDirection: "horizontal-inverted",
                  gestureEnabled: true,
                }}
              />
              {/* Tab */}
              <MainStack.Screen name="UserTab" component={UserTabNavigator} />
              <MainStack.Screen
                name="SitterTab"
                component={SitterTabNavigator}
              />
              {/* Search */}
              <MainStack.Screen name="SearchScreen" component={withSearchScreenPageProps(SearchScreen)} />
              {/* Sitter */}
              <MainStack.Screen
                name="SitterDetailScreen"
                component={SitterDetailScreen}
              />
              {/* Service */}
              <MainStack.Screen
                name="ServiceDetailScreen"
                component={ServiceDetailScreen}
              />
              {/* Order */}
              <MainStack.Screen name="CatboxScreen" component={CatboxScreen} />
              <MainStack.Screen
                name="CreateOrderScreen"
                component={CreateOrderScreen}
              />
              <MainStack.Screen
                name="OrderDetailScreen"
                component={OrderDetailScreen}
              />
              <MainStack.Screen
                name="OrderConfirmScreen"
                component={OrderConfirmScreen}
              />
              <MainStack.Screen
                name="OrderEditScreen"
                component={OrderEditScreen}
              />
              {/* Payment */}
              <MainStack.Screen
                name="PaymentScreen"
                component={PaymentScreen}
              />
              {/* Profile */}
              <MainStack.Screen
                name="ProfileEditScreen"
                component={ProfileEditScreen}
              />
              {/* Chat */}
              <MainStack.Screen
                name="ChatRoomScreen"
                component={ChatRoomScreen}
              />
              {/* Pet */}
              <MainStack.Screen name="PetsScreen" component={PetsScreen} />
              <MainStack.Screen
                name="PetProfileManagerScreen"
                component={PetProfileManagerScreen}
              />
              {/* Review */}
              <MainStack.Screen
                name="CreateReviewScreen"
                component={CreateReviewScreen}
              />
              {/* Verification */}
              <MainStack.Screen
                name="Verification"
                component={VerificationScreen}
              />
              {/* Certificate */}
              <MainStack.Screen
                name="CertificateCreateScreen"
                component={CertificateCreateScreen}
              />
              <MainStack.Screen
                name="CertificateListScreen"
                component={CertificateListScreen}
              />
              <MainStack.Screen
                name="CertificateUpdateScreen"
                component={CertificateUpdateScreen}
              />
            </MainStack.Navigator>
          </NavigationContainer>
          {/* <UserStack /> */}
        </LocalData>
      </View>
    </View>
  );
};

export default RootNavigation;
