import {
  KeyboardAvoidingView,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-native-modal";
import LoadingSpinner from "../../components/LoadingSpinner";
import { BaseColor, BaseStyle, useTheme } from "../../config";
import { ScrollView } from "react-native-gesture-handler";
import { Header, Icon } from "../../components";
import { Image, Text, TextInput } from "../../ui";
import { t } from "i18next";
import * as ImagePicker from "expo-image-picker";
import {
  getCurrentUserId,
  getIdentity,
  getIdentityIdentityUrl,
  updateIndetity,
} from "../../models";
import { useAuth } from "../../context/Auth";
import { usePromise } from "../../hooks";
import { minWidth } from "../../config/theme";

interface ProfileReviewProps {
  visible: boolean;
  onDismiss: () => void;
  style?: StyleProp<ViewStyle>;
}

const ProfileReviewScreen = (props: ProfileReviewProps) => {
  const { visible, style, onDismiss } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState<string>();
  const [birthDay, setBirthDay] = useState<string>();
  const [idNumber, setIdNumber] = useState<string>();
  const [experience, setExperience] = useState<string>();
  const [frontId, setFrontId] = useState<string>();
  const [selfieId, setSelfieId] = useState<string>();
  const [experienceHeight, setExperienceHeight] = useState<number>(45);
  const { colors } = useTheme();
  let { userId } = useAuth();
  userId = getCurrentUserId();
  const identity = usePromise(() => getIdentity(userId), [userId]);
  useEffect(() => {
    if (!identity) return;
    setName(identity.realName);
    if (identity.birthDate)
      setBirthDay(formatDateStr(identity.birthDate, [4, 6]));
    setIdNumber(identity.idNumber);
    setFrontId(identity.frontId);
    setSelfieId(identity.selfieId);
    setExperience(identity.experience);
  }, [identity]);

  const frontUrl = usePromise(
    () => getIdentityIdentityUrl(userId, frontId),
    [userId, frontId]
  );
  const selfieUrl = usePromise(
    () => getIdentityIdentityUrl(userId, selfieId),
    [userId, selfieId]
  );

  const formatDateStr = (dateStr: string, separators: number[]) => {
    if (!dateStr) return dateStr;

    let newDateStr = "";
    [...dateStr].forEach((char, index) => {
      if (separators.includes(index)) {
        newDateStr += "/";
      }
      newDateStr += char;
    });
    return newDateStr;
  };

  const handleChangeName = useCallback((text) => {
    setName(text);
  }, []);

  const checkIsNumber = (data: number) => {
    if (data.toString().length === 0) return true;
    if (
      isNaN(
        parseInt(
          data
            .toString()
            .split("")
            .filter((data) => data !== "/")
            .join("")
        )
      )
    ) {
      return false;
    } else {
      return true;
    }
  };
  let preTemp = "";
  const handleChangeBirthDay = useCallback((text) => {
    if (!checkIsNumber(text)) return;
    let temp = text;
    if (text.length > 10) return;
    if (text.length === 4 || text.length === 7) {
      if (preTemp.length > text.length) {
        setBirthDay(temp);
        return;
      }
      temp = temp + "/";
    }
    setBirthDay(temp);
    preTemp = text;
  }, []);

  const handleChangeIdNumber = useCallback((text) => {
    if (text.length > 10) return;
    setIdNumber(text.toUpperCase());
  }, []);

  const handleChangeExperience = useCallback((text) => {
    setExperience(text);
  }, []);

  const handleSelectFront = useCallback(async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    if (status !== "granted") {
      alert("沒有取得權限");
    } else {
      const result = await ImagePicker.launchCameraAsync({});
      if (!result.cancelled) {
        setFrontId(result.uri);
      }
    }
  }, []);

  const handleSelectSelfie = useCallback(async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    if (status !== "granted") {
      alert("沒有取得權限");
    } else {
      const result = await ImagePicker.launchCameraAsync({});
      if (!result.cancelled) {
        setSelfieId(result.uri);
      }
    }
  }, []);

  const onUpdate = async () => {
    if (
      !name ||
      !birthDay ||
      birthDay.length !== 10 ||
      !idNumber ||
      !frontId ||
      !selfieId ||
      !experience
    ) {
      alert(t("enum:must_fill_all_fields"));
      return;
    }
    setIsLoading(true);
    try {
      await updateIndetity({
        realName: name,
        birthDate: birthDay,
        idNumber,
        frontUriOrId: frontId,
        selfieUriOrId: selfieId,
        experience,
      });
    } catch (e) {
      console.warn(e);
    }
    setIsLoading(false);
    onDismiss();
  };

  return (
    <Modal isVisible={visible} style={[style, styles.bottomModal]}>
      <LoadingSpinner visible={isLoading} />
      <KeyboardAvoidingView
        style={[{ backgroundColor: colors.card }, styles.contentBottom]}
      >
        <ScrollView>
          <View style={{ paddingBottom: 15 }}>
            <Header
              style={BaseStyle.menu}
              titleStyle={{ color: BaseColor.whiteColor }}
              title={t("sitter_verifi:edit_verifi_detail")}
              subTitle={""}
              onPressRight={onUpdate}
              renderRight={() => {
                return (
                  <Text style={{ color: BaseColor.whiteColor }}>
                    {t("common:done")}
                  </Text>
                );
              }}
              renderLeft={() => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      onDismiss();
                    }}
                  >
                    <Icon
                      name="times"
                      size={20}
                      style={{ color: BaseColor.whiteColor }}
                    />
                  </TouchableOpacity>
                );
              }}
            />
            {/* real name */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginTop: 10,
                paddingVertical: 5,
                borderBottomWidth: 1,
                borderBottomColor: colors.card,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("sitter_verifi:real_name")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                }}
              >
                <TextInput
                  onChangeText={handleChangeName}
                  onFocus={() => {}}
                  placeholder={t("sitter_verifi:real_name_placeholder")}
                  value={name}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
            </View>

            {/* birthday */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginTop: 10,
                paddingVertical: 5,
                borderBottomWidth: 1,
                borderBottomColor: colors.card,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("sitter_verifi:birth_date")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                }}
              >
                <TextInput
                  onChangeText={handleChangeBirthDay}
                  onFocus={() => {}}
                  placeholder={t("sitter_verifi:birth_date_placeholder")}
                  value={birthDay}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
            </View>

            {/* idNumber */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginTop: 10,
                paddingVertical: 5,
                borderBottomWidth: 1,
                borderBottomColor: colors.card,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("sitter_verifi:identity_number")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                }}
              >
                <TextInput
                  onChangeText={handleChangeIdNumber}
                  onFocus={() => {}}
                  placeholder={t("sitter_verifi:identity_number_placeholder")}
                  value={idNumber}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
            </View>
            <View
              style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 5 }}
            >
              <Text grayColor>{t("sitter_verifi:identity_number_tips")}</Text>
            </View>

            {/* experience */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginTop: 10,
                paddingVertical: 5,
                borderBottomWidth: 1,
                borderBottomColor: colors.card,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("sitter_verifi:experience")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                  height: "auto",
                }}
              >
                <TextInput
                  onChangeText={handleChangeExperience}
                  onFocus={() => {}}
                  placeholder={t("sitter_verifi:experience_placeholder")}
                  value={experience}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderBottomWidth: 1,
                    maxHeight: 250,
                    minHeight: 44,
                    height: experienceHeight,
                  }}
                  multiline={true}
                  onContentSizeChange={(e) => {
                    setExperienceHeight(e.nativeEvent.contentSize.height);
                  }}
                />
              </View>
            </View>
            <View
              style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 5 }}
            >
              <Text grayColor>{t("sitter_verifi:experience_tips")}</Text>
            </View>
            {/* idcard front */}
            {frontUrl && frontUrl !== "" ? (
              <Image
                source={{ uri: frontUrl }}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <></>
            )}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginTop: 10,
                paddingVertical: 5,
                borderBottomWidth: 1,
                borderBottomColor: colors.card,
              }}
            >
              <TouchableOpacity onPress={handleSelectFront}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingHorizontal: 20,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text body2 semibold>
                      {t("sitter_verifi:take_identity_front")}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
            <View
              style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 5 }}
            >
              <Text grayColor>{t("sitter_verifi:identity_number_tips")}</Text>
            </View>

            {/* idcard front */}
            {selfieUrl && selfieUrl !== "" ? (
              <Image
                source={{ uri: selfieUrl }}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <></>
            )}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginTop: 10,
                paddingVertical: 5,
                borderBottomWidth: 1,
                borderBottomColor: colors.card,
              }}
            >
              <TouchableOpacity onPress={handleSelectSelfie}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingHorizontal: 20,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text body2 semibold>
                      {t("sitter_verifi:take_selfie")}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
            <View
              style={{ paddingHorizontal: 20, marginTop: 10, marginBottom: 10 }}
            >
              <Text grayColor>{t("sitter_verifi:selfie_tips")}</Text>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default ProfileReviewScreen;

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    width: "100%",
  },
  contentBottom: {
    width: "100%",
    minWidth: minWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flex: 1,
  },
});
