type AnyFn = (...args: any[]) => any;

const memo = <T extends AnyFn>(fn: T) => {
  let cache: ReturnType<T> | null = null;
  return (...args: Parameters<T>) => {
    if (cache === null) {
      cache = fn(...args);
    }
    return cache;
  };
};

export default memo;
