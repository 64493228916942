import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { BaseStyle, BaseColor, useTheme } from "../../config";
import {
  Header,
  SafeAreaView,
  Icon,
  StarRating,
  PetModal,
  CalendarPicker,
  TimePicker,
} from "../../components";
import { Text, Image, TextInput, Flex } from "../../ui";
import { Button } from "../../ui";
import { useTranslation } from "react-i18next";
import { usePromise, useSubscription } from "../../hooks";
import {
  calculateQuantity,
  createOrder,
  getCurrentUserId,
  getProfile,
  getProfilePictureUrl,
  getService,
  getServicePictureUrl,
  subscribePets,
  subscribeServiceDetail,
  TPet,
  TProfile,
  TService,
  TServiceDetail,
} from "../../models";
import {
  DayOfWeekUtil,
  ServiceType,
  ServiceTypeMap,
  PetCategory,
  PetSize,
} from "../../enums";
import { useLocalData } from "../../context/LocalData";
import moment from "moment";
import { useAuth } from "../../context/Auth";
import { Discount, useDiscount } from "../../modules";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PlaceModal from "../../components/PlaceModal";
import OrderBreakdown from "./OrderBreakdown";
import { useNavigation } from "../../hooks/useNavigation";
import LoadingSpinner from "../../components/LoadingSpinner";
import { getAuth } from "firebase/auth";
import { minWidth } from "../../config/theme";
import { MainStackScreenNavigationProp } from "../../config/typography";

const height = Dimensions.get("screen").height;

export default function CreateOrderScreen({ route }: any) {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { serviceId, sitterId } = route.params;
  const [showPetModal, setShowPetModal] = useState<boolean>(false);
  const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
  const [showStartTimePicker, setShowStartTimePicker] =
    useState<boolean>(false);
  const [showEndTimePicker, setShowEndTimePicker] = useState<boolean>(false);
  const [showPlaceModal, setShowPlaceModal] = useState<boolean>(false);
  let { userId } = useAuth();
  userId = getCurrentUserId();
  const auth = getAuth();
  const { currentUser } = auth;

  const [place, setPlace] = useState<any>();
  const [startHour, setStartHour] = useState<number>();
  const [endHour, setEndHour] = useState<number>();
  const [frames, setFrames] = useState(1);
  const [times, setTimes] = useState(1);
  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // serviceDetail
  const serviceDetail: TServiceDetail | undefined | null = useSubscription({
    subscription: subscribeServiceDetail,
    variables: {
      serviceId,
    },
  });
  const {
    type,
    title,
    place: servicePlace,
    availableDays = [],
    pictureIds = [],
    petCategories = [],
    petSizes = [],
    providerId,
    areaCodes = [],
    profile,
  } = serviceDetail || {};

  const FRAMES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const TIMES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const servicePictureUrl = getServicePictureUrl(
    sitterId,
    serviceDetail?.pictureIds[0]
  );
  const sitterPictureUrl = getProfilePictureUrl(sitterId, profile?.avatarId);
  const { currentFilters, updateCurrentFilters } = useLocalData();
  const { petIds: tempSelectedPetIds = [], dates: tempSelectedDates = [] } =
    currentFilters || {};
  // place
  useEffect(() => {
    AsyncStorage.getItem("currentLocation").then(
      (currentLocation: string | null) => {
        if (currentLocation !== null) {
          const location = currentLocation.split("&") as Array<string>;
          setPlace({
            name: location[0],
            address: location[0],
            areaCode: location[1],
            location: {
              latitude: parseInt(location[2]),
              longitude: parseInt(location[3]),
            },
          });
        }
      }
    );
  }, []);
  // pet
  const pets =
    useSubscription({
      subscription: subscribePets,
      variables: {
        userId,
      },
    }) || [];
  // dates
  const handleSelectDates = useCallback((dates) => {
    updateCurrentFilters({ dates });
  }, []);

  // pets
  const handleSelectPetIds = useCallback((petIds: any[]) => {
    updateCurrentFilters({ petIds });
  }, []);

  const disabledPets = pets.filter(
    (pet) =>
      !petCategories.includes(pet.category) || !petSizes.includes(pet.size)
  );
  const selectedPets: TPet[] = pets
    .filter((pet) => tempSelectedPetIds.includes(pet.id))
    .filter((pet) => !disabledPets.map((p) => p.id).includes(pet.id));

  // frames
  const minFrames = Math.ceil(selectedPets.length / 5);
  useEffect(() => {
    if (frames < minFrames) {
      setFrames(minFrames);
    }
  }, [frames, minFrames]);

  const framesOptions = useMemo(
    () =>
      FRAMES.map((n) => ({
        title: t("enum:minutes", { minutes: n * 30 }),
        value: n,
      })),
    []
  );

  // times
  const timesOptions = useMemo(
    () =>
      TIMES.map((n) => ({
        title: t("enum:times", { times: n }),
        value: n,
      })),
    []
  );
  // console.log('tempSelectedDates',tempSelectedDates);
  // console.log('moment(date).day()',moment(tempSelectedDates[0]).day());
  // console.log('availableDays',availableDays);
  const selectedDates = tempSelectedDates
    .map((date) => moment(date).day())
    .some((day) => availableDays.includes(day))
    ? tempSelectedDates
    : [];

  // console.log('selectedDates',selectedDates);
  const disabledDays = DayOfWeekUtil.list().filter(
    (day) => !availableDays.includes(day)
  );
  // const selectedPlace = places
  // .filter((place) => tempSelectedPlaceId === place.id)
  // .filter((place) => !disabledPlaces.map((p) => p.id).includes(place.id))?.[0];

  const conflictedDays = selectedDates
    .map((date) => moment(date).day())
    .filter((day) => disabledDays.includes(day));

  const { getDiscount } = useDiscount();
  const {
    detail: campaignDetail,
    basePrice,
    discountedBasePrice,
    breakdown,
  }: Discount = serviceDetail ? getDiscount(serviceDetail, selectedPets) : {};

  const originalPrice = !breakdown
    ? basePrice
    : Object.keys(breakdown).reduce((result, petId) => {
        const { basePrice: petBasePrice, extra = [] } = breakdown[petId];
        /* @ts-ignore */
        const petExtraPrice = Object.keys(extra).reduce(
          (r, key) => r + (extra[key] || 0),
          0
        );

        return result + petBasePrice + petExtraPrice;
      }, 0);

  const discountedPrice = !breakdown
    ? discountedBasePrice
    : Object.keys(breakdown).reduce((result, petId) => {
        const { discountedBasePrice: petDiscountedBasePrice, extra = [] } =
          breakdown[petId];
        /* @ts-ignore */
        const petExtraPrice = Object.keys(extra).reduce(
          (r, key) => r + (extra[key] || 0),
          0
        );

        return (
          result +
          (petDiscountedBasePrice ? petDiscountedBasePrice + petExtraPrice : 0)
        );
      }, 0);

  const price = Math.round(discountedPrice || originalPrice || 0);

  const quantity = type
    ? calculateQuantity({
        type,
        dates: selectedDates,
        frames,
        times,
        startHour,
        endHour,
      })
    : 0;
  const amount = price * quantity;
  const showConflictDaysWarning = conflictedDays.length > 0;
  const showTooManyPetsError =
    (type === ServiceType.DROPIN || type === ServiceType.WALKING) &&
    selectedPets.length > 5;

  const validated =
    selectedDates.length > 0 &&
    ((type !== ServiceType.DROPIN && type !== ServiceType.WALKING) || frames) &&
    ((type !== ServiceType.DROPIN && type !== ServiceType.WALKING) || times) &&
    providerId &&
    userId &&
    serviceId &&
    selectedPets.length > 0 &&
    // (type === ServiceType.TAVERN || type === ServiceType.VET_HOSPITAL || selectedPlace) &&
    amount &&
    amount > 0 &&
    price &&
    price > 0;

  const handleCreateOrder = useCallback(async () => {
    if (!serviceId || !providerId || !validated) return;
    setIsLoading(true);

    try {
      const { name, address, doorNumber, areaCode, location } =
        place || servicePlace || {};
      const orderId = await createOrder({
        place: {
          name,
          address,
          doorNumber,
          areaCode,
          location,
        },
        dates: selectedDates,
        startHour: type === ServiceType.TAVERN ? startHour : undefined,
        endHour: type === ServiceType.TAVERN ? endHour : undefined,
        frames:
          type === ServiceType.DROPIN || type === ServiceType.WALKING
            ? frames
            : undefined,
        times:
          type === ServiceType.DROPIN || type === ServiceType.WALKING
            ? times
            : undefined,
        notes,
        providerId,
        serviceId,
        petIds: selectedPets.map((pet) => pet.id),
        breakdown,
        unconfirmedPrice: price,
        unconfirmedAmount: amount,
      });

      if (orderId) {
        setTimeout(() => {
          navigation.push("OrderDetailScreen", { orderId });
          setIsLoading(false);
        }, 750);
      }
    } catch (e) {
      // TODO: use snackbar to display the status
      setIsLoading(false);
      console.warn(e);
    }
  }, [
    type,
    place,
    selectedDates,
    startHour,
    endHour,
    frames,
    times,
    notes,
    providerId,
    userId,
    serviceId,
    selectedPets,
    breakdown,
    price,
    amount,
    price,
    validated,
  ]);

  const handleSelectStartHours = useCallback((value) => {
    setStartHour(value);
  }, []);

  const handleSelectEndHours = useCallback((value) => {
    setEndHour(value);
  }, []);

  const getCurrentAsyncStorageLocation = () => {
    AsyncStorage.getItem("currentLocation").then(
      (currentLocation: string | null) => {
        if (currentLocation !== null) {
          const location = currentLocation.split("&") as Array<string>;
          setPlace({
            name: location[0],
            address: location[0],
            areaCode: location[1],
            location: {
              latitude: parseInt(location[2]),
              longitude: parseInt(location[3]),
            },
          });
        }
      }
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <LoadingSpinner visible={isLoading} />
      <Header
        title={t("page:preview_reserve")}
        subTitle={""}
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} color={colors.primary} />;
        }}
        onPressLeft={() => {
          navigation.pop();
          // navigation.navigate('ServiceDetailScreen', {
          //   serviceId: serviceId
          // });
        }}
      />
      <SafeAreaView
        style={[BaseStyle.safeAreaView]}
        edges={["right", "left", "bottom"]}
      >
        <ScrollView>
          <View style={{ paddingHorizontal: 20, paddingBottom: 167 }}>
            <View
              style={[
                styles.blockView,
                styles.bottomBorder,
                { flexDirection: "column" },
              ]}
            >
              <TouchableOpacity
                style={[
                  { flexDirection: "row", justifyContent: "space-between" },
                ]}
                onPress={() => {
                  navigation.navigate("ServiceDetailScreen", {
                    serviceId,
                    sitterId,
                  });
                }}
              >
                <View style={{ flex: 5 }}>
                  <Text body2 style={{ marginBottom: 10 }}>
                    {serviceDetail ? (
                      t(
                        `search:type_${ServiceTypeMap.get(serviceDetail?.type)}`
                      )
                    ) : (
                      <></>
                    )}
                  </Text>
                  <Text body1 semibold numberOfLines={3}>
                    {serviceDetail?.title}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 2,
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Image
                    style={{ width: 72, height: 72 }}
                    source={servicePictureUrl}
                    resizeMode="cover"
                  />
                </View>
              </TouchableOpacity>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  style={{ marginTop: 15, flex: 5 }}
                  onPress={() => {
                    navigation.navigate("SitterDetailScreen", {
                      sitterId: sitterId,
                    });
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Image
                      style={{
                        width: 48,
                        height: 48,
                        borderRadius: 24,
                        marginRight: 10,
                      }}
                      source={sitterPictureUrl}
                      resizeMode="cover"
                    />
                    <View>
                      <Text headline>{profile?.displayName}</Text>
                      <StarRating
                        disabled={true}
                        starSize={14}
                        maxStars={5}
                        rating={profile?.avgRating}
                        selectedStar={(rating) => {}}
                        fullStarColor={BaseColor.yellowColor}
                        containerStyle={{ width: 60, marginTop: 5 }}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
                {/* <TouchableOpacity
                  style={{
                    flex: 5,
                    justifyContent: 'center',
                    alignItems: 'flex-end'
                  }}
                  onPress={() => {
                    if(currentUser !== null) {
                      navigation.navigate('Messages', { userId: sitterId, name: encodeURI(profile?.displayName || '') });
                    } else {
                      navigation.navigate('SignIn');
                    }
                  }}
                >
                  <Icon name="comment" size={20} color={BaseColor.color1}/>
                </TouchableOpacity>             */}
              </View>
            </View>
            <View style={[{ borderBottomColor: colors.card }]}>
              <TouchableOpacity
                style={[
                  { flexDirection: "row", marginTop: 15 },
                  styles.bottomBorder,
                ]}
                onPress={() => {
                  if (currentUser !== null) {
                    setShowPetModal(true);
                  } else {
                    navigation.navigate("Auth");
                  }
                }}
              >
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <View
                    style={{
                      marginRight: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon name="heart" size={14} />
                  </View>
                  <Text body2 semibold>
                    {t("order:pets")}
                  </Text>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <View>
                    <Text
                      body2
                      grayColor={selectedPets.length <= 0 ? true : false}
                    >
                      {selectedPets.length > 0
                        ? selectedPets.map((pet) => `${pet.name}`).join(" ,")
                        : t("search:select_pets")}
                    </Text>
                    <PetModal
                      visable={showPetModal}
                      onDismiss={setShowPetModal}
                      onSelect={handleSelectPetIds}
                      defaultPet={selectedPets.map((pet) => pet.id)}
                      disabledPet={disabledPets.map((pet) => pet.id)}
                      serviceId={serviceId}
                      sitterId={sitterId}
                    />
                  </View>
                </View>
              </TouchableOpacity>

              {(type === ServiceType.BATHING ||
                type === ServiceType.GROOMING ||
                type === ServiceType.VET_TO_HOME ||
                type === ServiceType.VET_HOSPITAL) && (
                <TouchableOpacity
                  style={[
                    { flexDirection: "row", marginTop: 15 },
                    styles.bottomBorder,
                  ]}
                  onPress={() => {
                    setShowCalendarModal(true);
                  }}
                >
                  <View style={{ flex: 1, flexDirection: "row" }}>
                    <View
                      style={{
                        marginRight: 5,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon name="arrow-right" size={14} />
                    </View>
                    <Text body2 semibold>
                      {t("search:dates")}
                    </Text>
                  </View>
                  <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <View style={{ flexDirection: "row" }}>
                      {currentFilters?.dates &&
                      currentFilters?.dates.length > 0 ? (
                        <Text body2>
                          {currentFilters?.dates[0].split("-")[1]}/
                          {currentFilters?.dates[0].split("-")[2]} ~{" "}
                          {
                            currentFilters?.dates[
                              currentFilters?.dates.length - 1
                            ].split("-")[1]
                          }
                          /
                          {
                            currentFilters?.dates[
                              currentFilters?.dates.length - 1
                            ].split("-")[2]
                          }
                        </Text>
                      ) : (
                        <Text body2 grayColor>
                          {t("search:select_dates")}
                        </Text>
                      )}
                      <CalendarPicker
                        visable={showCalendarModal}
                        onSelectDates={handleSelectDates}
                        defaultDates={selectedDates}
                        disabledDays={disabledDays}
                        dismissModal={() => {
                          setShowCalendarModal(false);
                        }}
                        markingType="period"
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              )}

              {(type === ServiceType.DROPIN ||
                type === ServiceType.WALKING ||
                type === ServiceType.TAVERN) && (
                <TouchableOpacity
                  style={[
                    { flexDirection: "row", marginTop: 15 },
                    type !== ServiceType.TAVERN ? styles.bottomBorder : {},
                  ]}
                  onPress={() => {
                    setShowCalendarModal(true);
                  }}
                >
                  <View
                    style={{
                      marginRight: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon name="arrow-right" size={14} />
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text body2 semibold>
                      {type === ServiceType.TAVERN
                        ? t("order:start_time")
                        : t("order:start_date")}
                    </Text>
                  </View>
                  <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <View>
                      {currentFilters?.dates &&
                      currentFilters?.dates.length > 0 ? (
                        <Text body2>
                          {currentFilters?.dates[0].split("-")[1]}/
                          {currentFilters?.dates[0].split("-")[2]}
                        </Text>
                      ) : (
                        <Text body2 grayColor>
                          {t("search:select_dates")}
                        </Text>
                      )}
                      <CalendarPicker
                        visable={showCalendarModal}
                        onSelectDates={handleSelectDates}
                        defaultDates={selectedDates}
                        disabledDays={disabledDays}
                        dismissModal={() => {
                          setShowCalendarModal(false);
                        }}
                        markingType="period"
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              )}

              {type === ServiceType.TAVERN && (
                <TouchableOpacity
                  style={[
                    { flexDirection: "row", marginTop: 15 },
                    type === ServiceType.TAVERN ? styles.bottomBorder : {},
                  ]}
                  onPress={() => {
                    setShowStartTimePicker(true);
                  }}
                >
                  <View style={{ flex: 1 }}></View>
                  <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <View>
                      <Text body2 grayColor={!startHour ? true : false}>
                        {startHour
                          ? [startHour].map((n) => `${n - 1}:00 ~ ${n}:00`)
                          : t("order:select_start_time")}
                      </Text>
                      <TimePicker
                        visible={showStartTimePicker}
                        onDismiss={() => {
                          setShowStartTimePicker(false);
                        }}
                        onSelect={(time) => {
                          handleSelectStartHours(time);
                        }}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              )}

              {(type === ServiceType.DROPIN ||
                type === ServiceType.WALKING ||
                type === ServiceType.TAVERN) && (
                <TouchableOpacity
                  style={[
                    { flexDirection: "row", marginTop: 15 },
                    type !== ServiceType.TAVERN ? styles.bottomBorder : {},
                  ]}
                  onPress={() => {
                    setShowCalendarModal(true);
                  }}
                >
                  <View style={{ flex: 1, flexDirection: "row" }}>
                    <View
                      style={{
                        marginRight: 5,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon name="arrow-left" size={14} />
                    </View>
                    <Text body2 semibold>
                      {type === ServiceType.TAVERN
                        ? t("order:end_time")
                        : t("order:end_date")}
                    </Text>
                  </View>
                  <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <View>
                      {currentFilters?.dates &&
                      currentFilters?.dates.length > 0 ? (
                        <Text body2>
                          {
                            currentFilters?.dates[
                              currentFilters?.dates.length - 1
                            ].split("-")[1]
                          }
                          /
                          {
                            currentFilters?.dates[
                              currentFilters?.dates.length - 1
                            ].split("-")[2]
                          }
                        </Text>
                      ) : (
                        <Text body2 grayColor>
                          {t("search:select_dates")}
                        </Text>
                      )}
                      <CalendarPicker
                        visable={showCalendarModal}
                        onSelectDates={handleSelectDates}
                        defaultDates={selectedDates}
                        disabledDays={disabledDays}
                        dismissModal={() => {
                          setShowCalendarModal(false);
                        }}
                        markingType="period"
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              )}

              {type === ServiceType.TAVERN && (
                <TouchableOpacity
                  style={[
                    { flexDirection: "row", marginTop: 15 },
                    type === ServiceType.TAVERN ? styles.bottomBorder : {},
                  ]}
                  onPress={() => {
                    setShowEndTimePicker(true);
                  }}
                >
                  <View style={{ flex: 1 }}></View>
                  <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <View>
                      <Text body2 grayColor={!endHour ? true : false}>
                        {endHour
                          ? [endHour].map((n) => `${n - 1}:00 ~ ${n}:00`)
                          : t("order:select_end_time")}
                      </Text>
                      <TimePicker
                        visible={showEndTimePicker}
                        onDismiss={() => {
                          setShowEndTimePicker(false);
                        }}
                        onSelect={(time) => {
                          handleSelectEndHours(time);
                        }}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              )}

              {(type === ServiceType.DROPIN ||
                type === ServiceType.WALKING) && (
                <View
                  style={[
                    { flexDirection: "row", marginTop: 15 },
                    styles.bottomBorder,
                  ]}
                >
                  <View style={{ flex: 1, flexDirection: "row" }}>
                    <View
                      style={{
                        marginRight: 5,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon name="clock" size={14} />
                    </View>
                    <Text body2 semibold>
                      {t("order:service_length")}
                    </Text>
                  </View>
                  <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onPress={() => {
                          if (frames - 1 !== 0) {
                            setFrames(frames - 1);
                          }
                        }}
                        style={{ marginRight: 10 }}
                      >
                        <View
                          style={{
                            borderColor:
                              frames - 1 === 0 ? colors.border : colors.text,
                            borderRadius: 12,
                            borderWidth: 1,
                            width: 24,
                            height: 24,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            name="chevron-left"
                            size={12}
                            color={
                              frames - 1 === 0 ? colors.border : colors.text
                            }
                          />
                        </View>
                      </TouchableOpacity>
                      <Text body2 semibold>
                        {
                          framesOptions.filter(
                            (item) => item.value === frames
                          )[0].title
                        }
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          if (frames + 1 <= framesOptions.length) {
                            setFrames(frames + 1);
                          }
                        }}
                        style={{ marginLeft: 10 }}
                      >
                        <View
                          style={{
                            borderColor:
                              frames + 1 >= framesOptions.length
                                ? colors.border
                                : colors.text,
                            borderRadius: 12,
                            borderWidth: 1,
                            width: 24,
                            height: 24,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            name="chevron-right"
                            size={12}
                            color={
                              frames + 1 >= framesOptions.length
                                ? colors.border
                                : colors.text
                            }
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}

              {showTooManyPetsError && (
                <Flex.Row paddingLeft={36} paddingTop={4} paddingBottom={20}>
                  <Flex paddingTop={1} paddingRight={12}>
                    <Icon
                      size={18}
                      name="exclamation-triangle"
                      color={BaseColor.warningColor}
                    />
                  </Flex>
                  <Flex fill>
                    <Text footnote style={{ color: BaseColor.warningColor }}>
                      {t("order:too_many_pets")}
                    </Text>
                  </Flex>
                </Flex.Row>
              )}

              {(type === ServiceType.DROPIN ||
                type === ServiceType.WALKING) && (
                <View
                  style={[
                    { flexDirection: "row", marginTop: 15 },
                    styles.bottomBorder,
                  ]}
                >
                  <View style={{ flex: 1, flexDirection: "row" }}>
                    <View
                      style={{
                        marginRight: 5,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon name="columns" size={14} />
                    </View>
                    <Text body2 semibold>
                      {t("preview_reserve:times_per_day")}
                    </Text>
                  </View>
                  <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onPress={() => {
                          if (times - 1 !== 0) {
                            setTimes(times - 1);
                          }
                        }}
                        style={{ marginRight: 10 }}
                      >
                        <View
                          style={{
                            borderColor:
                              times - 1 === 0 ? colors.border : colors.text,
                            borderRadius: 12,
                            borderWidth: 1,
                            width: 24,
                            height: 24,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            name="chevron-left"
                            size={12}
                            color={
                              times - 1 === 0 ? colors.border : colors.text
                            }
                          />
                        </View>
                      </TouchableOpacity>
                      <Text body2 semibold>
                        {
                          timesOptions.filter((item) => item.value === times)[0]
                            .title
                        }
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          if (times + 1 <= timesOptions.length) {
                            setTimes(times + 1);
                          }
                        }}
                        style={{ marginLeft: 10 }}
                      >
                        <View
                          style={{
                            borderColor:
                              times + 1 >= timesOptions.length
                                ? colors.border
                                : colors.text,
                            borderRadius: 12,
                            borderWidth: 1,
                            width: 24,
                            height: 24,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            name="chevron-right"
                            size={12}
                            color={
                              times + 1 >= timesOptions.length
                                ? colors.border
                                : colors.text
                            }
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}
            </View>

            <TouchableOpacity
              style={[
                { flexDirection: "row", marginTop: 15 },
                styles.bottomBorder,
              ]}
              onPress={() => {
                setShowPlaceModal(true);
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 5,
                  }}
                >
                  <Icon name="map-marker-alt" size={14} />
                </View>
                <View
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Text body2 semibold>
                    {t("order:location")}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 1, alignItems: "flex-end" }}>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: "100%" }}>
                    <Text body2 semibold>
                      {place ? place.name : t("order:select_location")}
                    </Text>
                  </View>
                  <PlaceModal
                    visible={showPlaceModal}
                    onDismiss={(visable) => {
                      setShowPlaceModal(visable);
                    }}
                    onSelect={(place) => {
                      if (Object.keys(place).length <= 0) {
                        getCurrentAsyncStorageLocation();
                      } else {
                        setPlace(place);
                        AsyncStorage.setItem(
                          "currentLocation",
                          `${
                            place.address +
                            "&" +
                            place.areaCode +
                            "&" +
                            place.location?.latitude +
                            "&" +
                            place.location?.longitude
                          }`
                        );
                      }
                    }}
                  />
                </View>
              </View>
            </TouchableOpacity>

            <View
              style={[{ marginVertical: 10, borderBottomColor: colors.border }]}
            >
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    marginRight: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon name="sticky-note" size={14} />
                </View>
                <Text body2 semibold>
                  {t("order:notes")}
                </Text>
              </View>
              <TextInput
                onChangeText={(text) => setNotes(text)}
                onFocus={() => {}}
                placeholder={t("order:notes_placeholder")}
                value={notes}
                success={true}
              />
            </View>

            <View
              style={{
                borderTopWidth: 1,
                paddingVertical: 10,
                borderColor: colors.border,
              }}
            >
              {type === ServiceType.TAVERN && (
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 5,
                    }}
                  >
                    <Icon name="coffee" size={16} />
                  </View>
                  <Text regular caption2>
                    {t("order:annotation_TAVERN")}
                  </Text>
                </View>
              )}
              {showConflictDaysWarning && (
                <Flex.Row paddingTop={20}>
                  <Flex paddingTop={1} paddingRight={12}>
                    <Icon
                      size={18}
                      name="user-clock"
                      color={BaseColor.warningColor}
                    />
                  </Flex>
                  <Flex fill>
                    <Text footnote style={{ color: BaseColor.warningColor }}>
                      {t("order:unavailable_days")}
                    </Text>
                  </Flex>
                </Flex.Row>
              )}
              {/* {!!campaignDetail && ( //有折扣
                <Flex.Row paddingTop={20}>
                  <Flex paddingTop={1} paddingRight={12}>
                    <Icon size={18} name="thumbs-up" color={BaseColor.highlightedTextColor} />
                  </Flex>
                  <Flex fill>
                    <Text footnote style={{color:BaseColor.warningColor}}>{campaignDetail}</Text>
                  </Flex>
                </Flex.Row>
              )} */}
            </View>
            {type && (
              <OrderBreakdown
                type={type}
                pets={selectedPets}
                dates={selectedDates}
                startHour={startHour}
                endHour={endHour}
                frames={frames}
                times={times}
                breakdown={breakdown}
                price={price}
                discountedPrice={discountedPrice}
              />
            )}
          </View>
        </ScrollView>
        <View
          /* @ts-ignore */
          style={[
            {
              flexDirection: "column",
              backgroundColor: "white",
              position: "fixed",
              width: "100%",
              minWidth: minWidth,
              bottom: 0,
            },
          ]}
        >
          <View
            style={[
              styles.contentButtonBottom,
              { borderTopColor: colors.border },
            ]}
          >
            <View style={{ flexDirection: "row" }}>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <Icon name="shopping-cart" size={14} color={colors.primary} />
              </View>
              <Text title3 primaryColor semibold>
                {t("order:check_total")}：{t("enum:currency")}
                {amount}
              </Text>
            </View>
            <Button onPress={handleCreateOrder} disabled={!validated}>
              {t("order:request_to_reserve")}
            </Button>
          </View>
          <View style={{ paddingHorizontal: 20, paddingBottom: 20 }}>
            <View style={{ flexDirection: "row", marginTop: 5 }}>
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <Icon
                  name="exclamation-circle"
                  color={BaseColor.grayColor}
                  size={10}
                  style={{ marginRight: 8 }}
                />
              </View>
              <Text footnote semibold grayColor>
                {t("order:request_info")}
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 5 }}>
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <Icon
                  name="flag"
                  color={BaseColor.grayColor}
                  size={10}
                  style={{ marginRight: 8 }}
                />
              </View>
              <Text footnote semibold grayColor>
                {t("order:suggestion")}
              </Text>
            </View>
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentButtonBottom: {
    borderTopWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  blockView: {
    paddingVertical: 10,
    borderBottomWidth: 1,
  },
  bottomBorder: {
    borderBottomColor: BaseColor.fieldColor,
    borderBottomWidth: 2,
    paddingBottom: 10,
  },
});
