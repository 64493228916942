import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { removeEmpty } from "../utils";
import { getStoragePrivateUrl } from "../utils/url";
import { getCurrentUserId } from "./auth";
import { isUri, uploadImage } from "./storage";

export type TIdentityInput = {
  realName: string;
  birthDate: string;
  idNumber: string;
  frontUriOrId: string;
  selfieUriOrId: string;
  experience: string;
};
const toIdentity = (snapshot: DocumentSnapshot<DocumentData>) => {
  if (!snapshot.data()) return null;

  const {
    createdAt,
    updatedAt,
    realName,
    birthDate,
    idNumber,
    frontId,
    selfieId,
    experience,
  } = snapshot.data() || {};
  return {
    id: snapshot.id,
    createdAt: createdAt?.seconds * 1000,
    updatedAt: updatedAt?.seconds * 1000,
    realName,
    birthDate,
    idNumber,
    frontId,
    selfieId,
    experience,
  };
};

export const getIdentityIdentityUrl = async (
  userId?: string,
  pictureId?: string
): Promise<string | undefined> => {
  if (!userId || !pictureId) return undefined;
  if (isUri(pictureId)) return pictureId;

  return getStoragePrivateUrl(
    `users_private/${userId}/identity_pictures/${pictureId}.jpg`
  );
};

const identityRef = collection(db, "identities");

const getIdentityDoc = (userId: string) => {
  if (!userId) return;
  return doc(identityRef, userId);
};

export const getIdentity = async (userId: string) => {
  if (!userId) return;
  const identityDoc = getIdentityDoc(userId) as any;
  const snapshot = (await getDoc(identityDoc)) as any;
  return toIdentity(snapshot);
};

export const updateIndetity = async (
  identity: TIdentityInput
): Promise<void> => {
  const myId = getCurrentUserId();
  if (!myId) return;

  const identityDoc = getIdentityDoc(myId) as any;

  const {
    realName,
    birthDate,
    idNumber,
    frontUriOrId,
    selfieUriOrId,
    experience,
  } = identity;
  const [frontId, selfieId] = await Promise.all([
    uploadImage(frontUriOrId, `users_private/${myId}/identity_pictures`),
    uploadImage(selfieUriOrId, `users_private/${myId}/identity_pictures`),
  ]);

  await setDoc(
    identityDoc,
    removeEmpty({
      updatedAt: serverTimestamp(),
      realName,
      birthDate: birthDate.replace(/\//g, ""),
      idNumber,
      frontId,
      selfieId,
      experience,
    }),
    { merge: true }
  );
};
