import { Dimensions, StyleSheet, TouchableOpacity, View } from "react-native";
import React, { useEffect, useState } from "react";
import Checkbox from "expo-checkbox";
import { useTranslation } from "react-i18next";
import { Text } from "../ui";
import { BaseColor, minWidth } from "../config/theme";

const width = Dimensions.get("window").width;

export type TPetCategory = 1 | 2 | 3 | 4 | 5;
export type TPetSize = 5 | 10 | 20 | 40 | 999;

interface FilterOptioinProps {
  getFilterOption: (category: TPetCategory[], size: TPetSize[]) => void;
}

const FilterOption = React.memo<FilterOptioinProps>((props) => {
  const { getFilterOption } = props;
  const { t } = useTranslation();
  const [category, setCategory] = useState<TPetCategory[]>([1]);
  const [size, setSize] = useState<TPetSize[]>([5]);
  useEffect(() => {
    getFilterOption(category, size);
  }, [category, size]);

  const categoryOptions = [
    {
      type: 1,
      name: "category_DOG",
    },
    {
      type: 2,
      name: "category_CAT",
    },
    {
      type: 3,
      name: "category_RABBIT",
    },
    {
      type: 4,
      name: "category_HAMSTER",
    },
    {
      type: 5,
      name: "category_BIRD",
    },
  ];

  const petSizeOptions = [
    {
      name: "TINY",
      type: 5,
    },
    {
      name: "SMALL",
      type: 10,
    },
    {
      name: "MEDIUM",
      type: 20,
    },
    {
      name: "LARGE",
      type: 40,
    },
    {
      name: "HUGE",
      type: 999,
    },
  ];

  return (
    <View style={{ width: "100%" }}>
      {/* pet category */}
      <View
        style={{
          flexDirection: "row",
          marginVertical: 10,
          width: "100%",
          minWidth: minWidth,
        }}
      >
        {categoryOptions.map((option: any) => (
          <TouchableOpacity
            style={[
              styles.checkBoxSection,
              {
                backgroundColor: category.includes(option.type)
                  ? BaseColor.color1
                  : BaseColor.whiteColor,
              },
            ]}
            key={option.type}
            onPress={() => {
              if (category.includes(option.type)) {
                setCategory(category.filter((item) => item !== option.type));
              } else {
                setCategory((oldCategory) => [...oldCategory, option.type]);
              }
            }}
          >
            <Text caption2 whiteColor={category.includes(option.type)}>
              {t(`pets:${option.name}`)} {t(`pets:emoji_${option.name}`)}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      {/* pet category */}

      {/* pet size */}
      <View
        style={{
          flexDirection: "row",
          marginVertical: 5,
          flexWrap: "wrap",
          alignItems: "flex-start",
        }}
      >
        {petSizeOptions.map((option: any) => {
          return (
            <TouchableOpacity
              style={[
                styles.checkBoxSection,
                {
                  backgroundColor: size.includes(option.type)
                    ? BaseColor.color1
                    : BaseColor.whiteColor,
                },
              ]}
              key={option.type}
              onPress={() => {
                if (size.includes(option.type)) {
                  setSize(size.filter((item) => item !== option.type));
                } else {
                  setSize((oldSize) => [...oldSize, option.type]);
                }
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text
                  whiteColor={size.includes(option.type)}
                  bold={size.includes(option.type)}
                >
                  {t(`pets:pet_size_${option.name}`)}
                </Text>
                <Text
                  fieldColor={size.includes(option.type)}
                  overline
                  grayColor={!size.includes(option.type)}
                >
                  {t(`pets:pet_size_tip_${option.name}`)}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
      {/* pet size */}
    </View>
  );
});

export default FilterOption;

const styles = StyleSheet.create({
  checkbox: {
    margin: 8,
  },
  checkBoxSection: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 50,
    paddingHorizontal: 10,
    paddingVertical: 5,
    shadowColor: BaseColor.highlightedTextColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginRight: 10,
    marginBottom: 5,
  },
});
