import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  snapshotEqual,
  updateDoc,
  where,
} from "firebase/firestore";
import { Dimensions } from "react-native";
import { db } from "../config/firebase";
import { ServiceType } from "../enums";
import { removeEmpty } from "../utils";
import { getPictureSize, getStoragePublicUrl } from "../utils/url";
import { getCurrentUserId } from "./auth";
import { getManagedBrands } from "./brand";
import { profileRef, toProfile, TProfile } from "./profile";
import { uploadImage } from "./storage";
import { TSubscription } from "./types";

const screenWidth = Dimensions.get("window").width;

export type TChatPreview = {
  id: string;
  lastSentAt: number;
  lastMessageId: string;
  lastMessageText: string;
  unread: number;
  snapshots: {
    profile?: TProfile;
  };
};

export type TMessage = {
  id: string;
  createdAt: number;
  senderId: string;
  text?: string;
  pictureUrl?: string;
  pictureOriginUrl?: string;
  sys?: {
    type: string;
    action: string;
    order?: {
      id: string;
      providerId: string;
      pictureId: string;
      type: ServiceType;
      dates: Array<string>;
      title: string;
      amount: number;
    };
  };
};

export type TMessageInput = {
  text?: string;
  imageUrl?: string;
};

const toChatPreview = (
  snapshot: DocumentSnapshot<DocumentData>
): TChatPreview | null => {
  if (!snapshot.data()) return null;

  const { lastSentAt, lastMessageId, lastMessageText, unread, snapshots } =
    snapshot.data() || {};
  if (!snapshot.data()) return null;
  const { profile } = snapshots || {};
  return {
    id: snapshot.id,
    lastSentAt: lastSentAt?.seconds * 1000,
    lastMessageId,
    lastMessageText,
    unread,
    snapshots: {
      /* @ts-ignore */
      profile: toProfile({
        id: snapshot.id,
        data: () => profile,
      }),
    },
  };
};

const toMessage = (
  snapshot: DocumentSnapshot<DocumentData>
): TMessage | null => {
  if (!snapshot.data()) return null;

  const {
    createdAt,
    senderId,
    text,
    pictureId,
    pictureUrl: pictureRemoteUrl,
    sys,
  } = snapshot.data() || {};
  const { type, action, order } = sys || {};

  let pictureUrl;
  let pictureOriginUrl;
  if (pictureId) {
    pictureUrl = getStoragePublicUrl(
      `users_public/${senderId}/chat_pictures/${pictureId}_thumb_${getPictureSize(
        screenWidth
      )}x.jpg`
    );
    pictureOriginUrl = getStoragePublicUrl(
      `users_public/${senderId}/chat_pictures/${pictureId}.jpg`
    );
  } else if (pictureRemoteUrl) {
    pictureUrl = pictureRemoteUrl;
    pictureOriginUrl = pictureRemoteUrl;
  }

  return {
    id: snapshot.id,
    senderId,
    text,
    pictureUrl,
    pictureOriginUrl,
    createdAt: createdAt?.seconds * 1000,
    sys: sys
      ? {
          type,
          action,
          order,
        }
      : undefined,
  };
};

const preferencesRef = collection(db, "preferences");

const chatRef = collection(db, "chats");

export const getPreferencesDoc = (userId: string) => {
  return doc(preferencesRef, userId);
};

const getChatPreviewsRef = (userId: string) => {
  return collection(getPreferencesDoc(userId), "chatPreviews");
};

const getChatPreviewDoc = (userId: string, otherUserId: string) => {
  const chatPreviewsCollection = getChatPreviewsRef(userId);
  return doc(chatPreviewsCollection, otherUserId);
};

export const getChatId = (myId: string, otherUserId: string) => {
  return myId > otherUserId
    ? `${otherUserId}+${myId}`
    : `${myId}+${otherUserId}`;
};

const getChatDoc = (userId: string, otherUserId: string) => {
  const chatId = getChatId(userId, otherUserId);
  return doc(chatRef, chatId);
};

const getMessagesCollection = (userId: string, otherUserId: string) => {
  const chatDoc = getChatDoc(userId, otherUserId);
  if (!chatDoc) return null;

  return collection(chatDoc, "messages");
};

export const subscribeChatPreviews: TSubscription<
  { userId?: string },
  TChatPreview[]
> = ({ variables, size, onChange }) => {
  const { userId } = variables || {};
  if (!userId) return () => {};
  const chatPreviewsCollection = getChatPreviewsRef(userId);
  if (!chatPreviewsCollection) return () => {};

  let chatQuery = query(chatPreviewsCollection, orderBy("lastSentAt", "desc"));
  if (size) {
    chatQuery = query(chatQuery, limit(size));
  }

  return onSnapshot(chatQuery, (snapshot) => {
    if (!onChange) return;
    onChange(
      snapshot.docs.map(toChatPreview).filter(Boolean) as TChatPreview[]
    );
  });
};

export const nextChatPreviews = async (chatPreviewId: any) => {
  if (!chatPreviewId) return;
};

export const getChatPreviews = async (
  userId: string
): Promise<TChatPreview[] | null> => {
  const chatPreviewsCollection = getChatPreviewsRef(userId);
  if (!chatPreviewsCollection) return null;

  const snapshot = await getDocs(chatPreviewsCollection);
  return snapshot.docs.map(toChatPreview).filter(Boolean) as TChatPreview[];
};

export const subscribeMessages: TSubscription<
  { userId: string; otherUserId: string },
  TMessage[]
> = ({
  variables,
  size,
  onChange, // onAdd,
}) => {
  const { userId, otherUserId } = variables || {};
  if (!userId || !otherUserId) return () => {};
  const messagesCollection = getMessagesCollection(userId, otherUserId);
  if (!messagesCollection) return () => {};

  let messagesQuery = query(messagesCollection, orderBy("createdAt", "desc"));
  if (size) {
    messagesQuery = query(messagesQuery, limit(size));
  }

  return onSnapshot(messagesQuery, (snapshot) => {
    if (!onChange) return;
    onChange(snapshot.docs.map(toMessage).filter(Boolean) as TMessage[]);
  });
};

export const sendMessage = async (
  recipientId: string,
  message: TMessageInput,
  onImageUploadProgress?: (progress: number, tempPictureId: string) => void
): Promise<void> => {
  const auth = getAuth();
  const { currentUser } = auth;
  const uid = getCurrentUserId();
  const myId = uid || undefined;
  if (!myId) return;
  const { text, imageUrl } = message;

  let pictureId;
  if (imageUrl) {
    try {
      pictureId = await uploadImage(
        imageUrl,
        `users_public/${myId}/chat_pictures`,
        {
          onProgressChange: (progress: any, tempPictureId: any) => {
            if (onImageUploadProgress)
              onImageUploadProgress(progress, tempPictureId);
          },
        }
      );
    } catch (_) {
      return;
    }
  }

  // update chat
  const chatDoc = getChatDoc(myId, recipientId);
  const messagesCollection = getMessagesCollection(myId, recipientId);
  if (!chatDoc || !messagesCollection) return;

  await Promise.all([
    setDoc(chatDoc, {
      updatedAt: serverTimestamp(),
      participantIds: [myId, recipientId].sort(),
    }),
    addDoc(
      messagesCollection,
      removeEmpty({
        senderId: myId,
        text,
        pictureId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      })
    ),
  ]);
};

export const subscribeChatReads: TSubscription<{ userId?: string }, number> = ({
  variables,
  onChange,
}) => {
  const { userId } = variables || {};
  if (!userId) return () => 0;
  const chatPreviewsCollection = getChatPreviewsRef(userId);
  if (!chatPreviewsCollection) return () => 0;

  let chatPreviewQuery = query(chatPreviewsCollection, where("unread", ">", 0));
  return onSnapshot(chatPreviewQuery, (snapshot) => {
    if (!onChange) return;
    onChange(snapshot.size);
  });
};

export const updateBadge = async (realUserId: string): Promise<void> => {
  const chatPreviews = await getChatPreviews(realUserId);
  if (!chatPreviews) return;

  // get the badge count of real user
  let badgeCount = chatPreviews.reduce((result, cp) => result + cp.unread, 0);

  // get the badge count of all managed brands
  const managedBrands = await getManagedBrands(realUserId);
  const calculateBrandBadgePromises = managedBrands.map(async (brand) => {
    const brandPreviews = (await getChatPreviews(brand.id)) || [];
    return brandPreviews.reduce((result, cp) => result + cp.unread, 0);
  });
  const brandBadgeCounts = await Promise.all(calculateBrandBadgePromises);

  // sum all badges of brands
  brandBadgeCounts.forEach((count) => {
    badgeCount += count;
  });
  // await Notifications.setBadgeCountAsync(badgeCount);

  // TODO: dismiss the message on notification center (tray)
  // FIXME: expo method Notifications.getPresentedNotificationsAsync() return expo specific format of notification.
  // it is not accepted custom data from server, so it is impossible to pass chatId to recongnize which messages should be dismiss.
  // also dismiss all notification on Android is not make sense (messages will all disappear on notification center)
  // temporarily not to do anything.
  // const notifications = await Notifications.getPresentedNotificationsAsync();
  // console.log(notifications);
};

export const readChat = async (
  realUserId: string,
  brandOrUserId: string,
  otherUserId: string
): Promise<void> => {
  const chatPreviewDoc = getChatPreviewDoc(brandOrUserId, otherUserId);
  if (!chatPreviewDoc) return;

  await updateDoc(chatPreviewDoc, {
    unread: 0,
  });

  await updateBadge(realUserId);
};
