import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import { BaseColor, Typography, useTheme } from "../config";
import { Text } from ".";

export default function Tag(props: any) {
  const { colors } = useTheme();
  const {
    style,
    textStyle,
    icon,
    primary,
    primaryIcon,
    outline,
    outlineIcon,
    outlineSecondary,
    outlineSecondaryIcon,
    small,
    light,
    gray,
    chip,
    status,
    rate,
    rateSmall,
    sale,
    children,
    onPress,
    ...rest
  } = props;

  return (
    <TouchableOpacity
      {...rest}
      onPress={onPress}
      style={StyleSheet.flatten([
        styles.default,
        primary && [styles.primary, { backgroundColor: colors.primary }],
        primaryIcon && styles.primary,
        outline && [
          styles.outline,
          { borderColor: colors.primary, backgroundColor: colors.card },
        ],
        outlineIcon && styles.outline,
        outlineSecondary && styles.outlineSecondary,
        outlineSecondaryIcon && [
          styles.outlineSecondary,
          { borderColor: colors.accent },
        ],
        small && [styles.small, { backgroundColor: colors.primary }],
        light && [styles.light, { backgroundColor: colors.primary }],
        gray && styles.gray,
        chip && [
          styles.chip,
          { backgroundColor: colors.card, borderColor: colors.accent },
        ],
        status && [styles.status, { backgroundColor: colors.primary }],
        rate && [styles.rate, { backgroundColor: colors.primaryLight }],
        rateSmall && [styles.rateSmall, { backgroundColor: colors.accent }],
        sale && [styles.sale, { backgroundColor: colors.primaryLight }],
        style,
      ])}
      activeOpacity={0.9}
    >
      {icon ? icon : null}
      <Text
        style={StyleSheet.flatten([
          primary && styles.textPrimary,
          primaryIcon && styles.textPrimary,
          outline && [styles.textOutline, { color: colors.primary }],
          outlineIcon && [styles.textOutline, { color: colors.primary }],
          outlineSecondary && [
            styles.textOutlineSecondary,
            { color: colors.accent },
          ],
          outlineSecondaryIcon && [
            styles.textOutlineSecondary,
            { color: colors.accent },
          ],
          small && styles.textSmall,
          light && [styles.textLight, { color: colors.primaryLight }],
          gray && styles.textGray,
          chip && [styles.textChip, { color: colors.accent }],
          status && styles.textStatus,
          rate && styles.textRate,
          rateSmall && styles.textRateSmall,
          sale && styles.textSale,
          textStyle,
        ])}
        numberOfLines={1}
      >
        {children || "Tag"}
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  default: {
    flexDirection: "row",
  },
  primary: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 17,
    alignItems: "center",
    justifyContent: "center",
  },
  textPrimary: StyleSheet.flatten([
    Typography.caption1,
    { color: BaseColor.whiteColor },
  ]),
  outline: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 16,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  textOutline: Typography.caption1,
  outlineSecondary: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 17,
    backgroundColor: BaseColor.whiteColor,
    alignItems: "center",
    justifyContent: "center",
  },
  textOutlineSecondary: Typography.caption1,
  small: {
    paddingHorizontal: 5,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  textSmall: StyleSheet.flatten([
    Typography.caption2,
    { color: BaseColor.whiteColor },
  ]),
  light: {
    padding: 5,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  textLight: Typography.caption2,
  gray: {
    padding: 5,
    backgroundColor: BaseColor.fieldColor,
    borderColor: BaseColor.fieldColor,
    alignItems: "center",
    justifyContent: "center",
  },
  textGray: StyleSheet.flatten([Typography.caption2]),
  chip: {
    paddingVertical: 4,
    paddingHorizontal: 6,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 0.5,
  },
  textChip: Typography.overline,
  status: {
    paddingVertical: 3,
    paddingHorizontal: 5,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  textStatus: StyleSheet.flatten([
    Typography.caption2,
    { color: BaseColor.whiteColor },
  ]),
  rate: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },

  textRate: StyleSheet.flatten([
    Typography.headline,
    { color: BaseColor.whiteColor },
  ]),
  rateSmall: {
    borderRadius: 3,
    paddingHorizontal: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  textRateSmall: StyleSheet.flatten([
    Typography.caption2,
    { color: BaseColor.whiteColor },
  ]),
  sale: {
    width: 50,
    height: 50,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
  },
  textSale: StyleSheet.flatten([
    Typography.headline,
    { color: BaseColor.whiteColor },
  ]),
});
