import React, { useCallback } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Icon } from "../components";
import { Text, Image, Flex } from "../ui";
import { useTheme, Images, BaseColor } from "../config";
import { useTranslation } from "react-i18next";
import { ServiceType } from "../enums";
import {
  getProfilePictureUrl,
  getServicePictureUrl,
  TProfile,
} from "../models";
import { datesToString } from "../utils/date";
import { useNavigation } from "../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../config/typography";

type Props = {
  id: string;
  type: ServiceType;
  title: string;
  dates: Array<string>;
  userId: string;
  profile: TProfile | null;
  pictureId: string;
  amount: number;
  statusString: string;
  statusColor: string;
  onPress?: () => {};
};

const OrderItem = React.memo((props: any) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const {
    id,
    type,
    title,
    dates,
    userId,
    profile,
    pictureId,
    amount,
    statusString,
    statusColor,
    onPress,
  } = props;
  const navigation = useNavigation<MainStackScreenNavigationProp>();

  const coverUrl = getServicePictureUrl(userId, pictureId);
  const avatarUrl = getProfilePictureUrl(profile?.id, profile?.avatarId);
  const dateString = datesToString(dates, "YYYYMMDD");

  const handleOpenDetail = useCallback((orderId: string | null) => {
    if (!orderId) return;
    navigation.navigate("OrderDetailScreen", { orderId });
  }, []);

  return (
    <TouchableOpacity
      onPress={() => {
        handleOpenDetail(id);
        onPress?.();
      }}
      activeOpacity={0.9}
    >
      <Flex fill paddingVertical={12}>
        <Flex.Row fill paddingVertical={8}>
          <Flex paddingRight={20}>
            <Image
              style={{ width: 72, height: 72 }}
              source={{ uri: coverUrl }}
            />
            <View style={styles.avatarWrapper}>
              <Image
                style={[styles.circleOutline, { width: 48, height: 48 }]}
                source={{ uri: avatarUrl }}
              />
            </View>
          </Flex>
          <Flex fill>
            <Flex.Row paddingBottom={8} vCenter between>
              <Text body2 style={{ color: colors.primaryDark }}>
                {t(`search:type_${ServiceType[type]}`)}
              </Text>
              <Text callout>${amount}</Text>
            </Flex.Row>
            <Flex paddingBottom={10}>
              <Text body2 style={{ color: BaseColor.highlightedTextColor }}>
                {title}
              </Text>
            </Flex>
            {dateString && (
              <Flex.Row paddingBottom={8} vCenter>
                <Icon size={14} name="calendar" color={BaseColor.textColor} />
                <Flex paddingLeft={12}>
                  <Text subhead style={{ color: BaseColor.textColor }}>
                    {dateString}
                  </Text>
                </Flex>
              </Flex.Row>
            )}
            <Flex.Row vCenter>
              <View style={[styles.dot, { backgroundColor: statusColor }]} />
              <Text subhead style={{ color: BaseColor.textColor }}>
                {statusString}
              </Text>
            </Flex.Row>
          </Flex>
        </Flex.Row>
      </Flex>
    </TouchableOpacity>
  );
});

export default OrderItem;

const styles = StyleSheet.create({
  avatarWrapper: {
    alignItems: "flex-end",
    marginTop: -36,
    marginRight: -12,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 12,
  },
  circleOutline: {
    backgroundColor: "white",
    borderColor: "white",
    borderWidth: 3,
    overflow: "hidden",
    borderRadius: 200,
  },
});
