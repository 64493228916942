import { Icon } from ".";
import { minWidth } from "../config/theme";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "../ui";
import { useAuth } from "../context/Auth";
import { useNotificationBannerVisibility } from "../hooks";
import { useTheme } from "../config";
import { useTranslation } from "react-i18next";
import React from "react";

const NotificationBanner = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { userId } = useAuth();
  const [isLoading, isVisible, onClose] = useNotificationBannerVisibility();

  if (!userId || isLoading || !isVisible) {
    return null;
  }

  return (
    <View style={[styles.bannerContiner, { backgroundColor: colors.card }]}>
      <View>
        <Text black title3>
          {t("Fluv needs your permission to enable notification.")}
        </Text>
      </View>
      <TouchableOpacity onPress={onClose} disabled={isLoading}>
        <View>
          <Icon name="times" size={20} />
        </View>
      </TouchableOpacity>
    </View>
  );
};
export default NotificationBanner;

const styles = StyleSheet.create({
  bannerContiner: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    minWidth: minWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingHorizontal: 24,
    paddingVertical: 16,
    bottom: 0,
  },
});
