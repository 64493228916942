import { Platform, Alert, Linking } from "react-native";
import i18n from "i18next";
// import * as Updates from 'expo-updates';
import * as Permissions from "expo-permissions";
// import * as IntentLauncher from 'expo-intent-launcher';
// import { PACKAGE_NAME } from '../constant/backend';

// In development mode, expo-updates is disabled. `Updates.manifest` is empty.
/* @ts-ignore */
// const packageName = Updates.manifest?.android?.package ? Updates.manifest?.android?.package : 'host.exp.exponent';
import * as Location from 'expo-location';

export const getPermissionAsync = async (
  permission: Permissions.PermissionType
): Promise<boolean> => {
  /* @ts-ignore */
  const t = i18n.getFixedT();
  const { status: currentStatus } = await Permissions.getAsync(permission);
  if (currentStatus === "granted") return true;

  const { status } = await Permissions.askAsync(permission);
  if (status !== "granted") {
    // TODO: better permission name tips
    const permissionName = permission.toUpperCase().replace("_", " ");

    Alert.alert(
      t("permission:permission_denied_title"),
      t("permission:permission_denied_message", { permissionName }),
      [
        {
          text: t("permission:settings"),
          onPress: () => Platform.OS === "android",
          // ? IntentLauncher.startActivityAsync(IntentLauncher.ACTION_APPLICATION_DETAILS_SETTINGS, {
          //     data: `package:${PACKAGE_NAME}`,
          //   })
          // : Linking.openURL('app-settings:'),
        },
        { text: t("permission:no_thanks"), onPress: () => {}, style: "cancel" },
      ],
      { cancelable: true }
    );
    return false;
  }
  return true;
};

export const checkLocationPermission = async (): Promise<boolean> => {
  try {
    const { status } = await Location.getForegroundPermissionsAsync();
    return status === 'granted';
  } catch (e) {
    console.error("E9QD");
    throw e;
  }
}
