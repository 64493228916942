import { StyleSheet, Text, View } from "react-native";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ServiceType } from "../enums";
import AsyncStorage from "@react-native-async-storage/async-storage";

type TFilters = {
  serviceType?: ServiceType;
  dates?: string[];
  petIds?: string[];
  place?: any;
  distance?: number;
};

export type TLocalData = {
  currentFilters?: TFilters;
  updateCurrentFilters: (filters: TFilters) => void;
};

const defaultFilters = {
  serviceType: ServiceType.DROPIN,
  dates: [],
  pets: [],
};

const defaultLocalData: TLocalData = {
  currentFilters: defaultFilters,
  updateCurrentFilters: () => {},
};

const LocalDataContext = React.createContext<TLocalData>(defaultLocalData);

export const LocalData = React.memo<{ children: ReactNode }>((props) => {
  const { children } = props;
  const [currentFilters, setCurrentFilters] = useState<TFilters | undefined>(
    undefined
  );

  useEffect(() => {
    AsyncStorage.getItem("LocalData").then((value: any) => {
      const persistedData = value ? JSON.parse(value) : null;
      setCurrentFilters(persistedData?.currentFilters || defaultFilters);
    });
  }, []);

  useEffect(() => {
    AsyncStorage.setItem(
      "LocalData",
      JSON.stringify({
        currentFilters,
      })
    );
  }, [currentFilters]);

  const updateCurrentFilters = useCallback((filters: TFilters) => {
    setCurrentFilters((prevCurrentFilters: any) => ({
      ...prevCurrentFilters,
      ...filters,
    }));
  }, []);

  const value = {
    currentFilters,
    updateCurrentFilters,
  };

  return (
    <LocalDataContext.Provider value={value}>
      {children}
    </LocalDataContext.Provider>
  );
});

export const useLocalData = (): TLocalData => useContext(LocalDataContext);
