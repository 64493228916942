import { StyleSheet, View, Animated, TouchableOpacity } from "react-native";
import {
  Header,
  SafeAreaView,
  FilterSort,
  FilterModal,
  ChatIcon,
} from "../../components";
import Menu from "../../ui/Menu";
import React, { useEffect, useState } from "react";
import { BaseColor, BaseStyle, useTheme } from "../../config";
import Icon from "react-native-vector-icons/FontAwesome5";
import * as Utils from "../../utils";
import { ServiceTypeMap } from "../../enums";
import { useAuth } from "../../context/Auth";
import LoadingSpinner from "../../components/LoadingSpinner";
import { SortSelected, TSortOptions } from "../../constant/sortOptions";
import SearchSubScreen from "./SearchSubScreen";
import { getAuth } from "firebase/auth";
import { minWidth } from "../../config/theme";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";
import { useTranslation } from "react-i18next";
import Service from "../../models/Service.v1";

const SearchScreen = ({ route, isFetching, isSsr }: any) => {
  const { colors } = useTheme();
  const { userId } = useAuth();
  const { t } = useTranslation();
  const {
    type,
    size,
    category,
    areaCode,
    isReferred,
    serviceSearchResults,
    routeParams,
  } = route?.params;
  const [, cityName, areaName] = routeParams;
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const [refreshing] = useState(false);
  const scrollAnim = new Animated.Value(0);
  const offsetAnim = new Animated.Value(0);
  const [modeView, setModeView] = useState("list");
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState(SortSelected.value);
  const [isShowFilterModal, setIsShowFilterModal] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const auth = getAuth();
  const { currentUser } = auth;
  const clampedScroll = Animated.diffClamp(
    Animated.add(
      scrollAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
        extrapolateLeft: "clamp",
      }),
      offsetAnim
    ),
    0,
    40
  );

  useEffect(() => {
    (async () => {
      const defaultSort = await Service.getDefaultSort(isReferred);
      setOrderBy(defaultSort.value);
    })()
  }, [])

  const onChangeSort = (sorted: TSortOptions) => {
    setOrderBy(sorted.value);
  };

  const onFilter = () => {
    setIsShowFilterModal(true);
  };

  const onChangeView = () => {
    Utils.enableExperimental();
    switch (modeView) {
      case "list":
        setModeView("list");
        break;
      default:
        setModeView("list");
        break;
    }
  };

  const renderContent = () => {
    const navbarTranslate = clampedScroll.interpolate({
      inputRange: [0, 40],
      outputRange: [0, -40],
      extrapolate: "clamp",
    });
    switch (modeView) {
      case "list":
        return (
          <View style={{ flex: 1, backgroundColor: colors.card }}>
            <Animated.View
              style={[
                {
                  width: "100%",
                  minWidth: minWidth,
                  backgroundColor: "white",
                  transform: [{ translateY: navbarTranslate }],
                  margin: "auto",
                },
              ]}
            >
              <FilterSort
                modeView={modeView}
                onChangeSort={onChangeSort}
                onChangeView={onChangeView}
                onFilter={onFilter}
                isReferred={isReferred}
              />
              <h1
                style={{
                  fontSize: 16,
                  paddingRight: 10,
                  paddingLeft: 10,
                  color: BaseColor.highlightedTextColor,
                }}
              >
                {t("search:metadata_h1", {
                  cityName,
                  areaName,
                  searchResultNumber: serviceSearchResults.searchResult.length,
                })}
              </h1>
              <FilterModal
                onDismiss={(visible) => {
                  setIsShowFilterModal(visible);
                }}
                visible={isShowFilterModal}
              />
            </Animated.View>
            {/* Move <LoadingSpinner /> and <SearchSubScreen /> to a separated <View> to avoid it blocking the <h1> renders */}
            <View style={{ flex: 1 }}>
              <LoadingSpinner visible={isLoading || isFetching} />
              <SearchSubScreen
                isSsr={isSsr}
                type={type}
                size={size}
                category={category}
                areaCode={areaCode}
                orderBy={orderBy}
                routeParams={routeParams}
                serviceSearchResults={serviceSearchResults}
                checkLoading={(isLoading) => {
                  setIsLoading(isLoading);
                }}
                isReferred={isReferred}
              />
            </View>
          </View>
        );
      default:
        return <></>;
    }
  };
  return (
    <View style={{ flex: 1 }}>
      <Menu
        visible={isShowMenu}
        onDismiss={() => {
          setIsShowMenu(false);
        }}
      />
      <Header
        style={BaseStyle.menu}
        titleStyle={{ color: BaseColor.whiteColor }}
        title={t(`search:type_${ServiceTypeMap.get(parseInt(type))}`)}
        subTitle={""}
        renderLeft={() => {
          return (
            <Icon name="arrow-left" size={20} color={BaseColor.whiteColor} />
          );
        }}
        onPressLeft={() => {
          navigation.pop();
          // navigation.navigate("UserTab", {screen: 'Home'});
        }}
        renderRight={() => {
          return (
            <View style={{ flexDirection: "row" }}>
              {currentUser !== null ? (
                <TouchableOpacity
                  style={{
                    width: 32,
                    height: 32,
                    marginRight: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 16,
                  }}
                  onPress={() => {
                    navigation.navigate("UserTab", { screen: "Chat" });
                  }}
                >
                  <ChatIcon />
                </TouchableOpacity>
              ) : (
                <></>
              )}
              <TouchableOpacity
                style={{
                  width: 32,
                  height: 32,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 16,
                }}
                onPress={() => {
                  setIsShowMenu(true);
                }}
              >
                <View>
                  <Icon name="bars" size={18} color={BaseColor.whiteColor} />
                </View>
              </TouchableOpacity>
            </View>
          );
        }}
      />
      <View
        style={BaseStyle.safeAreaView}
      >
        {renderContent()}
      </View>
    </View>
  );
};

export default SearchScreen;

const styles = StyleSheet.create({
  navbar: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
});
