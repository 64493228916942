export const removeEmpty = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
    // cannot exclude null, because null is legal value of firestore field
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};
