import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  FlatList,
  RefreshControl,
  TouchableOpacity,
  Switch,
} from "react-native";
import { BaseStyle, BaseColor, useTheme } from "../../config";
import {
  Header,
  SafeAreaView,
  Icon,
  OrderItem,
  ChatIcon,
  FeatherIcon,
} from "../../components";
import { Text, Image } from "../../ui";
import Menu from "../../ui/Menu";
import { TabView, TabBar } from "react-native-tab-view";
import { useTranslation } from "react-i18next";
import { CurrentReserve } from "../../fakedata";
import { useNavigation } from "../../hooks/useNavigation";
import { useSubscription } from "../../hooks";
import { getCurrentUserId, subscribeOrders, TOrder } from "../../models";
import { useAuth } from "../../context/Auth";
import Logo from "../../assets/images/fluv_tw_w.webp";
import { MainStackScreenNavigationProp } from "../../config/typography";

export default function OrderScreen() {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { colors } = useTheme();
  const { t } = useTranslation();
  let { userId } = useAuth();
  userId = getCurrentUserId();
  const [index, setIndex] = useState(0);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);
  const [routes] = useState([
    { key: "consumer", title: t("order:i_am_consumer") },
    { key: "provider", title: t("order:i_am_provider") },
    { key: "history_c", title: t("order:history_i_am_consumer") },
    { key: "history_p", title: t("order:history_i_am_provider") },
  ]);

  const sortHistoryOrders = (orders: TOrder[]): TOrder[] => {
    return orders.sort((o1, o2) => (o1.updatedAt < o2.updatedAt ? 1 : -1));
  };

  const consumerOrders = useSubscription({
    subscription: subscribeOrders,
    variables: {
      consumerId: userId,
      state: "processing",
    },
  });

  const providerOrders = useSubscription({
    subscription: subscribeOrders,
    variables: {
      providerId: userId,
      state: "processing",
    },
  });

  const providerHistoryCompletedOrders = useSubscription({
    subscription: subscribeOrders,
    variables: {
      providerId: userId,
      state: "completed",
    },
  });

  const providerHistoryCancelledOrders = useSubscription({
    subscription: subscribeOrders,
    variables: {
      providerId: userId,
      state: "cancelled",
    },
  });

  const providerHistoryOrders = useMemo(() => {
    return sortHistoryOrders([
      ...(providerHistoryCompletedOrders || []),
      ...(providerHistoryCancelledOrders || []),
    ]);
  }, [providerHistoryCompletedOrders, providerHistoryCancelledOrders]);

  const consumerHistoryCompletedOrders = useSubscription({
    subscription: subscribeOrders,
    variables: {
      consumerId: userId,
      state: "completed",
    },
  });

  const consumerHistoryCancelledOrders = useSubscription({
    subscription: subscribeOrders,
    variables: {
      consumerId: userId,
      state: "cancelled",
    },
  });

  const consumerHistoryOrders = useMemo(() => {
    return sortHistoryOrders([
      ...(consumerHistoryCompletedOrders || []),
      ...(consumerHistoryCancelledOrders || []),
    ]);
  }, [consumerHistoryCompletedOrders, consumerHistoryCancelledOrders]);

  // When tab is activated, set what's index value
  const handleIndexChange = (index: any) => {
    setIndex(index);
  };

  // Customize UI tab bar
  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      indicatorStyle={[styles.indicator, { backgroundColor: BaseColor.color2 }]}
      style={[styles.tabbar, { backgroundColor: BaseColor.color1 }]}
      tabStyle={styles.tab}
      inactiveColor={BaseColor.grayColor}
      activeColor={colors.text}
      renderLabel={({ route, focused, color }) => (
        <View style={{ flex: 1, alignItems: "center" }}>
          <Text
            headline
            semibold={focused}
            style={{ color: BaseColor.whiteColor }}
          >
            {route.title}
          </Text>
        </View>
      )}
    />
  );

  // Render correct screen container when tab is activated
  const renderScene = ({ route, jumpTo }: any) => {
    switch (route.key) {
      case "consumer":
        return (
          <OrderListView
            jumpTo={jumpTo}
            side={"consumer"}
            orders={consumerOrders}
          />
        );
      case "provider":
        return (
          <OrderListView
            jumpTo={jumpTo}
            side={"provider"}
            orders={providerOrders}
          />
        );
      case "history_c":
        return (
          <OrderListView
            jumpTo={jumpTo}
            side={"consumer"}
            orders={
              showCancelled
                ? consumerHistoryOrders
                : consumerHistoryCompletedOrders
            }
          />
        );
      case "history_p":
        return (
          <OrderListView
            jumpTo={jumpTo}
            side={"provider"}
            orders={
              showCancelled
                ? providerHistoryOrders
                : providerHistoryCompletedOrders
            }
          />
        );
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Menu
        visible={isShowMenu}
        onDismiss={() => {
          setIsShowMenu(false);
        }}
      />
      <Header
        style={BaseStyle.menu}
        title={""}
        subTitle={""}
        onPressLeft={() => {
          navigation.navigate("UserTab", { screen: "Home" });
        }}
        renderLeft={() => {
          return (
            <Image source={{ uri: Logo }} style={BaseStyle.headerLogoStyle} />
          );
        }}
        renderRight={() => {
          return (
            <View style={{ flexDirection: "row" }}>
              {
                <TouchableOpacity
                  style={{
                    width: 32,
                    height: 32,
                    marginRight: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 16,
                  }}
                  onPress={() => {
                    navigation.navigate("UserTab", { screen: "Chat" });
                  }}
                >
                  <ChatIcon />
                </TouchableOpacity>
              }
              <TouchableOpacity
                style={{
                  width: 32,
                  height: 32,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 16,
                }}
                onPress={() => {
                  setIsShowMenu(true);
                }}
              >
                <View>
                  <Icon name="bars" size={18} color={BaseColor.whiteColor} />
                </View>
              </TouchableOpacity>
            </View>
          );
        }}
        onPressRight={() => {}}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        <TabView
          lazy
          style={{ backgroundColor: BaseColor.fieldColor }}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          renderTabBar={renderTabBar}
          onIndexChange={handleIndexChange}
        />
        {index === 2 || index === 3 ? (
          <View style={styles.showCancelled}>
            <View style={{ flexDirection: "row" }}>
              <FeatherIcon
                style={{ marginRight: 10 }}
                name="trash-2"
                size={20}
              />
              <Text>{t("order:show_cancelled_orders")}</Text>
            </View>
            <View>
              <Switch onValueChange={setShowCancelled} value={showCancelled} />
            </View>
          </View>
        ) : null}
      </SafeAreaView>
    </View>
  );
}

type Props = {
  orders?: TOrder[] | null;
  side: "provider" | "consumer";
  jumpTo: any;
};

const OrderListView = React.memo<Props>((props) => {
  const { orders = [], side } = props;
  const { t } = useTranslation();
  const keyExtractor = useCallback((item) => item.id, []);

  const renderItem = useCallback((item) => {
    const {
      id,
      dates,
      providerId,
      amount,
      accepted,
      paid,
      executed,
      checked,
      cancelled,
      refunded,
      settled,
      snapshots,
    } = item;
    const { service, providerProfile, consumerProfile } = snapshots;
    const { type, title, pictureIds = [] } = service || {};

    const isProvider = side === "provider";
    const profile = isProvider ? consumerProfile : providerProfile;

    let statusString;
    let statusColor;
    if (refunded) {
      statusString = t("order:refunded");
      statusColor = BaseColor.dangerColor;
    } else if (cancelled) {
      statusString = t("order:cancelled");
      statusColor = BaseColor.dangerColor;
    } else if (settled) {
      statusString = t("order:settled");
      statusColor = BaseColor.successColor;
    } else if (checked) {
      statusString = t("order:checked");
      statusColor = BaseColor.successColor;
    } else if (executed) {
      statusString = t("order:executed");
      statusColor = isProvider
        ? BaseColor.successColor
        : BaseColor.warningColor;
    } else if (paid) {
      statusString = t("order:paid");
      statusColor = isProvider
        ? BaseColor.warningColor
        : BaseColor.successColor;
    } else if (accepted) {
      statusString = t("order:waiting_for_payment");
      statusColor = isProvider
        ? BaseColor.successColor
        : BaseColor.warningColor;
    } else {
      statusString = t("order:waiting_for_acceptance");
      statusColor = isProvider
        ? BaseColor.warningColor
        : BaseColor.successColor;
    }

    return (
      <OrderItem
        id={id}
        type={type}
        title={title}
        dates={dates}
        userId={providerId}
        profile={profile}
        pictureId={pictureIds[0]}
        amount={amount}
        statusString={statusString}
        statusColor={statusColor}
      />
    );
  }, []);
  return (
    <View style={{ flex: 1, paddingHorizontal: 16 }}>
      {orders !== null && orders?.length > 0 ? (
        <FlatList
          data={orders}
          keyExtractor={keyExtractor}
          renderItem={({ item }) => renderItem(item)}
        />
      ) : (
        <View style={{ padding: 20, alignItems: "center" }}>
          <Icon
            name="copy"
            size={72}
            style={{ paddingTop: 50, paddingBottom: 20 }}
          />
          <Text title3 style={{ marginVertical: 25 }} semibold>
            {t("reserve:no_current_reserve")}
          </Text>
        </View>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  tabbar: {
    height: 40,
  },
  tab: {
    flex: 1,
  },
  indicator: {
    height: 2,
  },
  label: {
    fontWeight: "400",
  },
  containProfileItem: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  showCancelled: {
    width: "100%",
    paddingVertical: 12,
    paddingHorizontal: 15,
    backgroundColor: BaseColor.whiteColor,
    bottom: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    /* @ts-ignore */
    position: "fixed",
  },
});
