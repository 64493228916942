import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { TSubscription } from "./types";

export type TCampaign = {
  id: string;
  quota: number;
  cost: number;
  overrides: {
    fee: number;
    price: number;
  };
  providersMap?: {
    [key: string]: {
      discount: number;
    };
  };
};

export type TCampaignProvider = {
  id: string;
  discount: number;
};

export const toCampaign = (
  snapshot: DocumentSnapshot<DocumentData>
): TCampaign | null => {
  if (!snapshot.data()) return null;

  const { quota, cost, overrides, providersMap } = snapshot.data() || {};

  return {
    id: snapshot.id,
    quota,
    cost,
    overrides,
    providersMap,
  };
};

const campaignRef = collection(db, "campaigns");

const getCampaignDoc = (campaignId: string) => {
  return doc(campaignRef, campaignId);
};

export const subscribeCampaign: TSubscription<
  { campaignId?: string },
  TCampaign
> = ({ variables, onChange }) => {
  const { campaignId } = variables || {};
  if (!campaignId) return () => {};
  const campaignDoc = getCampaignDoc(campaignId);

  return onSnapshot(campaignDoc, (snapshot) => {
    if (!onChange) return;
    onChange(toCampaign(snapshot));
  });
};
