import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { Icon } from ".";
import { BaseColor } from "../config";
import { useSubscription } from "../hooks";
import { getCurrentUserId, subscribeChatReads } from "../models";

interface ChatIconProps {
  isSidebar: boolean;
}

const ChatIcon = ({ isSidebar }: ChatIconProps) => {
  const currentUserId = getCurrentUserId();
  const chatReadNumber = useSubscription({
    subscription: subscribeChatReads,
    variables: {
      userId: currentUserId,
    },
  });
  return (
    <View>
      {chatReadNumber && chatReadNumber > 0 ? (
        <View
          style={[
            styles.redDot,
            isSidebar && { right: 14, width: 10, height: 10, borderRadius: 5 },
          ]}
        />
      ) : (
        <></>
      )}
      {isSidebar ? (
        <Icon name="comment" size={26} style={{ marginRight: 15 }} />
      ) : (
        <Icon name="comment" size={18} color={BaseColor.whiteColor} />
      )}
    </View>
  );
};

export default ChatIcon;

const styles = StyleSheet.create({
  redDot: {
    backgroundColor: BaseColor.color2,
    width: 8,
    height: 8,
    borderRadius: 4,
    position: "absolute",
    right: -2,
  },
});
