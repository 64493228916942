import moment from "moment";

const A_DAY = 86400e3;
const AN_HOUR = 3600e3;
const A_MINUTE = 60e3;
const A_SECOND = 1e3;

// copied from fluv/mobile/src/utils/date.ts
export const relativeToNow = (lang: string, date: Date, now: Date = new Date): string => {
  const rtf = new Intl.RelativeTimeFormat(lang, { style: 'short' })
  const ms = (date.valueOf() - now.valueOf());
  const diff = Math.abs(ms);
  let amount: number;
  let unit: Intl.RelativeTimeFormatUnit;
  if (diff >= A_DAY) {
    amount = Math.round(ms / A_DAY);
    unit = 'day';
  } else if (diff >= AN_HOUR) {
    amount = Math.round(ms / AN_HOUR);
    unit = 'hour';
  } else if (diff >= A_MINUTE) {
    amount = Math.round(ms / A_MINUTE);
    unit = 'minute';
  } else {
    amount = Math.round(ms / A_SECOND);
    unit = 'second';
  }
  return rtf.format(amount, unit);
};

export const datesToString = (
  dates: string[],
  inputFormat: string
): string | null => {
  if (dates.length === 0) return null;

  const startDate = moment(dates[0], inputFormat);
  const endDate = moment(dates[dates.length - 1], inputFormat);
  const thisYear = moment().year();
  const format =
    startDate.year() === thisYear && endDate.year() === thisYear
      ? "MM/DD"
      : "YYYY/MM/DD";

  if (dates.length === 1) {
    return startDate.format(format);
  }
  return `${startDate.format(format)} ~ ${endDate.format(format)}`;
};

export const periodToString = (start: number, end: number): string => {
  const startDate = moment(start);
  const endDate = moment(end);
  const thisYear = moment().year();
  const dateFormat =
    startDate.year() === thisYear && endDate.year() === thisYear
      ? "MM/DD"
      : "YYYY/MM/DD";
  const timeFormat = "HH:mm";

  if (startDate.date() === endDate.date()) {
    return `${startDate.format(
      `${dateFormat} ${timeFormat}`
    )} ~ ${endDate.format(timeFormat)}`;
  }
  return `${startDate.format(`${dateFormat} ${timeFormat}`)} ~ ${endDate.format(
    `${dateFormat} ${timeFormat}`
  )}`;
};

export const age = ({
  birthYear,
  birthMonth,
}: {
  birthYear: number;
  birthMonth?: number;
}): number => {
  const birthDate = moment()
    .year(birthYear)
    .month(birthMonth || 0);
  return moment().diff(birthDate, "years");
};
