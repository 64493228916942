import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native';
import { useAuthentication } from '../../hooks';
import Verification from '../../models/Verification';
import { getProfileWithEmail, sendMessage, updateOldUidFromProfile } from '../../models';
import { OFFICIAL_ACCOUNT_ID } from '../../constant/backend';
import { useTranslation } from 'react-i18next';
import { MainStackScreenNavigationProp } from '../../config/typography';


const VerificationScreen = () => {
  const route = useRoute();
  const { code } = route.params;
  const { user } = useAuthentication()
  const { t } = useTranslation();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const [status, setStatus] = useState('account_issue_verify_pending');

  const contactServiceTeam = async () => {
    await sendMessage(OFFICIAL_ACCOUNT_ID, {
      text: t('account_issue_message')
    })
    navigation.navigate('ChatRoomScreen', {
      userId: OFFICIAL_ACCOUNT_ID,
      name: '毛小愛小幫手'
    })
  }

  useEffect(async () => {
    if(!user) return;
    const {uid, email} = user;
    try {
      await Verification.verify({email, uid, code})
      const { id: oldUid } = await getProfileWithEmail(email, uid);
      await updateOldUidFromProfile(uid, oldUid);
      navigation.navigate("UserTab", {screen: "Home"});
      setStatus('account_issue_verify_success')
    } catch (e) {
      if(Verification.isVerifyError(e as Error)) {
        setStatus('account_issue_verify_fail')
        contactServiceTeam()
      } else {
        throw e
      }
    }
  }, [user])
  return (
    <View>
      <Text>{t(status)}</Text>
    </View>
  )
}

export default VerificationScreen

const styles = StyleSheet.create({})
