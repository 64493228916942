export enum PetSize {
  TINY = 5, // < 5
  SMALL = 10, // 5 ~ 10
  MEDIUM = 20, // 10 ~ 20
  LARGE = 40, // 20 ~ 40
  HUGE = 999, // > 40
}

export const PetSizeUtil = {
  list: (): PetSize[] => [
    PetSize.TINY,
    PetSize.SMALL,
    PetSize.MEDIUM,
    PetSize.LARGE,
    PetSize.HUGE,
  ],
};
