// copied from https://github.com/invertase/react-native-firebase/blob/v10.1.0/packages/dynamic-links/lib/index.d.ts#L122-L141
interface DynamicLinkAndroidParameters {
  /**
   * Sets the link to open when the app isn't installed. Specify this to do something other than install your app from
   * the Play Store when the app isn't installed, such as open the mobile web version of the content, or display a
   * promotional page for your app.
   */
  fallbackUrl?: string;

  /**
   * Sets the version code of the minimum version of your app that can open the link.
   */
  minimumVersion?: string;

  /**
   * The package name of the Android app to use to open the link. The app must be connected to your project from the Overview page of the Firebase console.
   *
   * This option is required if passing android options to your dynamic link.
   */
  packageName: string;
}

// copied from https://github.com/invertase/react-native-firebase/blob/v10.1.0/packages/dynamic-links/lib/index.d.ts#L229C1-L244C4
interface DynamicLinkITunesParameters {
  /**
   * The affiliate token used to create affiliate-coded links.
   */
  affiliateToken?: string;

  /**
   * The campaign token that developers can add to any link in order to track sales from a specific marketing campaign.
   */
  campaignToken?: string;

  /**
   * The provider token that enables analytics for Dynamic DynamicLinks from within iTunes Connect.
   */
  providerToken?: string;
}

// copied from https://github.com/invertase/react-native-firebase/blob/v10.1.0/packages/dynamic-links/lib/index.d.ts#L169-L211
interface DynamicLinkIOSParameters {
  /**
   * Sets the App Store ID, used to send users to the App Store when the app isn't installed.
   */
  appStoreId?: string;

  /**
   * The bundle ID of the iOS app to use to open the link. The app must be connected to your project from the Overview page of the Firebase console.
   *
   * This option is required if passing ios options to your dynamic link.
   */
  bundleId: string;

  /**
   * Sets the app's custom URL scheme, if defined to be something other than your app's parameters ID.
   */
  customScheme?: string;

  /**
   * Sets the link to open when the app isn't installed. Specify this to do something other than install your app from
   * the App Store when the app isn't installed, such as open the mobile web version of the content, or display a
   * promotional page for your app.
   */
  fallbackUrl?: string;

  /**
   * Sets the bundle ID of the iOS app to use on iPads to open the link. The app must be connected to your project
   * from the Overview page of the Firebase console.
   */
  iPadBundleId?: string;

  /**
   * Sets the link to open on iPads when the app isn't installed. Specify this to do something other than install your
   * app from the App Store when the app isn't installed, such as open the web version of the content, or display a
   * promotional page for your app. Overrides the fallback link set by `setFallbackUrl` on iPad.
   */
  iPadFallbackUrl?: string;

  /**
   * Sets the minimum version of your app that can open the link.
   */
  minimumVersion?: string;
}

export namespace FirebaseDynamicLinksTypes {
  // Copied from https://github.com/invertase/react-native-firebase/blob/v10.1.0/packages/dynamic-links/lib/index.d.ts
  export interface DynamicLinkAnalyticsParameters {
    /**
     * The individual campaign name, slogan, promo code, etc. for a product.
     */
    campaign?: string;

    /**
     * The campaign content; used for A/B testing and content-targeted ads to differentiate ads or links that point to the same URL.
     */
    content?: string;

    /**
     * The campaign medium; used to identify a medium such as email or cost-per-click (cpc).
     */
    medium?: string;

    /**
     * The campaign source; used to identify a search engine, newsletter, or other source.
     */
    source?: string;

    /**
     * The campaign term; used with paid search to supply the keywords for ads.
     */
    term?: string;
  }

  // copied from https://github.com/invertase/react-native-firebase/blob/v10.1.0/packages/dynamic-links/lib/index.d.ts#L318-L362
  export interface DynamicLinkParameters {
    /**
     * The link the target app will open. You can specify any URL the app can handle, such as a link to the app’s content,
     * or a URL that initiates some app-specific logic such as crediting the user with a coupon, or displaying a
     * specific welcome screen. This link must be a well-formatted URL, be properly URL-encoded, and use the HTTP or
     * HTTPS scheme.
     */
    link: string;

    /**
     * Domain URI Prefix of your App. This value must be your assigned domain from the Firebase console,
     * (e.g. https://xyz.page.link). The domain URI prefix must start with a valid HTTPS scheme (https://).
     */
    domainUriPrefix: string;

    /**
     * Access Google Analytics specific link parameters.
     */
    analytics?: DynamicLinkAnalyticsParameters;

    /**
     * Access Android specific link parameters.
     */
    android?: DynamicLinkAndroidParameters;

    /**
     * Access iOS specific link parameters.
     */
    ios?: DynamicLinkIOSParameters;

    /**
     * Access iTunes Connect specific link parameters.
     */
    itunes?: DynamicLinkITunesParameters;
  }
}

type ShortLinkType = "SHORT";

const toAndroidInfo = (
  android: FirebaseDynamicLinksTypes.DynamicLinkParameters["android"]
) =>
  android
    ? {
        androidInfo: {
          androidPackageName: android.packageName
        }
      }
    : {};

const toIosInfo = (
  ios: FirebaseDynamicLinksTypes.DynamicLinkParameters["ios"]
) =>
  ios
    ? {
        iosInfo: {
          iosBundleId: ios.bundleId,
          iosAppStoreId: ios.appStoreId
        }
      }
    : {};

const toAnalyticsInfo = (
  analytics: FirebaseDynamicLinksTypes.DynamicLinkParameters["analytics"]
) =>
  analytics
    ? {
        analyticsInfo: {
          googlePlayAnalytics: {
            utmSource: analytics.source,
            utmMedium: analytics.medium,
            utmCampaign: analytics.campaign
          }
        }
      }
    : {};

const toDynamicLinkInfo = (
  {
    domainUriPrefix,
    link,
    android,
    ios,
    analytics
  }: FirebaseDynamicLinksTypes.DynamicLinkParameters,
  shortLinkType: ShortLinkType
) => {
  return {
    dynamicLinkInfo: {
      domainUriPrefix: domainUriPrefix,
      link: link,
      ...toAndroidInfo(android),
      ...toIosInfo(ios),
      ...toAnalyticsInfo(analytics)
    },
    suffix: {
      option: shortLinkType
    }
  };
};

const firebaseLinksModule = {
  async buildShortLink(
    dynamicLinkParams: FirebaseDynamicLinksTypes.DynamicLinkParameters,
    shortLinkType: ShortLinkType
  ): Promise<string> {
    const resp = await fetch(
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCD6HedQDuBXOpDwgdLs48C714oVDBE5J8",
      {
        method: "POST",
        body: JSON.stringify(
          toDynamicLinkInfo(dynamicLinkParams, shortLinkType)
        )
      }
    );
    const { shortLink } = await resp.json();
    return shortLink;
  }
};

const dynamicLinks = () => firebaseLinksModule;

dynamicLinks.ShortLinkType = {
  SHORT: "SHORT" as const
};

export default dynamicLinks;
