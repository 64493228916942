import { BaseColor, useTheme } from "../config";
import { CertificateType } from "../enums";
import { Icon } from "../components";
import { TCertificate } from "../models";
import { Text } from "../ui";
import { TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import React from "react";

type Props = {
  item: TCertificate;
  onViewCertificateDetail: (id: string, type: number, category: string, pictureId: string) => void;
};

export default function CertificateItem({ item, onViewCertificateDetail }: Props) {
  const { id, type, category, pictureId } = item;
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        paddingHorizontal: 20,
        paddingVertical: 12,
        backgroundColor: BaseColor.whiteColor,
        borderBottomWidth: 1,
        borderBottomColor: colors.card,
      }}
      onPress={() => onViewCertificateDetail(id, type, category!, pictureId)}
    >
      <View style={{ flexDirection: "column" }}>
        <View>
          <Text style={{ flex: 1, fontSize: 16, lineHeight: 21 }}>
            {t(`license:certificate_type_${CertificateType[type]}`)}
          </Text>
        </View>
        <View>
          <Text textColor>{category ? t(`license:certificate_category_${category}`) : undefined}</Text>
        </View>
      </View>
      <Icon name="angle-right" color={BaseColor.borderColor} size={18} style={{ marginLeft: 10 }} />
    </TouchableOpacity>
  );
}
