import {
  FlatList,
  Modal,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React, { useCallback, useState } from "react";
import { useNavigation } from "../hooks/useNavigation";
import FilterOption, { TPetCategory, TPetSize } from "./FilterOption";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import serviceType from "../constant/serviceType";
import { Icon } from "../components";
import { Text, Button } from "../ui";
import { useTheme } from "../config";
import { minWidth } from "../config/theme";

type TFilterModal = {
  onDismiss: (visible: boolean) => void;
  visible: boolean;
};

const FilterModal = React.memo<TFilterModal>((props) => {
  const { visible, onDismiss } = props;
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation();
  const [category, setCategory] = useState<TPetCategory[]>([]);
  const [size, setSize] = useState<TPetSize[]>([]);
  const [type, setType] = useState<number>(3);
  const getFilterOption = (category: TPetCategory[], size: TPetSize[]) => {
    setCategory(category);
    setSize(size);
  };

  const renderIconService = useCallback(() => {
    return (
      <FlatList
        data={serviceType}
        numColumns={4}
        keyExtractor={(item) => item.name}
        renderItem={({ item }) => {
          return (
            <TouchableOpacity
              style={styles.itemService}
              activeOpacity={0.9}
              onPress={() => {
                setType(parseInt(item.type));
              }}
            >
              <View
                style={[
                  styles.iconContent,
                  {
                    backgroundColor:
                      type === parseInt(item.type)
                        ? colors.primary
                        : colors.card,
                  },
                ]}
              >
                <Icon
                  name={t(item.icon)}
                  color={
                    type === parseInt(item.type) ? colors.card : colors.primary
                  }
                  size={18}
                  solid
                />
              </View>
              <Text footnote grayColor numberOfLines={1}>
                {t(`search:${item.name}`)}
              </Text>
            </TouchableOpacity>
          );
        }}
      />
    );
  }, [type]);

  return (
    <Modal transparent={true} visible={visible}>
      <TouchableWithoutFeedback
        onPress={() => {
          onDismiss(false);
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(33, 33, 33, 0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 15,
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                maxWidth: minWidth,
                height: "auto",
                maxHeight: "auto",
                paddingTop: 12,
                paddingBottom: 24,
                paddingHorizontal: 15,
              }}
            >
              <View>
                <FilterOption getFilterOption={getFilterOption} />
                {renderIconService()}
                <Button
                  style={{ marginVertical: 10 }}
                  onPress={() => {
                    if (Platform.OS === "web") {
                      AsyncStorage.getItem("currentLocation").then(
                        (location) => {
                          window.location.replace(
                            `/search?type=${type}&category=${category}&size=${size}&areaCode=${
                              location?.split("&")[1]
                            }`
                          );
                        }
                      );
                    }
                    onDismiss(false);
                  }}
                >
                  {t("enum:filter")}
                </Button>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
});

export default FilterModal;

const styles = StyleSheet.create({
  itemService: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    paddingTop: 10,
  },
  iconContent: {
    justifyContent: "center",
    alignItems: "center",
    width: 36,
    height: 36,
    borderRadius: 18,
  },
});
