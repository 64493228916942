export enum PetCategory {
  DOG = 1,
  CAT = 2,
  RABBIT = 3,
  HAMSTER = 4,
  BIRD = 5,
}

export const PetCategoryUtil = {
  list: (): PetCategory[] => [
    PetCategory.DOG,
    PetCategory.CAT,
    PetCategory.RABBIT,
    PetCategory.HAMSTER,
    PetCategory.BIRD,
  ],
};
