import i18next from "i18next";
import { FLATTENED_AREAS_MAP } from "../constant/areas";
import { GOOGLE_SERVICE_API_KEY } from "../constant/backend";
import AsyncStorage from '@react-native-async-storage/async-storage';

export type TLocation = {
  latitude: number;
  longitude: number;
};

export type TSearchPlace = {
  id: string;
  name?: string;
  address?: string;
  location: {
    latitude?: number;
    longitude?: number;
  };
};

export const getFirstAddress = async (
  latitude: number,
  longitude: number
): Promise<string> => {
  // zh-Hant-TW will be process as zh-TW on Google
  const language = i18next.language === "zh" ? "zh-TW" : i18next.language;
  const data = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=${language}&key=${GOOGLE_SERVICE_API_KEY}`
  ).then((d) => d.json());
  const { results } = data;
  return results[0];
};

export const searchPlaces = async (query: string): Promise<TSearchPlace[]> => {
  const language = i18next.language === "zh" ? "zh-TW" : i18next.language;
  const data = await fetch(
    `https://apiservice.fluv.com/googlemap/?query=${query}&language=${language}&key=${GOOGLE_SERVICE_API_KEY}`
  ).then((d) => d.json());
  const { results = [] } =
    (data as {
      results?: {
        id: string;
        name?: string;
        formatted_address?: string;
        geometry?: {
          location?: {
            lat?: number;
            lng?: number;
          };
        };
      }[];
    }) || {};

  return results.map((result) => ({
    id: result.id,
    name: result.name,
    address: result.formatted_address,
    location: {
      latitude: result.geometry?.location?.lat,
      longitude: result.geometry?.location?.lng,
    },
  }));
};

export const getAreaCode = async (
  location?: TLocation
): Promise<string | null> => {
  if (!location) return null;

  const { latitude, longitude } = location;
  const data = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=zh-TW&key=${GOOGLE_SERVICE_API_KEY}`
  ).then((d) => d.json());

  const { results = [] } =
    (data as {
      results?: {
        // eslint-disable-next-line camelcase
        address_components: {
          types: string[];
          // eslint-disable-next-line camelcase
          long_name?: string;
        }[];
      }[];
    }) || {};

  let postalCode: string | null = null;
  results.some((result) => {
    const components = result.address_components || [];
    components.some((component) => {
      if (component.types.includes("postal_code")) {
        postalCode = component.long_name?.substr(0, 3) || null;
      }
      return !!postalCode;
    });
    return !!postalCode;
  });

  if (postalCode && FLATTENED_AREAS_MAP[postalCode]) {
    return postalCode;
  }
  return null;
};
