import * as Solito from "solito/link";
import { ReactNode } from "react";
import { TouchableOpacity } from "react-native";

interface LinkProps {
  children: ReactNode;
  href: string;
  isSsr?: boolean;
  onPress?: () => void;
}

export const Link = ({ children, href, isSsr, onPress }: LinkProps) =>
  isSsr ? (
    <Solito.Link href={href}>{children}</Solito.Link>
  ) : (
    <TouchableOpacity onPress={onPress} activeOpacity={0.9}>
      {children}
    </TouchableOpacity>
  );
