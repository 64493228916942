export interface Verification {
  email: string;
  uid: string;
  code?: string;
}

class VerificationError extends Error {}
VerificationError.prototype.name = "VerificationError";

class SendError extends VerificationError {}
SendError.prototype.name = "SendError";

class VerifyError extends VerificationError {}
SendError.prototype.name = "SendError";

const VERIFICATION = "/api/verification";

const createUrl = (path: string) => {
  return new URL(path, process.env.NEXT_PUBLIC_BASE).toString();
};

const sendVerify = async (verification: Verification) => {
  let url = createUrl(VERIFICATION);

  let options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(verification),
  };

  const { status } = await fetch(url, options);
  if (status !== 200) {
    throw new SendError();
  }
};

const Verification = {
  isSendError: (e: Error) => e instanceof SendError,

  isVerifyError: (e: Error) => e instanceof VerifyError,

  send: async ({ email, uid }: Verification): Promise<void> => {
    try {
      await sendVerify({ email, uid });
    } catch (e) {
      throw new SendError();
    }
  },

  verify: async ({ code, email, uid }: Verification): Promise<void> => {
    try {
      await sendVerify({ code, email, uid });
    } catch (e) {
      throw new VerifyError();
    }
  },
};

export default Verification;
