import {
  Modal,
  StyleSheet,
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { Image } from "../ui";
import { BaseColor, minWidth } from "../config/theme";
import { Icon } from "../components";

type ImageModalProps = {
  visible: boolean;
  images: string[];
  onDismiss: (visible: boolean) => void;
};

const ImageModal = React.memo<ImageModalProps>((props) => {
  const { images, onDismiss, visible } = props;

  const screenHeight = Dimensions.get("window").height;
  const screenWidth = Dimensions.get("window").width;

  return (
    <Modal transparent={true} visible={visible}>
      <TouchableWithoutFeedback>
        <View
          style={{
            backgroundColor: "rgba(33, 33, 33, 0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            minHeight: screenHeight,
            position: "relative",
          }}
        >
          <TouchableOpacity
            style={{ position: "absolute", top: 10, left: 15 }}
            onPress={() => onDismiss(false)}
          >
            <Icon name="times" size={20} color={BaseColor.whiteColor} />
          </TouchableOpacity>
          <TouchableWithoutFeedback>
            <View
              style={{
                flex: 1,
                width: screenWidth,
                minWidth: minWidth,
              }}
            >
              <Image
                source={images.length > 0 ? images[0] : ""}
                style={{
                  width: "100%",
                  minWidth: minWidth,
                  height: 400,
                  maxHeight: 400,
                  paddingHorizontal: 15,
                }}
                resizeMode={"contain"}
              />
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
});

export default ImageModal;

const styles = StyleSheet.create({});
