import { useState, useEffect } from "react";

export const usePromise = <D>(
  promiseFn: () => Promise<D | null>,
  filters: unknown[],
  init: D | null = null,
): D | null => {
  const [data, setData] = useState<D | null>(init);
  const fetchData = async () => {
    const newData = await promiseFn();
    setData(newData || null);
  };
  useEffect(() => {
    fetchData();
  }, [...filters]);

  return data;
};
