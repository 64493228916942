import React from "react";
import {
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";
import { BaseColor, useTheme, Typography } from "../config";
import PropTypes from "prop-types";
import { Text } from "../ui";
interface ButtonProps {
  style?: StyleProp<ViewStyle>;
  styleText?: StyleProp<TextStyle>;
  icon?: any;
  outline?: any;
  full?: any;
  round?: any;
  loading?: boolean;
  children?: any;
  onPress?: any;
  disabled?: boolean;
}
export default (props: ButtonProps) => {
  const { colors } = useTheme();
  const {
    style,
    styleText,
    icon,
    outline,
    full,
    round,
    loading,
    children,
    disabled,
    onPress,
  } = props;

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={StyleSheet.flatten([
        [
          styles.default,
          { backgroundColor: !disabled ? colors.primary : colors.border },
        ],
        outline && [
          styles.outline,
          { backgroundColor: colors.card, borderColor: colors.primary },
        ],
        full && styles.full,
        round && styles.round,
        style,
      ])}
      activeOpacity={0.9}
    >
      {icon ? icon : null}
      <Text
        style={StyleSheet.flatten([
          styles.textDefault,
          outline && { color: colors.primary },
          styleText,
        ])}
        numberOfLines={1}
      >
        {children || "Button"}
      </Text>
      {loading ? (
        <ActivityIndicator
          size="small"
          color={outline ? colors.primary : BaseColor.whiteColor}
          style={{ paddingLeft: 5 }}
        />
      ) : null}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  default: {
    height: 42,
    borderRadius: 21,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 16,
  },
  textDefault: {
    ...Typography.headline,
    color: BaseColor.whiteColor,
    fontSize: 16,
    lineHeight: 21,
  },
  outline: {
    borderWidth: 1,
  },

  full: {
    width: "100%",
    alignSelf: "auto",
  },
  round: {
    borderRadius: 28,
  },
});
