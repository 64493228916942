import React from 'react'
import { Text, View } from 'react-native'

const CatboxScreen = () => {
  return (
    <View>
      <Text>hi</Text>
    </View>
  )
}

export default CatboxScreen
