import {
  Modal,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Button } from "../ui";
import { minWidth } from "../config/theme";
import { THolidayServiceContent, updateAlert, updateIsAvailableHoliday } from "../models";

type THolidayAvailableModal = {
  onDismiss: (visible: boolean) => void;
  visible: boolean;
  holidayAvailableContext: THolidayServiceContent | null;
  alertId: string;
};

const HolidayAvailableModal = React.memo<THolidayAvailableModal>((props) => {
  const { visible, onDismiss, holidayAvailableContext, alertId } = props;

  return (
    <Modal transparent={true} visible={visible}>
      <TouchableWithoutFeedback
        onPress={() => {
          onDismiss(false);
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(33, 33, 33, 0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 15,
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                maxWidth: minWidth,
                height: "auto",
                maxHeight: "auto",
                paddingTop: 25,
                paddingBottom: 25,
                paddingHorizontal: 15,
                borderRadius: 15,
              }}
            >
              <View style={{ padding: 15 }}>
                <Text style={{ textAlign: "center" }} title1 semibold>{holidayAvailableContext?.title}</Text>
                <Text style={{ textAlign: "center", marginTop: 10 }}>{holidayAvailableContext?.description}</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                <Button
                  outline={true}
                  style={{ marginVertical: 10 }}
                  onPress={() => {
                    onDismiss(false);
                    updateAlert(alertId);
                    updateIsAvailableHoliday(false);
                  }}
                >
                  {holidayAvailableContext?.option.no}
                </Button>
                <Button
                  style={{ marginVertical: 10 }}
                  onPress={() => {
                    onDismiss(false);
                    updateAlert(alertId);
                    updateIsAvailableHoliday(true);
                  }}
                >
                  {holidayAvailableContext?.option.yes}
                </Button>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
});

export default HolidayAvailableModal;
