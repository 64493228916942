import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Icon } from "../components";
import { Image, Text, Tag } from "../ui";
import { BaseColor, useTheme } from "../config";
import { CertificateType, CertificateTypeUtil } from "../enums";
import { usePromise } from "../hooks";
import { checkVerifyKyc } from "../models";
import type { TProfile } from "../models";
import { relativeToNow } from "../utils";

type Props = {
  profile: TProfile;
  style?: any;
  image?: any;
  styleThumb?: any;
  onPress: () => void;
  name?: string;
  subName?: string;
  description?: string;
  tags?: any;
  userId?: string;
};

export default function ProfileDescription(props: Props) {
  const {
    profile,
    style,
    image,
    styleThumb,
    onPress,
    name,
    subName,
    description,
    tags,
    userId,
  } = props;
  const { colors } = useTheme();
  const { t, i18n } = useTranslation();
  const currentCertificateTypes = tags
    ? tags.map((cert: any) => cert.type)
    : [];
  const displayCertificateTypes = CertificateTypeUtil.list().filter((ct: any) =>
    currentCertificateTypes.includes(ct),
  );
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [isKyc, setIsKyc] = useState(false);
  usePromise(async () => {
    if (!userId) return;
    setIsKyc(await checkVerifyKyc(userId));
  }, [userId]);

  return (
    <TouchableOpacity
      style={[styles.contain, style]}
      onPress={onPress}
      activeOpacity={0.9}
    >
      <Image source={image} style={[styles.thumb, styleThumb]} />
      <View style={{ flex: 1, alignItems: "flex-start" }}>
        <Text headline semibold numberOfLines={1}>
          {name}
        </Text>
        <Text
          body2
          primaryColor
          style={{ marginTop: 3, marginBottom: 3 }}
          numberOfLines={1}
        >
          {subName}
        </Text>
        <Text
          footnote
          grayColor
          numberOfLines={showMoreDescription ? undefined : 2}
          style={{ paddingRight: 5 }}
        >
          {description}
        </Text>
        <TouchableOpacity
          onPress={() => {
            setShowMoreDescription(!showMoreDescription);
          }}
        >
          <View>
            <Text darkPrimaryColor style={{ marginVertical: 5 }}>
              {showMoreDescription ? (
                <>{t("enum:show_less")}</>
              ) : (
                <>{t("enum:show_more")}</>
              )}
            </Text>
          </View>
        </TouchableOpacity>
        <View style={styles.listContentService}>
          {isKyc ? (
            <Tag
              rateSmall
              textRate
              icon={
                <Icon
                  name={"id-card"}
                  size={12}
                  color={`white`}
                  solid
                  style={{ marginRight: 5 }}
                />
              }
              style={{
                marginTop: 5,
                marginRight: 5,
                backgroundColor: BaseColor.color3,
              }}
            >
              {t(`license:identity_verified`)}
            </Tag>
          ) : (
            <></>
          )}
          {displayCertificateTypes ? (
            displayCertificateTypes.map((item: any, index: any) => {
              return (
                <Tag
                  rateSmall
                  textRate
                  key={"service" + index}
                  icon={
                    <Icon
                      name={"id-card"}
                      size={12}
                      color={`white`}
                      solid
                      style={{ marginRight: 5 }}
                    />
                  }
                  style={{
                    marginTop: 5,
                    marginRight: 5,
                  }}
                >
                  {t(`license:certificate_type_${CertificateType[item]}`)}
                </Tag>
              );
            })
          ) : (
            <></>
          )}
        </View>
        {profile && (
          <Text
            numberOfLines={1}
            caption1
            grayColor
            style={{
              marginLeft: 3,
            }}
          >
            {t("last seen {{time}}", {
              time: relativeToNow(i18n.language, profile.lastCheckedInAt),
            })}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contain: {
    flexDirection: "row",
    alignItems: "center",
  },
  thumb: {
    width: 80,
    height: 80,
    borderRadius: 40,
    marginRight: 10,
  },
  //css Tags
  listContentService: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
