import {
  Dimensions,
  Linking,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../config";
import { useNavigation } from "../hooks/useNavigation";
import Modal from "react-native-modal";
import { ChatIcon, FeatherIcon, Icon, ProfileDetail } from "../components";
import { Text } from "../ui";
import { getAuth, signOut } from "firebase/auth";
import { AsyncStorage } from "../components";
import { usePromise, useSubscription } from "../hooks";
import {
  getCurrentUserId,
  getProfile,
  getProfilePictureUrl,
  getProfileWithEmail,
  sendMessage,
  subscribeProfile,
  subscribeRegistration,
  TProfile,
  verifyRegistration,
} from "../models";
import { BaseColor, minWidth } from "../config/theme";
import SitterManage from "../screens/sitter/SitterManage";
import { MainStackScreenNavigationProp } from "../config/typography";
import Verification from "../models/Verification";
import { OFFICIAL_ACCOUNT_ID } from "../constant/backend";

const height = Dimensions.get("window").height;
const width = Dimensions.get("window").width;

type TMenu = {
  onDismiss: (visible: boolean) => void;
  visible: boolean;
};

const Menu = React.memo<TMenu>((props) => {
  const { visible, onDismiss } = props;
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const auth = getAuth();
  const { currentUser } = auth;
  const { providerData } = currentUser || {};
  const currentUserId = getCurrentUserId();
  const [isShowSitterManage, setIsShowSitterManage] = useState(false);

  const sitterVerify = useSubscription({
    subscription: subscribeRegistration,
    variables: {
      userId: currentUserId,
    },
  });

  const currentUserProfile = useSubscription({
    subscription: subscribeProfile,
    variables: {
      userId: currentUserId,
    },
  });

  const originUserProfile = useSubscription({
    subscription: subscribeProfile,
    variables: {
      userId: currentUser?.uid,
    },
  });

  const usePicture = getProfilePictureUrl(
    currentUserId,
    currentUserProfile?.avatarId
  );

  const oldProfile = usePromise(async () => {
    if (!originUserProfile) return;
    return await getProfileWithEmail(
      originUserProfile?.email,
      originUserProfile?.oldUid
    );
  }, [originUserProfile]);

  const isShowAccountIssueButton = () =>
    originUserProfile?.oldUid === null && oldProfile !== null;

  const onLogOut = () => {
    AsyncStorage.setItem(
      "Auth",
      JSON.stringify({
        currentUserId: null,
      })
    );
    signOut(auth);
  };

  return (
    <>
      <Modal
        isVisible={visible}
        onSwipeComplete={() => {
          onDismiss(false);
        }}
        swipeDirection={["down"]}
        style={[styles.bottomModal]}
      >
        <View style={[styles.contentBottom, { backgroundColor: colors.card }]}>
          <TouchableOpacity
            onPress={() => {
              onDismiss(false);
            }}
          >
            <View style={styles.contentSwipeDown}>
              <Icon name="times" size={20} />
            </View>
          </TouchableOpacity>
          <View style={{ height: height - 35 }}>
            {currentUser !== null ? (
              <View style={{ marginTop: 24, marginBottom: 16 }}>
                <ProfileDetail
                  image={usePicture}
                  textFirst={currentUserProfile?.displayName}
                  textSecond={""}
                  icon={true}
                  onPress={() => {
                    /*@ts-ignore*/
                    navigation.navigate("ProfileEditScreen", {
                      userId: currentUserId,
                    });
                    onDismiss(false);
                  }}
                />
              </View>
            ) : (
              <></>
            )}
            {currentUser !== null ? (
              <TouchableOpacity
                style={{ marginTop: 24, marginBottom: 16 }}
                onPress={() => {
                  /*@ts-ignore*/
                  navigation.navigate("ProfileEditScreen", {
                    userId: currentUserId,
                  });
                  onDismiss(false);
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Icon name="edit" size={26} style={{ marginRight: 15 }} />
                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text>{t("menu:edit_profile")}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : (
              <></>
            )}
            {currentUser !== null && !verifyRegistration(sitterVerify) ? (
              <TouchableOpacity
                style={{ marginTop: 24, marginBottom: 16 }}
                onPress={() => {
                  setIsShowSitterManage(true);
                  onDismiss(false);
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Icon
                    name={"user"}
                    size={26}
                    style={{ marginRight: 15 }}
                    solid
                  />
                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text>{t("menu:sitter_apply")}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : (
              <></>
            )}
            {currentUser !== null && verifyRegistration(sitterVerify) ? (
              <TouchableOpacity
                style={{ marginTop: 24, marginBottom: 16 }}
                onPress={() => {
                  /*@ts-ignore*/
                  navigation.navigate("SitterDetailScreen", {
                    sitterId: currentUserId,
                  });
                  onDismiss(false);
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Icon name="dna" size={26} style={{ marginRight: 15 }} />
                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text>{t("profile:my_sitter_profile")}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : (
              <></>
            )}

            {currentUser === null ? (
              <TouchableOpacity
                style={{ marginTop: 16, marginBottom: 16 }}
                onPress={() => {
                  /*@ts-ignore*/
                  navigation.navigate("Auth");
                  onDismiss(false);
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Icon name="user" size={26} style={{ marginRight: 15 }} />
                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text>{t("menu:signin_out")}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : (
              <></>
            )}

            {currentUser !== null ? (
              <TouchableOpacity
                style={{ marginTop: 24, marginBottom: 16 }}
                onPress={() => {
                  /*@ts-ignore*/
                  navigation.navigate("PetsScreen", {
                    userId: currentUserId,
                  });
                  onDismiss(false);
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Icon name="paw" size={26} style={{ marginRight: 15 }} />
                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text>{t("menu:my_pet")}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : (
              <></>
            )}
            {currentUser !== null ? (
              <TouchableOpacity
                style={{ marginTop: 24, marginBottom: 16 }}
                onPress={() => {
                  /*@ts-ignore*/
                  navigation.navigate("UserTab", { screen: "Chat" });
                  onDismiss(false);
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <ChatIcon isSidebar={true} />
                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text>{t("menu:my_chat")}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : (
              <></>
            )}
            {currentUser !== null ? (
              <TouchableOpacity
                style={{ marginTop: 24, marginBottom: 16 }}
                onPress={() => {
                  navigation.navigate("UserTab", { screen: "Order" });
                  onDismiss(false);
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Icon name="bookmark" size={26} style={{ marginRight: 15 }} />
                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text>{t("menu:order")}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : (
              <></>
            )}

            <TouchableOpacity
              style={{ marginVertical: 16 }}
              onPress={() => {
                window.open("https://bit.ly/3QrSvjk");
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Icon name="mobile-alt" size={26} style={{ marginRight: 15 }} />
                <View
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Text>{t("menu:download_app")}</Text>
                </View>
              </View>
            </TouchableOpacity>
            {currentUser !== null ? (
              <TouchableOpacity
                style={{ marginTop: 24, marginBottom: 16 }}
                onPress={() => {
                  /*@ts-ignore*/
                  Linking.openURL(`https://fluv.com/connect-me`);
                  onDismiss(false);
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Icon name="headset" size={26} style={{ marginRight: 15 }} />
                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text>{t("menu:common_problem")}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : (
              <></>
            )}
            {isShowAccountIssueButton() ? (
              <TouchableOpacity
                style={{
                  marginTop: 24,
                  marginBottom: 16,
                  backgroundColor: BaseColor.dangerColor,
                  paddingVertical: 10,
                  borderRadius: 50,
                }}
                onPress={async () => {
                  try {
                    await Verification.send({
                      email: originUserProfile.email,
                      uid: currentUserId,
                    });
                    alert(t("account_issue_alert"));
                  } catch (e) {
                    if (Verification.isSendError(e as Error)) {
                      await sendMessage(OFFICIAL_ACCOUNT_ID, {
                        text: t("account_issue_message"),
                      });
                      navigation.navigate("ChatRoomScreen", {
                        userId: OFFICIAL_ACCOUNT_ID,
                        name: "毛小愛小幫手",
                      });
                    }
                  }
                  onDismiss(false);
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FeatherIcon
                    name="alert-triangle"
                    size={26}
                    style={{ marginRight: 15 }}
                    color={BaseColor.whiteColor}
                  />
                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text whiteColor heavy>
                      {t("account_issue")}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : null}
            {currentUser !== null ? (
              <TouchableOpacity
                style={{ marginTop: 24, marginBottom: 16 }}
                onPress={() => {
                  onLogOut();
                  /*@ts-ignore*/
                  window.location.replace("/home");
                  onDismiss(false);
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Icon
                    name="sign-out-alt"
                    size={26}
                    style={{ marginRight: 15 }}
                  />
                  <View
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text>{t("menu:login_out")}</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ) : (
              <></>
            )}
          </View>
        </View>
      </Modal>
      <SitterManage
        modalVisible={isShowSitterManage}
        onPress={() => {}}
        onDismiss={() => {
          setIsShowSitterManage(false);
        }}
      />
    </>
  );
});

export default Menu;

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: "center",
    alignItems: width > 425 ? "flex-end" : "center",
    margin: 0,
    width: "100%",
  },
  contentBottom: {
    width: "100%",
    maxWidth: 425,
    minWidth: minWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingHorizontal: 24,
  },
  contentSwipeDown: {
    paddingTop: 20,
    alignItems: "flex-end",
  },
});
