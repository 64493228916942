import {
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useCallback, useEffect, useMemo } from "react";
import Modal from "react-native-modal";
import { useTranslation } from "react-i18next";
import { useTheme } from "../config";
import { getCurrentUserId, subscribeOrders, TOrder } from "../models";
import { Icon, OrderItem } from ".";
import { BaseColor, minWidth } from "../config/theme";
import { Text } from "../ui";
import { useSubscription } from "../hooks";

const height = Dimensions.get("window").height;

type TWalletModal = {
  onDissmiss: () => void;
  visible: boolean;
};

const WalletModal = React.memo((props: TWalletModal) => {
  const { onDissmiss, visible } = props;
  const { t } = useTranslation();
  const { colors } = useTheme();
  const myId = getCurrentUserId();
  const orders =
    useSubscription({
      subscription: subscribeOrders,
      variables: {
        providerId: myId,
        state: "receivable",
      },
    }) || [];

  const keyExtractor = useCallback((item) => item.id, []);

  const balance =
    useMemo(() => {
      if (orders.length <= 0) return;
      return orders.reduce((value: any, order: TOrder) => {
        const { amount, fee, allowance } = order;
        value += Math.round((amount + (allowance || 0)) * (1 - fee));
        return value;
      }, 0);
    }, [orders]) || 0;

  const renderItem = useCallback((item) => {
    const {
      id,
      dates,
      providerId,
      amount,
      accepted,
      paid,
      executed,
      checked,
      cancelled,
      refunded,
      settled,
      snapshots,
    } = item;
    const { service, providerProfile } = snapshots;
    const { type, title, pictureIds = [] } = service || {};

    const profile = providerProfile;

    let statusString;
    let statusColor;
    if (refunded) {
      statusString = t("order:refunded");
      statusColor = BaseColor.dangerColor;
    } else if (cancelled) {
      statusString = t("order:cancelled");
      statusColor = BaseColor.dangerColor;
    } else if (settled) {
      statusString = t("order:settled");
      statusColor = BaseColor.successColor;
    } else if (checked) {
      statusString = t("order:checked");
      statusColor = BaseColor.successColor;
    } else if (executed) {
      statusString = t("order:executed");
      statusColor = BaseColor.successColor;
    } else if (paid) {
      statusString = t("order:paid");
      statusColor = BaseColor.warningColor;
    } else if (accepted) {
      statusString = t("order:waiting_for_payment");
      statusColor = BaseColor.successColor;
    } else {
      statusString = t("order:waiting_for_acceptance");
      statusColor = BaseColor.warningColor;
    }

    return (
      <OrderItem
        id={id}
        type={type}
        title={title}
        dates={dates}
        userId={providerId}
        profile={profile}
        pictureId={pictureIds[0]}
        amount={amount}
        statusString={statusString}
        statusColor={statusColor}
        onPress={() => {
          onDissmiss();
        }}
      />
    );
  }, []);

  return (
    <Modal
      isVisible={visible}
      onSwipeComplete={() => {
        onDissmiss();
      }}
      swipeDirection={["down"]}
      style={styles.bottomModal}
    >
      <View
        style={[styles.walletModalContain, { backgroundColor: colors.card }]}
      >
        <TouchableOpacity
          onPress={() => {
            onDissmiss();
          }}
        >
          <View style={{ paddingTop: 20, alignItems: "flex-end" }}>
            <Icon name="times" size={20} />
          </View>
        </TouchableOpacity>
        <View style={{ height: height - 35 }}>
          <View style={{ paddingTop: 15 }}>
            <Text title3>{t("sitter_profile:account_balance")}</Text>
          </View>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingVertical: 20,
            }}
          >
            <Text title1 bold>
              NT${balance.toLocaleString()}
            </Text>
          </View>
          <FlatList
            data={orders}
            keyExtractor={keyExtractor}
            renderItem={({ item }) => renderItem(item)}
            style={{ borderTopWidth: 1, paddingTop: 10 }}
          />
        </View>
      </View>
    </Modal>
  );
});

export default WalletModal;

const styles = StyleSheet.create({
  walletModalContain: {
    width: "100%",
    maxWidth: 425,
    minWidth: minWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingHorizontal: 24,
  },
  bottomModal: {
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    width: "100%",
  },
});
