// copied from fluv mobile ./src/models/preference.ts
import {
  collection,
  doc,
  setDoc,
  serverTimestamp,
  getDoc
} from "firebase/firestore";
import { getCurrentUserId } from './auth';
import { getShortLink } from '../helpers/link';
import { WEB_URL } from '../constant/backend';
import { db } from '../config/firebase';
import { removeEmpty } from "../utils";

export type TPreference = {
  id: string;
  updatedAt: number;
  tutorials?: {
    onboarding?: boolean;
    firstPet?: boolean;
  };
  alerts?: {
    availableHolidayService?: boolean;
  };
};

const preferenceRef = collection(db, "preferences");

export const toPreference = (snapshot: any): TPreference | null => {
  if (!snapshot.data()) return null;

  const { tutorials, alerts, updatedAt } = snapshot.data() || {};
  return {
    id: snapshot.id,
    updatedAt: updatedAt?.seconds * 1000,
    tutorials,
    alerts,
  };
};

export const getPreferenceDoc = (id: string) => {
  return doc(preferenceRef, id);
};

export const getPreference =  async (userId: string): Promise<TPreference | null> => {
  if(!userId) return null;
  const preferenceSnap = getPreferenceDoc(userId);
  if (!preferenceSnap) return null;

  const snapahot = await getDoc(preferenceSnap);

  return toPreference(snapahot);
}

export const updateAlert = async (alertId: string): Promise<void> => {
  const myId = await getCurrentUserId();
  if (!myId) return;

  const preferenceDoc = getPreferenceDoc(myId);

  await setDoc(
    preferenceDoc,
    removeEmpty({
      updatedAt: serverTimestamp(),
      alerts: {
        [`${alertId}`]: true,
      },
    }),
    { merge: true }
  );
};


export const createSitterReferralLink = async ({
  userId,
  campaign,
}: {
  userId: string;
  campaign: 'share_by_provider' | 'share_by_user';
}): Promise<string> => {
  const myId = await getCurrentUserId();
  if (!myId) return '';

  const analytics = {
    source: 'share',
    medium: 'referral',
    campaign,
  };

  return getShortLink(`${WEB_URL}/app/u/${userId}/share`, true, analytics);
};
