import { StyleSheet, TouchableOpacity, View } from "react-native";
import { BaseColor, useTheme } from "../config";
import { StarRating } from "../components";
import { Text, Tag, Image } from "../ui";
import * as Utils from "../utils";
import { relativeToNow } from "../utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/FontAwesome5";
import { checkVerifyKyc, getServicePictureUrl } from "../models";
import type { TProfile } from "../models";
import {
  CertificateType,
  CertificateTypeUtil,
  DayOfWeek,
  ServiceType,
} from "../enums";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { distance } from "../utils";
import { usePromise } from "../hooks";
import { Link } from "../components/Link";

interface SearchItemProps {
  type: any;
  profile?: TProfile;
  providerId: string;
  style?: any;
  pictureId?: any;
  title?: any;
  rate?: any;
  onPress?: any;
  onPressUser?: any;
  onPressBookNow?: any;
  location?: any;
  availableDays?: number[];
  tags?: any[];
  numReviews?: any;
  label?: any;
  basePriceRangeStr?: any;
  discountedBasePriceRangeStr?: any;
  place?: any;
  enabled?: boolean;
  latitude: number;
  longitude: number;
}

const SearchItem = (props: SearchItemProps) => {
  const { t, i18n } = useTranslation();
  const {
    type,
    profile,
    providerId,
    style,
    pictureId,
    title,
    rate,
    onPress,
    onPressUser,
    onPressBookNow,
    location,
    availableDays,
    tags,
    numReviews,
    label,
    basePriceRangeStr,
    discountedBasePriceRangeStr,
    place,
    enabled = true,
    routePath,
    latitude,
    longitude,
  } = props;
  const picture = pictureId
    ? getServicePictureUrl(providerId, pictureId)
    : undefined;
  const currentCertificateTypes = tags
    ? tags.map((cert: any) => cert.type)
    : [];
  const displayCertificateTypes = CertificateTypeUtil.list().filter((ct: any) =>
    currentCertificateTypes.includes(ct)
  );
  const [currenPlace, setCurrentPlace] = useState({});
  const [serviceDistance, setServiceDistance] = useState<string | null>("0");
  const [isKyc, setIsKyc] = useState(false);
  const { colors } = useTheme();
  const unit = t(`enum:type_unit_${ServiceType[type] || ""}`);
  usePromise(async () => {
    setIsKyc(await checkVerifyKyc(providerId));
  }, [providerId]);
  const renderList = () => {
    useEffect(() => {
      if (!place) return;
      AsyncStorage.getItem("currentLocation").then(
        (currentLocation: string | null) => {
          const latitude2 = place.location.latitude;
          const longitude2 = place.location.longitude;
          if (currentLocation !== null) {
            const location = currentLocation.split("&") as Array<string>;
            const latitude1 = parseFloat(location[2]);
            const longitude1 = parseFloat(location[3]);
            const K = distance(
              latitude1,
              longitude1,
              latitude2,
              longitude2,
              "K"
            ).toFixed(2);
            setServiceDistance(K);
          } else {
            const K = distance(
              latitude,
              longitude,
              latitude2,
              longitude2,
              "K"
            ).toFixed(2);
            setServiceDistance(K);
          }
        }
      );
    }, []);
    return (
      <View style={[styles.listContent, style]}>
        <View>
          <Link href={routePath} {...props}>
            <img
              src={picture}
              style={{
                width: 120,
                height: 150,
                borderRadius: 8,
                objectFit: "cover",
              }}
              loading="lazy"
              alt="sitter avatar"
            />
          </Link>
        </View>
        <View style={styles.listContentRight}>
          <Link href={routePath} {...props}>
            <View>
              <View
                style={{ flexDirection: "row", justifyContent: "space-between" }}
              >
                <Text headline semibold numberOfLines={1}>
                  {title}
                </Text>
              </View>
              <View>
                {!enabled && (
                  <Tag
                    rateSmall
                    textRate
                    style={{
                      marginTop: 5,
                      marginRight: 5,
                      backgroundColor: BaseColor.placeholderTextColor,
                    }}
                  >
                    {t("service:unavailable")}
                  </Tag>
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginTop: 5,
              }}
            >
              {/* <View style={[styles.listContentIcon, {marginVertical: 3, flex: 4}]}>
                <Icon
                  name="map-marker-alt"
                  color={BaseColor.grayColor}
                  size={10}
                />
                <Text
                  numberOfLines={1}
                  caption1
                  grayColor
                  style={{
                    marginLeft: 3,
                  }}>
                  {location.split("灣")[1]}
                </Text>
              </View> */}
              {serviceDistance && (
                <View
                  style={{ flex: 2, alignItems: "center", marginVertical: 3 }}
                >
                  <Text
                    caption1
                    primaryColor
                    semibold
                    style={{ textAlign: "right", marginLeft: 3 }}
                  >
                    {serviceDistance}
                    {t("search:distance_km")}
                  </Text>
                </View>
              )}
            </View>
            <View style={styles.listContentRate}>
              <StarRating
                disabled={true}
                starSize={10}
                maxStars={1}
                rating={rate}
                selectedStar={(rating) => {}}
                fullStarColor={BaseColor.yellowColor}
              />
              <Text caption1 grayColor semibold style={{ marginLeft: 5 }}>
                {rate}
              </Text>
              <Text
                caption1
                grayColor
                semibold
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                · {t("enum:rating")}
              </Text>
              <Text caption1 primaryColor semibold>
                {"(" + numReviews + ")"}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginTop: 5,
              }}
            >
              <View
                style={[styles.listContentIcon, { marginVertical: 3, flex: 4 }]}
              >
                {profile && (
                  <Text
                    numberOfLines={1}
                    caption1
                    grayColor
                    style={{
                      marginLeft: 3,
                    }}
                  >
                    {t("last seen {{time}}", {
                      time: relativeToNow(
                        i18n.language,
                        profile.lastCheckedInAt
                      ),
                    })}
                  </Text>
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={[styles.listContentIcon, { marginVertical: 3, flex: 4 }]}
              >
                {isKyc ? (
                  <Tag
                    rateSmall
                    textRate
                    icon={
                      <Icon
                        name={"id-card"}
                        size={12}
                        color={`white`}
                        solid
                        style={{ marginRight: 5 }}
                      />
                    }
                    style={{
                      marginRight: 5,
                      backgroundColor: BaseColor.color3,
                    }}
                  >
                    {t(`license:identity_verified`)}
                  </Tag>
                ) : (
                  <></>
                )}
              </View>
              <View
                style={{ flex: 2, alignItems: "center", marginVertical: 3 }}
              >
                <Text body2 primaryColor semibold>
                  {t("enum:currency")} {basePriceRangeStr} {unit}
                </Text>
              </View>
            </View>
          </Link>
        </View>
      </View>
    );
  };
  return renderList();
};

export default SearchItem;

const styles = StyleSheet.create({
  //css Gird
  girdContent: {
    flexDirection: "column",
    flex: 1,
  },
  girdContentLocation: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 5,
  },
  girdContentRate: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5,
  },

  girdImage: {
    borderRadius: 8,
    height: 120,
    width: "100%",
  },
  //css List
  listContentService: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tag: {
    flexDirection: "row",
    padding: 5,
    marginTop: 5,
    marginRight: 5,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 10,
  },
  listContentRate: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  listContentIcon: {
    flexDirection: "row",
    alignItems: "center",
  },
  listContentRight: {
    marginHorizontal: 10,
    flex: 1,
  },
  listContent: {
    flexDirection: "row",
    padding: 8,
  },
  //css block
  blockPriceContent: {
    position: "absolute",
    top: 10,
    left: 20,
    borderRadius: 8,
    padding: 5,
  },
  blockDetailContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  blockImage: {
    height: 200,
    width: "100%",
  },
});
