import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { Status } from "../enums";
import { getProfile } from "./profile";
import { TSubscription } from "./types";

const registrationsRef = collection(db, "registrations");
export type TRegistration = {
  id: string;
  kyc: {
    identity?: Status;
  };
  tests: {
    experience?: Status;
    platform?: Status;
  };
  contracts: {
    brokerage?: Status;
  };
  banned: boolean;
  bannedReason: string;
};
export const toRegistration = (snapshot: DocumentSnapshot<DocumentData>) => {
  if (!snapshot.data()) return null;

  const {
    kyc,
    tests,
    contracts,
    banned = false,
    bannedReason,
  } = snapshot.data() || {};
  const { identity } = kyc || {};
  const { experience, platform } = tests || {};
  const { brokerage } = contracts || {};

  return {
    id: snapshot.id,
    kyc: {
      identity,
    },
    tests: {
      experience,
      platform,
    },
    contracts: {
      brokerage,
    },
    banned,
    bannedReason,
  };
};

const getRegistrationsDoc = (id: string) => {
  return doc(registrationsRef, id);
};

export const checkVerifyKyc = async (userId: string | undefined) => {
  if (!userId) return false;
  const registrationDoc = getRegistrationsDoc(userId);
  const snapshot = await getDoc(registrationDoc);
  const registration = toRegistration(snapshot);
  if (!registration) {
    return false;
  }
  if (registration.kyc.identity === 3) {
    return true;
  } else {
    return false;
  }
};

export const subscribeRegistration: TSubscription<
  { userId?: string },
  TRegistration
> = ({ variables, onChange }) => {
  const { userId } = variables || {};
  if (!userId) return () => {};
  const registrationDoc = getRegistrationsDoc(userId);

  return onSnapshot(registrationDoc, (snapshot) => {
    if (!onChange) return;
    onChange(toRegistration(snapshot));
  });
};

export const verifyRegistration = (
  registration?: TRegistration | null
): boolean => {
  if (!registration) return false;

  const { kyc, tests, contracts } = registration || {};
  const { identity } = kyc || {};
  const { experience, platform } = tests || {};
  const { brokerage } = contracts || {};

  return (
    identity === Status.ACCEPTED &&
    experience === Status.ACCEPTED &&
    platform === Status.ACCEPTED &&
    brokerage === Status.ACCEPTED
  );
};
