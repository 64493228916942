import React, { useCallback, useEffect, useState } from "react";
import {
  RefreshControl,
  FlatList,
  View,
  TouchableOpacity,
  ListRenderItem,
  StyleSheet,
  Platform,
} from "react-native";
import { BaseColor, BaseStyle, useTheme } from "../../config";
import { Header, SafeAreaView, Icon, AsyncStorage } from "../../components";
import { Flex, Image, Text } from "../../ui";
import Menu from "../../ui/Menu";
import { MessagesData } from "../../fakedata";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/Auth";
import {
  getCurrentUserId,
  getProfilePictureUrl,
  subscribeChatPreviews,
  TChatPreview,
} from "../../models";
import { useSubscription } from "../../hooks";
import { dateFromNow } from "../../utils/time";
import Logo from "../../assets/images/fluv_tw_w.webp";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";

export default function MessengerScreen() {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { colors } = useTheme();
  const { t } = useTranslation();
  let { userId } = useAuth();
  const currentUserId = getCurrentUserId();
  userId = currentUserId;
  AsyncStorage.getItem("Auth").then((value: any) => {
    const auth = value ? JSON.parse(value) : null;
    if (auth.currentUserId === null) {
      window.location.replace("/signin");
    }
  });
  const [refreshing] = useState(false);
  const [messenger] = useState(MessagesData);

  const [count, setCount] = useState(20);
  const [chatPreviews, setChatPreviews] = useState<TChatPreview[]>([]);
  const [chatPreviewEnd, setChatPreviewEnd] = useState<any>([]);
  const [isShowMenu, setIsShowMenu] = useState(false);

  const handleLoadMore = useCallback(() => {
    // 抓下10筆資料並且跟舊的Preview合併
    if (count === chatPreviews.length) {
      setCount((prevCount) => prevCount + 10);
    }
  }, [count, chatPreviews]);

  const handleDataChange = useCallback((newData: TChatPreview[] | null) => {
    if (newData && newData.length > 0) {
      setChatPreviews(newData);
    }
  }, []);

  const keyExtractor = useCallback((item) => item.id, []);

  const handleOpenChatRoom = useCallback((extra) => {
    const { id, snapshots } = extra;
    const { profile } = snapshots;
    navigation.navigate("ChatRoomScreen", {
      userId: id,
      name: encodeURI(profile?.displayName),
    }); // 先導入到App

    // const text = t('chat:download_app');
    // if (confirm(text) == true) {
    //   // window.open('https://bit.ly/3QrSvjk');
    // } else {
    // }
  }, []);

  useSubscription({
    subscription: subscribeChatPreviews,
    variables: {
      userId,
    },
    onChange: handleDataChange,
    size: count,
  });

  const renderItem = useCallback(({ item }: any) => {
    const {
      id: senderId,
      lastSentAt,
      lastMessageText,
      unread,
      snapshots,
    } = item;
    const { profile } = snapshots || {};
    const { displayName, avatarId } = profile || {};
    const avatarUrl = getProfilePictureUrl(senderId, avatarId);

    const text = lastMessageText.includes("order_notification:")
      ? `${t(lastMessageText, { senderName: t("i_subject") })} ${t(
          "chat:system_sent"
        )}`
      : lastMessageText;

    return (
      <TouchableOpacity
        onPress={() => {
          handleOpenChatRoom(item);
        }}
        style={{
          marginVertical: 2,
          backgroundColor: BaseColor.whiteColor,
          paddingHorizontal: 15,
          marginBottom: 5,
        }}
      >
        <Flex.Row fill paddingVertical={8}>
          <Flex paddingRight={16}>
            <Image
              style={{ width: 56, height: 56, borderRadius: 28 }}
              source={{ uri: avatarUrl }}
            />
          </Flex>
          <Flex fill vCenter>
            <Text headline semibold>
              {displayName}
            </Text>
            <Text footnote grayColor numberOfLines={2}>
              {text.replace("\n", " ")}
            </Text>
          </Flex>
          <Flex>
            <Flex fill vCenter hEnd>
              <Text caption2 grayColor numberOfLines={1}>
                {dateFromNow(new Date(lastSentAt))}
              </Text>
            </Flex>
            <Flex fill vCenter hEnd>
              {unread > 0 && (
                <View>
                  <Text style={styles.number}>{unread.toString()}</Text>
                </View>
              )}
            </Flex>
          </Flex>
        </Flex.Row>
      </TouchableOpacity>
    );
  }, []);

  if (Platform.OS === "web") {
    useEffect(() => {
      window.addEventListener("scroll", () => {
        let sHeight = document.documentElement.scrollHeight;
        let cHeight = document.documentElement.clientHeight;
        let sTop = document.documentElement.scrollTop;
        if (cHeight + sTop === sHeight) {
          handleLoadMore();
        }
      });
      return () => {
        window.removeEventListener("scroll", () => {});
      };
    });
  }

  return (
    <View style={{ flex: 1 }}>
      <Menu
        visible={isShowMenu}
        onDismiss={() => {
          setIsShowMenu(false);
        }}
      />
      <Header
        style={BaseStyle.menu}
        styleLeft={{}}
        onPressLeft={() => {
          navigation.navigate("UserTab", { screen: "Home" });
          // navigation.pop();
        }}
        renderLeft={() => {
          return (
            <Image source={{ uri: Logo }} style={BaseStyle.headerLogoStyle} />
          );
        }}
        title=""
        subTitle=""
        styleRight={{}}
        renderRight={() => {
          return (
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                style={{
                  width: 32,
                  height: 32,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 16,
                }}
                onPress={() => {
                  setIsShowMenu(true);
                }}
              >
                <View>
                  <Icon name="bars" size={18} color={BaseColor.whiteColor} />
                </View>
              </TouchableOpacity>
            </View>
          );
        }}
        onPressRight={() => {}}
      />
      <SafeAreaView
        style={[BaseStyle.safeAreaView, { backgroundColor: colors.card }]}
        edges={["right", "left", "bottom"]}
      >
        <FlatList
          data={chatPreviews || []}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          onEndReached={handleLoadMore}
          // onScroll={(e) => {
          //   const sHeight = e.nativeEvent.contentSize.height;
          //   const lHeight = e.nativeEvent.layoutMeasurement.height;
          //   const cHeight = e.nativeEvent.contentOffset.y;
          //   if((sHeight-lHeight) === cHeight) {
          //     handleLoadMore();
          //     console.log('end')
          //   }
          // }}
        />
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  number: {
    textAlign: "center",
    marginTop: 5,
    backgroundColor: BaseColor.dangerColor,
    paddingHorizontal: 6,
    paddingVertical: 2,
    borderRadius: 10,
    overflow: "hidden",
    color: BaseColor.whiteColor,
  },
});
