import {
  Alert,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import { Header, Icon, StarRating } from "../../components";
import { Button, Flex, Image, Separator, Text } from "../../ui";
import { useTranslation } from "react-i18next";
import { BaseColor, useTheme } from "../../config";
import { useNavigation } from "../../hooks/useNavigation";
import { useAuth } from "../../context/Auth";
import { useSubscription } from "../../hooks";
import {
  acceptOrder,
  cancelOrder,
  checkOrder,
  executeOrder,
  getCurrentUserId,
  getProfilePictureUrl,
  getServicePictureUrl,
  subscribeOrder,
  subscribeReview,
  TPet,
} from "../../models";
import { openMap } from "../../helpers/map";
import PetItem from "../../components/PetItem";
import moment from "moment";
import { getShortAddress } from "../../constant/areas";
import { PetCategory, PetGender, PetSize, ServiceType } from "../../enums";
import FieldRow from "./row/FieldRow";
import { age, datesToString } from "../../utils/date";
import OrderBreakdown from "./OrderBreakdown";
import LoadingSpinner from "../../components/LoadingSpinner";
import { minWidth } from "../../config/theme";
import { MainStackScreenNavigationProp } from "../../config/typography";
import { Form } from "../../models/Form";
import { getAuth } from "firebase/auth";

const OrderDetailScreen = React.memo(({ route }: any) => {
  const { t } = useTranslation();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const routes = navigation.getState().routes;
  const { orderId } = route.params;
  const { colors } = useTheme();
  let { userId: myId } = useAuth();
  myId = getCurrentUserId();
  const auth = getAuth();
  const { currentUser } = auth;
  const [isLoading, setIsLoading] = useState(false);

  const order = useSubscription({
    subscription: subscribeOrder,
    variables: {
      orderId,
    },
  });

  const {
    place,
    dates = [],
    startHour,
    endHour,
    frames,
    times,
    notes,
    couponId,
    breakdown,
    providerId,
    consumerId,
    price,
    amount,
    accepted,
    paid,
    executed,
    checked,
    settled,
    cancelled,
    refunded,
    snapshots,
  } = order || {};

  const {
    service,
    providerProfile,
    consumerProfile,
    pets = [],
    coupon,
  } = snapshots || {};
  const { id: serviceId, type, title, pictureIds = [] } = service || {};
  const { address, doorNumber, areaCode, location } = place || {};

  const iAmConsumer = myId === consumerId;
  const iAmProvider = myId === providerId;
  const profile = iAmProvider ? consumerProfile : providerProfile;
  const otherUserId = iAmProvider ? consumerId : providerId;

  const canViewPetDetail = iAmConsumer || (iAmProvider && !!paid && !cancelled);

  const { displayName, avatarId, avgRating = 0, totalReviews } = profile || {};
  const avatarUrl = getProfilePictureUrl(otherUserId, avatarId);
  const coverUrl = getServicePictureUrl(providerId, pictureIds[0]);
  const shortAddress = areaCode ? getShortAddress(areaCode) : "";
  const detailAddress = `${address}${doorNumber ? ` ${doorNumber}` : ""}`;
  const prevRoute = routes[routes.length - 2];

  useEffect(() => {
    if (prevRoute.name !== "CreateOrderScreen") return;
    if (!currentUser || !myId) return;
    const [provider] = currentUser.providerData;
    const isEmailProvider = provider.providerId === "password";
    const alertText = isEmailProvider
      ? "Please verify if the email is filled in correctly, as notifications will be sent via email."
      : "Would you like to download the app to receive notifications?";
    if (confirm(t(alertText))) {
      if (isEmailProvider) {
        navigation.push("ProfileEditScreen", { userId: myId });
      } else {
        Linking.openURL("https://bit.ly/3ONBaDM");
      }
    }
  }, [myId, currentUser]);

  const reviewFromProvider = useSubscription({
    subscription: subscribeReview,
    variables: {
      userId: consumerId,
      reviewId: orderId,
    },
  });

  const reviewFromConsumer = useSubscription({
    subscription: subscribeReview,
    variables: {
      userId: providerId,
      reviewId: orderId,
    },
  });

  const handleOpenProfile = useCallback(() => {
    navigation.navigate("SitterDetailScreen", {
      orderId: order?.id,
      sitterId: profile?.id,
      preRoute: "OrderDetail",
    });
  }, [profile]);

  const handleOpenServiceDetail = useCallback(() => {}, [serviceId]);

  const handleOpenChat = useCallback(() => {
    navigation.navigate("ChatRoomScreen", {
      userId: profile?.id,
      name: encodeURI(order?.snapshots.providerProfile?.displayName || ""),
    });
  }, [profile]);

  const handleAccept = useCallback(async () => {
    if (orderId) {
      // loading spinner show
      try {
        await acceptOrder(orderId);
      } catch (e) {
        //
      }
    }
  }, [orderId]);

  const handlePay = useCallback(() => {
    navigation.navigate("OrderConfirmScreen", { orderId });
  }, [orderId]);

  const handleReview = useCallback(() => {
    if (!profile?.id) {
      console.debug("TT3E");
      return;
    }
    navigation.navigate("CreateReviewScreen", {
      sitterId: profile.id,
      orderId,
    });
  }, [orderId, profile]);

  const handleCheck = useCallback(async () => {
    if (orderId) {
      // loading show
      try {
        await checkOrder(orderId);
        handleReview();
      } catch (e) {}
    }
  }, [orderId, profile]);

  const handleExecute = useCallback(async () => {
    if (orderId) {
      // loading show
      try {
        await executeOrder(orderId);
        handleReview();
      } catch (e) {
        //
      }
    }
  }, [orderId]);

  const handleCancel = useCallback(async () => {
    if (orderId) {
      if (Platform.OS === "web") {
        let text = `${t("order:confirm_cancel_title")}\n${t(
          "order:confirm_cancel_message"
        )}`;
        if (confirm(text) == true) {
          setIsLoading(true);
          try {
            await cancelOrder(orderId);
          } catch (e) {
            // TODO: network error snackbar
          }
          setIsLoading(false);
          navigation.navigate("UserTab", { screen: "Order" });
        } else {
          text = "You canceled!";
          /* @ts-ignore */
          console.log(text);
        }
      } else {
        // mobile
        // Alert.alert(t('order:confirm_cancel_title'), t('order:confirm_cancel_message'), {
        //   positive: {
        //     text: t('yes'),
        //     onPress: async () => {
        //       // LoadingSpinner.show();
        //       try {
        //         await cancelOrder(orderId);
        //       } catch (e) {
        //         // TODO: network error snackbar
        //       }
        //       // LoadingSpinner.hide();
        //     },
        //   },
        //   negative: {
        //     text: t('cancel'),
        //   },
        // });
      }
    }
  }, [orderId]);

  const handleTransferBookingToAnotherSitter = useCallback(() => {
    if (!iAmConsumer && !iAmProvider) {
      alert(t("Only pet parents and the sitter can transfer an order!"));
      return;
    }
    const address = type === ServiceType.TAVERN ? shortAddress : detailAddress;
    const typeName = t(`enum:type_${ServiceType[type]}`);
    const serviceDates = dates.map((date) =>
      /(\d{4})(\d{2})(\d{2})/.exec(date).slice(1).join("-")
    );
    const startDate = serviceDates[0];
    const endDate = serviceDates[serviceDates.length - 1];
    const petCategorys = pets.map((pet) =>
      t(`pets:category_${PetCategory[pet.category]}`)
    );
    const petDetail = pets
      .map(
        ({
          name,
          gender,
          birthYear,
          birthMonth,
          size,
          neuteredOrSpayed,
          regularVaccination,
          healthStatus,
        }) => {
          const petGender = t(`pets:pet_gender_${PetGender[gender]}`);
          const petAge = t(`age`, { age: age({ birthYear, birthMonth }) });
          const petSize = t(`pets:pet_size_${PetSize[size]}`);
          const petNeuteredOrSpayed = t(
            `pets:pet_neutered_or_spayed_${String(neuteredOrSpayed)}`
          );
          const petRegularVaccination = t(
            `pets:pet_regular_vaccination_${String(regularVaccination)}`
          );
          return [
            name,
            petGender,
            petAge,
            petSize,
            petNeuteredOrSpayed,
            petRegularVaccination,
            healthStatus,
          ].join("/");
        }
      )
      .join(",");
    Linking.openURL(
      Form.getRecommendSitterLink({
        typeName,
        startDate,
        endDate,
        areaCode,
        petDetail,
        address,
        petCategorys,
        notes,
        consumerId,
      })
    );
  }, [
    type,
    dates,
    detailAddress,
    shortAddress,
    pets,
    notes,
    areaCode,
    consumerId,
    iAmConsumer,
    iAmProvider,
  ]);

  const handleOpenMap = useCallback(async () => {
    if (!address || !location) return;
    await openMap(address, location);
  }, [address, location]);

  const handleChangeAmount = useCallback(() => {
    navigation.navigate("OrderEditScreen", { orderId });
  }, [orderId]);

  const renderPet = useCallback((pet: TPet) => {
      return consumerId ? (
        <View style={{ marginHorizontal: -24 }} key={pet.id}>
        <PetItem {...pet} userId={consumerId} orderId={orderId} allowsSelection={canViewPetDetail} />
        </View>
      ) : null;
  }, [consumerId, orderId, canViewPetDetail])



  let statusString;
  let statusColor;
  let positiveAction;
  let positiveTitle;
  let negativeAction;
  let negativeTitle;
  let transferAction: () => void | undefined;
  let transferTitle: string | undefined;
  let changeAmountAction: () => void | undefined;
  let changeAmountTitle: string | undefined;

  if (!cancelled) {
    if (executed && iAmProvider && reviewFromProvider === null) {
      positiveAction = handleReview;
      positiveTitle = t("review:add_review");
    }
    if (checked && !iAmProvider && reviewFromConsumer === null) {
      positiveAction = handleReview;
      positiveTitle = t("review:add_review");
    }
  }

  if (refunded) {
    statusString = t("order:refunded");
    statusColor = BaseColor.dangerColor;
  } else if (cancelled) {
    statusString = t("order:cancelled");
    statusColor = BaseColor.dangerColor;
    transferAction = handleTransferBookingToAnotherSitter;
    transferTitle = t("Transfer Booking to Another Sitter");
  } else if (settled) {
    statusString = t("order:settled");
    statusColor = BaseColor.successColor;
  } else if (checked) {
    statusString = t("order:checked");
    statusColor = BaseColor.successColor;
  } else if (executed) {
    statusString = t("order:executed");
    statusColor = iAmProvider ? BaseColor.successColor : BaseColor.warningColor;
    if (!iAmProvider) {
      positiveAction = handleCheck;
      positiveTitle = t("order:check");
    }
  } else if (paid) {
    statusString = t("order:paid");
    statusColor = iAmProvider ? BaseColor.warningColor : BaseColor.successColor;
    if (iAmProvider) {
      positiveAction = handleExecute;
      positiveTitle = t("order:execute");
    }
    negativeAction = handleCancel;
    negativeTitle = t("order:cancel_order");
  } else if (accepted) {
    statusString = t("order:waiting_for_payment");
    statusColor = iAmProvider ? BaseColor.successColor : BaseColor.warningColor;
    if (!iAmProvider) {
      positiveAction = handlePay;
      positiveTitle = t("order:pay_now");
    }
    negativeAction = handleCancel;
    negativeTitle = t("order:cancel_order");
  } else {
    statusString = t("order:waiting_for_acceptance");
    statusColor = iAmProvider ? BaseColor.warningColor : BaseColor.successColor;
    transferAction = handleTransferBookingToAnotherSitter;
    transferTitle = t("Transfer Booking to Another Sitter");
    if (iAmProvider) {
      positiveAction = handleAccept;
      positiveTitle = t("order:accept_order");
      negativeAction = handleCancel;
      negativeTitle = t('order:reject_order');
      changeAmountAction = handleChangeAmount;
      changeAmountTitle = t('order:change_amount');
    } else {
      negativeAction = handleCancel;
      negativeTitle = t("order:cancel_order");
    }
  }

  const afterLastDay =
    moment().diff(moment(dates[dates.length - 1], "YYYYMMDD")) >= 0;

  return (
    <View style={{ flex: 1 }}>
      <LoadingSpinner visible={isLoading} />
      <Header
        title={t("order:order_detail")}
        subTitle={""}
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} color={colors.primary} />;
        }}
        onPressLeft={() => {
          if (prevRoute.name === 'OrderEditScreen') {
            navigation.navigate('UserTab', { screen: 'Order' });
          } else {
            navigation.pop();
          }
        }}
      />
      <Flex fill backgroundColor={BaseColor.whiteColor} paddingBottom={50}>
        <ScrollView>
          <Flex paddingHorizontal={24} paddingVertical={20}>
            <TouchableOpacity onPress={handleOpenServiceDetail}>
              <Flex.Row vCenter>
                <Flex fill>
                  <Text darkPrimaryColor body2 regular>
                    {type
                      ? t(`search:type_${ServiceType[type]}`)
                      : t("processing")}
                  </Text>
                  <Flex paddingTop={12} paddingRight={16}>
                    <Text
                      style={{ color: BaseColor.highlightedTextColor }}
                      body2
                      regular
                    >
                      {title}
                    </Text>
                  </Flex>
                </Flex>
                <Image
                  style={{ width: 72, height: 72 }}
                  source={{ uri: coverUrl }}
                  resizeMode="cover"
                />
              </Flex.Row>
            </TouchableOpacity>
            <Flex.Row between vCenter paddingVertical={20}>
              <Flex fill>
                <TouchableOpacity onPress={handleOpenProfile}>
                  <Flex.Row vCenter>
                    <Image
                      style={{
                        width: 48,
                        height: 48,
                        backgroundColor: BaseColor.whiteColor,
                        borderColor: BaseColor.whiteColor,
                        borderWidth: 3,
                        overflow: "hidden",
                        borderRadius: 200,
                      }}
                      source={{ uri: avatarUrl }}
                    />
                    <Flex fill paddingLeft={16}>
                      <Flex paddingBottom={8}>
                        <Text
                          medium
                          subhead
                          style={{
                            color: BaseColor.highlightedTextColor,
                          }}
                        >
                          {displayName}
                        </Text>
                      </Flex>
                      <Flex.Row vCenter>
                        <Flex paddingRight={8}>
                          <StarRating
                            starSize={14}
                            maxStars={5}
                            disabled={true}
                            rating={avgRating}
                            selectedStar={(rating) => {}}
                            fullStarColor={BaseColor.yellowColor}
                          />
                        </Flex>
                        <Text style={{ color: BaseColor.textColor }} subhead>
                          {totalReviews}
                        </Text>
                      </Flex.Row>
                    </Flex>
                  </Flex.Row>
                </TouchableOpacity>
              </Flex>
              <Flex paddingLeft={8}>
                <TouchableOpacity onPress={handleOpenChat}>
                  <Icon name="comment" color={BaseColor.textColor} size={18} />
                </TouchableOpacity>
              </Flex>
            </Flex.Row>
            <Separator />
            <Flex vCenter paddingVertical={20} paddingHorizontal={4} between>
              <Flex.Row vCenter fill>
                <View style={[styles.dot, { backgroundColor: statusColor }]} />
                <Text style={{ color: BaseColor.highlightedTextColor }} subhead>
                  {statusString}
                </Text>
              </Flex.Row>
              <Flex.Row>
                {!!positiveAction && !!positiveTitle && (                
                  <Flex fill>
                    <Flex fill paddingTop={16} paddingHorizontal={4}>
                      <Button
                        style={{ backgroundColor: colors.accent }}
                        onPress={positiveAction}
                        disabled={paid && !afterLastDay}
                      >
                        {positiveTitle}
                      </Button>
                    </Flex>
                    {!!changeAmountAction && !!changeAmountTitle && (
                      <Flex fill paddingTop={16} paddingHorizontal={4}>
                        <Button
                          style={{
                            backgroundColor: BaseColor.whiteColor,
                            borderColor: colors.border,
                            borderWidth: 1 
                          }}
                          styleText={{
                            color: BaseColor.textColor,
                          }}
                          onPress={changeAmountAction}
                          disabled={paid && !afterLastDay}
                        >
                          {changeAmountTitle}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                )}
                {!!negativeAction && !!negativeTitle && (
                  <Flex fill>
                    <Flex fill paddingTop={16} paddingHorizontal={4}>
                      <Button
                        style={{
                          backgroundColor: BaseColor.whiteColor,
                          borderColor: colors.border,
                          borderWidth: 1
                        }}
                        styleText={{
                          color: BaseColor.textColor
                        }}
                        onPress={negativeAction}
                      >
                        {negativeTitle}
                      </Button>
                    </Flex>
                    {transferAction && transferTitle && (
                      <Flex fill paddingTop={16} paddingHorizontal={4}>
                        <Button
                          style={{
                            backgroundColor: BaseColor.whiteColor,
                            borderColor: colors.border,
                            borderWidth: 1
                          }}
                          styleText={{
                            color: BaseColor.textColor,
                          }}
                          onPress={transferAction}
                        >
                          {transferTitle}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                )}
              </Flex.Row>
              {!iAmProvider &&
                checked === false &&
                cancelled === false &&
                type !== ServiceType.VET_TO_HOME &&
                type !== ServiceType.VET_HOSPITAL && (
                  <Flex.Row paddingTop={16}>
                    <Flex paddingTop={1} paddingRight={12}>
                      <Icon
                        size={13}
                        name="check"
                        color={BaseColor.highlightedTextColor}
                      />
                    </Flex>
                    <Flex fill>
                      <Text footnote style={{ color: BaseColor.textColor }}>
                        {t("order:quality_assurance")}
                      </Text>
                    </Flex>
                  </Flex.Row>
                )}
            </Flex>
            <Separator />
            <Flex paddingVertical={8}>
              {(type === ServiceType.BATHING ||
                type === ServiceType.GROOMING ||
                type === ServiceType.VET_TO_HOME ||
                type === ServiceType.VET_HOSPITAL) && (
                <FieldRow
                  iconName="calendar"
                  text={t("order:dates")}
                  content={
                    <Text
                      callout
                      style={{ color: BaseColor.highlightedTextColor }}
                    >
                      {datesToString(dates, "YYYYMMDD")}
                    </Text>
                  }
                />
              )}
              {(type === ServiceType.DROPIN ||
                type === ServiceType.WALKING ||
                type === ServiceType.TAVERN) && (
                <FieldRow
                  iconName="arrow-right"
                  text={
                    type === ServiceType.TAVERN
                      ? t("order:start_time")
                      : t("order:start_date")
                  }
                  content={
                    <Text
                      callout
                      style={{ color: BaseColor.highlightedTextColor }}
                    >
                      {datesToString([dates[0]], "YYYYMMDD")}
                    </Text>
                  }
                />
              )}
              {type === ServiceType.TAVERN && startHour && (
                <FieldRow
                  iconName=""
                  content={
                    <Text
                      callout
                      style={{ color: BaseColor.highlightedTextColor }}
                    >{`${startHour - 1}:00 ~ ${startHour}:00`}</Text>
                  }
                />
              )}
              {(type === ServiceType.DROPIN ||
                type === ServiceType.WALKING ||
                type === ServiceType.TAVERN) && (
                <FieldRow
                  iconName="arrow-left"
                  text={
                    type === ServiceType.TAVERN
                      ? t("order:end_time")
                      : t("order:end_date")
                  }
                  content={
                    <Text
                      callout
                      style={{ color: BaseColor.highlightedTextColor }}
                    >
                      {datesToString([dates[dates.length - 1]], "YYYYMMDD")}
                    </Text>
                  }
                />
              )}
              {type === ServiceType.TAVERN && endHour && (
                <FieldRow
                  iconName=""
                  content={
                    <Text
                      callout
                      style={{ color: BaseColor.highlightedTextColor }}
                    >{`${endHour - 1}:00 ~ ${endHour}:00`}</Text>
                  }
                />
              )}
              {(type === ServiceType.DROPIN || type === ServiceType.WALKING) &&
                frames && (
                  <FieldRow
                    iconName="clock"
                    text={t("order:service_length")}
                    content={
                      <Text
                        callout
                        style={{ color: BaseColor.highlightedTextColor }}
                      >
                        {t("enum:minutes", { minutes: frames * 30 })}
                      </Text>
                    }
                  />
                )}
              {(type === ServiceType.DROPIN ||
                type === ServiceType.WALKING) && (
                <FieldRow
                  iconName="columns"
                  text={t("order:times_per_day")}
                  content={
                    <Text
                      callout
                      style={{ color: BaseColor.highlightedTextColor }}
                    >
                      {t("enum:times", { times })}
                    </Text>
                  }
                />
              )}
              {place && (
                <FieldRow
                  iconName="map-pin"
                  text={t("order:location")}
                  content={
                    type === ServiceType.TAVERN && !paid ? (
                      <Text
                        callout
                        style={{ color: BaseColor.highlightedTextColor }}
                      >
                        {shortAddress}
                      </Text>
                    ) : (
                      <Text
                        callout
                        style={{ color: BaseColor.highlightedTextColor }}
                        onPress={handleOpenMap}
                      >
                        {detailAddress}
                      </Text>
                    )
                  }
                />
              )}
              {!!notes && (
                <FieldRow
                  iconName="edit"
                  text={t("order:notes")}
                  content={
                    <Text
                      callout
                      style={{ color: BaseColor.highlightedTextColor }}
                    >
                      {notes}
                    </Text>
                  }
                />
              )}
              {!!couponId && (
                <FieldRow
                  iconName="gift"
                  text={t("order:coupon")}
                  content={
                    <Text
                      callout
                      style={{ color: BaseColor.highlightedTextColor }}
                    >
                      {couponId}
                    </Text>
                  }
                />
              )}
            </Flex>
            <Separator />
            <Flex paddingVertical={8}>{pets.map(renderPet)}</Flex>
            <Separator />
            {type && (
              <OrderBreakdown
                type={type}
                pets={pets}
                dates={dates}
                startHour={startHour}
                endHour={endHour}
                frames={frames}
                times={times}
                coupon={coupon}
                breakdown={breakdown}
                price={price}
              />
            )}
          </Flex>
        </ScrollView>
      </Flex>
      <View
        /* @ts-ignore */
        style={{
          position: "fixed",
          width: "100%",
          minWidth: minWidth,
          bottom: 0,
        }}
      >
        <Flex backgroundColor={BaseColor.whiteColor}>
          <Separator />
          <Flex paddingHorizontal={24} paddingTop={4} paddingBottom={8}>
            <FieldRow
              iconName="shopping-cart"
              text={t("order:check_total")}
              content={
                <Text callout style={{ color: BaseColor.highlightedTextColor }}>
                  ${amount}
                </Text>
              }
            />
          </Flex>
        </Flex>
      </View>
    </View>
  );
});

export default OrderDetailScreen;

const styles = StyleSheet.create({
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 12,
  },
});
