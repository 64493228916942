import { ServiceApi } from "../typescript-axios-client-generated";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentSnapshot,
  GeoPoint,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  where,
} from "firebase/firestore";
import { Dimensions } from "react-native";
import { db } from "../config/firebase";
import { TQueryStatus } from "../config/typography";
import {
  DayOfWeek,
  DayOfWeekUtil,
  PetCategory,
  PetCategoryUtil,
  PetSize,
  PetSizeUtil,
  PriceLevel,
  ServiceType,
  ServiceTypeMap,
} from "../enums";
import { removeEmpty } from "../utils";
import { getPictureSize, getStoragePublicUrl } from "../utils/url";
import { getExDocs, toDocSnap } from "./ex";
import { getCurrentUserId } from "./auth";
import { TLocation } from "./location";
import { TPet } from "./pets";
import {
  isProfileActive,
  getProfile,
  subscribeProfile,
  TProfile,
} from "./profile";
import { isUri, uploadImage } from "./storage";
import { TSubscription } from "./types";
import Geohash from "latlon-geohash";
import { ALL_AREAS } from "../constant/areas";
import Service from "./Service.v1";

export type TServicePlace = {
  name?: string;
  address: string;
  doorNumber?: string;
  areaCode?: string;
  location: TLocation;
  cityName?: string;
  areaName?: string;
};

export type TService = {
  id: string;
  createdAt: number;
  updatedAt: number;
  providerId: string;
  type: ServiceType;
  title: string;
  description: string;
  areaCodes?: string[];
  place: TServicePlace;
  availableDays: DayOfWeek[];
  pictureIds: string[];
  petCategories: PetCategory[];
  petSizes: PetSize[];
  price?: number;
  priceLevel?: PriceLevel;
  banned: boolean;
  bannedReason?: string;
  enabled: boolean;
  lastService: any;
};

export type TServiceDetail = TService & {
  profile?: TProfile;
};

export type TServiceSearchResult = TService & {
  profile: TProfile;
  // distance: number;
  score: number;
};

export type TSearchResult = {
  searchResult: TServiceSearchResult[];
  lastService: any;
};

export type TServiceInput = {
  type: ServiceType;
  title: string;
  description: string;
  areaCodes?: string[];
  place: TServicePlace;
  availableDays: DayOfWeek[];
  pictureUrisOrIds: string[]; // Uri for new pictures and id for old pictures already on server
  petCategories: PetCategory[];
  petSizes: PetSize[];
  price?: number;
  priceLevel?: PriceLevel;
};

const screenWidth = Dimensions.get("window").width;

const getScore = (profile: TProfile, distance: number) => {
  const { avgRating, inHouseRating } = profile;

  // avgRating 1 ~ 5
  // inHouseRating 1 ~ 10
  // distance < 8 is recommended

  // https://docs.google.com/spreadsheets/d/1cf04H6RNq0LaFqerVXGfPn4n1CVIUB9BZeE15_qb7X4/edit#gid=0
  return 1.5 * avgRating + inHouseRating + 8 / distance;
};

export const toService = (
  snapshot: DocumentSnapshot<DocumentData>
): TService | undefined => {
  if (!snapshot.data()) return undefined;

  const serviceId = snapshot.id;

  const service = snapshot.data() || {};
  const {
    createdAt,
    updatedAt,
    providerId,
    type,
    title,
    description,
    areaCodes = [],
    place,
    location,
    availableDays,
    pictureIds,
    petCategories,
    petSizes,
    price,
    priceLevel,
    banned = false,
    bannedReason,
    enabled = false,
    lastService,
  } = service;

  if (type === null) {
    console.warn("CR8E", serviceId);
    return undefined;
  }

  const numberType = Number(type);
  if (isNaN(numberType)) {
    console.warn("7YA1", serviceId);
    return undefined;
  }

  const areaCode = Service.getAreaCode(service);

  const city = ALL_AREAS.find((c) => c.areas[areaCode]);
  return {
    id: serviceId,
    createdAt: createdAt?.seconds * 1000,
    updatedAt: updatedAt?.seconds * 1000,
    providerId,
    type: numberType,
    title,
    description,
    areaCodes,
    place: {
      name: place.name || null,
      address: place.address,
      doorNumber: place.doorNumber || null,
      areaCode: areaCode || null,
      location: {
        latitude:
          Object.keys(location).length > 0
            ? location.geopoint.latitude
            : place.location.latitude,
        longitude:
          Object.keys(location).length > 0
            ? location.geopoint.longitude
            : place.location.longitude,
      },
      cityName: city.name ?? null,
      areaName: city.areas[areaCode].name || null,
    },
    availableDays: Object.keys(availableDays)
      .filter((key) => availableDays[key])
      .map((key) => parseInt(key, 10)),
    pictureIds: pictureIds || [],
    petCategories: Object.keys(petCategories)
      .filter((key) => petCategories[key])
      .map((key) => parseInt(key, 10)),
    petSizes: Object.keys(petSizes)
      .filter((key) => petSizes[key])
      .map((key) => parseInt(key, 10)),
    price: price || null,
    priceLevel: priceLevel || null,
    banned: banned || null,
    bannedReason: bannedReason || null,
    enabled,
    lastService: lastService || null,
  };
};

const toServiceDetail = (
  snapshot: DocumentSnapshot<DocumentData>,
  profile?: TProfile
): TServiceDetail | null => {
  const service = toService(snapshot);
  if (!service) return null;
  return {
    ...service,
    profile,
  };
};

const toServiceSearchResult = (
  snapshot: any,
  // distance: number,
  profile: TProfile
): TServiceSearchResult | null => {
  const service = toService(snapshot);
  if (!service) return null;

  return {
    ...service,
    profile,
    score: profile ? getScore(profile, 5) : 0,
  };
};

export const serviceRef = collection(db, "services");

const getServiceDoc = (id: string) => {
  return doc(serviceRef, id);
};

export const getServicePictureUrl = (
  providerId?: string,
  pictureId?: string
): string | undefined => {
  if (!providerId || !pictureId) return undefined;
  if (isUri(pictureId)) return pictureId;

  return getStoragePublicUrl(
    `users_public/${providerId}/service_pictures/${pictureId}_thumb_${getPictureSize(
      screenWidth
    )}x.jpg`
  );
};

export const getService = async (
  serviceId: string
): Promise<TService | undefined> => {
  const serviceSnap = getServiceDoc(serviceId);
  if (!serviceSnap) return undefined;
  const snapshot = await getDoc(serviceSnap);
  return toService(snapshot);
};

export const getServicesByProfile = async (
  profile: TProfile
): Promise<TService[]> => {
  const servicesQuery = query(
    serviceRef,
    where("providerId", "==", profile.id),
    orderBy("type", "asc"),
    orderBy("title", "asc")
  );

  const servicesSnap = await getDocs(servicesQuery);
  return servicesSnap.docs
    .map(snap => {
      return {
        ...toService(snap),
        profile: profile
      };
    })
    .filter(Boolean) as TService[];
};

export const getSitterServiceNumber = async (
  sitterId: string
): Promise<number | 0> => {
  if (sitterId === "" || sitterId === undefined) return 0;
  const servicesQuery = query(serviceRef, where("providerId", "==", sitterId));
  const services = await getDocs(servicesQuery);
  return services.size;
};

export const subscribeService: TSubscription<
  { serviceId: string },
  TService
> = ({ variables, onChange }) => {
  const { serviceId } = variables || {};
  if (!serviceId) return () => {};

  const serviceDoc = getServiceDoc(serviceId);

  return onSnapshot(serviceDoc, (snapshot) => {
    if (!onChange) return;
    onChange(toService(snapshot) || null);
  });
};

export const subscribeServices: TSubscription<
  { providerId?: string },
  TService[]
> = ({ variables, onChange }) => {
  const { providerId } = variables || {};
  if (!providerId) return () => {};
  const serviceQuery = query(
    serviceRef,
    where("providerId", "==", providerId),
    orderBy("type", "asc"),
    orderBy("title", "asc")
  );

  return onSnapshot(serviceQuery, async (snapshot) => {
    if (!onChange) return;
    const profile = (await getProfile(providerId)) as TProfile;
    onChange(
      snapshot.docs
        .map((snap) => {
          return {
            ...toService(snap),
            profile: profile,
          };
        })
        .filter(Boolean) as TService[]
    );
  });
};

export const subscribeNearBySitterService: TSubscription<
  { serviceType?: number },
  TSearchResult
> = ({ variables, onChange }) => {
  const { serviceType } = variables || {};
  if (!serviceType) return () => {};
  const servicesQuery = query(
    serviceRef,
    where("type", "==", serviceType),
    where("enabled", "==", true)
  );

  return onSnapshot(servicesQuery, async (snapshots) => {
    if (!onChange) return;
    const profiles: TProfile[] = await Promise.all(
      snapshots.docs.map(async (service) => {
        const { providerId } = service.data() || {};
        const profile = (await getProfile(providerId)) as TProfile;
        return profile;
      })
    );
    const profileMap = profiles.reduce<{ [key: string]: any }>(
      (result: any, profile: any) => {
        result[profile.id] = profile;
        return result;
      },
      {}
    );
    const searchResult: any = snapshots.docs.map((snapshot) => {
      const { id } = snapshot;
      try {
        return toServiceSearchResult(
          {
            id,
            data: () => snapshot.data(),
          },
          profileMap[snapshot.data().providerId]
        );
      } catch (e) {
        if (Service.isAreaCodeError(e)) {
          console.debug("B6X7", e, id, snapshot.data())
          return null;
        }
        throw e;
      }
    }).filter((maybeService) => maybeService);
    onChange({
      searchResult: searchResult,
      lastService: snapshots.docs[snapshots.docs.length - 1],
    });
  });
};

export const getAllServicesSearchResults = async ({type, areaCode, after}) => {
  const results = await Promise.allSettled([
    getServiceSearchResults({
      type: '8',
      areaCode,
      petSize: [],
      petCategory: [],
      after
    }),
    getServiceSearchResults({
      type,
      areaCode,
      petSize: [],
      petCategory: [],
      after
    })
  ])
  if(results.some(({status}) => status === 'rejected')) throw new Error("DE09");

  return {
    searchResult: results.flatMap(({value}) => value.searchResult).sort((a, b) => b.updatedAt - a.updatedAt).slice(0, 10)
  }
}

/* getSpaServiceSearchResults is the older version of getServiceSearchResults
 * at 7d0fc38bfaf8501e10f7a462165459357d7c47d6.
 *
 * It has to exist because using getServiceSearchResults requires:
 * 1. creating indexes based on all combinations of petSize and petCategory or
 * 2. remove order by updatedAt which breaks pagination
 */
export const getSpaServiceSearchResults = async (
  variables: {
    priceLevel?: PriceLevel,
    type: string,
    location?: TLocation,
    areaCode: string;
    dates?: string[];
    pets?: TPet[];
    petCategory: any[];
    petSize: PetSize[];
    resultHandler?: (result: unknown[]) => unknown[];
    queryStatus: string;
    lastService?: any;
  },
  pageSize: number = 20
): Promise<TSearchResult> => {
  const {
    priceLevel,
    type,
    location,
    areaCode,
    dates = [],
    pets = [],
    petSize = [],
    petCategory = [],
    lastService,
    queryStatus,
  } = variables || {};
  const distance = 5;
  let serviceQuery = query(
    serviceRef,
    where("type", "==", parseInt(type)),
    where('enabled', '==', true),
  )
  if(parseInt(type) !== 8) {
    serviceQuery = query(serviceQuery, where("areaCodes", 'array-contains', areaCode));
  } else {
    // serviceQuery = query(serviceQuery, where("place.areaCode", '==', areaCode))
  }
  // category
  if(petCategory.includes(1)) serviceQuery = query(serviceQuery, where(`petCategories.1`, '==', true));
  if(petCategory.includes(2)) serviceQuery = query(serviceQuery, where(`petCategories.2`, '==', true));
  if(petCategory.includes(3)) serviceQuery = query(serviceQuery, where(`petCategories.3`, '==', true));
  if(petCategory.includes(4)) serviceQuery = query(serviceQuery, where(`petCategories.4`, '==', true));
  if(petCategory.includes(5)) serviceQuery = query(serviceQuery, where(`petCategories.5`, '==', true));

  // size
  if(petSize.includes(5)) serviceQuery = query(serviceQuery, where(`petSizes.5`, '==', true));
  if(petSize.includes(10)) serviceQuery = query(serviceQuery, where(`petSizes.10`, '==', true));
  if(petSize.includes(20)) serviceQuery = query(serviceQuery, where(`petSizes.20`, '==', true));
  if(petSize.includes(40)) serviceQuery = query(serviceQuery, where(`petSizes.40`, '==', true));
  if(petSize.includes(999)) serviceQuery = query(serviceQuery, where(`petSizes.999`, '==', true));
  // if(lastService !== undefined) {
  //   console.log('lastService', lastService);
  //   serviceQuery = query(serviceQuery, startAfter(lastService));
  // }


  let servicesSnap = await getDocs(serviceQuery);
  if (new URL(String(globalThis.location)).searchParams.has("staging")) {
    servicesSnap = await getExDocs(serviceQuery) as any;
  }
  if(servicesSnap.size < 0) return {
    searchResult: [],
    lastService: undefined
  };
  const profiles: TProfile[] = await Promise.all(servicesSnap.docs.map(async (service) => {
    const { providerId } = service.data() || {};
    const profile = await getProfile(providerId) as TProfile;
    return profile;
  }))


  const profileMap = profiles.reduce<{[key: string]: any}>((result: any, profile: any) => {
    result[profile.id] = profile;
    return result;
  }, {});

  const searchResult: any = servicesSnap.docs.map((snap) => {
    const {id} = snap;
    try {
      return toServiceSearchResult(
          {
            id,
            data: () => snap.data(),
          },
          profileMap[snap.data().providerId],
        )
    } catch (e) {
      return null;
    }
  })
    .filter(
      serviceSearchResult =>
        serviceSearchResult && isProfileActive(serviceSearchResult.profile)
    );
  return {
    searchResult: searchResult,
    lastService: servicesSnap.docs[servicesSnap.docs.length - 1]
  };
}

// "queryStatus" and "pageSize" is not using, afraid that it will affect the previous code so that it become optional
export const getServiceSearchResults = async (
  variables: {
    priceLevel?: PriceLevel;
    type: string;
    location?: TLocation;
    areaCode: string;
    dates?: string[];
    pets?: TPet[];
    petCategory: any[];
    petSize: PetSize[];
    resultHandler?: (result: unknown[]) => unknown[];
    queryStatus?: string;
    lastService?: any;
    after?: number;
  },
  pageSize?: number
): Promise<TSearchResult> => {
  const {
    priceLevel,
    type,
    location,
    areaCode,
    dates = [],
    pets = [],
    petSize = [],
    petCategory = [],
    lastService,
    queryStatus,
    after
  } = variables || {};
  const distance = 5;
  const typeNumber = parseInt(type);
  let serviceQuery = query(
    serviceRef,
    where("enabled", "==", true),
    limit(10)
  );
  if(typeNumber) {
    serviceQuery = query(serviceQuery, where("type", "==", typeNumber));
  } else {
    serviceQuery = query(serviceQuery, where("type", "!=", 8))
  }
  if (typeNumber === 8) {
    serviceQuery = query(serviceQuery, where("place.areaCode", '==', areaCode))
  } else {
    serviceQuery = query(
      serviceQuery,
      where("areaCodes", "array-contains", areaCode)
    );
  }
  // category
  if(petCategory.length !== 0) {
    if (petCategory.includes(1))
      serviceQuery = query(serviceQuery, where(`petCategories.1`, "==", true));
    if (petCategory.includes(2))
      serviceQuery = query(serviceQuery, where(`petCategories.2`, "==", true));
    if (petCategory.includes(3))
      serviceQuery = query(serviceQuery, where(`petCategories.3`, "==", true));
    if (petCategory.includes(4))
      serviceQuery = query(serviceQuery, where(`petCategories.4`, "==", true));
    if (petCategory.includes(5))
      serviceQuery = query(serviceQuery, where(`petCategories.5`, "==", true));
  }

  // size
  if(petSize.length !== 0) {
    if (petSize.includes(5))
      serviceQuery = query(serviceQuery, where(`petSizes.5`, "==", true));
    if (petSize.includes(10))
      serviceQuery = query(serviceQuery, where(`petSizes.10`, "==", true));
    if (petSize.includes(20))
      serviceQuery = query(serviceQuery, where(`petSizes.20`, "==", true));
    if (petSize.includes(40))
      serviceQuery = query(serviceQuery, where(`petSizes.40`, "==", true));
    if (petSize.includes(999))
      serviceQuery = query(serviceQuery, where(`petSizes.999`, "==", true));
  }
  if(typeNumber) {
    //This orderBy type conforms to the query format of firebase
      serviceQuery = query(
        serviceQuery,
        orderBy('updatedAt', 'desc'),
        orderBy('providerId'),
        startAfter(after ? new Date(after) : new Date())
      );
    } else {
      serviceQuery = query(
        serviceQuery,
        orderBy('type'),
        orderBy('updatedAt', 'desc'),
        orderBy('providerId'),
        startAfter(3, after ? new Date(after) : new Date())
      );
    }

  let servicesSnap = await getDocs(serviceQuery);
  servicesSnap = await getExDocs(serviceQuery);
  if (servicesSnap.size < 0)
    return {
      searchResult: [],
      lastService: undefined,
    };
  const profiles: TProfile[] = await Promise.all(
    servicesSnap.docs.map(async (service) => {
      const { providerId } = service.data() || {};
      const profile = (await getProfile(providerId)) as TProfile;
      return profile;
    })
  );

  const profileMap = profiles.reduce<{ [key: string]: any }>(
    (result: any, profile: any) => {
      result[profile.id] = profile;
      return result;
    },
    {}
  );

  const searchResult: any = servicesSnap.docs.map((snap) => {
      const { id } = snap;
      return toServiceSearchResult(
        {
          id,
          data: () => snap.data(),
        },
        profileMap[snap.data().providerId]
      );
    })
    .filter(
      (serviceSearchResult) =>
        serviceSearchResult && isProfileActive(serviceSearchResult.profile)
    );
  /*
    lastService is not using and servicesSnap.docs[servicesSnap.docs.length - 1] will get undefined value
    so let lastService become null for now
   */
  return {
    searchResult: searchResult,
    lastService: null,
  };
};

export const subscribeServiceDetail: TSubscription<
  { serviceId: string },
  TServiceDetail
> = ({ variables, onChange }) => {
  const { serviceId } = variables || {};
  if (!serviceId) return () => {};
  const serviceDoc = getServiceDoc(serviceId);

  let profile: TProfile | undefined;
  let unsubscribeProfile: () => void;
  const unsubscribeService = onSnapshot(serviceDoc, (snapshot) => {
    if (!snapshot.data()) return;
    const { providerId } = snapshot.data() || {};

    if (!onChange) return;
    const notifyChange = () => {
      onChange(toServiceDetail(snapshot, profile));
    };
    notifyChange();

    unsubscribeProfile = subscribeProfile({
      variables: {
        userId: providerId,
      },
      onChange: (newProfile) => {
        profile = newProfile || undefined;
        notifyChange();
      },
    });
  });

  return () => {
    unsubscribeService();
    if (unsubscribeProfile) unsubscribeProfile();
  };
};

export const getNannySitterId = (areaCode: string) => {};

export const createService = async (
  serviceInput: TServiceInput
): Promise<void> => {
  const myId = getCurrentUserId();
  if (!myId) return;
  const {
    type,
    title,
    description,
    areaCodes,
    place,
    availableDays,
    pictureUrisOrIds,
    petCategories,
    petSizes,
    price,
    priceLevel,
  } = serviceInput;

  const shouldUseCustomPrice = price !== undefined && price !== null;
  if (!shouldUseCustomPrice && !priceLevel) {
    throw new Error("no_price");
  }

  const pictureIds = await Promise.all(
    pictureUrisOrIds.map((pictureUriOrId) =>
      uploadImage(pictureUriOrId, `users_public/${myId}/service_pictures`)
    )
  );
  addDoc(
    serviceRef,
    removeEmpty({
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      providerId: myId,
      type,
      title,
      description,
      areaCodes:
        type !== ServiceType.TAVERN && type !== ServiceType.VET_HOSPITAL
          ? areaCodes
          : undefined,
      place,
      location: {
        geohash: Geohash.encode(
          place.location.latitude,
          place.location.longitude
        ),
        geopoint: new GeoPoint(
          place.location.latitude,
          place.location.longitude
        ),
      },
      availableDays: availableDays.reduce<{ [key: number]: boolean }>(
        (result, availableDay) => {
          result[availableDay] = true;
          return result;
        },
        {}
      ),
      pictureIds,
      petCategories: petCategories.reduce<{ [key: number]: boolean }>(
        (result, petCategory) => {
          result[petCategory] = true;
          return result;
        },
        {}
      ),
      petSizes: petSizes.reduce<{ [key: number]: boolean }>(
        (result, petSize) => {
          result[petSize] = true;
          return result;
        },
        {}
      ),
      price,
      priceLevel: !shouldUseCustomPrice && priceLevel ? priceLevel : undefined,
    })
  );
};

export const updateService = async (
  serviceId: string,
  serviceInput: TServiceInput
): Promise<void> => {
  const myId = getCurrentUserId();
  console.log(serviceId);
  console.log(myId);
  if (serviceId === "") return;
  if (!myId) return;
  const {
    type,
    title,
    description,
    areaCodes,
    place: {
      name,
      address,
      areaCode,
      location: { latitude, longitude },
    },
    availableDays,
    pictureUrisOrIds,
    petCategories,
    petSizes,
    price,
    priceLevel,
  } = serviceInput;

  const shouldUseCustomPrice = price !== undefined && price !== null;
  if (!shouldUseCustomPrice && !priceLevel) {
    throw new Error("no_price");
  }

  const pictureIds = await Promise.all(
    pictureUrisOrIds.map((pictureUriOrId) =>
      uploadImage(pictureUriOrId, `users_public/${myId}/service_pictures`)
    )
  );

  await setDoc(
    getServiceDoc(serviceId),
    removeEmpty({
      updatedAt: serverTimestamp(),
      providerId: myId,
      type,
      title,
      description,
      areaCodes:
        type !== ServiceType.TAVERN && type !== ServiceType.VET_HOSPITAL
          ? areaCodes
          : null,
      place: removeEmpty({
        name,
        address,
        areaCode,
        location: { latitude, longitude },
      }),
      location: {
        geohash: Geohash.encode(latitude, longitude),
        geopoint: new GeoPoint(latitude, longitude),
      },
      availableDays: DayOfWeekUtil.list().reduce<{ [key: number]: boolean }>(
        (result, day) => {
          result[day] = availableDays.includes(day);
          return result;
        },
        {}
      ),
      pictureIds,
      petCategories: PetCategoryUtil.list().reduce<{ [key: number]: boolean }>(
        (result, petCategory) => {
          result[petCategory] = petCategories.includes(petCategory);
          return result;
        },
        {}
      ),
      petSizes: PetSizeUtil.list().reduce<{ [key: number]: boolean }>(
        (result, petSize) => {
          result[petSize] = petSizes.includes(petSize);
          return result;
        },
        {}
      ),
      price,
      priceLevel: !shouldUseCustomPrice && priceLevel ? priceLevel : null,
    }),
    { merge: true }
  );
};

export const getExService = async (
  serviceId: string
): Promise<TService | undefined> => {
  if (serviceId === "") return;
  const idToken = await getAuth()?.currentUser?.getIdToken();
  const axiosResponse = await new ServiceApi({
    baseOptions: {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    },
    basePath: process.env.NEXT_PUBLIC_API,
  }).getServiceById(serviceId);
  const snapshot = toDocSnap(serviceId, axiosResponse);
  // @ts-ignore
  return toService(snapshot);
};

export const deleteService = async (serviceId: string): Promise<void> => {
  const serviceDoc = getServiceDoc(serviceId);
  if (!serviceDoc) return;

  await deleteDoc(serviceDoc);
};
