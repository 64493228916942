// Copied from https://gitlab.com/fluv/fluv_global/-/blob/main/src/screens/certificate/CertificateUpdateScreen.tsx#L1-106
import { BaseColor } from "../../config";
import { BaseStyle } from "../../config";
import { changeOrderAmount, getCurrentUserId, subscribeOrder } from "../../models";
import { Header, Icon } from "../../components";
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet, TextInput, View } from "react-native";
import { MainStackScreenNavigationProp } from "../../config/typography";
import { Text } from "../../ui";
import { useNavigation } from "@react-navigation/native";
import { useSubscription } from "../../hooks";
import { useTheme } from "../../config/theme";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../components/LoadingSpinner";
import React, { useCallback, useEffect, useState } from "react";

export default function OrderEditScreen({ route }: any) {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { orderId } = route.params;

  const [originalAmount, setOriginalAmount] = useState<number>(0);
  const [changedAmount, setChangedAmount] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDataLoading, setDataLoading] = useState<boolean>(true);

  const order = useSubscription({
    subscription: subscribeOrder,
    variables: {
      orderId,
    },
  });
  const { amount } = order || {};

  useEffect(() => {
    if (amount) {
      setOriginalAmount(amount);
      setDataLoading(false);
    }
  }, [amount]);

  const handleUpdateAmount = useCallback(async () => {
    setIsLoading(true);
    try {
      await changeOrderAmount(orderId, Number(changedAmount));
      navigation.navigate("OrderDetailScreen", { orderId });
    } catch (e) {
      console.warn(e);
      alert(t("order:error_update_order_amount"));
    }
    setIsLoading(false);
  }, [orderId, changedAmount]);

  const handleChangeAmount = (value: string) => {
    if (/^(?!0)\d*$/.test(value)) {
      setChangedAmount(value);
    }
  };

  const isAmountLowerThanHalf = originalAmount ? Number(changedAmount) < originalAmount / 2 : false;
  const MAX_ORDER_AMOUNT = 50000;
  const isAmountHigherThanLimit = Number(changedAmount) > MAX_ORDER_AMOUNT;
  const shouldDisable = isAmountLowerThanHalf || isAmountHigherThanLimit;

  return (
    <View style={{ flex: 1, backgroundColor: colors.card }}>
      <LoadingSpinner visible={isLoading} />
      <Header
        style={BaseStyle.menu}
        titleStyle={{ color: BaseColor.whiteColor }}
        title={t("order:update_order_amount")}
        subTitle={""}
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} color={BaseColor.whiteColor} />;
        }}
        onPressLeft={() => {
          navigation.navigate("OrderDetailScreen", { orderId });
        }}
        renderRight={() => {
          return (
            <View>
              {shouldDisable ? (
                <Text grayColor bold>
                  {t("update")}
                </Text>
              ) : (
                <Text whiteColor bold>
                  {t("update")}
                </Text>
              )}
            </View>
          );
        }}
        onPressRight={handleUpdateAmount}
        disableRight={shouldDisable}
      />
      <View style={BaseStyle.safeAreaView}>
        <KeyboardAvoidingView behavior={Platform.OS === "android" ? "height" : "padding"} style={{ flex: 1 }}>
          <ScrollView contentContainerStyle={styles.contain}>
            {/* Original Amount */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginVertical: 5,
                paddingVertical: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("order:original_amount")}
                  </Text>
                </View>
                <View style={{ marginVertical: 10 }}>
                  <Text body2 semibold>
                    {isDataLoading ? <></> : originalAmount}
                  </Text>
                </View>
              </View>
            </View>

            {/* Changed Amount */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginVertical: 5,
                paddingVertical: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("order:changed_amount")}
                  </Text>
                </View>
                <View style={{ marginVertical: 5 }}>
                  <TextInput
                    keyboardType="numeric"
                    onChangeText={(value) => handleChangeAmount(value)}
                    onFocus={() => {}}
                    value={changedAmount.toString()}
                    style={styles.textInput}
                  />
                </View>
              </View>
            </View>

            {/* Fine Print */}
            <Text style={{ alignSelf: "flex-start", paddingHorizontal: 20 }} caption1>
              {t("order:update_order_amount_fine_print")}
            </Text>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  contain: {
    alignItems: "center",
  },
  textInput: {
    height: 32,
    width: "50%",
    alignSelf: "flex-end",
    borderRadius: 5,
    borderColor: BaseColor.dividerColor,
    paddingHorizontal: 10,
    borderWidth: 1,
  },
});
