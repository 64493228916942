import { AxiosResponse } from "axios";
import type { Query, DocumentData } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { QueryApi } from "../typescript-axios-client-generated";

// @ts-ignore
const firestoreQueryToExFrom = ({ _query }: Query<DocumentData>) => [
  {
    collectionId: _query.path.segments.join()
  }
];

// https://firebase.google.com/docs/firestore/reference/rest/v1/StructuredQuery#Operator_1
const firestoreQueryToExOp = (op: string) => {
  switch (op) {
    case "==":
      return "EQUAL";
    case "!=":
      return "NOT_EQUAL";
    case "array-contains":
      return "ARRAY_CONTAINS";
    default:
      console.log("6TX1", op);
      throw new Error("QFST"); // not supported operator, either add a new case or fix your code
  }
};

const firestoreQueryToExFilter = ({ field, op, value }: any) => ({
  fieldFilter: {
    field: {
      fieldPath: field.segments
        .map((segments: string) => `\`${segments}\``)
        .join(".")
    },
    op: firestoreQueryToExOp(op),
    value
  }
});

// @ts-ignore
const firestoreQueryToExWhere = ({ _query }: Query<DocumentData>) => ({
  compositeFilter: {
    op: "AND",
    filters: _query.filters.map(firestoreQueryToExFilter)
  }
});

// https://firebase.google.com/docs/firestore/reference/rest/v1/StructuredQuery
const firestoreQueryToEx = (query: Query<DocumentData>) => ({
  query: {
    structuredQuery: {
      from: firestoreQueryToExFrom(query),
      where: firestoreQueryToExWhere(query)
    }
  }
});

export const toDocSnapDate = (date: Date) => Timestamp.fromDate(new Date(date));

export const toDocSnap = (id: string, { data }: AxiosResponse) => ({
  id,
  data: () => data.data,
});

const toFirestoreSnap = (runQueryResponses: any) => ({
  docs: runQueryResponses.map(({ document }: any) => ({
    // TODO (Thistle Lin): Assume id is the basename of the name, this might break in the future
    id: document.name.split("/").at(-1),
    data: () => document
  })),
  size: runQueryResponses.length
});

export const getExDocs = async (query: Query<DocumentData>) => {
  const exQuery = firestoreQueryToEx(query);

  const { data } = await new QueryApi({
    basePath: process.env.NEXT_PUBLIC_API
  }).runQuery(exQuery);

  return toFirestoreSnap(data.data);
};
