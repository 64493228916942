export default {
  common: {
    "last seen {{time}}": "last seen {{time}}",
    "Add another home and pet photo": "Add another home and pet photo",
    "Only pet parents and the sitter can transfer an order!":
      "Only pet parents and the sitter can transfer an order!",
    "Add another pet bathing photo": "Add another pet bathing photo",
    "Add another pet grooming photo": "Add another pet grooming photo",
    "Add another pet service photo": "Add another pet service photo",
    "Add another pet walking photo": "Add another pet walking photo",
    "Add more service photos": "Add more service photos",
    "Cover photo": "Cover photo",
    "Select a pet bath work photo": "Select a pet bath work photo",
    "Select a pet grooming work photo": "Select a pet grooming work photo",
    "Select a photo with the pet for a walk":
      "Select a photo with the pet for a walk",
    "Select the home environment photo": "Select the home environment photo",
    "Select the photo with the pet": "Select the photo with the pet",
    "Service photos are the first impression your guests have of your service, so it’s important to choose a good photo!":
      "Service photos are the first impression your guests have of your service, so it’s important to choose a good photo!",
    "Learn how to choose good service photos.":
      "Learn how to choose good service photos.",
    "Transfer Booking to Another Sitter": "Transfer Booking to Another Sitter",
    "Fluv needs your permission to enable notification.": "Fluv needs your permission to enable notification.",
    "Verify": "Verify",
    "Verified": "Verified",
    "Send verification code": "Send verification code",
    "Resend verification code": "Resend verification code",
    "The verification code has been sent to {{phoneNumber}}. If you don't received the verification code, please ensure the phone number is correct, and press 'Resend' after {{time}} seconds.": "The verification code has been sent to {{phoneNumber}}. If you don't received the verification code, please ensure the phone number is correct, and press 'Resend' after {{time}} seconds.",
    "The verification code entered is incorrect. Please enter it again!": "The verification code entered is incorrect. Please enter it again!",
    "If you don't receive the verification code, please ensure your phone number is correct.": "If you don't receive the verification code, please ensure your phone number is correct.",
    "Please verify your phone number。": "請驗證電話號碼。",
    slogan1: "讓每一個毛小孩都被愛",
    slogan2: "毛小孩有第二個家人愛他",
    sign_in: "登入",
    sign_up: "註冊",
    sign_out: "登出",
    confirm_sign_out: "確定要登出？",
    settings: "設定",
    contact_us: "聯絡我們",
    yes: "確定",
    cancel: "取消",
    search: "搜尋",
    create: "建立",
    update: "更新",
    add: "新增",
    edit: "編輯",
    next: "下一步",
    done: "完成",
    select: "選擇",
    clear: "清除",
    delete: "刪除",
    reserve: "預訂",
    age: "{{age}} 歲",
    minutes: "{{minutes}} 分鐘",
    times: "{{times}} 次",
    i_subject: "我",
    you_subject: "您",
    processing: "處理中",
    copied: "已複製",
    show_more: "顯示更多",
    show_less: "顯示較少",
    close: "關閉",
    ignore: "略過",
    understood: "我瞭解了",
    continue: "繼續",
    i_am_good: "不用了",
    share: "分享",
    agree: "同意",
    nanny_sitter: "附近保姆",
    displayName: "使用者名稱",
    email: "Email",
    password: "密碼",
    nearby_sitter: "附近{{serviceType}}保姆",
    account_issue: "我的帳戶資料遺失",
    account_issue_alert:
      "請立即至Facebook Email 的電子信箱收取驗證信並完成驗證步驟。",
    account_issue_message:
      "My account is lost, please help me recover my account, thank you support team.",
  },
  sign_in: {
    title: "登入/註冊",
    slogan1: "當你需要時",
    slogan2: "毛小孩有第二個家人愛他",
    creating_profile: "建立個人檔案...",
    agree_tou_and_privacy:
      "如果您按下以上任一登入按鈕，但並不是『毛小愛』的使用者，我們會為你註冊並認定你同意『毛小愛』的 <0>使用條款</0> 以及 <1>隱私權政策</1>。",
    sign_in_with_facebook: "使用 Facebook 登入",
    sign_in_with_apple: "使用 Apple 登入",
    sign_in_with_apple_alert_title: "您無法在其他平台使用 Apple 登入",
    sign_in_with_apple_alert_message:
      "毛小愛 Android 與 Web 版目前尚不支援『使用 Apple 登入』功能\n這意味著您之後將無法使用相同帳號在 Android 或 Web 版登入，您確定要使用 Apple 登入毛小愛嗎？",
    have_an_account_yet: "還沒有帳號嗎？",
    i_have_an_account: "已有帳號嗎？",
    error_sign_in: "登入失敗，請確認帳號密碼無誤",
    typeing_email: "請輸入Email",
    typeing_password: "請輸入Password",
    typeing_display_name: "請輸入您的使用者名稱",
  },
  tabs: {
    home: "Home",
    chat: "Messenger",
    reserve: "Reserve",
    order: "Order",
    calendar: "Calendar",
    profile: "Profile",
  },
  search: {
    title: "Search",
    search_nearby: "目前離你最近的寵物保姆",
    distance_km: " 公里",
    cancel: "Cancel",
    type_care_dog: "到府狗保姆",
    type_care_cat: "到府貓保姆",
    type_walking: "陪伴散步",
    type_care_baby_dog: "幼犬照顧",
    type_care_baby_cat: "幼貓照顧",
    type_pet_behavior: "行為訓練",
    type_care_old_dog: "老犬照顧",
    type_OVERNIGHT: "隔夜寄宿",
    type_DAYCARE: "日托照顧",
    type_DROPIN: "到府照顧",
    type_WALKING: "陪伴散步",
    type_BATHING: "到府洗澡",
    type_GROOMING: "到府美容",
    type_TAVERN: "毛孩食宿",
    type_VET_TO_HOME: "到府獸醫",
    type_VET_HOSPITAL: "動物醫院",
    help: "小幫手",
    more: "了解更多",
    address_setting: "請設定地址",
    address_input: "請輸入地址",
    address_warn: "⚠ 輸入完請選擇您所在區域的大概位置",
    select_pets: "選擇寵物",
    select_dates: "選擇日期",
    add_more_pets: "新增更多寵物",
    dates: "日期",
    place_tips_to_provider:
      "請選擇您的服務地點，或是能與寵物飼主見面接毛小孩的地點。",
    place_tips_to_consumer:
      "請選擇您的住處，或是對您交通最便利、最接近您主要服務區域的地點，我們會以該地點為中心，盡可能安排能讓您快速抵達客戶住處的訂單給您。",
  },
  enum: {
    currency: "$",
    top_sitter: "TOP 寵物保姆",
    area: "地區：東京都新宿區",
    lowest_price: "最低價格",
    hightest_price: "最高價格",
    hightest_rating: "最高評分",
    popularity: "最高評價",
    round_distance: "距離最近",
    apply: "應用",
    filter: "篩選",
    reserve_now: "Reserve Now",
    rating: "評論",
    message: "聯絡保姆",
    feedback: "回覆率",
    quick_reserve: "查看報價",
    about_me: "關於我",
    other_service: "其他服務",
    service_photo: "服務照片",
    show_more: "查看更多",
    show_less: "查看較少",
    service: "服務",
    desceription: "詳細資訊",
    reviews: "評論",
    ratings: "則評論",
    continue: "繼續",
    cancel: "取消",
    done: "完成",
    setting: "設定",
    save: "保存",
    select: "選擇",
    clear: "清除",
    sign_out: "登出",

    // calendar
    reserved: "有被預約",
    unreserve: "未預約",
    noreserve: "無法預約",
    full_reserve: "預約已滿",

    // month
    month: "月",
    // types
    type_care_dog: "狗保姆",
    type_care_cat: "貓保姆",
    type_walking: "陪伴散步",
    type_care_baby_dog: "幼犬保姆",
    type_care_baby_cat: "幼貓保姆",
    type_pet_behavior: "行為訓練",
    type_care_old_dog: "老犬保姆",
    more: "了解更多",
    // credit card
    credit_card: "信用卡",
    mobile_wallet: "手機錢包",
    // Visit to the depositary
    visit_to_the_depositary: "預約家訪",
    month_1: "一月",
    month_2: "二月",
    month_3: "三月",
    month_4: "四月",
    month_5: "五月",
    month_6: "六月",
    month_7: "七月",
    month_8: "八月",
    month_9: "九月",
    month_10: "十月",
    month_11: "十一月",
    month_12: "十二月",
    day_of_week_MONDAY: "星期一",
    day_of_week_TUESDAY: "星期二",
    day_of_week_WEDNESDAY: "星期三",
    day_of_week_THURSDAY: "星期四",
    day_of_week_FRIDAY: "星期五",
    day_of_week_SATURDAY: "星期六",
    day_of_week_SUNDAY: "星期日",
    day_of_week_short_MONDAY: "一",
    day_of_week_short_TUESDAY: "二",
    day_of_week_short_WEDNESDAY: "三",
    day_of_week_short_THURSDAY: "四",
    day_of_week_short_FRIDAY: "五",
    day_of_week_short_SATURDAY: "六",
    day_of_week_short_SUNDAY: "日",
    type_unit_OVERNIGHT: " / 晚",
    type_unit_DAYCARE: " / 天",
    type_unit_DROPIN: " / 次",
    type_unit_WALKING: " / 次",
    type_unit_BATHING: " / 次",
    type_unit_GROOMING: " / 次",
    type_unit_TAVERN: " / 半天(12H)",
    type_unit_VET_TO_HOME: " / 次",
    type_unit_VET_HOSPITAL: " / 次",
    age: "{{age}} 歲",
    minutes: "{{minutes}} 分鐘",
    times: "{{times}} 次",
    pet_personality_ENTHUSIASM: "熱情",
    pet_personality_FRIENDLY: "友善",
    pet_personality_SMART: "聰明",
    pet_personality_ENERGETIC: "活潑",
    pet_personality_QUIET: "文靜",
    pet_personality_SHY: "害羞",
    pet_personality_NEEDY: "黏人",
    pet_personality_TIMID: "膽小",
    pet_personality_BRAVE: "勇敢",
    pet_personality_GLUTTONOUS: "貪吃",
    pet_personality_SENSITIVE: "敏感",
    pet_personality_STUBBORN: "固執",
    pet_personality_NAUGHTY: "頑皮",
    type_OVERNIGHT: "隔夜寄宿",
    type_DAYCARE: "日托照顧",
    type_DROPIN: "到府照顧",
    type_WALKING: "陪伴散步",
    type_BATHING: "到府洗澡",
    type_GROOMING: "到府美容",
    type_TAVERN: "安親寄宿",
    discount_5: "95 折",
    discount_10: "9 折",
    discount_15: "85 折",
    discount_20: "8 折",
    discount_25: "75 折",
    discount_30: "7 折",
    price_placeholder: "請輸入價格",
    price_level: "價格等級",
    select_type: "選擇服務類型",
    select_price_level: "選擇價格等級",
    select_available_days: "選擇可服務時間",
    select_place: "選擇地點",
    select_pictures: "選擇照片",
    add_more_pictures: "增加更多照片",
    select_pet_categories: "選擇寵物類別",
    select_pet_sizes: "選擇寵物尺寸",
    uploading_pictures: "正在上傳照片...",
    updating_service: "正在更新服務...",
    must_fill_all_fields: "您必須填寫所有欄位",
    price_level_LV1: "小資",
    price_level_LV2: "名流",
    price_level_LV3: "尊寵",
    status_WAITING: "等待審核",
    status_PROCESSING: "審核中",
    status_ACCEPTED: "審核通過",
    status_REJECTED: "已拒絕",
    status_COMPLETED: "已完成",
    certificate_type_PCRC: "警察刑事紀錄證明（良民證）",
    certificate_type_short_PCRC: "良民證",
    certificate_type_BUSINESS_PERMIT: "特定寵物業許可證（特寵證）",
    certificate_type_short_BUSINESS_PERMIT: "特寵證",
    certificate_type_VETERINARIAN: "獸醫師證書",
    certificate_type_short_VETERINARIAN: "獸醫師證書",
    certificate_type_VETERINARIAN_PRACTICE: "獸醫師執業執照",
    certificate_type_short_VETERINARIAN_PRACTICE: "獸醫師執業執照",
    certificate_type_VETERINARY_AIDE: "獸醫佐證書",
    certificate_type_short_VETERINARY_AIDE: "獸醫佐證書",
    certificate_type_PET_GROOMER: "美容師執照",
    certificate_type_short_PET_GROOMER: "美容師執照",
    certificate_category_ROC_C: "寵物美容師國家丙級證照",
    certificate_category_KCT_C: "KCT C 級",
    certificate_category_KCT_B: "KCT B 級",
    certificate_category_KCT_A: "KCT A 級",
    certificate_category_KCT_AS: "KCT AS 教師級",
    certificate_category_PGA_C: "PGA C 級",
    certificate_category_PGA_B: "PGA B 級",
    certificate_category_PGA_A: "PGA A 級",
    certificate_category_TGA_C: "TGA C 級",
    certificate_category_TGA_B: "TGA B 級",
    certificate_category_TGA_A: "TGA A 級",
    certificate_category_TGA_AA: "TGA 教士級",
    certificate_category_TGA_AAA: "TGA 教師級",
    certificate_category_TGA_AAAA: "TGA 師範級",
    certificate_category_OTHERS: "其他",
    certificate_type_PET_CARER_LICENSE: "寵物照護員證照",
    certificate_type_short_PET_CARER_LICENSE: "寵物照護員證照",
    certificate_type_PET_TRAINER: "寵物訓練師證照",
    certificate_type_short_PET_TRAINER: "寵物訓練師證照",
    certificate_type_SUPER_SITTER: "獨家保姆",
    certificate_type_short_SUPER_SITTER: "獨家保姆",
    certificate_type_VET_ASSISTANT: "獸醫助理經驗",
    certificate_type_short_VET_ASSISTANT: "獸醫助理經驗",
    certificate_type_PET_SITTER: "寵物保姆認證",
    certificate_type_short_PET_SITTER: "寵物保姆認證",
    certificate_category_STANDARD: "基礎認證",
    certificate_category_ADVANCED: "進階認證",
  },
  license: {
    trimmer: "修剪師",
    identity_verified: "身份已驗證",
    domestic_dog_trainer: "家養犬訓練師",
    animal_assisted_welfare_worker: "動物輔助福利工作者",
    certificate_type_PCRC: "警察刑事紀錄證明（良民證）",
    certificate_type_short_PCRC: "良民證",
    certificate_type_BUSINESS_PERMIT: "特定寵物業許可證（特寵證）",
    certificate_type_short_BUSINESS_PERMIT: "特寵證",
    certificate_type_VETERINARIAN: "獸醫師證書",
    certificate_type_short_VETERINARIAN: "獸醫師證書",
    certificate_type_VETERINARIAN_PRACTICE: "獸醫師執業執照",
    certificate_type_short_VETERINARIAN_PRACTICE: "獸醫師執業執照",
    certificate_type_VETERINARY_AIDE: "獸醫佐證書",
    certificate_type_short_VETERINARY_AIDE: "獸醫佐證書",
    certificate_type_PET_GROOMER: "美容師執照",
    certificate_type_short_PET_GROOMER: "美容師執照",
    certificate_category_ROC_C: "寵物美容師國家丙級證照",
    certificate_category_KCT_C: "KCT C 級",
    certificate_category_KCT_B: "KCT B 級",
    certificate_category_KCT_A: "KCT A 級",
    certificate_category_KCT_AS: "KCT AS 教師級",
    certificate_category_PGA_C: "PGA C 級",
    certificate_category_PGA_B: "PGA B 級",
    certificate_category_PGA_A: "PGA A 級",
    certificate_category_TGA_C: "TGA C 級",
    certificate_category_TGA_B: "TGA B 級",
    certificate_category_TGA_A: "TGA A 級",
    certificate_category_TGA_AA: "TGA 教士級",
    certificate_category_TGA_AAA: "TGA 教師級",
    certificate_category_TGA_AAAA: "TGA 師範級",
    certificate_category_OTHERS: "其他",
    discount_5: "95 折",
    discount_10: "9 折",
    discount_15: "85 折",
    discount_20: "8 折",
    discount_25: "75 折",
    discount_30: "7 折",
  },
  campaign: {
    copy_promo_code: "複製優惠碼",
    go_to_campaign: "前往活動頁面",
    all_campaign_title: "好評再加碼！開幕到府洗澡限量活動",
    all_campaign_description:
      "即日起至 12/05 23:59 前凡下訂紅色標示之指定美容師，可享用限量 100 元寵物到府洗澡乙次！",
    c20191115_free_week_label: "特惠價 100 元!!",
    c20191115_free_week_content:
      "即日起至 12/05 23:59 前凡下訂紅色標示之指定美容師，可享用限量 100 元寵物到府洗澡乙次！\n\n1. 此活動只可搭配一位美容師提供一次到府洗澡 100 元服務，使用後會恢復為原價\n2. 僅限一隻寵物一個時段\n3. 毛小愛保有隨時修改及終止本活動之權利",
  },
  page: {
    sitter_detail: "保姆細節",
    service_detail: "服務細節",
    preview_reserve: "預約細節",
    payment_method: "付款方式",
    reserve_detail: "預約",
  },
  preview_reserve: {
    pets: "寵物",
    start_date: "開始時間",
    end_date: "結束時間",
    times_per_day: "每天次數",
    sub_total: "小計",
    dates_number: "天數",
    times_number: "次數",
    service_length: "服務時間",
    service_length_30mins: "服務時間（每 30 分鐘）",
    address: "地址",
    notes: "備註",
  },
  reserve: {
    current_reserve: "當前預約",
    history_reserve: "歷史預約",
    no_current_reserve: "尚無預約",
    no_history_reserve: "尚無歷史預約",
  },
  profile: {
    edit: "編輯個人檔案",
    sitter_profile: "保姆中心",
    my_sitter_profile: "我的保姆中心",
    pets: "Pets",
    coupon: "Coupon",
    support: "聯絡客服",
    common_problem: "常見問題",
    invite_friends: "邀請朋友",
    setting: "Setting",
    select_cover: "選擇封面",
    select_avatar: "選擇你與你毛小孩的合照",
    display_name: "顯示名稱",
    display_name_placeholder: "輸入顯示名稱",
    description: "自我介紹",
    description_placeholder: "輸入你的寵物照顧經驗和愛毛小孩的心",
    contact_info: "聯絡資訊",
    emergency_contact_info_tips:
      "Please correct your contact information and verify your phone number. This information will be used in case of emergencies",
  },
  messenger: {
    type_message: "輸入訊息",
  },
  pets: {
    title: "我的寵物",
    health_status: "健康狀況描述",
    update_pet: "更新毛小孩檔案",
    add_pet: "新增您的毛小孩",
    select_avatar: "選擇毛小孩的大頭照",
    name: "毛小孩名字",
    name_placeholder: "毛毛、球球",
    health_status_placeholder: "請輸入毛小孩的健康狀況以及照顧時需要注意的事項",
    basic_information: "基本資料",
    category: "種類",
    select_category: "選擇種類",
    size: "尺寸",
    select_size: "選擇尺寸",
    birth_year: "出生年",
    select_birth_year: "選擇出生年",
    birth_month: "出生月（選填）",
    select_birth_month: "選擇出生月",
    gender: "性別",
    select_gender: "選擇性別",
    detail_information: "詳細資料",
    neutered_or_spayed: "是否結紮",
    select_neutered_or_spayed: "選擇是否結紮",
    regular_vaccination: "是否規律施打疫苗",
    select_regular_vaccination: "選擇是否規律施打疫苗",
    personalities: "個性（複選）",
    select_personalities: "選擇個性（複選）",
    category_DOG: "犬",
    category_CAT: "貓",
    emoji_category_DOG: "🐶",
    emoji_category_CAT: "🐱",
    pet_size_TINY: "迷你",
    pet_size_tip_TINY: "小於 5 公斤",
    pet_size_SMALL: "小型",
    pet_size_tip_SMALL: "5 ~ 10 公斤",
    pet_size_MEDIUM: "中型",
    pet_size_tip_MEDIUM: "10 ~ 20 公斤",
    pet_size_LARGE: "大型",
    pet_size_tip_LARGE: "20 ~ 40 公斤",
    pet_size_HUGE: "超大型",
    pet_size_tip_HUGE: "大於 40 公斤",
    pet_gender_MALE: "公",
    pet_gender_FEMALE: "母",
    pet_neutered_or_spayed_true: "已結紮",
    pet_neutered_or_spayed_false: "未結紮",
    pet_regular_vaccination_true: "有規律施打疫苗",
    pet_regular_vaccination_false: "沒有規律施打疫苗",
    must_fill_all_fields: "您必須填寫所有欄位",
  },
  sitter_profile: {
    join_time: "加入時間",
    rating_and_reviews: "則評分",
    service_list: "服務列表",
    add_service: "新增服務",
    my_service_photo: "服務照片",
    upload_image: "上傳照片",
    edit: "編輯",
    account_balance: "帳戶餘額",
    wallet: "錢包",
    tutorials: 'Tutorials',
    calendar: "行事曆",
    // 台灣帳戶
    bank_code: "銀行代碼",
    bank_code_placeholder: "013",
    bank_branch_code: "銀行分行代碼",
    bank_branch_code_placeholder: "0073",
    bank_account: "銀行帳號",
    bank_account_placeholder: "01234567890",
  },
  service_detail: {
    description: "服務介紹",
    available_days: "可服務時間",
    acceptable_pets: "可接受寵物",
    acceptable_sizes: "可接受體型",
    acceptable_areas: "可服務地區",
    acceptable_place: "可服務地點",
  },
  order: {
    pets: "寵物",
    request_info:
      "預約請求是免費的，在確認付款前您可以與對方討論服務細節，可以隨時取消預約請求",
    suggestion:
      "您可以再送出多個預約請求給其他人選，可以更快找到最適合您的服務",
    request_to_reserve: "預約請求",
    location: "地點",
    service_length: "服務時間",
    service_length_30mins: "服務時間（每 30 分鐘）",
    times_per_day: "每天次數",
    start_date: "開始日期",
    end_date: "結束日期",
    start_time: "到達時間",
    end_time: "離開時間",
    select_start_date: "選擇到達日期",
    select_end_date: "選擇離開日期",
    select_start_time: "選擇到達時間",
    select_end_time: "選擇離開時間",
    notes: "備註",
    notes_placeholder: "輸入備註或是您毛小孩的醫療需求與症狀",
    annotation_TAVERN:
      "免費提供毛小孩安親及寄宿服務，毛爸媽只需負擔平台媒合費用。",
    price_base: "基本價格",
    registration_fee: "掛號費",
    sub_total: "小計",
    dates_number: "天數",
    times_number: "次數",
    meals_number: "次數（每半天）",
    room: "住宿",
    free: "免費",
    insurance: "寵物險（最高兩百萬）",
    coupon_discount: "優惠券折扣",
    order_detail: "訂單詳情",
    confirm_cancel_title: "確認取消",
    confirm_cancel_message: "確定要取消這筆訂單嗎？",
    paid: "已付款等待服務",
    executed: "服務完成等待確認",
    checked: "已確認完成",
    settled: "已匯款",
    cancelled: "已取消",
    refunded: "已退款",
    payment: "付款",
    pay_now: "立即付款",
    execute: "完成服務",
    check: "確認已完成",
    waiting_for_acceptance: "等待對方接受",
    waiting_for_payment: "等待飼主付款",
    i_am_provider: "我的客戶",
    i_am_consumer: "我的訂單",
    history_i_am_consumer: "歷史訂單",
    history_i_am_provider: "歷史客戶",
    accept_order: "接受訂單",
    reject_order: "拒絕",
    cancel_order: "取消訂單",
    createing_order: "正在建立訂單...",
    must_fill_all_fields: "您必須填寫所有欄位",
    quality_assurance: "100% 品質保證，服務結束後三天內不滿意可全額退款",
    coupon: "優惠碼",
    order_breakdown_confirm: "確認訂單明細",
    vet_charge_reminder: "其餘診療費用會由醫師現場收取現金",
    confirm_pay: "確認付款",
    no_current_reserve: "尚無預約",
    too_many_pets: "每五隻寵物需至少 30 分鐘，讓保姆有足夠時間照顧您的毛小孩",
    unavailable: "無法預約",
    unavailable_days:
      "您選擇的時間包含了對方的休息日，請您透過訊息確認是否可以空出時間",
    price_extra_size: "{{size}}{{category}}費用",
    show_cancelled_orders: "顯示已取消訂單",
    update_order_amount: "更新訂單價格",
    update_order_amount_fine_print:
      "您可以透過調整價格為客人客製訂單總金額，為保障服務權益，⚠️請注意：價格調整不可低於原始價格50%、單筆訂單金額最高上限為5萬元。",
    original_amount: "原始價格",
    changed_amount: "調整價格",
    error_update_order_amount: "Error\n系統偵測異常，請稍後嘗試。",
    change_amount: "修改價格",
  },
  review: {
    review: "評分",
    add_review: "給予評分",
    leave_your_review: "請留下您給對方的評論（選填）",
    submit: "送出",
    add_referral: "給予推薦",
    createing_review: "正在新增評論...",
    dont_leave_review: "不留下您的評論嗎？",
    next_time: "下次再提醒我",
    give_up: "放棄評論",
    already_add_referral_alert_title: "您已經留過推薦囉！",
  },
  coupon: {
    referral_title: "推薦計畫首次使用回饋",
    referrer_title: "推薦人獎勵",
    price_discount: "$ {{discount}} 元折扣優惠",
    percentage_discount_5: "95 折優惠",
    percentage_discount_10: "9 折優惠",
    percentage_discount_15: "85 折優惠",
    percentage_discount_20: "8 折優惠",
    percentage_discount_25: "75 折優惠",
    percentage_discount_30: "7 折優惠",
    limited_services: "僅可使用於",
    expired_at: "使用期限：",
    no_expired: "無使用期限",
    select_coupon: "選擇優惠券",
    enter_coupon_code: "輸入優惠券代碼",
    discount_is_used: "此優惠碼已使用過",
    discount_5: "95 折",
    discount_10: "9 折",
    discount_15: "85 折",
    discount_20: "8 折",
    discount_25: "75 折",
    discount_30: "7 折",
    price_placeholder: "請輸入價格",
    price_level: "價格等級",
    select_type: "選擇服務類型",
    select_price_level: "選擇價格等級",
    select_available_days: "選擇可服務時間",
    select_place: "選擇地點",
    select_pictures: "選擇照片",
    add_more_pictures: "增加更多照片",
    select_pet_categories: "選擇寵物類別",
    select_pet_sizes: "選擇寵物尺寸",
    uploading_pictures: "正在上傳照片...",
    updating_service: "正在更新服務...",
    must_fill_all_fields: "您必須填寫所有欄位",
  },
  // from server
  order_notification: {
    new_order_title: "新訂單",
    new_order_desc: "您接到了一張新的訂單，請盡快回覆",
    accepted_order_title: "訂單已被接受",
    accepted_order_desc:
      "{{senderName}}已接受了您的訂單，請盡速付款！付款前都不算正式預訂，也不會安排您的服務時間，隨時可能被其他先付款的人預約",
    paid_order_title: "訂單付款完成",
    paid_order_desc: "{{senderName}}已付款完成，記得查看您的訂單",
    executed_order_title: "訂單已完成",
    executed_order_desc:
      "{{senderName}}已完成您訂購的服務，請盡快確認並留下您的評價",
    checked_order_title: "訂單結果已確認",
    checked_order_desc: "{{senderName}}已確認您提供的服務並給予您評價",
    cancelled_order_title: "訂單已被取消",
    cancelled_order_desc: "{{senderName}}已取消該訂單",
    cancelled_order_by_server_desc: "系統已取消該訂單",
  },
  chat: {
    system_sent: "(系統自動發送)",
    download_app: "下載App可以更快的收到保姆的訊息",
  },
  menu: {
    download_app: "下載 App",
    signin_out: "登入/註冊",
    login_out: "登出",
    order: "我的訂單",
    my_pet: "我的寵物",
    common_problem: "常見問題",
    history_order: "歷史訂單",
    sitter_apply: "我要照顧毛孩",
    edit_profile: "編輯個人檔案",
    my_chat: "我的訊息",
  },
  service: {
    type: "服務類別",
    select_type: "選擇服務",
    select_day_of_week: "請選擇星期幾有空（複選）",
    available_days: "可服務時間",
    title_and_description: "服務介紹",
    title: "服務標題",
    title_placeholder: "標題，例如「妮妮專業美容～五年美容經驗、提供洗毛精」",
    description: "詳細服務內容",
    description_placeholder:
      "詳細描述你的服務內容，例如「提供剪毛、按摩、洗澡、剪指甲服務」",
    place_info: "服務地點資訊",
    areas: "服務區域",
    select_areas: "選擇區域",
    place: "服務地點",
    select_place: "請選擇您的服務地點",
    place_tips_to_provider:
      "請選擇您的服務地點，或是能與寵物飼主見面接毛小孩的地點。",
    place_tips_to_consumer:
      "請選擇您的住處，或是對您交通最便利、最接近您主要服務區域的地點，我們會以該地點為中心，盡可能安排能讓您快速抵達客戶住處的訂單給您。",
    pictures: "服務照片",
    select_pictures: "選擇照片",
    add_more_pictures: "增加更多照片",
    select_pet_categories: "選擇寵物類別",
    select_pet_sizes: "選擇寵物尺寸",
    acceptable_pets: "可接受寵物",
    price_level: "價格等級",
    unavailable: "無法預約",
  },
  sitter_verifi: {
    title: "保姆帳戶設定",
    verifi_tip: "人工審核需 3~5 個工作天",
    steps: "審查步驟",
    experience_test: "合適度測驗",
    platform_test: "平台使用測驗",
    profile_review: "資料審核",
    salary_account: "設置收款帳號",
    sign_contract: "簽署合約",
    two_referrals: "至少兩位推薦人",
    edit_verifi_detail: "編輯審核資訊",
    identity_front: "身分證正面或護照內頁",
    identity_front_tips:
      "若您持有中華民國身分證，請拍攝身分證正面\n若您為外國人，請拍攝護照內頁",
    identity_back: "身分證反面",
    selfie: "自拍照",
    selfie_tips:
      "自拍照需清晰不模糊，且能清楚辨識為身分證件上照片之同一人，方可通過審核",
    take_identity_front: "拍攝身分證正面或護照內頁",
    take_identity_back: "拍攝身分證反面",
    take_selfie: "拍攝自拍照",
    real_name: "真實姓名",
    real_name_placeholder: "輸入真實姓名",
    phone: "電話",
    phone_placeholder: "0912345678",
    experience: "寵物經驗",
    experience_placeholder: "輸入經驗",
    experience_tips:
      "輸入曾擔任寵物保姆或自己飼養寵物的經驗，告訴我們為什麼你適合擔任寵物保姆",
    birth_date: "生日（西元）",
    birth_date_placeholder: "1990/01/01",
    identity_number: "身分證件號碼",
    identity_number_placeholder: "A123456789",
    identity_number_tips:
      "若您持有中華民國身分證，請輸入您的身分證號碼\n若您為外國人，請輸入您的護照號碼",
    identity_issued_date: "身分證發證日期（民國）",
    identity_issued_date_placeholder: "079/01/01",
    bank_code: "銀行代碼",
    bank_code_placeholder: "013",
    bank_branch_code: "銀行分行代碼",
    bank_branch_code_placeholder: "0073",
    bank_account: "銀行帳號",
    bank_account_placeholder: "01234567890",
    bank_account_alert:
      "您必須填寫您自己擁有之帳戶，帳戶名稱與真實姓名必須符合。\n我們不會對帳戶的真實性進行審核，但您仍然必須正確填寫，否則可能會造成收款失敗，您必須自行承擔可能的損失。",
  },
  certificate: {
    certificate: "專業證照",
    add_certificate: "新增專業證照",
    update_certificate: "更新專業證照",
    pet_care_related_certificates: "寵物照顧相關",
    veterinarian_certificates: "獸醫相關證照",
    other_pet_services_certificates: "其他寵物服務",
    special_type: "特殊類型",
    select_type: "選擇類型",
    select_category: "選擇詳細分類",
    select_picture: "選擇證照照片",
    take_picture: "拍攝證明照片",
    type: "類型",
    category: "詳細分類",
    confirm_delete_title: "確認刪除",
    confirm_delete_message: "確定要刪除這個證照嗎？",
    identity_verified: "身份已驗證",
    must_fill_all_fields: "您必須填寫所有欄位",
    manage_certificates: "管理證照",
    upload_certificates_tip: "上傳您的專業證照可以讓寵物主人更信任您",
  },
};
