import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { TSubscription } from "./types";

export type TAccess = {
  id: string;
  updatedAt: number;
  role?: "admin";
};

const accessRef = collection(db, "accesses");

const getAccessDoc = (userId: string) => {
  return doc(accessRef, userId);
};

export const toAccess = (
  snapshot: DocumentSnapshot<DocumentData>
): TAccess | null => {
  if (!snapshot.data()) return null;

  const { updatedAt, role } = snapshot.data() || {};

  return {
    id: snapshot.id,
    updatedAt: updatedAt?.seconds * 1000,
    role,
  };
};

export const subscribeAccess: TSubscription<{ userId?: string }, TAccess> = ({
  variables,
  onChange,
}) => {
  const { userId } = variables || {};
  if (!userId) return () => {};
  const accessDoc = getAccessDoc(userId);

  return onSnapshot(accessDoc, (snapshot) => {
    if (!onChange) return;
    onChange(toAccess(snapshot));
  });
};
