import {
  Animated,
  Dimensions,
  Platform,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BaseColor, useTheme } from "../../config";
import { SearchItem } from "../../components";
import { useNavigation } from "../../hooks/useNavigation";
import { usePromise } from "../../hooks";
import { useAuth } from "../../context/Auth";
import {
  getServiceSearchResults,
  TLocation,
  TPet,
  TSearchResult,
  TServiceSearchResult,
} from "../../models";
import { PetSize, ServiceType } from "../../enums";
import {
  MainStackScreenNavigationProp,
  TQueryStatus,
} from "../../config/typography";
import { useDiscount } from "../../modules";
import { contains } from "validate.js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { distance } from "../../utils";
import locations from "../../constant/locations.json";
import { join } from "../../utils/URL.v1";
import { ServiceName } from "../../models/Service.v1";
import Link from "next/link";
import { Button } from "../../ui";
import { useTranslation } from "react-i18next";

type Props = {
  isSsr: boolean;
  type: string;
  size: PetSize[];
  category: any[];
  areaCode: string;
  orderBy: string;
  checkLoading: (isLoading: boolean) => void;
  serviceSearchResults: TSearchResult;
  isReferred: boolean;
};

interface Coordinate {
  latitude: number;
  longitude: number;
}

type AreaCode = string;

type Locations = Record<AreaCode, Coordinate>;

const SearchSubScreen = React.memo<Props>((props) => {
  const {
    isSsr,
    type,
    size,
    category,
    areaCode,
    orderBy,
    checkLoading,
    serviceSearchResults,
    isReferred,
  } = props;
  const { getDiscount } = useDiscount();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [refreshing] = useState(false);
  const scrollAnim = new Animated.Value(0);
  const [queryStatus, setQueryStatus] = useState<string>("first");
  const [searchResult, setSearchResult] = useState<TServiceSearchResult[]>([]);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentLocation, setCurrentLocation] = useState<TLocation>();
  const location = (locations as Locations)[areaCode];
  const [isServerSide, setIsServerSide] = useState(true);

  const updatedAtNumber =
    serviceSearchResults.searchResult?.at?.(-1)?.updatedAt ?? null;
  useEffect(() => {
    if (orderBy !== "round_distance" && type !== "8") return;
    AsyncStorage.getItem("currentLocation")
      .then(async (location: string | null) => {
        if (!location) return;
        const [, , latitude, longitude] = location.split("&");
        setCurrentLocation({
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        });
      })
      .catch((e) => {
        // This error only handle the dom.storage.enabled false
        switch (e.message) {
          case "window.localStorage is null":
            console.error("UDR4");
            break;
          default:
            throw e;
        }
      });
  }, [orderBy]);

  useEffect(() => {
    checkLoading(true);
    if (serviceSearchResults === null) return;
    setTimeout(() => {
      let addResult: TServiceSearchResult[] = [];
      if (searchResult.length > 0) {
        addResult = serviceSearchResults.searchResult.filter((newItem) => {
          return (
            searchResult.findIndex((item) => item.id === newItem.id) === -1
          );
        });
      } else {
        addResult = serviceSearchResults.searchResult;
      }

      // console.log('serviceSearch', serviceSearchResults.searchResult);
      // if(queryStatus.split("-")[0] === 'page') {
      //   console.log('searchReasult', searchResult);
      //   setSearchResult((oldSearchResult) => [...oldSearchResult, ...serviceSearchResults.searchResult])
      // } else {
      //   console.log('serviceSearchResults',serviceSearchResults.searchResult)
      // }
      setSearchResult((oldItem) => [...oldItem, ...addResult]);
      checkLoading(false);
    }, 750);
  }, [serviceSearchResults]);

  useEffect(() => {
    setIsServerSide(false);
    setSearchResult([]);
  }, []);

  if (Platform.OS === "web") {
    useEffect(() => {
      // window.addEventListener('scroll', () => {
      //   let sHeight = document.documentElement.scrollHeight;
      //   let cHeight = document.documentElement.clientHeight;
      //   let sTop = document.documentElement.scrollTop;
      //   if(cHeight+sTop === sHeight) {
      //     setPageSize(pageSize+10);
      //     console.log('search sub screen');
      //   }
      // });
      // return () => {
      //   window.removeEventListener('scroll', () => {});
      // }
    });
  }

  const latitude = currentLocation
    ? currentLocation.latitude
    : location.latitude;
  const longitude = currentLocation
    ? currentLocation.longitude
    : location.longitude;

  const sortServiceSearchResults = useMemo(() => {
    if (searchResult.length === 0) return null;

    let distanceFilter = searchResult;
    if (orderBy === "round_distance" || type === "8") {
      if (!currentLocation && !isReferred) return;
      distanceFilter = searchResult.filter(
        (result) =>
          distance(
            latitude,
            longitude,
            result.place.location.latitude,
            result.place.location.longitude,
            "K",
          ) <= 20,
      );
    }
    return distanceFilter.sort((a, b) => {
      const aHightPrice: number = a.priceLevel || 0;
      const bHightPrice: number = b.priceLevel || 0;
      if (orderBy === "popular") {
        return a.profile.totalReviews < b.profile.totalReviews ? 1 : -1;
      } else if (orderBy === "hight_price") {
        return aHightPrice < bHightPrice ? 1 : -1;
      } else if (orderBy === "low_price") {
        return aHightPrice > bHightPrice ? 1 : -1;
      } else {
        const aDistance = distance(
          latitude,
          longitude,
          a.place.location.latitude,
          a.place.location.longitude,
          "K",
        ).toFixed(2);
        const bDistance = distance(
          latitude,
          longitude,
          b.place.location.latitude,
          b.place.location.longitude,
          "K",
        ).toFixed(2);
        return parseFloat(aDistance) > parseFloat(bDistance) ? 1 : -1;
      }
    });
  }, [searchResult, currentLocation, orderBy]);

  const renderItem = useCallback(
    ({ item }) => {
      if (!item) return;
      const { label, basePriceRangeStr, discountedBasePriceRangeStr } =
        getDiscount(item);

      const serviceName = ServiceName.getServiceTypeName(item.type);
      const routePath = join(
        "services",
        ...props.routeParams,
        serviceName,
        item.id,
      );
      return (
        <SearchItem
          isSsr={isSsr}
          type={item.type}
          profile={item.profile}
          providerId={item.providerId}
          pictureId={item.pictureIds[0] ? item.pictureIds[0] : undefined}
          title={item.title}
          location={item.place.address ? item.place.address : undefined}
          place={item.place}
          availableDays={item.availableDays}
          rate={item.profile?.avgRating}
          numReviews={item.profile?.totalReviews}
          tags={item.profile?.certificates}
          label={label}
          basePriceRangeStr={basePriceRangeStr}
          discountedBasePriceRangeStr={discountedBasePriceRangeStr}
          style={{
            marginVertical: 4,
            backgroundColor: BaseColor.whiteColor,
          }}
          onPress={() => {
            navigation.navigate("ServiceDetailScreen", {
              serviceId: item.id,
            });
          }}
          routePath={routePath}
          latitude={latitude}
          longitude={longitude}
        />
      );
    },
    [getDiscount],
  );

  return (
    <>
      {isServerSide ? (
        <>
          <ScrollView>
            {serviceSearchResults.searchResult.map((item) =>
              renderItem({ item }),
            )}
          </ScrollView>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-around",
              paddingHorizontal: 10,
              marginVertical: 30,
            }}
          >
            <Link href={`/services${join(...props.routeParams)}`}>
              <Button>{t("First Page")}</Button>
            </Link>
            <Link
              href={`/services${join(...props.routeParams)}?after=${updatedAtNumber}`}
            >
              <Button>{t("Next Page")}</Button>
            </Link>
          </View>
        </>
      ) : (
        <Animated.FlatList
          refreshControl={
            <RefreshControl
              colors={[colors.primary]}
              tintColor={colors.primary}
              refreshing={refreshing}
              onRefresh={() => {}}
            />
          }
          scrollEventThrottle={1}
          // onEndReached={() => setQueryStatus('next')}
          onScroll={(e) => {
            Animated.event(
              [
                {
                  nativeEvent: {
                    contentOffset: {
                      y: scrollAnim,
                    },
                  },
                },
              ],
              { useNativeDriver: true },
            );
            // const sHeight = e.nativeEvent.contentSize.height;
            // const lHeight = e.nativeEvent.layoutMeasurement.height;
            // const cHeight = e.nativeEvent.contentOffset.y;
            // if((sHeight-lHeight) === cHeight) {
            //   console.log('ios next')
            //   setPageSize(pageSize+10);
            // }
          }}
          data={
            sortServiceSearchResults
              ? sortServiceSearchResults
              : serviceSearchResults.searchResult
          }
          key={"list"}
          keyExtractor={(item) =>
            item ? `${item.id}+${item.profile?.id}` : ""
          }
          renderItem={renderItem}
        />
      )}
    </>
  );
});

export default SearchSubScreen;

const styles = StyleSheet.create({});
