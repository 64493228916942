import { t } from "i18next";
import React from "react";
import { View, StyleSheet } from "react-native";
import { Icon } from "../components";
import { Text } from "../ui";
import { BaseColor, useTheme } from "../config";

export default function RateDetail(props: any) {
  const { colors } = useTheme();
  const { style, point, totalReviews, reviews } = props;
  let data = [];
  for (var i = 1; i <= 5; i++) {
    const number =
      ((reviews?.payload.filter((review: any) => review.rating === i).length ||
        0) /
        (totalReviews || 0)) *
      100;
    data.push(`${number.toFixed(0)}%`);
  }
  return (
    <View style={[styles.contain, style]}>
      <View style={styles.contentLeft}>
        <Text primaryColor style={{ fontSize: 48 }}>
          {point}
        </Text>
      </View>
      <View style={styles.containRight}>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.starLeft}>
            <View style={styles.lineStar}>
              {[1, 2, 3, 4, 5].map((icon, index) => {
                return (
                  <Icon
                    key={`star5` + index}
                    name="star"
                    color={BaseColor.yellowColor}
                    solid
                    size={14}
                    style={{ marginLeft: 2 }}
                  />
                );
              })}
            </View>
            <View style={styles.lineStar}>
              {[1, 2, 3, 4].map((icon, index) => {
                return (
                  <Icon
                    key={`star4` + index}
                    name="star"
                    color={BaseColor.yellowColor}
                    solid
                    size={14}
                    style={{ marginLeft: 2 }}
                  />
                );
              })}
            </View>
            <View style={styles.lineStar}>
              {[1, 2, 3].map((icon, index) => {
                return (
                  <Icon
                    key={`star3` + index}
                    name="star"
                    color={BaseColor.yellowColor}
                    solid
                    size={14}
                    style={{ marginLeft: 2 }}
                  />
                );
              })}
            </View>
            <View style={styles.lineStar}>
              {[1, 2].map((icon, index) => {
                return (
                  <Icon
                    key={`star2` + index}
                    name="star"
                    color={BaseColor.yellowColor}
                    solid
                    size={14}
                    style={{ marginLeft: 2 }}
                  />
                );
              })}
            </View>
            <View style={styles.lineStar}>
              <Icon name="star" color={BaseColor.yellowColor} solid size={14} />
            </View>
          </View>
          <View style={styles.containStatus}>
            {data.reverse().map((percent: any, index: any) => {
              return (
                <View style={styles.contentLineStatus} key={"status" + index}>
                  <View style={styles.lineStatusGray} />
                  <View
                    style={[
                      styles.lineStatusPrimary,
                      { width: percent, backgroundColor: colors.primary },
                    ]}
                  />
                </View>
              );
            })}
          </View>
        </View>
        <Text body2 semibold style={{ marginTop: 5 }}>
          {totalReviews} {t("enum:ratings")}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  contain: {
    flexDirection: "row",
    width: "100%",
    height: 150,
    marginTop: 5,
    paddingHorizontal: 20,
  },
  contentLeft: {
    flex: 3.5,
    justifyContent: "center",
    alignItems: "center",
  },
  containRight: {
    flex: 6.5,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  starLeft: {
    flex: 3.5,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  lineStar: {
    height: 10,
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 3,
  },
  containStatus: { flex: 6.5, justifyContent: "center" },
  contentLineStatus: {
    height: 15,
    justifyContent: "center",
    marginLeft: 10,
  },
  lineStatusGray: {
    height: 3,
    width: "100%",
    borderRadius: 1.5,
    backgroundColor: BaseColor.fieldColor,
  },
  lineStatusPrimary: {
    height: 3,
    width: "10%",
    borderTopLeftRadius: 1.5,
    borderBottomLeftRadius: 1.5,
    position: "absolute",
  },
});
