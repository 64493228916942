/* tslint:disable */
/* eslint-disable */
/**
 * fluv global
 * This API spec is for fluv global.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: engineer@ifluvyou.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorResponse } from '../models';
import { PhoneVerificationResponse } from '../models';
import { PhoneverificationsBody } from '../models';
import { SuccessResponse } from '../models';
/**
 * PhoneVerificationApi - axios parameter creator
 * @export
 */
export const PhoneVerificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint retrieve the phone verification document depends on the user Id and phone number
         * @summary Get phone verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoneVerification: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/phone-verifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint either creates a verification or verifies a code, depending on the provided request body content.
         * @summary Handle phone verification
         * @param {PhoneverificationsBody} [body] Request to either create or verify phone verification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handlePhoneVerification: async (body?: PhoneverificationsBody, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/phone-verifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhoneVerificationApi - functional programming interface
 * @export
 */
export const PhoneVerificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * This endpoint retrieve the phone verification document depends on the user Id and phone number
         * @summary Get phone verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhoneVerification(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneVerificationResponse>> {
            const localVarAxiosArgs = await PhoneVerificationApiAxiosParamCreator(configuration).getPhoneVerification(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint either creates a verification or verifies a code, depending on the provided request body content.
         * @summary Handle phone verification
         * @param {PhoneverificationsBody} [body] Request to either create or verify phone verification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handlePhoneVerification(body?: PhoneverificationsBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await PhoneVerificationApiAxiosParamCreator(configuration).handlePhoneVerification(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PhoneVerificationApi - factory interface
 * @export
 */
export const PhoneVerificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * This endpoint retrieve the phone verification document depends on the user Id and phone number
         * @summary Get phone verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoneVerification(options?: any): AxiosPromise<PhoneVerificationResponse> {
            return PhoneVerificationApiFp(configuration).getPhoneVerification(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint either creates a verification or verifies a code, depending on the provided request body content.
         * @summary Handle phone verification
         * @param {PhoneverificationsBody} [body] Request to either create or verify phone verification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handlePhoneVerification(body?: PhoneverificationsBody, options?: any): AxiosPromise<SuccessResponse> {
            return PhoneVerificationApiFp(configuration).handlePhoneVerification(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PhoneVerificationApi - object-oriented interface
 * @export
 * @class PhoneVerificationApi
 * @extends {BaseAPI}
 */
export class PhoneVerificationApi extends BaseAPI {
    /**
     * This endpoint retrieve the phone verification document depends on the user Id and phone number
     * @summary Get phone verification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhoneVerificationApi
     */
    public getPhoneVerification(options?: any) {
        return PhoneVerificationApiFp(this.configuration).getPhoneVerification(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint either creates a verification or verifies a code, depending on the provided request body content.
     * @summary Handle phone verification
     * @param {PhoneverificationsBody} [body] Request to either create or verify phone verification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhoneVerificationApi
     */
    public handlePhoneVerification(body?: PhoneverificationsBody, options?: any) {
        return PhoneVerificationApiFp(this.configuration).handlePhoneVerification(body, options).then((request) => request(this.axios, this.basePath));
    }
}
