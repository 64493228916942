import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { TProfile } from "./profile";

export type TBrandManger = {
  id: string;
  active: boolean;
};

export type TBrand = {
  id: string;
  createdAt: number;
  updatedAt: number;
  ownerId: string;
  managers: TBrandManger[];
  orderedServiceIds: string[];
};

export type TBrandInput = {
  ownerId?: string;
  managers?: TBrandManger[];
  orderedServiceIds?: string[];
};

export type TBrandDetail = TBrand & {
  profile?: TProfile;
};

export const toBrand = (
  snapshot: DocumentSnapshot<DocumentData>
): TBrand | null => {
  if (!snapshot.data()) return null;

  const {
    createdAt,
    updatedAt,
    ownerId,
    managersMap = {},
    orderedServiceIds = [],
  } = snapshot.data() || {};

  return {
    id: snapshot.id,
    createdAt: createdAt?.seconds * 1000,
    updatedAt: updatedAt?.seconds * 1000,
    ownerId,
    managers: Object.keys(managersMap).map((id) => ({
      id,
      ...managersMap[id],
    })),
    orderedServiceIds,
  };
};

const brandRef = collection(db, "brand");

export const getManagedBrandsCollection = (userId: string) => {
  const brandsQuery = query(
    brandRef,
    where(`managerMap.${userId}.active`, "==", true)
  );
  return brandsQuery;
};

export const getManagedBrands = async (userId: string): Promise<TBrand[]> => {
  const managedBrandsCollection = getManagedBrandsCollection(userId);

  const snapshot = await getDocs(managedBrandsCollection);
  return snapshot.docs.map(toBrand).filter(Boolean) as TBrand[];
};

const getBrandDoc = (userId: string) => {
  return doc(brandRef, userId);
};

export const getBrand = async (userId: string): Promise<TBrand | null> => {
  const brandDoc = getBrandDoc(userId);
  if (!brandDoc) return null;

  const snapshot = await getDoc(brandDoc);
  return toBrand(snapshot);
};
