import React, { useEffect, useState } from "react";
import "./src/config/i18n";
import "./src/config/firebase";
import RootNavigation from "./src/navigation/RootNavigation";
import i18next from "i18next";
import { updateLanguage } from "./src/models/device";
import { Auth } from "./src/context/Auth";
import { Text, View } from "react-native";
import * as Font from "expo-font";
import BackgroundMessage from "./src/helpers/BackgroundMessage";
import NotificationBanner from "./src/components/NotificationBanner";
/*
 * https://github.com/expo/google-fonts/issues/9
 * rm -rf ./node_modules/expo/node_modules/expo-font/
 * Icon not loading
 */
const App = () => {
  useEffect(() => {
    let lang = i18next.language;
    lang = lang.toLowerCase();
    if (
      lang.startsWith("zh") &&
      (lang.includes("hant") || lang.includes("tw"))
    ) {
      lang = "zh-TW";
    } else if (lang.startsWith("en")) {
      lang = "en";
    } else if (lang.startsWith("ja")) {
      lang = "ja";
    }
    updateLanguage(lang);
  }, []);

  return (
    <>
      <Auth>
        <RootNavigation />
        <BackgroundMessage />
        <NotificationBanner />
      </Auth>
    </>
  );
};

export default App;
