import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  RefreshControl,
  StyleSheet,
  Linking,
} from "react-native";
import { BaseStyle, BaseColor, Images, useTheme } from "../../config";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  Header,
  Icon,
  ProfileDescription,
  ProfilePerformance,
  SearchItem,
  RateDetail,
  CommentItem,
  FeatherIcon,
  WalletModal,
} from "../../components";
import { Text } from "../../ui";
import Menu from "../../ui/Menu";
import Service from "../../models/Service.v1";
import { TabView, TabBar } from "react-native-tab-view";

import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { usePromise, useSubscription } from "../../hooks";
import {
  TReview,
  getCurrentUserId,
  getExProfile,
  getProfile,
  getProfilePictureUrl,
  getReview,
  getSitterServiceNumber,
  subscribeProfile,
  subscribeReviews,
  subscribeServices,
  subscribePets,
} from "../../models";
import { useDiscount } from "../../modules";
import { getAuth } from "firebase/auth";
import ServiceManage from "../service/ServiceManage";
import SitterManage from "./SitterManage";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";
import Complain from "../../components/Complain";
import PetItem from "../../components/PetItem";

const routeKeyIndexes = {
  pet: 0,
  service: 1,
  review: 2,
};

export default function SitterDetail({ route, isSsr }: any) {
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { sitterId, serviceId, preRoute, orderId, ...params } = route.params;
  let sitterProfile = usePromise(
    async () => await getProfile(sitterId),
    [sitterId],
    params.sitterProfile
  );
  sitterProfile = usePromise(
    async () => await getExProfile(sitterId),
    [sitterId],
    params.sitterProfile
  );

  const sitterAvatar = getProfilePictureUrl(sitterId, sitterProfile?.avatarId);
  const serviceNumber = usePromise(async () => {
    if (!sitterProfile) return;
    return await getSitterServiceNumber(sitterProfile?.id);
  }, [sitterProfile], params.serviceNumber);
  const auth = getAuth();
  const { currentUser } = auth;
  const currentUserId = getCurrentUserId();
  const [showSitterManage, setShowSitterManage] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isOpenWallet, setIsOpenWallet] = useState(false);
  const [index, setIndex] = useState(params.index ?? 1);
  const [routes] = useState([
    { key: "pet", title: t("enum:pets") },
    { key: "service", title: t("enum:service") },
    { key: "review", title: t("enum:reviews") },
  ]);
  const userPerformance = [
    { value: "97.01%", title: "message" },
    { value: serviceNumber, title: "service" },
    { value: sitterProfile?.totalReviews, title: "reviews" },
  ];
  // When tab is activated, set what's index value
  const handleIndexChange = (index: any) => setIndex(index);

  // Copied from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/screens/profile/SitterSettingsScreen.tsx#L102-106
  const handleOpenSitterManual = useCallback(() => {
    Linking.openURL(
      `https://docs.google.com/document/d/e/2PACX-1vQLHzy02ZHImG5fhsH0iv6pR5GyWRJbMdxzRWOvcUeu9RkWNSEsN2tlKejnvQBA38taYAFqJngI0Xn6/pub`
    );
  }, []);

  // Customize UI tab bar
  const renderTabBar = (props: any) => {
    const routeKey = new URL(location.href).searchParams.get("routeKey");
    if (routeKey) {
      props.navigationState.index = routeKeyIndexes[routeKey];
    }
    return (
      <TabBar
        {...props}
        indicatorStyle={[styles.indicator, { backgroundColor: colors.primary }]}
        style={[styles.tabbar, { backgroundColor: colors.background }]}
        inactiveColor={BaseColor.grayColor}
        activeColor={colors.text}
        renderLabel={({ route, focused, color }) => (
          <View style={{ flex: 1, width: 130, alignItems: "center" }}>
            <Text headline semibold={focused} style={{ color }}>
              {route.title}
            </Text>
          </View>
        )}
      />
    )
  };

  // Render correct screen container when tab is activated
  const renderScene = ({ route, jumpTo }: any) => {
    switch (new URL(location.href).searchParams.get("routeKey") || route.key) {
      case "pet":
        return <PetsTab jumpTo={jumpTo} pets={params.pets} sitterId={sitterId} />;
      case "service":
        return (
          <ServicesTab
            isSsr={isSsr}
            jumpTo={jumpTo}
            navigation={navigation}
            services={params.services}
            sitterId={sitterId}
            currentUserId={currentUserId}
          />
        );
      case "review":
        return (
          <ReviewTab
            jumpTo={jumpTo}
            navigation={navigation}
            reviews={params.reviews}
            sitterProfile={sitterProfile}
            sitterId={sitterId}
            currentUserId={currentUserId}
          />
        );
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <SitterManage
        modalVisible={showSitterManage}
        onPress={() => {}}
        onDismiss={() => {
          setShowSitterManage(false);
        }}
      />
      <Menu
        visible={isShowMenu}
        onDismiss={() => {
          setIsShowMenu(false);
        }}
      />
      <Header
        style={BaseStyle.menu}
        titleStyle={{ color: BaseColor.whiteColor }}
        title={t("profile:sitter_profile")}
        subTitle={""}
        renderLeft={() => {
          return (
            <Icon name="arrow-left" size={20} color={BaseColor.whiteColor} />
          );
        }}
        onPressLeft={() => {
          navigation.pop();
          // if(preRoute !== undefined) {
          //   navigation.navigate(preRoute, {
          //     orderId,
          //     serviceId,
          //     sitterId,
          //   })
          // } else {
          //   navigation.navigate("UserTab", {screen: "Home"});
          // }
        }}
        onPressRight={() => {}}
        renderRight={() => {
          return (
            <>
              <View style={{ flexDirection: "row" }}>
                {currentUserId === sitterId ? (
                  <TouchableOpacity
                    style={{
                      marginRight: 15,
                      width: 32,
                      height: 32,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 16,
                    }}
                    onPress={() => {
                      setShowSitterManage(true);
                    }}
                  >
                    <Icon name="cog" size={20} color={BaseColor.whiteColor} />
                  </TouchableOpacity>
                ) : (
                  <Complain
                    context={{
                      target: sitterProfile,
                      contextId: sitterProfile?.id,
                      contextType: "profiles",
                    }}
                  />
                )}
                <TouchableOpacity
                  style={{
                    width: 32,
                    height: 32,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 16,
                  }}
                  onPress={() => {
                    setIsShowMenu(true);
                  }}
                >
                  <View>
                    <Icon name="bars" size={18} color={BaseColor.whiteColor} />
                  </View>
                </TouchableOpacity>
              </View>
            </>
          );
        }}
      />
      <View style={BaseStyle.safeAreaView} edges={["right", "left", "bottom"]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <ProfileDescription
            profile={sitterProfile}
            userId={sitterProfile?.id}
            image={sitterAvatar}
            name={params.title ?? sitterProfile?.displayName}
            description={sitterProfile?.description} // 地址
            tags={sitterProfile?.certificates}
            style={{ marginTop: 25, paddingHorizontal: 20, width: "70%" }}
            onPress={() => {}}
          />
          <View style={{ width: "30%", alignItems: "flex-end" }}>
            <TouchableOpacity
              style={[styles.buttonWithIconContainer, { marginTop: 25 }]}
              onPress={() => {
                setIsOpenWallet(true);
              }}
            >
              <View style={styles.buttonWithIcon}>
                <Icon
                  style={{ marginRight: 5 }}
                  name="wallet"
                  size={16}
                  color={BaseColor.color1}
                />
                <Text>{t("sitter_profile:wallet")}</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.buttonWithIconContainer, { marginTop: 10 }]}
              onPress={handleOpenSitterManual}
            >
              <View style={styles.buttonWithIcon}>
                <Icon
                  style={{ marginRight: 5 }}
                  name="book"
                  size={16}
                  color={BaseColor.color1}
                />
                <Text>{t("sitter_profile:tutorials")}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 30,
          }}
        >
          <View style={{ flex: 1, paddingLeft: 10, paddingVertical: 5 }}>
            <ProfilePerformance
              isMe={currentUserId === sitterId}
              flexDirection="row"
              data={userPerformance}
              type="primary"
              renderLeft={() => {
                return (
                  <View
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <FeatherIcon
                      name={
                        currentUser && currentUserId === sitterId
                          ? "edit"
                          : "message-square"
                      }
                      size={18}
                      color={BaseColor.color1}
                    />
                    <Text body2 grayColor>
                      {currentUser && currentUserId === sitterId
                        ? t("profile:edit")
                        : t("enum:message")}
                    </Text>
                  </View>
                );
              }}
              onLeftPress={() => {
                if (currentUser && currentUserId === sitterId) {
                  navigation.navigate("ProfileEditScreen", {
                    userId: currentUserId,
                  });
                } else {
                  navigation.navigate("ChatRoomScreen", {
                    userId: sitterId,
                    name: encodeURI(sitterProfile?.displayName || ""),
                  });
                }
              }}
            />
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <TabView
            lazy
            navigationState={{ index, routes }}
            renderScene={renderScene}
            renderTabBar={renderTabBar}
            onIndexChange={handleIndexChange}
          />
        </View>
      </View>
      <WalletModal
        visible={isOpenWallet}
        onDissmiss={() => {
          setIsOpenWallet(false);
        }}
      />
    </View>
  );
}

function ServicesTab({ navigation, sitterId, currentUserId, ...props }: any) {
  const { getDiscount } = useDiscount();
  const [showServiceManage, setShowServiceManage] = useState(false);
  const services = useSubscription({
    subscription: subscribeServices,
    variables: {
      providerId: sitterId,
    },
  }, props.services);
  const renderItem = useCallback(
    ({ item }) => {
      const { label, basePriceRangeStr, discountedBasePriceRangeStr } =
        getDiscount(item);
      return (
        <SearchItem
          isSsr={props.isSsr}
          type={item.type}
          providerId={item.providerId}
          pictureId={item.pictureIds[0] ? item.pictureIds[0] : undefined}
          title={item.title}
          location={item.place.address ? item.place.address : undefined}
          place={item.place}
          availableDays={item.availableDays}
          rate={item.profile?.avgRating}
          numReviews={item.profile?.totalReviews}
          tags={item.profile?.certificates}
          enabled={item?.enabled}
          label={label}
          basePriceRangeStr={basePriceRangeStr}
          discountedBasePriceRangeStr={discountedBasePriceRangeStr}
          style={{
            marginBottom: 20,
            paddingVertical: 10,
            backgroundColor: BaseColor.whiteColor,
          }}
          onPress={() => {
            navigation.navigate("ServiceDetailScreen", {
              serviceId: item.id,
              sitterId,
            });
          }}
          routePath={Service.toLoc(item)}
        />
      );
    },
    [getDiscount]
  );

  const { colors } = useTheme();
  return (
    <View style={{ flex: 1, backgroundColor: colors.card }}>
      <ServiceManage
        modalVisible={showServiceManage}
        onPress={() => {}}
        onDismiss={() => {
          setShowServiceManage(false);
        }}
      />
      {currentUserId !== sitterId ? (
        <></>
      ) : (
        <TouchableOpacity
          onPress={() => {
            setShowServiceManage(true);
          }}
          style={{ marginTop: 5 }}
        >
          <View
            style={{
              flexDirection: "row",
              backgroundColor: BaseColor.whiteColor,
              paddingVertical: 15,
              paddingHorizontal: 20,
            }}
          >
            <Icon name="plus" size={20} style={{ marginRight: 10 }} />
            <Text body2>{t("sitter_profile:add_service")}</Text>
          </View>
        </TouchableOpacity>
      )}
      <FlatList
        contentContainerStyle={{
          paddingVertical: 20,
          // maxHeight: height
        }}
        data={services}
        renderItem={renderItem}
      />
    </View>
  );
}

function ReviewTab({ navigation, sitterId, currentUserId, ...props }: any) {
  const { colors } = useTheme();
  const sitterProfile = useSubscription({
    subscription: subscribeProfile,
    variables: {
      userId: sitterId,
    },
  }, props.sitterProfile);
  const reviews = useSubscription({
    subscription: subscribeReviews,
    variables: {
      userId: sitterId || "",
      lastReview: "",
    },
  }, props.reviews);
  const { avgRating, totalReviews, id } = sitterProfile || {};

  useEffect(() => {
    return () => {
      sitterProfile;
      reviews;
    };
  }, []);

  const isReferenceReviewAllowed: boolean | null = usePromise(async () => {
    if (!sitterId || !currentUserId) return false;
    return (await getReview(sitterId, currentUserId)) !== null;
  }, [sitterId, currentUserId]);

  return (
    <View style={{ flex: 1, backgroundColor: colors.card }}>
      {currentUserId !== sitterId && !isReferenceReviewAllowed ? (
        <TouchableOpacity
          onPress={() => {
            if (!sitterId && sitterId === "") return;
            if (!currentUserId || currentUserId === "") {
              navigation.navigate("Auth");
            } else {
              navigation.navigate("CreateReviewScreen", {
                sitterId: sitterId,
              });
            }
          }}
          style={{ marginTop: 5 }}
        >
          <View
            style={{
              flexDirection: "row",
              backgroundColor: BaseColor.whiteColor,
              paddingVertical: 15,
              paddingHorizontal: 20,
            }}
          >
            <Icon name="plus" size={20} style={{ marginRight: 10 }} />
            <Text body2>{t("review:add_referral")}</Text>
          </View>
        </TouchableOpacity>
      ) : (
        <></>
      )}
      <RateDetail
        point={avgRating}
        totalReviews={totalReviews}
        reviews={reviews}
        style={{
          backgroundColor: BaseColor.whiteColor,
          padding: 10,
        }}
      />
      <FlatList
        contentContainerStyle={{ padding: 20 }}
        horizontal={false}
        data={reviews?.payload}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <View>
            <CommentItem
              userId={item.snapshots.senderProfile?.id || ""}
              style={{ marginTop: 10, backgroundColor: BaseColor.whiteColor }}
              avatarId={item.snapshots.senderProfile?.avatarId || ""}
              name={item.snapshots.senderProfile?.displayName || ""}
              rate={item.rating}
              comment={item.comment || ""}
              createdAt={item.createdAt}
            />
          </View>
        )}
      />
    </View>
  );
}

function PetsTab({ sitterId, ...props }: any) {
  const pets = useSubscription({
    subscription: subscribePets,
    variables: {
      userId: sitterId,
    },
  }, props.pets);
  const renderItem = (item: any) => {
    return (
      <PetItem
        {...item}
        userId={sitterId}
        allowsSelection={false}
        style={{
          marginBottom: 20,
          paddingVertical: 10,
          backgroundColor: BaseColor.whiteColor,
        }}
      />
    );
  };

  const { colors } = useTheme();
  return (
    <View style={{ flex: 1, backgroundColor: colors.card }}>
      {/* Copied from https://github.com/fluvo/fluv_global/blob/develop/src/screens/pets/PetsScreen.tsx#L118C1-L126*/}
      {pets && pets?.length > 0 ? (
        <FlatList
          data={pets}
          keyExtractor={(item, index) => item.id}
          renderItem={({ item }) => renderItem(item)}
          contentContainerStyle={{
            paddingVertical: 20,
          }}
        />
      ) : (
        <></>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  location: {
    flexDirection: "row",
    marginTop: 10,
  },
  contentTag: {
    marginLeft: 20,
    marginTop: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  tabbar: {
    height: 40,
  },
  indicator: {
    height: 2,
  },
  label: {
    fontWeight: "400",
  },
  contentImageGird: {
    flexDirection: "row",
    height: 160,
    marginTop: 10,
  },
  SearchItem: {
    width: 160,
  },
  contentButtonBottom: {
    borderTopWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  lineInformation: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 10,
    borderBottomWidth: 1,
  },

  // calendar
  contentPickDate: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    padding: 10,
  },
  itemPick: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  contentCalendar: {
    borderRadius: 8,
    width: "100%",
  },
  contentActionCalendar: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 15,
  },
  buttonWithIconContainer: {
    paddingHorizontal: 20,
  },
  buttonWithIcon: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 50,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
});
