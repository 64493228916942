import { Images } from "../config";
export default [
  {
    id: "1",
    image: Images.services.service10,
    name: "犬を散歩に連れて行く",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "July 19th 2019",
    price: "1500",
    rate: 5,
    rateCount: "85 of 100",
    numReviews: 100,
    author: {
      image: Images.topsitter.sitter1,
      point: "9.5",
      name: "by Steve Garrett",
    },
    tags: [
      { icon: "history", name: "8 Days 1 Night" },
      { icon: "medkit", name: "Insurrance" },
      { icon: "users", name: "Family Dinner" },
      { icon: "beer", name: "Free Beer" },
    ],
  },
  {
    id: "2",
    image: Images.services.service11,
    name: "犬の世話をする",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "July 19th 2019",
    price: "2000",
    rate: 5,
    rateCount: "85 of 100",
    numReviews: 100,
    author: {
      image: Images.topsitter.sitter2,
      point: "9.5",
      name: "by Steve Garrett",
    },
    tags: [
      { icon: "history", name: "8 Days 1 Night" },
      { icon: "medkit", name: "Insurrance" },
      { icon: "users", name: "Family Dinner" },
      { icon: "beer", name: "Free Beer" },
    ],
  },
  {
    id: "3",
    image: Images.services.service8,
    name: "Tour in Italy",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "July 19th 2019",
    price: "$1500,99",
    rate: 5,
    rateCount: "85 of 100",
    numReviews: 100,
    author: {
      image: Images.topsitter.sitter3,
      point: "9.5",
      name: "by Steve Garrett",
    },
    tags: [
      { icon: "history", name: "8 Days 1 Night" },
      { icon: "medkit", name: "Insurrance" },
      { icon: "users", name: "Family Dinner" },
      { icon: "beer", name: "Free Beer" },
    ],
  },
];
