import { Modal, TouchableWithoutFeedback, View } from "react-native";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseColor, useTheme } from "../config";
import { Button, Flex, TextInput } from "../ui";
import { Text } from "../ui";
import LoadingSpinner from "./LoadingSpinner";
import { PhoneVerification } from "../api-typescript-axios-client-generated/models";

interface PhoneVerificationModalProps {
  code: string;
  error: string;
  loading: boolean;
  onChangeCode: Dispatch<SetStateAction<string>>;
  onDismiss: (visable: boolean) => void;
  onVerify: (code?: string) => void;
  phone: string | undefined;
  phoneVerification: PhoneVerification | null;
  visable: boolean;
}

const PhoneVerificationModal = React.memo(
  (props: PhoneVerificationModalProps) => {
    const {
      code,
      error,
      loading,
      onChangeCode,
      onDismiss,
      onVerify,
      phone,
      phoneVerification,
      visable,
    } = props;

    const { t } = useTranslation();
    const { colors } = useTheme();

    const [timeLeft, setTimeLeft] = useState(0);

    const { updatedAt } = phoneVerification || {};
    const RESEND_INTERVAL = 60 * 1000;

    useEffect(() => {
      if (!updatedAt) return;

      const timeElapsed = Date.now() - updatedAt;
      const remainingTime = Math.max(RESEND_INTERVAL - timeElapsed, 0);

      setTimeLeft(Math.floor(remainingTime / 1000));

      const interval: ReturnType<typeof setInterval> = setInterval(() => {
        setTimeLeft((prevTimeLeft) => Math.max(prevTimeLeft - 1, 0));
      }, 1000);

      return () => clearInterval(interval);
    }, [updatedAt]);

    const canResent = timeLeft === 0;

    return (
      <Modal transparent={true} visible={visable}>
        <LoadingSpinner visible={loading} />
        <TouchableWithoutFeedback
          onPress={() => {
            onDismiss(false);
          }}
        >
          <View
            style={{
              backgroundColor: "rgba(33, 33, 33, 0.5)",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 15,
            }}
          >
            <TouchableWithoutFeedback>
              <View
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "auto",
                  maxHeight: "auto",
                  paddingTop: 12,
                  paddingBottom: 24,
                  paddingHorizontal: 8,
                }}
              >
                {(error === "error:unmatched_code" || error === "6ZAD") && (
                  <Flex paddingHorizontal={8} paddingTop={16}>
                    <Text dangerColor bold>
                      {t(
                        "The verification code entered is incorrect. Please enter it again!"
                      )}
                    </Text>
                  </Flex>
                )}

                <Flex paddingTop={16}>
                  <TextInput
                    onChangeText={onChangeCode}
                    onFocus={() => {}}
                    value={code}
                    style={{
                      borderColor: BaseColor.dividerColor,
                      borderWidth: 1,
                    }}
                  />
                </Flex>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Flex paddingTop={8} paddingHorizontal={4} fill>
                    <Button
                      onPress={() => {
                        onVerify();
                      }}
                      style={{
                        backgroundColor: canResent ? colors.accent : "gray",
                      }}
                      disabled={!canResent}
                    >
                      {t("Resend verification code")}
                    </Button>
                  </Flex>
                  <Flex paddingTop={8} paddingHorizontal={4} fill>
                    <Button onPress={() => onVerify(code)}>
                      {t("Verify")}
                    </Button>
                  </Flex>
                </View>
                <Text style={{ paddingTop: 8 }}>
                  {t(
                    "The verification code has been sent to {{phoneNumber}}. If you don't received the verification code, please ensure the phone number is correct, and press 'Resend' after {{time}} seconds.",
                    { phoneNumber: phone, time: timeLeft }
                  )}
                </Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }
);

export default PhoneVerificationModal;
