import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
} from "react-native";
import { BaseStyle, useTheme } from "../../config";
import { SafeAreaView, Icon, SelectModal } from "../../components";
import { Image, Text, TextInput } from "../../ui";
import { useTranslation } from "react-i18next";
import { BaseColor } from "../../config";
import { TouchableOpacity } from "react-native-gesture-handler";
import { getPetPictureUrl, TPetInput } from "../../models";
import {
  PetCategory,
  PetCategoryUtil,
  PetGender,
  PetGenderUtil,
  PetPersonality,
  PetPersonalityUtil,
  PetSize,
  PetSizeUtil,
} from "../../enums";
import * as ImagePicker from "expo-image-picker";
import { useAuth } from "../../context/Auth";
import { minWidth } from "../../config/theme";

type Props = {
  defaultInput?: TPetInput; // for update
  onChangeInput: (input: TPetInput | null) => void;
  onDelete?: () => void;
};

export default React.memo<Props>((props) => {
  const { defaultInput, onChangeInput, onDelete } = props;
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { userId } = useAuth();
  const offsetKeyboard = Platform.select({
    ios: 0,
    android: 20,
  });
  const [avatarUriOrId, setAvatarUriOrId] = useState<string>(
    defaultInput?.avatarUriOrId || ""
  );
  const [name, setName] = useState<string>(defaultInput?.name || "");
  const [category, setCategory] = useState<PetCategory | undefined>(
    defaultInput?.category || 0
  );
  const [size, setSize] = useState<PetSize | undefined>(
    defaultInput?.size || 0
  );
  const [birthYear, setBirthYear] = useState<number | undefined>(
    defaultInput?.birthYear || 0
  );
  const [birthMonth, setBirthMonth] = useState<number | undefined>(
    defaultInput?.birthMonth || 0
  );
  const [gender, setGender] = useState<PetGender | undefined>(
    defaultInput?.gender || 0
  );
  const [neuteredOrSpayed, setNeuteredOrSpayed] = useState<boolean | undefined>(
    defaultInput?.neuteredOrSpayed
  );
  const [regularVaccination, setRegularVaccination] = useState<
    boolean | undefined
  >(defaultInput?.regularVaccination);
  const [healthStatus, setHealthStatus] = useState<string | undefined>(
    defaultInput?.healthStatus
  );
  const [personalities, setPersonalities] = useState<PetPersonality[]>(
    defaultInput?.personalities || [0]
  );

  const [onOpenCategoryModal, setOnOpenCategoryModal] = useState(false);
  const [onOpenSizeModal, setOnOpenSizeModal] = useState(false);
  const [onOpenBirthYearModal, setOnOpenBirthYearModal] = useState(false);
  const [onOpenBirthMonthModal, setOnOpenBirthMonthModal] = useState(false);
  const [onOpenGenderModal, setOnOpenGenderModal] = useState(false);
  const [onOpenNeuteredOrSpayedModal, setOnOpenNeuteredOrSpayedModal] =
    useState(false);
  const [onOpenRegularVaccinationModal, setOnOpenRegularVaccinationModal] =
    useState(false);
  const [onOpenPersonalitiesModal, setOnOpenPersonalitiesModal] =
    useState(false);

  useEffect(() => {
    if (!defaultInput) return;
    const {
      avatarUriOrId,
      name,
      category,
      size,
      birthYear,
      birthMonth,
      gender,
      neuteredOrSpayed,
      regularVaccination,
      healthStatus,
      personalities,
    } = defaultInput;
    setAvatarUriOrId(avatarUriOrId);
    setName(name);
    setCategory(category);
    setSize(size);
    setBirthYear(birthYear);
    setBirthMonth(birthMonth);
    setGender(gender);
    setNeuteredOrSpayed(neuteredOrSpayed);
    setRegularVaccination(regularVaccination);
    setHealthStatus(healthStatus);
    setPersonalities(personalities);
  }, [defaultInput]);

  useEffect(() => {
    if (
      avatarUriOrId &&
      name &&
      category &&
      size &&
      birthYear &&
      gender &&
      neuteredOrSpayed !== undefined &&
      regularVaccination !== undefined &&
      healthStatus &&
      /* @ts-ignore */
      personalities.length > 0 &&
      personalities[0] !== 0
    ) {
      onChangeInput({
        avatarUriOrId,
        name,
        category,
        size,
        birthYear,
        birthMonth,
        gender,
        neuteredOrSpayed,
        regularVaccination,
        healthStatus,
        personalities,
      });
    } else {
      onChangeInput(null);
    }
  }, [
    avatarUriOrId,
    name,
    category,
    size,
    birthYear,
    birthMonth,
    gender,
    neuteredOrSpayed,
    regularVaccination,
    healthStatus,
    personalities,
  ]);

  // Category
  const petCategoryOptions = PetCategoryUtil.list().map((pc) => ({
    text: t(`pets:category_${PetCategory[pc]}`),
    value: pc,
  }));
  petCategoryOptions.unshift({ text: t("pets:select_category"), value: 0 });

  // Size
  const petSizeOptions = PetSizeUtil.list().map((ps) => ({
    text: t(`pets:pet_size_${PetSize[ps]}`),
    description: t(`pets:pet_size_tip_${PetSize[ps]}`),
    value: ps,
  }));
  petSizeOptions.unshift({
    text: t("pets:select_size"),
    value: 0,
    description: "",
  });

  // Birth Year
  const thisYear = new Date().getFullYear();
  const birthYearOptions = new Array(100).fill(null).map((_, i) => ({
    text: thisYear - i,
    value: thisYear - i,
  }));
  birthYearOptions.unshift({ text: t("pets:select_birth_year"), value: 0 });

  // Birth Month
  const birthMonthOptions = new Array(12).fill(null).map((_, i) => ({
    text: t(`enum:month_${i + 1}`),
    value: i + 1,
  }));
  birthMonthOptions.unshift({ text: t("pets:select_birth_month"), value: 0 });

  // Gender
  const genderOptions = PetGenderUtil.list().map((g) => ({
    text: t(`pets:pet_gender_${PetGender[g]}`),
    value: g,
  }));
  genderOptions.unshift({ text: t("pets:select_gender"), value: 0 });

  //NenteredOrSpayed
  const neuteredOrSpayedOptions: any = [true, false].map((n) => ({
    text: t(`pets:pet_neutered_or_spayed_${String(n)}`),
    value: n ? 1 : 2,
  }));
  neuteredOrSpayedOptions.unshift({
    text: t("pets:select_neutered_or_spayed"),
    value: 0,
  });

  //regularVaccination
  const regularVaccinationOptions: any = [true, false].map((n) => ({
    text: t(`pets:pet_regular_vaccination_${String(n)}`),
    value: n ? 1 : 2,
  }));
  regularVaccinationOptions.unshift({
    text: t("pets:select_regular_vaccination"),
    value: 0,
  });

  // Personalities
  const personalitiesOptions = PetPersonalityUtil.list().map((pp) => ({
    text: t(`enum:pet_personality_${PetPersonality[pp]}`),
    value: pp,
  }));
  personalitiesOptions.unshift({
    text: t("pets:select_personalities"),
    value: 0,
  });

  const handleSelectAvatar = useCallback(async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert("沒有取得您的權限");
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: true,
        aspect: [1, 1],
      });
      if (!result.cancelled) {
        setAvatarUriOrId(result.uri);
      }
    }
  }, []);

  const handleChangeName = useCallback((text) => {
    setName(text);
  }, []);

  const handleChangeHealthStatus = useCallback((text) => {
    setHealthStatus(text);
  }, []);

  const handleChangePetCategory = useCallback((option) => {
    setCategory(option.value);
  }, []);

  const handleChangePetSize = useCallback((option) => {
    setSize(option.value);
  }, []);

  const handleChangeBirthYear = useCallback((option) => {
    setBirthYear(option.value);
  }, []);

  const handleChangeBirthMonth = useCallback((option) => {
    setBirthMonth(option.value);
  }, []);

  const handleChangeGender = useCallback((option) => {
    setGender(option.value);
  }, []);

  const handleChangePetNeuteredOrSpayed = useCallback((option) => {
    switch (option.value) {
      case 1:
        setNeuteredOrSpayed(true);
        break;
      case 2:
        setNeuteredOrSpayed(false);
        break;
      case 0:
        setNeuteredOrSpayed(undefined);
        break;
      default:
        break;
    }
  }, []);

  const handleChangePetRegularVaccination = useCallback((option) => {
    switch (option.value) {
      case 1:
        setRegularVaccination(true);
        break;
      case 2:
        setRegularVaccination(false);
        break;
      case 0:
        setRegularVaccination(undefined);
        break;
      default:
        break;
    }
  }, []);

  const handleChangePersonalities = useCallback((option) => {
    let personalitiesItems = [];
    if (
      option.length <= 0 ||
      (option.length === 1 &&
        option.filter((item: any) => item.value === 0).length === 1)
    ) {
      personalitiesItems = [0];
    } else {
      personalitiesItems = option
        .filter((item: any) => item.value !== 0)
        .map((item: any) => item.value);
    }
    setPersonalities(personalitiesItems);
  }, []);

  return (
    <View
      style={[
        BaseStyle.safeAreaView,
        {
          backgroundColor: colors.card,
        },
      ]}
    >
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "android" ? "height" : "padding"}
          keyboardVerticalOffset={offsetKeyboard}
          style={{ flex: 1 }}
        >
          <ScrollView contentContainerStyle={styles.contain}>
            <View
              style={{
                backgroundColor: BaseColor.whiteColor,
                width: "100%",
                minWidth: minWidth,
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 12,
                marginBottom: 10,
              }}
            >
              {/* image */}
              <TouchableOpacity onPress={handleSelectAvatar}>
                {avatarUriOrId !== "" ? (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Image
                      source={{ uri: getPetPictureUrl(userId, avatarUriOrId) }}
                      style={{ width: 80, height: 80, borderRadius: 40 }}
                    />
                  </View>
                ) : (
                  <></>
                )}
                <View>
                  <Text darkPrimaryColor>{t("pets:select_avatar")}</Text>
                </View>
              </TouchableOpacity>
            </View>

            {/* Pet Name */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginVertical: 5,
                paddingVertical: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("pets:name")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                }}
              >
                <TextInput
                  onChangeText={handleChangeName}
                  onFocus={() => {}}
                  placeholder={t("pets:name_placeholder")}
                  value={name}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
            </View>

            {/* Health Status */}
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                marginVertical: 5,
                paddingVertical: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: 20,
                }}
              >
                <View>
                  <Text body2 semibold>
                    {t("pets:health_status")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  marginVertical: 10,
                  paddingHorizontal: 20,
                }}
              >
                <TextInput
                  onChangeText={handleChangeHealthStatus}
                  onFocus={() => {}}
                  placeholder={t("pets:health_status_placeholder")}
                  value={healthStatus}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                    height: 80,
                  }}
                  numberOfLines={2}
                  multiline={true}
                />
              </View>
            </View>

            {/* Category */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
                backgroundColor: BaseColor.whiteColor,
                marginVertical: 5,
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("pets:category")}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  style={styles.selectButton}
                  onPress={() => {
                    setOnOpenCategoryModal(true);
                  }}
                >
                  {/* @ts-ignore */}
                  <Text grayColor={category === 0 ? true : false}>
                    {
                      petCategoryOptions.filter(
                        (option) => option.value === category
                      )[0].text
                    }
                  </Text>
                  <Icon name="angle-down" size={18} style={{ marginLeft: 5 }} />
                </TouchableOpacity>
              </View>
              <SelectModal
                visible={onOpenCategoryModal}
                options={petCategoryOptions}
                selected={
                  petCategoryOptions.filter(
                    (option) => option.value === category
                  )[0]
                }
                onChange={(item: any) => {
                  setOnOpenCategoryModal(false);
                  handleChangePetCategory(item);
                }}
              />
            </View>

            {/* size */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
                backgroundColor: BaseColor.whiteColor,
                marginVertical: 5,
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("pets:size")}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  style={styles.selectButton}
                  onPress={() => {
                    setOnOpenSizeModal(true);
                  }}
                >
                  {/* @ts-ignore */}
                  <Text grayColor={size === 0 ? true : false}>
                    {
                      petSizeOptions.filter(
                        (option) => option.value === size
                      )[0].text
                    }
                  </Text>
                  <Icon name="angle-down" size={18} style={{ marginLeft: 5 }} />
                </TouchableOpacity>
              </View>
              <SelectModal
                visible={onOpenSizeModal}
                options={petSizeOptions}
                selected={
                  petSizeOptions.filter((option) => option.value === size)[0]
                }
                onChange={(item: any) => {
                  setOnOpenSizeModal(false);
                  handleChangePetSize(item);
                }}
              />
            </View>

            {/* Birth Year */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
                backgroundColor: BaseColor.whiteColor,
                marginVertical: 5,
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("pets:birth_year")}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  style={styles.selectButton}
                  onPress={() => {
                    setOnOpenBirthYearModal(true);
                  }}
                >
                  {/* @ts-ignore */}
                  <Text grayColor={birthYear === 0 ? true : false}>
                    {
                      birthYearOptions.filter(
                        (option) => option.value === birthYear
                      )[0].text
                    }
                  </Text>
                  <Icon name="angle-down" size={18} style={{ marginLeft: 5 }} />
                </TouchableOpacity>
              </View>
              <SelectModal
                visible={onOpenBirthYearModal}
                options={birthYearOptions}
                selected={
                  birthYearOptions.filter(
                    (option) => option.value === birthYear
                  )[0]
                }
                onChange={(item: any) => {
                  setOnOpenBirthYearModal(false);
                  handleChangeBirthYear(item);
                }}
              />
            </View>

            {/* Birth Month */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
                backgroundColor: BaseColor.whiteColor,
                marginVertical: 5,
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("pets:birth_month")}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  style={styles.selectButton}
                  onPress={() => {
                    setOnOpenBirthMonthModal(true);
                  }}
                >
                  {/* @ts-ignore */}
                  <Text grayColor={birthMonth === 0 ? true : false}>
                    {
                      birthMonthOptions.filter(
                        (option) => option.value === birthMonth
                      )[0]?.text
                    }
                  </Text>
                  <Icon name="angle-down" size={18} style={{ marginLeft: 5 }} />
                </TouchableOpacity>
              </View>
              <SelectModal
                visible={onOpenBirthMonthModal}
                options={birthMonthOptions}
                selected={
                  birthMonthOptions.filter(
                    (option) => option.value === birthMonth
                  )[0]
                }
                onChange={(item: any) => {
                  setOnOpenBirthMonthModal(false);
                  handleChangeBirthMonth(item);
                }}
              />
            </View>

            {/* Gender */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
                backgroundColor: BaseColor.whiteColor,
                marginVertical: 5,
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("pets:gender")}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  style={styles.selectButton}
                  onPress={() => {
                    setOnOpenGenderModal(true);
                  }}
                >
                  {/* @ts-ignore */}
                  <Text grayColor={gender === 0 ? true : false}>
                    {
                      genderOptions.filter(
                        (option) => option.value === gender
                      )[0].text
                    }
                  </Text>
                  <Icon name="angle-down" size={18} style={{ marginLeft: 5 }} />
                </TouchableOpacity>
              </View>
              <SelectModal
                visible={onOpenGenderModal}
                options={genderOptions}
                selected={
                  genderOptions.filter((option) => option.value === gender)[0]
                }
                onChange={(item: any) => {
                  setOnOpenGenderModal(false);
                  handleChangeGender(item);
                }}
              />
            </View>

            {/* NeuteredOrSpayed */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
                backgroundColor: BaseColor.whiteColor,
                marginVertical: 5,
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("pets:neutered_or_spayed")}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  style={styles.selectButton}
                  onPress={() => {
                    setOnOpenNeuteredOrSpayedModal(true);
                  }}
                >
                  <Text
                    grayColor={neuteredOrSpayed === undefined ? true : false}
                  >
                    {
                      neuteredOrSpayedOptions.filter((option: any) => {
                        return (
                          option.value ===
                          (neuteredOrSpayed
                            ? 1
                            : neuteredOrSpayed !== undefined
                            ? 2
                            : 0)
                        );
                      })[0].text
                    }
                  </Text>
                  <Icon name="angle-down" size={18} style={{ marginLeft: 5 }} />
                </TouchableOpacity>
              </View>
              <SelectModal
                visible={onOpenNeuteredOrSpayedModal}
                options={neuteredOrSpayedOptions}
                selected={
                  neuteredOrSpayedOptions.filter(
                    (option: any) =>
                      option.value ===
                      (neuteredOrSpayed
                        ? 1
                        : neuteredOrSpayed !== undefined
                        ? 2
                        : 0)
                  )[0]
                }
                onChange={(item: any) => {
                  setOnOpenNeuteredOrSpayedModal(false);
                  handleChangePetNeuteredOrSpayed(item);
                }}
              />
            </View>

            {/* RegularVaccination */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
                backgroundColor: BaseColor.whiteColor,
                marginVertical: 5,
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("pets:neutered_or_spayed")}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  style={styles.selectButton}
                  onPress={() => {
                    setOnOpenRegularVaccinationModal(true);
                  }}
                >
                  <Text
                    grayColor={regularVaccination === undefined ? true : false}
                  >
                    {
                      regularVaccinationOptions.filter((option: any) => {
                        return (
                          option.value ===
                          (regularVaccination
                            ? 1
                            : regularVaccination !== undefined
                            ? 2
                            : 0)
                        );
                      })[0].text
                    }
                  </Text>
                  <Icon name="angle-down" size={18} style={{ marginLeft: 5 }} />
                </TouchableOpacity>
              </View>
              <SelectModal
                visible={onOpenRegularVaccinationModal}
                options={regularVaccinationOptions}
                selected={
                  regularVaccinationOptions.filter(
                    (option: any) =>
                      option.value ===
                      (regularVaccination
                        ? 1
                        : regularVaccination !== undefined
                        ? 2
                        : 0)
                  )[0]
                }
                onChange={(item: any) => {
                  setOnOpenRegularVaccinationModal(false);
                  handleChangePetRegularVaccination(item);
                }}
              />
            </View>

            {/* PersonalitiesModal */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
                backgroundColor: BaseColor.whiteColor,
                marginVertical: 5,
              }}
            >
              <View>
                <Text body2 semibold>
                  {t("pets:personalities")}
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  style={styles.selectButton}
                  onPress={() => {
                    setOnOpenPersonalitiesModal(true);
                  }}
                >
                  <View style={{ maxWidth: 200, justifyContent: "flex-end" }}>
                    {/* @ts-ignore */}
                    <Text
                      style={{ textAlign: "right" }}
                      grayColor={personalities[0] === 0 ? true : false}
                    >
                      {personalitiesOptions
                        .filter((option: any) => {
                          return personalities.includes(option.value);
                        })
                        .map((item) => {
                          return item.text;
                        })
                        .join(" ,")}
                    </Text>
                  </View>
                  <Icon name="angle-down" size={18} style={{ marginLeft: 5 }} />
                </TouchableOpacity>
              </View>
              <SelectModal
                visible={onOpenPersonalitiesModal}
                options={personalitiesOptions}
                multiSelected={personalitiesOptions.filter((option: any) =>
                  personalities.includes(option.value)
                )}
                multiSelect={true}
                onChange={(item: any) => {
                  setOnOpenPersonalitiesModal(false);
                  handleChangePersonalities(item);
                }}
              />
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </View>
  );
});

const styles = StyleSheet.create({
  contentTitle: {
    // alignItems: 'flex-start',
    // width: '100%',
    // height: 32,
    // justifyContent: 'center',
    // marginTop: 10
  },
  contain: {
    alignItems: "center",
    paddingVertical: 20,
  },
  textInput: {
    height: 46,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 5,
    padding: 10,
    width: "100%",
    color: BaseColor.grayColor,
  },
  thumb: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 20,
  },
  selectContent: {
    // width: '100%',
    // flex: 1,
  },
  selectButton: {
    padding: 10,
    height: 46,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    color: BaseColor.grayColor,
    borderRadius: 5,
    flexDirection: "row",
  },
});
