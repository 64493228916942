import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { ServiceType } from "../enums";
import { TSubscription } from "./types";

export type TPrices = {
  [priceLevel: number]: number;
};

export type TAllPrices = {
  [serviceType: number]: TPrices;
};

export type TExtrasSizes = {
  [petSize: number]: number;
};

export type TExtrasCategories = {
  [petCategory: number]: {
    sizes: TExtrasSizes;
  };
};

export type TExtras = {
  categories: TExtrasCategories;
};

export type TAllExtras = {
  [serviceType: number]: TExtras;
};

export type THolidayServiceContent = {
  title: string;
  description: string;
  isShow: boolean;
  option: {
    yes: string,
    no: string
  }
};

type THolidayServiceAlert = {
  [locale: string]: THolidayServiceContent;
}

const globalsRef = collection(db, "_globals");

const toServicePrices = (
  snapshot: DocumentSnapshot<DocumentData>
): TAllPrices | null => {
  const serviceTypeMap = snapshot.data();
  if (!serviceTypeMap) return null;

  return Object.keys(serviceTypeMap).reduce<TAllPrices>(
    (serviceTypes, serviceTypeKey) => {
      const serviceType = parseInt(serviceTypeKey, 10);
      const priceLevelMap = serviceTypeMap[serviceTypeKey];

      serviceTypes[serviceType] = Object.keys(priceLevelMap).reduce<TPrices>(
        (priceLevels, priceLevelKey) => {
          const priceLevel = parseInt(priceLevelKey, 10);
          priceLevels[priceLevel] = priceLevelMap[priceLevelKey];
          return priceLevels;
        },
        {}
      );
      return serviceTypes;
    },
    {}
  );
};

const toServiceExtras = (
  snapshot: DocumentSnapshot<DocumentData>
): TAllExtras | null => {
  const serviceTypeMap = snapshot.data();
  if (!serviceTypeMap) return null;

  return Object.keys(serviceTypeMap).reduce<TAllExtras>(
    (serviceTypes, serviceTypeKey) => {
      const serviceType = parseInt(serviceTypeKey, 10);
      const petCategoryMap = serviceTypeMap[serviceTypeKey].categories || {};

      serviceTypes[serviceType] = {
        categories: Object.keys(petCategoryMap).reduce<TExtrasCategories>(
          (petCategories, petCategoryKey) => {
            const petCategory = parseInt(petCategoryKey, 10);
            const petSizeMap = petCategoryMap[petCategoryKey].sizes || {};
            petCategories[petCategory] = {
              sizes: Object.keys(petSizeMap).reduce<TExtrasSizes>(
                (petSizes, petSizeKey) => {
                  const petSize = parseInt(petSizeKey, 10);
                  petSizes[petSize] = petSizeMap[petSizeKey];
                  return petSizes;
                },
                {}
              ),
            };
            return petCategories;
          },
          {}
        ),
      };
      return serviceTypes;
    },
    {}
  );
};

const serviceExtrasDoc = () => {
  return doc(globalsRef, "serviceExtras");
};

const servicePricesDoc = () => {
  return doc(globalsRef, "servicePrices");
};

const sitterRecommendDoc = () => {
  return doc(globalsRef, "sitterRecommend");
};

const holidayServiceAlertDoc = () => {
  return doc(globalsRef, "holidayServiceAlert");
};

export const getServiceExtras = async () => {
  const snap = await getDoc(serviceExtrasDoc());
  return snap.data();
};

export const getServicePrices = async (serviceType: any, priceLevel: any) => {
  if (!serviceType || !priceLevel) return 0;
  const snap = (await getDoc(servicePricesDoc())) as any;
  return snap.data()[serviceType][priceLevel];
};

export const subscribeAllPrices: TSubscription<unknown, TAllPrices> = ({
  onChange,
}) => {
  const pricesDoc = servicePricesDoc();

  return onSnapshot(pricesDoc, (snapshot: DocumentSnapshot<DocumentData>) => {
    const servicePrices = toServicePrices(snapshot);
    if (!onChange) return;
    onChange(servicePrices);
  });
};

export const subscribeAllExtras: TSubscription<unknown, TAllExtras> = ({
  onChange,
}) => {
  const extrasDoc = serviceExtrasDoc();

  return onSnapshot(extrasDoc, (snapshot) => {
    const extras = toServiceExtras(snapshot);
    if (!onChange) return;
    onChange(extras);
  });
};

export const subscribeServicePrices: TSubscription<
  { serviceType?: ServiceType },
  TPrices
> = ({ variables, onChange }) => {
  const { serviceType } = variables || {};
  if (!serviceType) return () => {};
  const pricesDoc = servicePricesDoc();

  return onSnapshot(pricesDoc, (snapshot) => {
    const servicePrices = toServicePrices(snapshot);
    if (!onChange) return;
    onChange(servicePrices?.[serviceType] || null);
  });
};
export const getSitterRecommend = async () => {
  const snap = (await getDoc(sitterRecommendDoc())) as any;
  return snap.data().sitterIds;
};

export const getHolidayServiceAlert = async (): Promise<THolidayServiceAlert> => {
  try {
    const snap = (await getDoc(holidayServiceAlertDoc())) as any;
    return snap.data() as THolidayServiceAlert;
  } catch (error) {
    console.error("Q33D", error);
    throw error;
  }
};

export const getHolidayServiceAlertContext = async (): Promise<THolidayServiceContent> => {
  const holidayServiceAlert = await getHolidayServiceAlert();

  if (!holidayServiceAlert) return {
    title: '',
    description: '',
    isShow: false,
    option: {
      yes: '',
      no: ''
    }
  }
  return holidayServiceAlert['zh-TW'];
}
