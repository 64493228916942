import Constants from "expo-constants";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { Platform } from "react-native";
import { db } from "../config/firebase";

const deviceRef = collection(db, "devices");

const getDeviceDoc = (deviceId: string) => {
  return doc(deviceRef, deviceId);
};

// copied from https://gitlab.com/fluv/fluv/-/blob/develop/mobile/src/models/device.ts#L31-44
export const updateDeviceFcmToken = async (
  userId: string | null = null,
  fcmToken: string | null = null
) => {
  // null means unsubscribe notification
  const { installationId } = Constants;
  const deviceDoc = getDeviceDoc(installationId);
  await setDoc(
    deviceDoc,
    {
      updatedAt: serverTimestamp(),
      userId,
      fcmToken,
      platform: Platform.OS,
    },
    { merge: true }
  );
};

export const updateLanguage = async (language: string): Promise<void> => {
  const { sessionId } = Constants;
  const deviceDoc = getDeviceDoc(sessionId);
  setDoc(
    deviceDoc,
    {
      updatedAt: serverTimestamp(),
      language,
    },
    { merge: true }
  );
};
