import { BreadcrumbJsonLd, NextSeo } from "next-seo";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { ServiceTypeMap } from "../enums";
import {
  TSearchResult,
  getAllServicesSearchResults,
  getServiceSearchResults,
  getSpaServiceSearchResults,
} from "../models";
import { AreaName, ServiceName } from "../models/Service.v1";
import { SearchScreen } from "../screens";
import { getPageNotFound } from "../utils/getPageNotFound";
import { toCanonical } from "../utils/toCanonical";
import { usePromise } from "../hooks";
import { WEB_URL } from "../constant/backend";

export function matchContext({ params }) {
  const [, cityName, areaName, typeName, foundMutipleError] = params.slug;
  return (
    !foundMutipleError &&
    (AreaName.toAreaCode(cityName, areaName) ||
      ServiceName.toServiceType(typeName))
  );
}

export const getServerSideProps = async ({ query }) => {
  const [, cityName, areaName, typeName] = query.slug;
  let areaCode;
  let type;
  let serviceSearchResults;
  try {
    areaCode = AreaName.toAreaCode(cityName, areaName);
  } catch (e) {
    if (AreaName.isAreaCodeError(e)) return getPageNotFound();
    else {
      throw e;
    }
  }
  if (typeName) {
    try {
      type = ServiceName.toServiceType(typeName);
    } catch (e) {
      if (ServiceName.isServiceTypeError(e)) return getPageNotFound();
      else {
        throw e;
      }
    }
    serviceSearchResults = await getServiceSearchResults({
      type,
      areaCode,
      petSize: [],
      petCategory: [],
    });
  } else {
    type = null;
    serviceSearchResults = await getAllServicesSearchResults({
      type,
      areaCode,
      after: parseInt(query.after) || null,
    });
  }

  serviceSearchResults.searchResult.forEach(({ profile: sitterProfile }) => {
    if (sitterProfile.lastCheckedInAt instanceof Date) {
      // Next complains, Reason: `object` ("[object Date]") cannot be serialized as JSON. Please only return JSON serializable data types.
      sitterProfile.lastCheckedInAt =
        sitterProfile.lastCheckedInAt.toISOString() as any;
    }
  });

  return {
    props: {
      isSsr: true,
      route: {
        params: {
          type,
          areaCode,
          size: [],
          category: [],
          serviceSearchResults,
          routeParams: query.slug,
        },
      },
    },
  };
};

// this hoc lives here because:
// this is not a generic hoc that can be used by any component
// the data structure it injects into the wrapped component exists in this file, putting them in the same file makes it more possible that one is changed, the other is changed.
const getSpaServiceSearchResultsDefault = {
  searchResult: [],
  lastService: null,
};
export const withSearchScreenPageProps = (Component: React.ComponentType) => {
  const WithSearchScreenPageProps = ({ route }: any) => {
    const { params } = route;
    const serviceSearchResults = usePromise(
      () =>
        getSpaServiceSearchResults({
          type: params.type,
          areaCode: params.areaCode,
          petSize: Array.isArray(params.size)
            ? params.size
            : params.size.split(",").map(Number),
          petCategory: Array.isArray(params.category)
            ? params.category
            : params.category.split(",").map(Number),
          queryStatus: "first",
        }),
      [params],
      getSpaServiceSearchResultsDefault as TSearchResult
    );
    const names = AreaName.get(params.areaCode);
    const props: any = {
      isFetching: serviceSearchResults === getSpaServiceSearchResultsDefault,
      route: {
        params: {
          ...params,
          isReferred: "ff" in params,
          serviceSearchResults,
          routeParams: [, ...names],
        },
      },
    };
    return <Component {...props} />;
  };
  return WithSearchScreenPageProps;
};

export default function SearchScreenPage(props) {
  const { t } = useTranslation();
  const route = useRouter();
  const { type, routeParams, serviceSearchResults } = props.route.params;
  const [, cityName, areaName] = routeParams;

  serviceSearchResults.searchResult.forEach(({ profile: sitterProfile }) => {
    if (
      typeof sitterProfile.lastCheckedInAt === "string" &&
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(
        sitterProfile.lastCheckedInAt
      )
    ) {
      sitterProfile.lastCheckedInAt = new Date(sitterProfile.lastCheckedInAt);
    }
  });

  const serviceName = ServiceName.getServiceTypeName(type);
  const BREADCRUMB_PATH = "services/台灣";

  return (
    <>
      <NextSeo
        title={
          type
            ? t(`search:metadata_${ServiceTypeMap.get(parseInt(type))}`)
            : t(`search:metadata_title`, { cityName, areaName })
        }
        description={t("search:metadata_description", { cityName, areaName })}
        canonical={
          toCanonical("services", ...route.query.slug) +
          (route.query.after ? "?after=" + route.query.after : "")
        }
        additionalMetaTags={[
          {
            name: "keywords",
            content: t("search:metadata_keywords"),
          },
        ]}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: t("tabs:home"),
            item: `${WEB_URL}/home`,
          },
          {
            position: 2,
            name: `${cityName}${areaName}`,
            item: `${WEB_URL}/${BREADCRUMB_PATH}/${cityName}/${areaName}`,
          },
          {
            position: 3,
            name: serviceName,
            item: `${WEB_URL}/${BREADCRUMB_PATH}/${cityName}/${areaName}/${serviceName}`,
          },
        ]}
      />
      <SearchScreen {...props} />
    </>
  );
}
