import { BaseColor } from "../../config";
import { BaseStyle } from "../../config";
import { CertificateType } from "../../enums";
import { deleteCertificate, TCertificateInput, updateCertificate } from "../../models";
import { Header, Icon } from "../../components";
import { MainStackScreenNavigationProp } from "../../config/typography";
import { Text } from "../../ui";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import CertificateEditableView from "./CertificateEditableView";
import LoadingSpinner from "../../components/LoadingSpinner";
import React, { useCallback, useEffect, useState } from "react";

export default function CertificateUpdateScreen({ route }: any) {
  let { id, type, category, pictureUriOrId } = route.params;
  const { t } = useTranslation();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const [certificateInput, setCertificateInput] = useState<TCertificateInput | null>(null);
  const [defaultInput, setDefaultInput] = useState<TCertificateInput | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      type &&
      (![CertificateType.PET_GROOMER, CertificateType.PET_SITTER].includes(type) || category) &&
      pictureUriOrId
    ) {
      type = Number(type);
      setDefaultInput({ type, category, pictureUriOrId });
    }
  }, [type, category, pictureUriOrId]);

  const handleChangeInput = useCallback((inputValue: TCertificateInput | null) => {
    if (inputValue) {
      setCertificateInput(inputValue);
    }
  }, []);

  const handleUpdateCertificate = useCallback(async () => {
    if (certificateInput?.pictureUriOrId === defaultInput?.pictureUriOrId) {
      navigation.navigate("CertificateListScreen", {});
      return;
    }

    setIsLoading(true);
    try {
      if (certificateInput) {
        await updateCertificate(certificateInput);
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);

    navigation.navigate("CertificateListScreen", {});
  }, [certificateInput]);

  const handleDelete = useCallback(() => {
    const isConfirmed = confirm(
      `${t("certificate:confirm_delete_title")}\n\n${t("certificate:confirm_delete_message")}`
    );

    if (isConfirmed) {
      deleteCertificate(id);
      navigation.navigate("CertificateListScreen", {});
    } 
  }, [id]);

  return (
    <View style={{ flex: 1 }}>
      {defaultInput && (
        <View style={{ flex: 1 }}>
          <LoadingSpinner visible={isLoading} />
          <Header
            style={BaseStyle.menu}
            titleStyle={{ color: BaseColor.whiteColor }}
            title={t("certificate:update_certificate")}
            subTitle={""}
            renderLeft={() => {
              return <Icon name="arrow-left" size={20} color={BaseColor.whiteColor} />;
            }}
            onPressLeft={() => {
              navigation.navigate("CertificateListScreen", {});
            }}
            renderRight={() => {
              return (
                <View>
                  <Text whiteColor bold>
                    {t("update")}
                  </Text>
                </View>
              );
            }}
            onPressRight={handleUpdateCertificate}
          />
          <CertificateEditableView
            onChangeInput={handleChangeInput}
            defaultInput={defaultInput}
            onDelete={handleDelete}
          />
        </View>
      )}
    </View>
  );
}
