import {
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { usePromise, useSubscription } from "../hooks";
import { getCurrentUserId, subscribePets, TPet } from "../models";
import { PetCategory, PetSize } from "../enums";
import { useTranslation } from "react-i18next";
import { BaseColor, useTheme } from "../config";
import { Button, Flex } from "../ui";
import { useNavigation } from "../hooks/useNavigation";
import { useAuth } from "../context/Auth";
import { minWidth } from "../config/theme";
import { MainStackScreenNavigationProp } from "../config/typography";
interface PetModalProps {
  visable: boolean;
  onSelect: (pets: any[]) => void;
  onDismiss: (visable: boolean) => void;
  defaultPet: string[];
  disabledPet: string[];
  serviceId?: string;
  sitterId?: string;
}

const PetModal = React.memo((props: PetModalProps) => {
  const {
    visable,
    onDismiss,
    onSelect,
    defaultPet,
    disabledPet,
    serviceId,
    sitterId,
  } = props;

  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation<MainStackScreenNavigationProp>();
  const [selectPets, setSelectPets] = useState<string[]>(defaultPet);
  let { userId } = useAuth();
  userId = getCurrentUserId();
  // pet
  const pets =
    useSubscription({
      subscription: subscribePets,
      variables: {
        userId,
      },
    }) || [];

  useEffect(() => {
    setSelectPets(defaultPet);
  }, [defaultPet]);

  const handleSelect = () => {
    onSelect(selectPets);
    onDismiss(false);
  };
  const handleClear = () => {
    onDismiss(false);
    onSelect([]);
    setSelectPets([]);
  };

  return (
    <Modal transparent={true} visible={visable}>
      <TouchableWithoutFeedback
        onPress={() => {
          onDismiss(false);
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(33, 33, 33, 0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 15,
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                backgroundColor: "white",
                width: "100%",
                minWidth: minWidth,
                height: "auto",
                maxHeight: "auto",
                paddingTop: 12,
                paddingBottom: 24,
              }}
            >
              <ScrollView style={{ height: "auto", maxHeight: 150 }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {pets.map((pet) => (
                    <View
                      key={pet.id}
                      style={{
                        width: "40%",
                        marginHorizontal: 5,
                        marginVertical: 5,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          if (disabledPet.includes(pet.id)) return;
                          if (selectPets.includes(pet.id)) {
                            setSelectPets(
                              selectPets.filter((item) => item !== pet.id)
                            );
                          } else {
                            setSelectPets((oldPets) => [...oldPets, pet.id]);
                          }
                        }}
                      >
                        <View
                          style={[
                            styles.itemContainer,
                            selectPets.includes(pet.id)
                              ? styles.active
                              : { backgroundColor: colors.card },
                          ]}
                        >
                          <Text
                            style={[
                              selectPets.includes(pet.id)
                                ? { color: BaseColor.color2 }
                                : {},
                              disabledPet.includes(pet.id)
                                ? styles.disableText
                                : {},
                            ]}
                          >
                            {pet.name}
                          </Text>
                          <Text
                            style={[
                              selectPets.includes(pet.id)
                                ? { color: BaseColor.color2 }
                                : {},
                              disabledPet.includes(pet.id)
                                ? styles.disableText
                                : {},
                            ]}
                          >
                            {t(`pets:category_${PetCategory[pet.category]}`)} -{" "}
                            {t(`pets:pet_size_${PetSize[pet.size]}`)}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  ))}
                </View>
              </ScrollView>
              <Flex paddingHorizontal={8} paddingTop={16}>
                <Button
                  onPress={handleSelect}
                  style={{ backgroundColor: colors.accent, borderRadius: 100 }}
                >
                  {t("enum:select")}
                </Button>
              </Flex>
              <Flex paddingHorizontal={8} paddingTop={8}>
                <Button
                  onPress={() => {
                    navigation.navigate("PetProfileManagerScreen", {
                      petId: "",
                      userId,
                      serviceId,
                      sitterId,
                    });
                    onDismiss(false);
                  }}
                  style={{ backgroundColor: "white" }}
                  styleText={{ color: colors.text }}
                >
                  {t("search:add_more_pets")}
                </Button>
              </Flex>
              <Flex paddingHorizontal={8} paddingTop={8}>
                <Button
                  onPress={handleClear}
                  style={{ backgroundColor: "white" }}
                  styleText={{ color: colors.text }}
                >
                  {t("enum:clear")}
                </Button>
              </Flex>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
});

export default PetModal;

const styles = StyleSheet.create({
  itemContainer: {
    borderRadius: 5,
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  active: {
    borderColor: BaseColor.color2,
    borderWidth: 1,
    backgroundColor: "white",
    color: BaseColor.color2,
  },
  disableText: {
    color: '#c7c7cc'
  }
});
