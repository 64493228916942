import React from "react";
import { TextInput, View, StyleProp, ViewStyle } from "react-native";
import { BaseStyle, BaseColor, useTheme } from "../config";

interface TextInputProps {
  style?: StyleProp<ViewStyle>;
  onChangeText: (text: string) => void;
  onFocus: () => void;
  onContentSizeChange?: (e: any) => void;
  placeholder?: string;
  placeholderTextColor?: string;
  value?: any;
  success?: any;
  secureTextEntry?: any;
  keyboardType?: any;
  multiline?: boolean;
  textAlignVertical?: any;
  icon?: any;
  onSubmitEditing?: any;
  numberOfLines?: any;
  textStyle?: any;
}

export default (props: TextInputProps) => {
  const { colors } = useTheme();
  const cardColor = colors.card;
  const {
    style,
    textStyle,
    onChangeText,
    onFocus,
    onContentSizeChange,
    placeholder,
    value,
    success,
    secureTextEntry,
    keyboardType,
    multiline,
    textAlignVertical,
    icon,
    onSubmitEditing,
    numberOfLines,
    placeholderTextColor,
  } = props;
  return (
    <View style={[BaseStyle.textInput, style]}>
      <TextInput
        style={[
          {
            flex: 1,
            height: "100%",
            color: colors.text,
            paddingTop: 5,
            paddingBottom: 5,
          },
          textStyle,
        ]}
        onChangeText={(text) => onChangeText(text)}
        onContentSizeChange={(e) => {
          if (onContentSizeChange === undefined) return;
          onContentSizeChange(e);
        }}
        onFocus={() => onFocus()}
        autoCorrect={false}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        secureTextEntry={secureTextEntry}
        value={value}
        selectionColor={colors.primary}
        keyboardType={keyboardType}
        multiline={multiline}
        numberOfLines={numberOfLines}
        textAlignVertical={textAlignVertical}
        onSubmitEditing={onSubmitEditing}
      />
      {icon}
    </View>
  );
};
