import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { ServiceType } from "../enums";
import { removeEmpty } from "../utils";
import { PrivateAPI } from "./api";
import { getCurrentUserId } from "./auth";
import { TCoupon, toCoupon } from "./coupon";
import { TLocation } from "./location";
import { toPet, TPet } from "./pets";
import { toProfile, TProfile } from "./profile";
import { toService, TService } from "./services";
import { TSubscription } from "./types";
import Service from "./Service.v1";

export type TBreakdown = {
  [petId: string]: {
    basePrice: number;
    discountedBasePrice?: number;
    extra: {
      size?: number;
    };
  };
};

export type TOrderPlace = {
  name?: string;
  address: string;
  doorNumber?: string;
  areaCode?: string;
  location: TLocation;
};

export type TOrder = {
  id: string;
  createdAt: number;
  updatedAt: number;
  place: TOrderPlace;
  dates: string[];
  startHour?: number;
  endHour?: number;
  frames?: number;
  times?: number;
  notes: string;
  couponId?: string;
  providerId: string;
  consumerId: string;
  serviceId: string;
  petIds: string[];
  breakdown?: TBreakdown;
  unconfirmedPrice: number;
  unconfirmedAmount: number;
  // snapshots
  snapshots: {
    providerProfile?: TProfile;
    consumerProfile?: TProfile;
    service?: TService;
    pets: TPet[];
    coupon?: TCoupon;
  };
  price: number;
  amount: number;
  fee: number; // 0 ~ 1
  discount?: number;
  allowance?: number;
  campaignId?: string;
  accepted: boolean; // provider
  acceptedAt?: number;
  paid: boolean; // consumer
  paidAt?: number;
  executed: boolean; // provider
  executedAt?: number;
  checked: boolean; // consumer
  checkedAt?: number;
  cancelled: boolean;
  cancelledBy?: string; // userId or 999: system
  cancelledAt?: number;
  charged: boolean;
  chargedAt?: number;
  changedAmount?: number;
  settled: boolean;
  settledAt?: number;
  refunded: boolean;
  refundedAt?: number;
};

export type TOrderInput = {
  place: TOrderPlace;
  dates: string[];
  startHour?: number;
  endHour?: number;
  frames?: number;
  times?: number;
  notes: string;
  providerId: string;
  serviceId: string;
  petIds: string[];
  breakdown?: TBreakdown;
  unconfirmedAmount: number;
  unconfirmedPrice: number;
};

const toOrder = (
  snapshot: DocumentSnapshot<DocumentData>
): TOrder | undefined => {
  if (!snapshot.data()) return undefined;

  const {
    createdAt,
    updatedAt,
    place,
    dates = [],
    startHour,
    endHour,
    frames,
    times,
    notes,
    couponId,
    providerId,
    consumerId,
    serviceId,
    petIds,
    breakdown,
    unconfirmedPrice,
    unconfirmedAmount,
    snapshots,
    price,
    amount,
    fee = 0,
    discount = 0,
    allowance = 0,
    campaignId,
    accepted,
    acceptedAt,
    paid,
    paidAt,
    executed,
    executedAt,
    checked,
    checkedAt,
    cancelled,
    cancelledBy,
    cancelledAt,
    charged,
    chargedAt,
    changedAmount,
    settled,
    settledAt,
    refunded,
    refundedAt,
  } = snapshot.data() || {};
  const { providerProfile, consumerProfile, service, pets, coupon } =
    snapshots || {};

  return {
    id: snapshot.id,
    createdAt: createdAt?.seconds * 1000,
    updatedAt: updatedAt?.seconds * 1000,
    place,
    dates,
    startHour,
    endHour,
    frames,
    times,
    notes,
    couponId: couponId ?? null,
    providerId,
    consumerId,
    serviceId,
    petIds,
    breakdown,
    unconfirmedPrice,
    unconfirmedAmount,
    snapshots: {
      /* @ts-ignore */
      providerProfile: toProfile({
        id: providerId,
        data: () => providerProfile,
      }),
      /* @ts-ignore */
      consumerProfile: toProfile({
        id: consumerId,
        data: () => consumerProfile,
      }),
      /* @ts-ignore */
      service: toService({
        id: serviceId,
        data: () => service,
      }),
      pets: pets
        ? /* @ts-ignore */
          pets.map((pet, i) =>
            /* @ts-ignore */
            toPet({
              id: petIds[i],
              data: () => pet,
            })
          )
        : [],
      /* @ts-ignore */
      coupon: toCoupon({
        id: couponId,
        data: () => coupon,
      }),
    },
    price,
    amount,
    fee,
    discount,
    allowance,
    campaignId,
    accepted,
    acceptedAt,
    paid,
    paidAt,
    executed,
    executedAt,
    checked,
    checkedAt,
    cancelled,
    cancelledBy,
    cancelledAt,
    charged,
    chargedAt,
    changedAmount,
    settled,
    settledAt,
    refunded,
    refundedAt,
  };
};

export const orderRef = collection(db, "orders");

const getOrderDoc = (orderId: string) => {
  return doc(orderRef, orderId);
};

export const getOrder = async (
  orderId: string
): Promise<TOrder | undefined> => {
  const orderDoc = getOrderDoc(orderId);
  if (!orderDoc) return undefined;

  const snapshot = await getDoc(orderDoc);
  return toOrder(snapshot);
};

export const getLastCheckedOrder = async (
  userId: string
): Promise<TOrder | undefined> => {
  const queryOrder = query(
    orderRef,
    where("consumerId", "==", userId),
    where("checked", "==", true),
    orderBy("createdAt", "desc"),
    limit(1)
  );
  const snapshot = await getDocs(queryOrder);
  const orderDoc = snapshot.docs[0];
  return orderDoc ? toOrder(orderDoc) : undefined;
};

export const subscribeOrders: TSubscription<
  {
    providerId?: string;
    consumerId?: string;
    state?:
      | "processing"
      | "completed"
      | "cancelled"
      | "receivable"
      | "paid"
      | "settled";
  },
  TOrder[]
> = ({ variables, onChange }) => {
  const { providerId, consumerId, state } = variables || {};
  if (!providerId && !consumerId) return () => {};
  let orderQuery = query(orderRef);
  if (providerId) {
    orderQuery = query(orderQuery, where("providerId", "==", providerId));
  }
  if (consumerId) {
    orderQuery = query(orderQuery, where("consumerId", "==", consumerId));
  }
  if (state === "processing") {
    orderQuery = query(
      orderQuery,
      where("checked", "==", false),
      where("cancelled", "==", false)
    );
  } else if (state === "completed") {
    orderQuery = query(
      orderQuery,
      where("checked", "==", true),
      where("cancelled", "==", false)
    );
  } else if (state === "cancelled") {
    orderQuery = query(orderQuery, where("cancelled", "==", true));
  } else if (state === "receivable") {
    orderQuery = query(
      orderQuery,
      where("checked", "==", true),
      where("cancelled", "==", false),
      where("settled", "==", false),
      where("refunded", "==", false)
    );
  } else if (state === "paid") {
    orderQuery = query(
      orderQuery,
      where("paid", "==", true),
      where("cancelled", "==", false)
    );
  } else if (state === "settled") {
    orderQuery = query(
      orderQuery,
      where("settled", "==", true),
      where("cancelled", "==", false)
    );
  }

  orderQuery = query(orderQuery, orderBy("dates", "desc"));

  return onSnapshot(orderQuery, (snapshot) => {
    if (!onChange) return;
    onChange(
      snapshot.docs
        .map((doc) => {
          try {
            return toOrder(doc);
          } catch (e) {
            if (Service.isAreaCodeError(e)) {
              console.debug("TQNR", e, doc.id, doc.data());
              return null;
            }
            throw e;
          }
        })
        .filter(Boolean) as TOrder[]
    );
  });
};

export const subscribeOrder: TSubscription<
  { orderId?: string },
  TOrder | undefined
> = ({ variables, onChange }) => {
  const { orderId } = variables || {};
  if (!orderId) return () => {};
  const orderDoc = getOrderDoc(orderId);

  return onSnapshot(orderDoc, (snapshot) => {
    if (!onChange) return;
    onChange(toOrder(snapshot));
  });
};

export const calculateQuantity = (variables: {
  type: ServiceType;
  dates: string[];
  frames?: number;
  times?: number;
  startHour?: number;
  endHour?: number;
}): number => {
  const { type, dates, frames, times, startHour, endHour } = variables;
  let quantity = 0;
  if (type === ServiceType.DROPIN || type === ServiceType.WALKING) {
    quantity = dates.length * (frames || 1) * (times || 1);
  } else if (
    type === ServiceType.BATHING ||
    type === ServiceType.GROOMING ||
    type === ServiceType.VET_TO_HOME ||
    type === ServiceType.VET_HOSPITAL
  ) {
    quantity = dates.length;
  } else if (type === ServiceType.TAVERN) {
    if (startHour && endHour && dates.length > 0) {
      quantity = Math.ceil((dates.length * 24 - startHour - 24 + endHour) / 12);
    }
  }
  return quantity;
};

export const subscribeCampaignOrderCount: TSubscription<
  { consumerId?: string; campaignId?: string },
  number
> = ({ variables, onChange }) => {
  const { consumerId, campaignId } = variables || {};
  if (!consumerId && !campaignId) return () => {};
  const campaignOrdersCollection = query(
    orderRef,
    where("campaignId", "==", campaignId),
    where("consumerId", "==", consumerId),
    where("cancelled", "==", true)
  );

  return onSnapshot(campaignOrdersCollection, (snapshot) => {
    if (!onChange) return;
    onChange(snapshot.docs.length);
  });
};

export const createOrder = async (
  orderInput: TOrderInput
): Promise<string | null> => {
  const myId = getCurrentUserId() || undefined;
  if (!myId) return null;

  const {
    place,
    dates,
    startHour,
    endHour,
    frames,
    times,
    notes,
    providerId,
    serviceId,
    petIds,
    breakdown,
    unconfirmedAmount,
    unconfirmedPrice,
  } = orderInput;

  const payload = removeEmpty({
    place: removeEmpty(place),
    dates: dates.map((date) => date.replace(/-/g, "")),
    startHour,
    endHour,
    frames,
    times,
    notes,
    providerId,
    consumerId: myId,
    serviceId,
    petIds,
    breakdown,
    unconfirmedAmount,
    unconfirmedPrice,
  });
  const result = await PrivateAPI.post<{ orderId: string }>(
    "createOrder",
    payload
  );
  const { success, data, error } = result || {};
  if (!success) throw new Error(error);
  return data?.orderId || null;
};

export const acceptOrder = async (orderId: string): Promise<void> => {
  const result = await PrivateAPI.post("acceptOrder", { orderId });
  const { success, error } = result || {};
  if (!success) throw new Error(error);
};

export const executeOrder = async (orderId: string): Promise<void> => {
  const result = await PrivateAPI.post("executeOrder", { orderId });
  const { success, error } = result || {};
  if (!success) throw new Error(error);
};

export const checkOrder = async (orderId: string): Promise<void> => {
  const result = await PrivateAPI.post("checkOrder", { orderId });
  const { success, error } = result || {};
  if (!success) throw new Error(error);
};

export const cancelOrder = async (orderId: string): Promise<void> => {
  const result = await PrivateAPI.post("cancelOrder", { orderId });
  const { success, error } = result || {};
  if (!success) throw new Error(error);
};

export const redeemCoupon = async (
  orderId: string,
  couponId: string | null, // if couponId is null, means remove couponId
  unconfirmedPrice: number,
  unconfirmedAmount: number
): Promise<void> => {
  const result = await PrivateAPI.post("redeemCoupon", {
    orderId,
    couponId,
    unconfirmedPrice,
    unconfirmedAmount,
  });
  const { success, error } = result || {};
  if (!success) throw new Error(error);
};

export const changeOrderAmount = async (
  orderId: string,
  amount: number
): Promise<any> => {
  const result = await PrivateAPI.post("changeOrderAmount", {
    orderId,
    amount,
  });
  const { success, error } = result || {};
  if (!success) throw new Error(error);
};
