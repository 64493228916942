import { notificationAdapter } from "../utils";
import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const KEY = "notificationBannerClosedAt";
const HIDDEN_TIME = 24 * 60 * 60 * 1000;

export const useNotificationBannerVisibility = (): [
  boolean,
  boolean,
  () => Promise<void>
] => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isGranted, setIsGranted] = useState<boolean>(false);
  const [closedAt, setClosedAt] = useState<number>(0);

  if (typeof window === "undefined" || typeof Notification === "undefined") {
    return [false, false, async () => {}];
  }
  const notification = notificationAdapter(Notification);

  useEffect(() => {
    const getClosedAt = async () => {
      try {
        const value = await AsyncStorage.getItem(KEY);
        if (value !== null) {
          setClosedAt(Number(value));
        }
      } catch (e) {
        console.error("143Y");
        throw e;
      } finally {
        setIsLoading(false);
      }
    };
    getClosedAt();

    const checkPermission = async () => {
      try {
        const permission = await notification?.getPermissionsAsync();
        setIsGranted(permission === "granted");
      } catch (e) {
        console.error("EG2Q");
        throw e;
      }
    };
    checkPermission();
  }, []);

  const isVisible =
    !isGranted && !isLoading && Date.now() - closedAt > HIDDEN_TIME;

  const onClose = async () => {
    const now = Date.now();
    const nowString = String(now);
    try {
      await AsyncStorage.setItem(KEY, nowString);
      setClosedAt(now);
    } catch (e) {
      console.error("T214");
      throw e;
    }
  };

  return [isLoading, isVisible, onClose];
};
