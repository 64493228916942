import React from "react";
import { ActivityIndicator, Dimensions, StyleSheet, View } from "react-native";

const height = Dimensions.get("window").height;
interface LoadingSpinner {
  visible: boolean;
}

const LoadingSpinner = (props: LoadingSpinner) => {
  const { visible } = props;
  return (
    <>
      {visible ? (
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator />
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

export default LoadingSpinner;

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    width: "100%",
    height: height,
    flex: 1,
    backgroundColor: "black",
    opacity: 0.5,
    position: "absolute",
    zIndex: 1000,
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
});
