import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { TQueryStatus } from "../config/typography";
import { ReviewSide } from "../enums";
import { removeEmpty } from "../utils";
import { getCurrentUserId } from "./auth";
import { getProfileDoc, toProfile, TProfile } from "./profile";
import { TSubscription } from "./types";
import { PrivateAPI } from "./api";

export type TReview = {
  id: string;
  createdAt: number;
  updatedAt: number;
  rating: number;
  comment?: string;
  senderSide: ReviewSide;
  senderId: string;
  hidden: boolean;
  rejected: boolean;
  snapshots: {
    senderProfile?: TProfile;
  };
};

export type TReviewInput = {
  rating: number;
  comment?: string;
  senderSide?: ReviewSide;
  senderId?: string;
  recipientId?: string;
};

export type TReviews = {
  payload: TReview[];
  lastReviews: DocumentSnapshot<DocumentData> | undefined;
};

export const toReview = (snapshot: DocumentSnapshot<DocumentData>) => {
  if (!snapshot.data()) return null;

  const {
    createdAt,
    updatedAt,
    rating,
    comment,
    senderSide,
    senderId,
    hidden,
    rejected,
    snapshots,
  } = snapshot.data() || {};
  const { senderProfile } = snapshots || {};

  return {
    id: snapshot.id,
    createdAt: createdAt?.seconds * 1000,
    updatedAt: updatedAt?.seconds * 1000,
    rating,
    comment: comment || null,
    senderSide: senderSide || null,
    senderId,
    hidden,
    rejected: rejected || null,
    snapshots: {
      /* @ts-ignore */
      senderProfile: toProfile({
        id: senderId,
        data: () => senderProfile,
      }),
    },
  };
};

const reviewsRef = (userId: string) => {
  return collection(getProfileDoc(userId), "reviews");
};

const getReviewDoc = (userId: string, reviewId: string) => {
  return doc(reviewsRef(userId), reviewId);
};

export const getReview = async (
  userId: string,
  reviewId: string
): Promise<TReview | null> => {
  const reviewDoc = getReviewDoc(userId, reviewId);
  if (!reviewDoc) return null;

  const snapshot = await getDoc(reviewDoc);
  return toReview(snapshot);
};

const reviewQuery = (userId: string) => {
  return query(
    reviewsRef(userId),
    where("hidden", "==", false),
  );
};

// Duplicate code needs refactor
export const getReviews = async (userId: string) => {
  const queryReviews = reviewQuery(userId);
  const reviews = await getDocs(queryReviews);
  return reviews.docs.map(toReview);
};

export const subscribeReviews: TSubscription<
  { userId: string; queryStatus?: TQueryStatus; lastReview?: any },
  { payload: TReview[]; lastReviews: DocumentSnapshot<DocumentData> }
> = ({ variables, onChange }) => {
  const { userId, queryStatus, lastReview } = variables || {};
  if (!userId) return () => {};
  let queryReview = reviewQuery(userId);
  if (queryStatus === "next") {
    queryReview = query(queryReview, startAfter(lastReview));
  }
  return onSnapshot(queryReview, (snapshot) => {
    if (snapshot.size <= 0) return;
    if (!onChange) return;
    onChange({
      payload: snapshot.docs
        .map(toReview)
        .sort((low, high) => high.updatedAt - low.updatedAt)
        .filter(Boolean) as TReview[],
      lastReviews: snapshot.docs[snapshot.docs.length - 1],
    });
  });
};

export const subscribeReview: TSubscription<
  { userId?: string; reviewId?: string },
  TReview | undefined
> = ({ variables, onChange }) => {
  const { userId, reviewId } = variables || {};
  if (!userId || !reviewId) return () => {};
  const reviewDoc = getReviewDoc(userId, reviewId);
  return onSnapshot(reviewDoc, (snapshot) => {
    if (!onChange) return;
    onChange(toReview(snapshot));
  });
};

export const createReview = async (
  reviewId: string,
  reviewInput: TReviewInput
): Promise<string | null> => {
  const myId = getCurrentUserId();
  if (!myId) return null;

  const { rating, comment, senderSide, senderId, recipientId } = reviewInput;
  const payload = removeEmpty({
    reviewId,
    rating,
    comment,
    senderSide,
    senderId,
    recipientId,
  });
  const result = await PrivateAPI.post<{ reviewId: string }>(
    "createReview",
    payload
  );
  const { success, data, error } = result || {};
  if (!success) throw new Error(error);
  return data?.reviewId || null;
};

export const rejectReview = async (
  reviewId: string,
  senderId: string,
  recipientId: string
): Promise<void> => {
  const myId = getCurrentUserId();
  if (!myId || myId === "") return;
  const reviewDoc = getReviewDoc(recipientId, reviewId);

  await setDoc(
    reviewDoc,
    {
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      senderId,
      hidden: true,
      rejected: true,
    },
    { merge: true }
  );
};
