import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import appenv from "../appenv";
import { browserPopupRedirectResolver, initializeAuth } from "firebase/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  staging: {
    apiKey: "AIzaSyCD6HedQDuBXOpDwgdLs48C714oVDBE5J8",
    authDomain: "fluv-prod.firebaseapp.com",
    databaseURL: "https://fluv-prod.firebaseio.com",
    projectId: "fluv-prod",
    storageBucket: "fluv-prod.appspot.com",
    messagingSenderId: "958791800397",
    appId: "1:958791800397:web:9857d397259c62201d2e04",
    measurementId: "G-HFWWL61X37",
  },
  prod: {
    apiKey: "AIzaSyCD6HedQDuBXOpDwgdLs48C714oVDBE5J8",
    authDomain: "fluv-prod.firebaseapp.com",
    databaseURL: "https://fluv-prod.firebaseio.com",
    projectId: "fluv-prod",
    storageBucket: "fluv-prod.appspot.com",
    messagingSenderId: "958791800397",
    appId: "1:958791800397:web:9857d397259c62201d2e04",
    measurementId: "G-HFWWL61X37",
  },
};

const app = initializeApp(
  appenv.isProd ? firebaseConfig.prod : firebaseConfig.staging
);  

/**
 * 1. Because persistence and popupRedirectResolver breaks SSR, so we only initialize auth in client side 
 * 2. Because dynamic import will raise Firebase error auth/already-initialized, use require instead
 */ 
let deps;
if (typeof window !== "undefined") {
  const AsyncStorage = require("@react-native-async-storage/async-storage").default;
  const { getReactNativePersistence } = require("firebase/auth/react-native");
  deps = {
    persistence: getReactNativePersistence(AsyncStorage),
    popupRedirectResolver: browserPopupRedirectResolver,
  }
}
const auth = initializeAuth(app, deps);

const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
