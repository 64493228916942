import type { DependencyList } from "react";
import { usePromise } from "./promise";
import { useAuth } from "../context/Auth";

export const useAuthReady = <T>(onReady: () => T, deps: DependencyList, init?: T) => {
  const { loading, userId, realUserId } = useAuth();
  const privateResult = usePromise<T>(
    async () => (userId && realUserId && loading ? onReady() : null),
    [userId, realUserId, loading, ...deps],
    init
  );
  const publicResult = usePromise<T>(
    async () => onReady(),
    [],
    init
  );
  return privateResult ?? publicResult;
};
