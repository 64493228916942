import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Icon } from "../components";
import { Text } from "../ui";
import Modal from "react-native-modal";
import { BaseColor, useTheme, DefaultFont } from "../config";
import { useTranslation } from "react-i18next";
import dynamic from "next/dynamic";

// We need `{ssr: false}` otherwise there'll be a "Cannot use import statement outside a module" error.
const Calendar: any = dynamic(
  import("react-native-calendars").then((module) => module.Calendar),
  { ssr: false }
);

export default function DatePicker(props: any) {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const [selected, setSelected] = useState(props.selected);
  const [markedDates, setMarkedDates] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [renderCalendar, setRenderCalendar] = useState(true);

  useEffect(() => {
    setRenderCalendar(false);
    setTimeout(() => {
      setRenderCalendar(true);
    }, 250);
  }, [colors.card]);

  useEffect(() => {
    let marked: any = {};
    marked[props.selected] = {
      selected: true,
      marked: true,
      selectedColor: colors.primary,
    };
    setMarkedDates(marked);
  }, [colors.primary, props.selected]);

  const setDaySelected = (selected: any) => {
    let marked: any = {};
    marked[selected] = {
      selected: true,
      marked: true,
      selectedColor: colors.primary,
    };
    setMarkedDates(marked);
    setSelected(selected);
  };

  const { style, label, minDate, maxDate, onCancel, onChange } = props;

  return (
    <View
      style={[styles.contentPickDate, { backgroundColor: colors.card }, style]}
    >
      <Modal
        isVisible={modalVisible}
        backdropColor="rgba(0, 0, 0, 0.5)"
        backdropOpacity={1}
        animationIn="fadeIn"
        animationInTiming={600}
        animationOutTiming={600}
        backdropTransitionInTiming={600}
        backdropTransitionOutTiming={600}
      >
        <View
          style={[styles.contentCalendar, { backgroundColor: colors.card }]}
        >
          {renderCalendar && (
            <Calendar
              style={{
                borderRadius: 8,
                backgroundColor: colors.card,
              }}
              renderArrow={(direction: any) => {
                return (
                  <Icon
                    name={direction == "left" ? "angle-left" : "angle-right"}
                    size={14}
                    color={colors.primary}
                  />
                );
              }}
              markedDates={markedDates}
              current={selected}
              minDate={minDate}
              maxDate={maxDate}
              onDayPress={(day: any) => setDaySelected(day.dateString)}
              monthFormat={"dd-MM-yyyy"}
              theme={{
                calendarBackground: colors.card,
                textSectionTitleColor: colors.text,
                selectedDayBackgroundColor: colors.primary,
                selectedDayTextColor: "#ffffff",
                todayTextColor: colors.primary,
                dayTextColor: colors.text,
                textDisabledColor: BaseColor.grayColor,
                dotColor: colors.primary,
                selectedDotColor: "#ffffff",
                arrowColor: colors.primary,
                monthTextColor: colors.text,
                textDayFontFamily: DefaultFont,
                textMonthFontFamily: DefaultFont,
                textDayHeaderFontFamily: DefaultFont,
                textMonthFontWeight: "bold",
                textDayFontSize: 14,
                textMonthFontSize: 16,
                textDayHeaderFontSize: 14,
              }}
            />
          )}
          <View style={styles.contentActionCalendar}>
            <TouchableOpacity
              onPress={() => {
                setModalVisible(false);
                setSelected(props.selected);
                onCancel();
              }}
            >
              <Text body1>{t("cancel")}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setModalVisible(false);
                onChange(selected);
              }}
            >
              <Text body1 primaryColor>
                {t("done")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <TouchableOpacity
        style={styles.itemPick}
        onPress={() => setModalVisible(true)}
      >
        <Text caption1 light style={{ marginBottom: 5 }}>
          {label}
        </Text>
        <Text headline semibold numberOfLines={1}>
          {selected}
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  contentPickDate: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    padding: 10,
  },
  itemPick: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  contentCalendar: {
    borderRadius: 8,
    width: "100%",
  },
  contentActionCalendar: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 15,
  },
});
