import { ServiceType } from "../enums";

type PictureDoc = [string, string, string];

const DEFAULT_PICTURES_DOC: PictureDoc = ['Cover photo', 'Add more service photos', ''];

const PICTURES_DOC: Record<ServiceType, PictureDoc> = {
  [ServiceType.DROPIN]: ['Select the photo with the pet', 'Add another pet service photo', 'https://bit.ly/3prk0B1'],
  [ServiceType.TAVERN]: [
    'Select the home environment photo',
    'Add another home and pet photo',
    'https://bit.ly/3puWVxu',
  ],
  [ServiceType.WALKING]: [
    'Select a photo with the pet for a walk',
    'Add another pet walking photo',
    'https://bit.ly/3VPGMyu',
  ],
  [ServiceType.BATHING]: ['Select a pet bath work photo', 'Add another pet bathing photo', 'https://bit.ly/42pPnKM'],
  [ServiceType.GROOMING]: ['Select a pet grooming work photo', 'Add another pet grooming photo', 'https://bit.ly/41jJwoZ'],
  [ServiceType.VET_TO_HOME]: DEFAULT_PICTURES_DOC,
  [ServiceType.VET_HOSPITAL]: DEFAULT_PICTURES_DOC,
};

const PICTURES_TEXT_LINK_IDX = 2;

export const getPicturesText = (type: ServiceType | undefined, pictureUrisOrIds: string[]): string =>
  (type === undefined ? DEFAULT_PICTURES_DOC : PICTURES_DOC[type])[Math.min(1, pictureUrisOrIds.length)];

export const getPicturesLink = (type: ServiceType | undefined): string =>
  (type === undefined ? DEFAULT_PICTURES_DOC : PICTURES_DOC[type])[PICTURES_TEXT_LINK_IDX];

export const hasPicturesLink = (type: ServiceType | undefined): boolean => getPicturesLink(type) !== ''
