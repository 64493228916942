import {
  FlatList,
  ListRenderItem,
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSubscription } from "../../hooks";
import { subscribeCoupon, subscribeCoupons, TCoupon } from "../../models";
import { useAuth } from "../../context/Auth";
import { TextInput } from "../../ui";
import { BaseColor, useTheme } from "../../config";
import CouponItem from "./CouponItem";
import { minWidth } from "../../config/theme";

interface CouponSelectModalProps {
  visible: boolean;
  onSelect: (coupon: string) => void;
  onDismiss: (visible: boolean) => void;
  serviceType: any;
  userId: string;
}

const CouponSelectModal = React.memo<CouponSelectModalProps>((props) => {
  const { visible, onDismiss, onSelect, userId, serviceType } = props;
  const { colors } = useTheme();
  const { t } = useTranslation();
  const [couponId, setCouponId] = useState("");
  const [searchCouponId, setSearchCouponId] = useState<string>("");

  const coupons = useSubscription({
    subscription: subscribeCoupons,
    variables: {
      userId,
      available: true,
    },
  });

  const searchCoupon = useSubscription({
    subscription: subscribeCoupon,
    variables: {
      serviceType,
      userId,
      couponId: searchCouponId,
    },
  });

  const data = useMemo(() => {
    if (
      !searchCoupon ||
      !searchCouponId ||
      coupons?.map((c) => c.id).includes(searchCouponId)
    )
      return coupons;
    return [searchCoupon, ...(coupons || [])];
  }, [coupons, searchCouponId, searchCoupon]);

  const renderItem: ListRenderItem<TCoupon> = useCallback(({ item }) => {
    return (
      <CouponItem
        {...item}
        allowsSelection={false}
        selected={false}
        onPress={(couponId) => {
          setCouponId(couponId);
        }}
      />
    );
  }, []);

  useEffect(() => {
    if (couponId === "") return;
    onSelect(couponId);
    setCouponId("");
    onDismiss(false);
  }, [couponId]);

  return (
    <Modal transparent={true} visible={visible}>
      <TouchableWithoutFeedback
        onPress={() => {
          onDismiss(false);
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(33, 33, 33, 0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 15,
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                backgroundColor: colors.card,
                width: "100%",
                minWidth: minWidth,
                height: 400,
                maxHeight: 400,
                paddingTop: 12,
                paddingBottom: 24,
                paddingHorizontal: 15,
              }}
            >
              <View style={{ marginBottom: 5 }}>
                <Text
                  style={{ color: BaseColor.warningColor, marginBottom: 5 }}
                >
                  {t("coupon:select_coupon")}
                </Text>
                <TextInput
                  onChangeText={(text) => {
                    if (text.split("#").length === 1) {
                      setSearchCouponId(`#${text.toUpperCase()}`);
                    } else {
                      setSearchCouponId(text.toUpperCase());
                    }
                  }}
                  onFocus={() => {}}
                  placeholder={t("coupon:enter_coupon_code")}
                  value={`${searchCouponId}`}
                  style={{
                    borderColor: BaseColor.dividerColor,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                  }}
                />
              </View>
              {<FlatList data={data} renderItem={renderItem} />}
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
});

export default CouponSelectModal;

const styles = StyleSheet.create({});
