import React, { useCallback } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Icon } from "../components";
import { Text, Image } from "../ui";
import { useTheme, Images } from "../config";
import { useTranslation } from "react-i18next";
import { getPetPictureUrl, TPet } from "../models";
import { PetCategory, PetGender, PetPersonality, PetSize } from "../enums";
import { age } from "../utils/date";

type Props = TPet & {
  userId: string;
  orderId?: string;
  allowsSelection?: boolean;
  style?: any;
  onPress?: () => void;
};

export default function PetItem(props: Props) {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const {
    userId,
    orderId,
    allowsSelection,
    id,
    avatarId,
    name,
    category,
    size,
    birthYear,
    birthMonth,
    gender,
    healthStatus,
    personalities,
    neuteredOrSpayed,
    regularVaccination,
    onPress,
    style,
  } = props;

  const avatarUrl = getPetPictureUrl(userId, avatarId);
  const handleDetail = useCallback(() => {}, [userId, orderId, id]);

  return (
    <TouchableOpacity
      disabled={!allowsSelection}
      style={[styles.contain, style]}
      onPress={onPress}
      activeOpacity={0.9}
    >
      <View>
        <View style={{ flexDirection: "column", width: "100%" }}>
          <View style={[styles.mainContent]}>
            <View style={{ flex: 2 }}>
              <Image
                source={{ uri: avatarUrl }}
                style={{
                  width: 48,
                  height: 48,
                  borderRadius: 24,
                  marginRight: 10,
                }}
                resizeMode={"cover"}
              />
            </View>
            <View style={{ flexDirection: "column", flex: 7 }}>
              <Text style={{ marginBottom: 5 }}>
                <Text headline semibold>
                  {name}
                </Text>
                <Text> </Text>
                <Text> </Text>
                <Text>
                  {t(`pets:emoji_category_${PetCategory[category]}`)}{" "}
                  {t(`enum:age`, { age: age({ birthYear, birthMonth }) })},{" "}
                  {t(`pets:pet_gender_${PetGender[gender]}`)},{" "}
                  {t(`pets:pet_size_${PetSize[size]}`)}
                </Text>
              </Text>
              <Text footnote darkPrimaryColor style={{ marginBottom: 3 }}>
                {personalities
                  .map((p) => t(`enum:pet_personality_${PetPersonality[p]}`))
                  .join(", ")}
              </Text>
              <Text accentColor footnote style={{ marginBottom: 5 }}>
                {t(`pets:pet_neutered_or_spayed_${String(neuteredOrSpayed)}`)},{" "}
                {t(
                  `pets:pet_regular_vaccination_${String(regularVaccination)}`
                )}
              </Text>
              <Text
                footnote
                regular
                numberOfLines={3}
                ellipsizeMode={"head"}
                style={{ width: "100%" }}
              >
                {healthStatus}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon name="angle-right" color={colors.border} size={18} />
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contain: {},
  nameContent: {
    alignItems: "flex-start",
    paddingHorizontal: 10,
    paddingVertical: 8,
  },
  validContent: {
    flexDirection: "row",
    paddingHorizontal: 12,
    paddingVertical: 7,
    justifyContent: "space-between",
  },
  mainContent: {
    paddingHorizontal: 12,
    paddingVertical: 20,
    flexDirection: "row",
    flex: 1,
  },
});
