import { TSubscription, TSubscriptionAsync } from "../models";
import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual } from "shallow-equal-object";

type Props<V, D> = {
  subscription: TSubscription<V, D> | TSubscriptionAsync<V, D>;
  variables?: V;
  limit?: number;
  onAdd?: (data: D | null) => void;
  onRemove?: (data: D | null) => void;
  onModify?: (data: D | null) => void;
  onChange?: (data: D | null) => void;
  size?: number;
};

const usePrevious = (value: unknown) => {
  const ref = useRef<unknown>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useSubscription = <V, D>(
  props: Props<V, D>,
  init?: D
): D | null | undefined => {
  const {
    subscription,
    variables,
    limit,
    onAdd,
    onRemove,
    onModify,
    onChange,
    size,
  } = props;
  const prevVariables = usePrevious(variables);
  const prevLimit = usePrevious(limit);
  const preSize = usePrevious(size);
  const prevOnAdd = usePrevious(onAdd);
  const prevOnRemove = usePrevious(onRemove);
  const prevOnModify = usePrevious(onModify);
  const prevOnChange = usePrevious(onChange);
  const [data, setData] = useState<D | null | undefined>(init); // init value must be undefined because return value may be null

  const shouldSubscribeAgain =
    !shallowEqual(variables || {}, prevVariables) ||
    limit !== prevLimit ||
    size !== preSize ||
    onAdd !== prevOnAdd ||
    onRemove !== prevOnRemove ||
    onModify !== prevOnModify ||
    onChange !== prevOnChange;

  const unsubscribeRef = useRef<any>();
  const unsubscribe = useCallback(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
    }
  }, [unsubscribeRef.current]);

  const updateData = useCallback(
    (newData) => {
      if (onChange) onChange(newData);
      setData(newData);
    },
    [onChange]
  );

  useEffect(() => {
    if (shouldSubscribeAgain) {
      unsubscribe();

      if (data) {
        updateData(undefined);
      }

      unsubscribeRef.current = subscription({
        variables,
        limit,
        size,
        onAdd,
        onRemove,
        onModify,
        onChange: (newData) => {
          updateData(newData);
        },
      });
    }
  }, [
    subscription,
    variables,
    limit,
    size,
    onAdd,
    onRemove,
    onModify,
    onChange,
  ]);

  // useEffect(() => unsubscribe, []);

  return data;
};
