import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { storage } from "../config/firebase";
import { FIREBASE_STORAGE_BUCKET } from "../constant/backend";

export const getStoragePublicUrl = (path: string): string => {
  return `https://firebasestorage.googleapis.com/v0/b/${FIREBASE_STORAGE_BUCKET}/o/${encodeURIComponent(
    path
  )}?alt=media`;
};

export const getStoragePrivateUrl = async (path: string) => {
  const storage = getStorage();
  return await getDownloadURL(ref(storage, path));
};

// export const getStoragePrivateUrl = async (path: string): Promise<string> => {
//   const docRef = ref(storage, path);
//   return docRef.fullPath;
// };

export const getPictureSize = (width: number): string => {
  const sizes = [1280, 960, 720, 480, 320];
  return String(
    sizes.reduce((result, size) => {
      if (width > size) return sizes[3];
      if (size > width) return size;
      return result;
    }, 0)
  );
};
