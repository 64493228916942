// Copy from https://gitlab.com/fluv/fluv/-/blob/main/mobile/src/constants/certificates.ts#L1-35
import { CertificateType } from '../enums';

export const CERTIFICATE_CATEGORIES = {
  [CertificateType.PCRC]: [] as string[],
  [CertificateType.PET_CARER_LICENSE]: [] as string[],
  [CertificateType.PET_SITTER]: [
    'STANDARD',
    'ADVANCED'
  ],
  [CertificateType.BUSINESS_PERMIT]: [] as string[],
  [CertificateType.SUPER_SITTER]: [] as string[],
  [CertificateType.VETERINARIAN]: [] as string[],
  [CertificateType.VETERINARIAN_PRACTICE]: [] as string[],
  [CertificateType.VETERINARY_AIDE]: [] as string[],
  [CertificateType.VET_ASSISTANT]: [] as string[],
  [CertificateType.PET_GROOMER]: [
    // 國家丙級技術士證照
    'ROC_C', // 社團法人台灣畜犬協會 (KCT)
    'KCT_C',
    'KCT_B',
    'KCT_A',
    'KCT_AS', // 中華民國愛犬美容協會 (PGA)
    'PGA_C',
    'PGA_B',
    'PGA_A', // 寵物美容協會 (TGA)
    'TGA_C',
    'TGA_B',
    'TGA_A',
    'TGA_AA',
    'TGA_AAA',
    'TGA_AAAA', // others
    'OTHERS',
  ],
  [CertificateType.PET_TRAINER]: [] as string[],
};
