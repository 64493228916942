import moment from "moment";

import "moment/locale/zh-tw";

moment.locale("zh-tw");

export const dateFromNow = (date: Date): string => {
  const diffDays = moment().diff(date, "days");
  const diffYears = moment().diff(date, "years");

  if (diffDays === 0) {
    return moment(date).format("HH:mm");
  }
  if (diffDays === 1) {
    return moment(date)
      .calendar({
        // FIXME: probably deprecated
        /* @ts-ignore */
        lastDay: "[Yesterday]",
      })
      .split(" ")[0];
  }
  if (diffDays < 7) {
    return moment(date).format("dddd");
  }
  if (diffYears === 0) {
    return moment(date).format("MM/DD");
  }
  return moment(date).format("YYYY/MM/DD");
};
