export interface TSortOptions {
  value: string;
  icon: string;
  text: string;
}
export const SortOptions = [
  {
    value: "low_price",
    icon: "sort-amount-up",
    text: "lowest_price",
  },
  {
    value: "hight_price",
    icon: "sort-amount-down",
    text: "hightest_price",
  },
  {
    value: "round_distance",
    icon: "sort-amount-down",
    text: "round_distance",
  },
  {
    value: "popular",
    icon: "sort-amount-down",
    text: "popularity",
  },
];

export const SortSelected = {
    value: 'popular',
    icon: 'sort-amount-down',
    text: 'popularity',
}
