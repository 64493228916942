// import { Platform } from 'react-native';
// import firestore/*, { FirebaseFirestoreTypes }*/ from '@react-native-firebase/firestore';
// import { NativeFirebaseError } from '@react-native-firebase/app/lib/internal';
import {
  FirestoreError,
  collection,
  doc,
  getDoc,
} from "firebase/firestore";
// import {
//   DocumentData,
//   DocumentSnapshot,
//   getDocs,
//   onSnapshot,
//   query,
//   serverTimestamp,
//   setDoc,
//   where,
//   deleteField,
// } from "firebase/firestore";
import { db } from "../config/firebase";
// import { auth } from "../config/firebase";

// import { TSubscription } from './types';

// export type TVersion = {
//   minimun: string;
// };

// const toVersion = (snapshot: FirebaseFirestoreTypes.DocumentSnapshot): TVersion | null => {
//   if (!snapshot.data()) return null;
//
//   const { minimum } = snapshot.data()?.[Platform.OS] || {};
//
//   return {
//     minimun: minimum || '0.0.0',
//   };
// };

export const configRef = collection(db, "_configs");
export const getConfigDoc = (name: string) => doc(configRef, name);

// export const getVersion = async (): Promise<TVersion | null> => {
//   const versionDoc = getConfigDoc('version');
//   if (!versionDoc) return null;
//
//   const snapshot = await versionDoc.get();
//   return toVersion(snapshot);
// };

// export const subscribeVersion: TSubscription<unknown, TVersion> = ({ onChange }) => {
//   const versionDoc = getConfigDoc('version');
//
//   return versionDoc.onSnapshot((snapshot) => {
//     const version = toVersion(snapshot);
//     if (!onChange) return;
//     onChange(version);
//   });
// };

export interface FeatureFlag {
  id: string;
  enabled: boolean;
  note: string;
}

const getFeatureFlags = () => getDoc(getConfigDoc("featureFlags"));

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const isFirebaseError = (e: any) => e instanceof FirestoreError;

export const isFeatureEnabled = async (id: FeatureFlag["id"]): Promise<boolean> => {
  let featureFlags;
  try {
    featureFlags = await getFeatureFlags();
  } catch (e) {
    if (isFirebaseError(e)) {
      console.error("Q383", e);
      return false;
    }
    throw e;
  }
  if (!featureFlags.exists) {
    console.error("4ASC", featureFlags);
    return false;
  }

  const maybeFeatureFlag = featureFlags.get(id) as unknown as FeatureFlag;
  if (!maybeFeatureFlag) {
    console.error("BWB7", featureFlags);
    return false;
  }
  if (typeof maybeFeatureFlag !== "object") {
    console.error("Q955", maybeFeatureFlag);
    return false;
  }
  if (typeof maybeFeatureFlag.enabled !== "boolean") {
    console.error("9Y5C", maybeFeatureFlag);
    return false;
  }

  return maybeFeatureFlag.enabled;
};
