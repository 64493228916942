import React from "react";
import { StyleSheet, View } from "react-native";
import { BaseColor, useTheme } from "../config";

export default React.memo((props: any) => {
  const { children } = props;
  const { colors } = useTheme();

  const finalStyle = [styles.root, { borderColor: colors.border }];

  return <View style={finalStyle}>{children}</View>;
});

const styles = StyleSheet.create({
  root: {
    backgroundColor: "#ddd",
    height: StyleSheet.hairlineWidth,
    marginVertical: 3,
    borderTopWidth: 1,
    // borderColor: BaseColor.dividerColor
  },
});
